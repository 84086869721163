import { useMutation, useQuery } from "react-query";
import {
  GetDocumentDetailsByContactId,
  PutUpdateRestoreArchiveDocument,
  PostActorDocumentViewDetail,
  PostActorUploadDocument,
  GetDocumentType,
  PostShareDocumentViaMail,
} from "../../services/Documents";

//Get actor document details by tenancyId
export const useGetDocumentDetailsByContactId = (
  contract_id,
  actor_id,
  type
) => {
  // console.log({actor_id,
  //   contract_id,
  //   type})
  return useQuery(
    ["qGetActorDocumnetsByContractId", contract_id, actor_id, type],
    () => GetDocumentDetailsByContactId(contract_id, actor_id, type),
    {
      enabled: !!contract_id,
    },
    {
      enabled: !!actor_id,
    },
    {
      enabled: !!type,
    }
  );
};

// Put Update restore archive documents
export const useUpdateRestoreArchiveDocuments = () => {
  return useMutation(PutUpdateRestoreArchiveDocument);
};

//  Post Actor Document View Detail

export const usePostActorDocumentViewDetail = () => {
  return useMutation(PostActorDocumentViewDetail);
};

/// post upload document
export const usePostActorDocumentUpload = () => {
  return useMutation(PostActorUploadDocument);
};
/// post share document
export const usePostActorDocumentShare = () => {
  return useMutation(PostShareDocumentViaMail);
};

// Get Document Type
export const useGetDocumentType = (DoctyType) => {
  // console.log("DoctSau",DoctyType)
  return useQuery(
    ["getDocumentType", DoctyType],
    () => GetDocumentType(DoctyType),
    { enabled: !!DoctyType }
  );
};
