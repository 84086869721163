import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Image, InputGroup } from "react-bootstrap";
import managementLogo from "../../assets/images/management-logo.svg";
import loginImagePattern from "../../assets/images/login-image-pattern.svg";
import { useForm } from "react-hook-form";
import PasswordValidation from "../create-user/PasswordValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetActorBasicDetails,
  useResetActorPassword,
} from "../../hooks/queries/useActorQueries";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { H1 } from "../../styles/Common.style";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const reser_id = searchParams.get("reset_id");
  //------------------Validation----------------------//
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enterPassword: "",
      confirmPassword: "",
    },
  });
  //---------------------States------------------------//
  const [showPassword, setShowPassword] = useState(false);
  const [formValue, setFormValue] = useState({
    enterPassword: getValues("enterPassword"),
    confirmPassword: getValues("confirmPassword"),
  });

  // strong password
  const [isStrong, initRobustPassword] = useState(null);
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };
  // end strong password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handelconfirmpassword = () => {
    setShowConfirmPassword(true);
  };

  //---------------------React query-------------------//
  const { data: getActorBasicDetailsData } = useGetActorBasicDetails(reser_id);
  const { mutateAsync: PutResetActorPassword } = useResetActorPassword();

  useEffect(() => {
    sessionStorage.clear();
    return () => {};
  }, []);

  //-------------------------handle reset actor password-------------------//
  const handleSavePassword = async (data) => {
    const resetPasswordPayload = {
      actor_id: String(getActorBasicDetailsData?.data?.[0]?.actor_id),
      new_pass: data?.confirmPassword,
    };

    try {
      const response = await PutResetActorPassword(resetPasswordPayload);
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div
      className="pt-4 pb-3 bg-login d-flex justify-content-center align-items-center px-4"
      style={{ position: "relative" }}
    >
      <div>
        {" "}
        <img
          src={managementLogo}
          alt="management-logo"
          className="management-logo d-block m-auto"
        />
        <Form onSubmit={handleSubmit(handleSavePassword)}>
          <Row className="mb-1">
            <Col sm={12} className="m-auto">
              <Row className="loginForm mt-3 mb-3">
                <Col sm={6} md={6} className="login-right-section p-md-4 p-2">
                  <H1>Reset your password</H1>
                  <div className="">
                    <div className="form-group">
                      <label className="pt-3 pb-2 medium-text">
                        New password
                      </label>
                      {/* <input
                        style={{ border: "1px solid #ADBE9E" }}
                        className="d-block w-100 radius-6"
                        name="password"
                        type="text"
                        id="password"
                      /> */}
                      <InputGroup>
                        <input
                          style={{
                            color: !showPassword
                              ? "black"
                              : "rgba(17, 23, 71, 0.50)",
                          }}
                          className="d-block w-100 radius-6"
                          id="password"
                          name="password"
                          placeholder="Enter password"
                          type={showPassword ? "text" : "password"}
                          {...register("enterPassword", {
                            required: true,
                            minLength: {
                              value: 8,
                              message: "min length is 8",
                            },
                            onChange: (e) => {
                              setFormValue({
                                ...formValue,
                                enterPassword: e.target.value,
                              });
                            },
                          })}
                        />
                        <div
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            border: "none",
                          }}
                          onClick={() => setShowPassword((prev) => !prev)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                          ) : (
                            <FontAwesomeIcon icon="fa-regular fa-eye" />
                          )}
                        </div>
                      </InputGroup>
                      {formValue.enterPassword == "" ? (
                        <>
                          {errors.enterPassword ? (
                            <p className="error-msg pt-0 ps-3">
                              This field is required
                            </p>
                          ) : (
                            <p className="error-msg pt-0 ps-3 color-white">
                              This field is required
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {formValue.enterPassword.length >= 8 ? (
                            <p className="error-msg pt-0 ps-3 color-white">
                              Password should be minimum 8 characters
                            </p>
                          ) : (
                            <p className="error-msg pt-0 ps-3">
                              Password should be minimum 8 characters
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="pt-4 pb-2 medium-text">
                        Confirm password
                      </label>
                      {/* <input
                        style={{ border: "1px solid #ADBE9E" }}
                        className="d-block w-100 radius-6"
                        name="password"
                        type="text"
                        id="confirm password"
                      /> */}
                      <InputGroup>
                        <input
                          style={{
                            color: !showConfirmPassword
                              ? "black"
                              : "rgba(17, 23, 71, 0.50)",
                          }}
                          className="d-block w-100 radius-6  "
                          id="password"
                          name="password"
                          placeholder="Confirm password"
                          type={showConfirmPassword ? "text" : "password"}
                          {...register("confirmPassword", {
                            required: true,
                            validate: (value) =>
                              value === getValues().enterPassword ||
                              "Password does not match",
                            onChange: (e) => {
                              setFormValue({
                                ...formValue,
                                confirmPassword: e.target.value,
                              });
                            },
                          })}
                        />
                        <div
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            border: "none",
                          }}
                          // onClick={handelconfirmpassword}
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showConfirmPassword ? (
                            <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                          ) : (
                            <FontAwesomeIcon icon="fa-regular fa-eye" />
                          )}
                        </div>
                      </InputGroup>
                      {formValue.confirmPassword == "" ? (
                        <>
                          {errors.confirmPassword ? (
                            <p className="error-msg pt-0 ps-3">
                              This field is required
                            </p>
                          ) : (
                            <p className="error-msg color-white pt-0 ps-3">:</p>
                          )}
                        </>
                      ) : (
                        <>
                          {formValue.confirmPassword !==
                          formValue.enterPassword ? (
                            <p className="error-msg ps-3 pt-0 pb-4">
                              Password and confirm password should be same
                            </p>
                          ) : (
                            <p className=" error-msg color-white ps-3 pt-0">
                              :
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className="ForgotPassword fw-500 fs-16 m-0"
                      style={{
                        lineHeight: "18px",
                        color: "#ADBE9E",
                      }}
                    ></div>
                    {formValue.enterPassword ? (
                      <PasswordValidation
                        password={formValue.enterPassword}
                        actions={initPwdInput}
                        isStrong={isStrong}
                      />
                    ) : (
                      <>
                        {" "}
                        <div
                          className="w-100 color-white radius-8"
                          style={{
                            marginBottom: "38.3px",
                          }}
                        >
                          :
                        </div>{" "}
                      </>
                    )}
                    {/* </div> */}
                    <div className="mt-5">
                      <Button
                        className="w-100"
                        style={{
                          height: "44px",
                          fontFamily: "Cerebri sans-medium",
                          background: "#ADBE9E",
                          border: "none",
                          borderRadius: "6px",
                        }}
                        type="submit"
                      >
                        SAVE
                      </Button>
                    </div>
                  </div>
                  {/* <div
                    className=" medium-text mt-4"
                    style={{ color: "#ADBE9E" }}
                  >
                    <span className="cursor cla" onClick={() => sendOTP()}>
                      Send code again
                    </span>
                    <div className="mt-1">
                      <span className="cursor">
                        Having trouble receiving the code?
                      </span>
                    </div>
                  </div> */}
                </Col>
                <Col sm={6} className="d-none d-sm-block">
                  <Image
                    className="w-100 h-100"
                    src={loginImagePattern}
                    alt="login-image-pattern"
                    style={{ objectFit: "cover", borderRadius: "8px" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
