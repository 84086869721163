import { useMediaQuery } from "@mui/material";
import { useUnitId } from "../../hooks/stores/useUnitStore";
import ScreeningFormContainer from './desktopView/ScreeningFormContainer';
import ScreeningMobileContainer from './mobileView/ScreeningFormMobileContainer';
import { useScreeningFormAnswerStore, useScreeningFormStore, useScreeningFormsNames } from "../../hooks/stores/useScreeningFormStore";
import { useEffect } from "react";
import { useGetScreeningForm } from "../../hooks/queries/useScreeningQueries";
import { question } from "../../utils/dummy-data/screening-data/screening-data"

const ScreeningWizard = ({unitInformation}) => {
  // console.log(unitInformation, 'unitInformation')
  const isMobile = useMediaQuery("(max-width:1016px)");

  const Screening_Form_State           = useScreeningFormStore(
      (state) => state.Screening_Form_State
  );
  const setScreening_Form_State = useScreeningFormStore(
      (state) => state.setScreening_Form_State
  );
  const setScreeningFormNames = useScreeningFormsNames(
      (state) => state.setScreeningFormNames
  );
  const screeningFormNames = useScreeningFormsNames(
      (state) => state.screeningFormNames
  );
  const screeningFormAnswerState = useScreeningFormAnswerStore(
    (state) => state.screeningFormAnswerStore
  );


  const { UnitId } = useUnitId((state) => state);
  const { data: getScreeningFormData } = useGetScreeningForm(2, "0");

  useEffect(() => {
    if ( getScreeningFormData?.data?.length || question) {
      
      const formCategory    = question?.map(fc => ({formName: fc.name, icon: fc.icon_name}))
      const maxFormCategory = question?.reduce((ac, sfd) =>  sfd.section.length ? ac += sfd.section.length : ac+=1, 0)
      formCategory.push({
        formName: "confirmation",
        icon    : "fa-solid fa-circle-check"
      })
      let conditionQuestionIds = [];
      // filtering all field questions id so that we don't have to show this id in frontend till required
      question?.forEach((q) => {
        if(q.section.length) {
            q.section.forEach((s) => {
                if(s.question.length) {
                  s.question.forEach((sq) => {
                    if(sq.field_condition_rules?.rules?.length) {
                      sq.field_condition_rules?.rules.forEach((r)=> {
                        conditionQuestionIds.push(r.screening_question_id)
                      })
                    }
                  })
                }
            })
        }
      })
      
      setScreening_Form_State({
        maxIndex                          : question?.length,
        maxIndexIncludingFormSubWizard    : maxFormCategory,
        currentFormIndex                  : 0,
        currentFormSubIndex               : 0,
        currentIndexIncludingFormSubWizard: 0,      //progress bar counting number
        // formData                          : getScreeningFormData?.data,
        formData                          : question,
        prevIndex                         : 0,
        welcomePage                       : true,
        conditionQuestionIds              : conditionQuestionIds ,
        isFieldConditionActivate          : false,
        fieldConditionQueIndex            : null,
        isFlagConditionActivate           : false,
        activeFlagData                    : {},
        lockedFlagCondition               : false,
        isOverViewBtnTrue                 : false,
        isOverViewBackBtnTrue             : false
      });
      setScreeningFormNames([...formCategory]);
    }
  }, [getScreeningFormData, UnitId]);

  const prev = () => {
      if(Screening_Form_State.currentFormIndex >= Screening_Form_State.maxIndex) {
        setScreening_Form_State({
          ...Screening_Form_State,
          currentFormIndex: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentFormIndex - 1 : Screening_Form_State.currentFormIndex,   // previous form index number to switch prev page/wizard
          isFieldConditionActivate          : false,
          fieldConditionQueIndex            : null,
          currentFieldCondition             : null,
          isFlagConditionActivate : false,
          activeFlagData          : {},
          isOverViewBtnTrue                 : false
        })
      }else if(Screening_Form_State.currentFormIndex > 0) {
        let findPrevQuestionId = [];
        const currentFormIndex = Screening_Form_State.currentFormIndex - 1;
        const currentFormSubIndex = Screening_Form_State.formData[currentFormIndex]
        ?.section?.length ? Screening_Form_State.formData[currentFormIndex]
        ?.section?.length - 1 : 0;
        const prevSection = Screening_Form_State.formData[currentFormIndex]?.section?.[currentFormSubIndex]?.question
  
        const findFlagRaiseAnswer = prevSection?.filter((q) => q.screening_condition_rules?.rules?.length)

        for(let i=0; i<prevSection?.length; i++) {
          const currentQuestion       = prevSection?.[i]
          const currentQuestionRules  = currentQuestion?.field_condition_rules?.rules
          if(currentQuestionRules?.length) {
            const currentQuestionAns = screeningFormAnswerState[currentQuestion?.screening_question_id];
       
            // findPrevQuestionId = currentQuestionRules.filter(cqr => isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns))
            findPrevQuestionId = currentQuestionRules.filter(cqr => {
              if(isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns)) {
                return isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns)
              }
              return false;
            })
            break;
          }
        }
        
        // If prev question have a field condition then it will render that
        if(findPrevQuestionId?.length && Screening_Form_State.fieldConditionQueIndex === null) {
          let fieldConditionQueIndex = prevSection?.findIndex((sq) => sq?.screening_question_id === findPrevQuestionId[0]?.screening_question_id);
          return setScreening_Form_State({
            ...Screening_Form_State,
            currentFormIndex   : !Screening_Form_State.isOverViewBtnTrue ? currentFormIndex : Screening_Form_State.maxIndex,                                  // previous form index number to switch prev page/wizard
            currentFormSubIndex: !Screening_Form_State.isOverViewBtnTrue ? currentFormSubIndex : Screening_Form_State.currentFormSubIndex,
            currentIndexIncludingFormSubWizard: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentIndexIncludingFormSubWizard - 1 : Screening_Form_State.currentIndexIncludingFormSubWizard,
            isFieldConditionActivate: true,
            currentFieldCondition   : findPrevQuestionId,
            fieldConditionQueIndex   : fieldConditionQueIndex,
            isFlagConditionActivate : false,
            activeFlagData          : {},
            isOverViewBtnTrue                 : false
          });
        }else if(findFlagRaiseAnswer?.length) {
          const logic_type = findFlagRaiseAnswer[0]?.screening_condition_rules?.logic_type;
          const currentQueAnswer = screeningFormAnswerState[findFlagRaiseAnswer?.[0]?.screening_question_id]
          const isFlagValid = logic_type === 1 ? findFlagRaiseAnswer[0]?.screening_condition_rules?.rules?.every((any) => isConditionTrue(any?.operator, any?.value, currentQueAnswer)) : logic_type === 2 ? findFlagRaiseAnswer[0]?.screening_condition_rules?.rules?.some((any) => isConditionTrue(any?.operator, any?.value, currentQueAnswer)) : false;

          if(findFlagRaiseAnswer[0]?.screening_condition_rules?.show_message && isFlagValid) {
            return setScreening_Form_State({
              ...Screening_Form_State,
              currentFormIndex   : currentFormIndex,                                  // previous form index number to switch prev page/wizard
              currentFormSubIndex: currentFormSubIndex,
              currentIndexIncludingFormSubWizard: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentIndexIncludingFormSubWizard - 1 : Screening_Form_State?.currentIndexIncludingFormSubWizard,
              isFieldConditionActivate: false,
              currentFieldCondition   : null,
              fieldConditionQueIndex  : null,
              isFlagConditionActivate : true,
              activeFlagData          : findFlagRaiseAnswer[0],
              isOverViewBtnTrue                 : false
            })
          }else {
            setScreening_Form_State({
              ...Screening_Form_State,
              currentFormIndex   : !Screening_Form_State?.isOverViewBtnTrue ? currentFormIndex : Screening_Form_State?.maxIndex,                                  // previous form index number to switch prev page/wizard
              currentFormSubIndex: !Screening_Form_State?.isOverViewBtnTrue ? currentFormSubIndex : Screening_Form_State?.currentFormSubIndex,
              currentIndexIncludingFormSubWizard: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentIndexIncludingFormSubWizard - 1 : Screening_Form_State?.currentIndexIncludingFormSubWizard,
              isFieldConditionActivate          : false,
              fieldConditionQueIndex            : null,
              currentFieldCondition             : null,
              isFlagConditionActivate           : false,
              activeFlagData                    : {},
              isOverViewBtnTrue                 : false
            })
          }
        }
        else{
          setScreening_Form_State({
            ...Screening_Form_State,
            currentFormIndex   : !Screening_Form_State?.isOverViewBtnTrue ? currentFormIndex : Screening_Form_State?.maxIndex,                                  // previous form index number to switch prev page/wizard
            currentFormSubIndex: !Screening_Form_State?.isOverViewBtnTrue ? currentFormSubIndex : Screening_Form_State?.currentFormSubIndex,
            currentIndexIncludingFormSubWizard: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentIndexIncludingFormSubWizard - 1 : Screening_Form_State?.currentIndexIncludingFormSubWizard,
            isFieldConditionActivate          : false,
            fieldConditionQueIndex            : null,
            currentFieldCondition             : null,
            isFlagConditionActivate           : false,
            activeFlagData                    : {},
            isOverViewBtnTrue                 : false
          })
        }
      }else if(Screening_Form_State.currentFormIndex === 0) {
        setScreening_Form_State({
          ...Screening_Form_State,
          screeningIndex: !Screening_Form_State?.isOverViewBtnTrue ? 0 : Screening_Form_State?.screeningIndex,   // previous form index number to switch prev page/wizard
          prevIndex     : !Screening_Form_State?.isOverViewBtnTrue ? 2 : Screening_Form_State?.prevIndex,
          isFlagConditionActivate : false,
          activeFlagData          : {},
          currentFormIndex: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentFormIndex : Screening_Form_State?.maxIndex,
          isOverViewBtnTrue                 : false
        })
      }
  }

  const next = () => {
    if(Screening_Form_State.currentFormIndex < Screening_Form_State.maxIndex - 1) {
      setScreening_Form_State({
        ...Screening_Form_State,
        currentFormIndex                  : !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentFormIndex+1 : Screening_Form_State?.currentFormIndex,                     // next form index number to switch next page/wizard
        currentFormSubIndex               : 0,
        currentIndexIncludingFormSubWizard: !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentIndexIncludingFormSubWizard + 1 : Screening_Form_State.currentIndexIncludingFormSubWizard,
        isFieldConditionActivate          : !Screening_Form_State?.isOverViewBtnTrue ? false : Screening_Form_State?.isFieldConditionActivate,
        fieldConditionQueIndex            : !Screening_Form_State?.isOverViewBtnTrue ? null : Screening_Form_State?.fieldConditionQueIndex,
        currentFieldCondition             : !Screening_Form_State?.isOverViewBtnTrue ? null : Screening_Form_State?.currentFieldCondition,
        isFlagConditionActivate           : !Screening_Form_State?.isOverViewBtnTrue ? false : Screening_Form_State?.isFlagConditionActivate,
        activeFlagData                    : !Screening_Form_State?.isOverViewBtnTrue ? {} : Screening_Form_State.activeFlagData
        // isOverViewBackBtnTrue             : false
      })
    }else if(Screening_Form_State.currentFormIndex >= Screening_Form_State.maxIndex - 1 && Screening_Form_State.currentFormIndex <= Screening_Form_State.maxIndex) {
      setScreening_Form_State({
        ...Screening_Form_State,
        currentFormIndex       : !Screening_Form_State?.isOverViewBtnTrue ? Screening_Form_State.currentFormIndex+1 : Screening_Form_State?.currentFormIndex,   // next form index number to switch next page/wizard
        currentFormSubIndex    : 0,
        isFlagConditionActivate: !Screening_Form_State?.isOverViewBtnTrue ? false : Screening_Form_State?.isFlagConditionActivate,
        activeFlagData         : !Screening_Form_State?.isOverViewBtnTrue ? {} : Screening_Form_State.activeFlagData
        // isOverViewBackBtnTrue             : false
      })
    }
  };

  // This function will check the field condition coming from the API
  function isConditionTrue(condition, value, compareValue) {
    compareValue = !isNaN(compareValue) ? parseFloat(compareValue) : compareValue;
    value = !isNaN(value) ? parseFloat(value) : value;
    switch (condition) {
      case "=": 
        return compareValue === value;
      case "<=": 
        return compareValue <= value;
      case ">=": 
        return compareValue >= value;
      case "<": 
      return compareValue < value;
      case ">": 
        return compareValue > value;
      default: 
          return false;
    }
  }

  // Skip form Category fucntion
  const skipFormCategory = (selectedIndex, isOverViewBtnTrue) => {
    if(selectedIndex === Screening_Form_State?.formData?.length) {
      setScreening_Form_State({
        ...Screening_Form_State,
        currentFormIndex: selectedIndex,
        currentFormSubIndex: 0,
        currentIndexIncludingFormSubWizard: !isOverViewBtnTrue ? Screening_Form_State?.formData.slice(0, Screening_Form_State?.formData?.length - 1).reduce((ac, sfd) =>  sfd.section.length ? ac += sfd.section.length : ac+=1, 0) : Screening_Form_State?.currentIndexIncludingFormSubWizard,
        isFieldConditionActivate          : false,
        fieldConditionQueIndex            : null,
        currentFieldCondition             : null,
        isOverViewBtnTrue                 : isOverViewBtnTrue
      })
    }else {
      setScreening_Form_State({
        ...Screening_Form_State,
        currentFormIndex: selectedIndex,
        currentFormSubIndex: 0,
        currentIndexIncludingFormSubWizard: !isOverViewBtnTrue ? Screening_Form_State?.formData.slice(0, selectedIndex).reduce((ac, sfd) =>  sfd.section.length ? ac += sfd.section.length : ac+=1, 0) : Screening_Form_State?.currentIndexIncludingFormSubWizard,
        isFieldConditionActivate          : false,
        fieldConditionQueIndex            : null,
        currentFieldCondition             : null,
        isOverViewBtnTrue                 : isOverViewBtnTrue
      })
    }
  };

  // Post Screening Data 
  const handleScreeningData = () => {
    next();
    console.log('data')
  }

  const childProps = { Screening_Form_State, screeningFormNames, prev, next, isConditionTrue, skipFormCategory, handleScreeningData }
  return(
      <>
      {
          isMobile ?
          <ScreeningMobileContainer {...childProps} />
            : 
          <ScreeningFormContainer {...childProps} />
      }
      </>
  )
}

export default ScreeningWizard;