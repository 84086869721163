import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { H1, H2, H3, H4 } from "../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import {
  useFAQChatbox,
  useFAQChatbox2,
  useMobileFaqPopup,
  useScreeningNeedHelpChatbox,
  useScreeningNeedHelpChatbox2,
} from "../../../hooks/stores/useScreeningFormStore";
import ScreeningNeedHelp from "./ScreeningNeedHelp";
import ScreeningNeedHelpChatbox from "./ScreeningNeedHelpChatbox";

export default function ScreeningMobileFAQ() {
  // console.log("kp")
  // const isMobile = useMediaQuery("(max-width:600px)");
  const isMobile = useMediaQuery("(max-width:1016px)");

  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const mobileFaqPopup = useMobileFaqPopup((state) => state.mobileFaqPopup);
  const setMobileFaqPopup = useMobileFaqPopup(
    (state) => state.setMobileFaqPopup
  );
  // const FAQChatbox = useFAQChatbox((state) => state.FAQChatbox);
  // const setFAQChatbox = useFAQChatbox((state) => state.setFAQChatbox);
  // const FAQChatbox2 = useFAQChatbox2((state) => state.FAQChatbox2);

  const screeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.screeningNeedHelpChatbox
    );
  const setScreeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.setScreeningNeedHelpChatbox
  );
  const screeningNeedHelpChatbox2 = useScreeningNeedHelpChatbox2(
    (state) => state.screeningNeedHelpChatbox2
  );
  const setScreeningNeedHelpChatbox2 = useScreeningNeedHelpChatbox2(
    (state) => state.setScreeningNeedHelpChatbox2
  );

  const formCategories = [
    {
      icon: "fa-solid fa-file-lines",
      tabName: "Documents",
      isSelected: false,
      queries: [
        {
          Q: "What documents do I need?",
          A: "These typically include a completed rental application, proof of identity, proof of income, and documentation related to your rental history.",
        },
        { Q: "Understanding the document requirements", A: "Something..." },
      ],
    },
    {
      icon: "fa-solid fa-shield-halved",
      tabName: "Credit Check",
      isSelected: false,
      queries: [
        {
          Q: "Why do I have to make a credit check?",
          A: "A credit check is conducted to check your financial responsibility and ability to make rent payments on time. We use this to minimize the risk of non-payment.",
        },
      ],
    },
    {
      icon: "fa-solid fa-comment-lines",
      tabName: "something1",
      isSelected: false,
    },
    {
      icon: "fa-solid fa-comment-lines",
      tabName: "something2",
      isSelected: false,
    },
    {
      icon: "fa-solid fa-comment-lines",
      tabName: "something4",
      isSelected: false,
    },
  ];
  const handleCategoryClick = (index) => {
    setSelectedCategory(index);
  };

  return (
    <div>
      {isMobile && mobileFaqPopup && (
        <div>
          <div
            // className="display-block"
            className="position-absolute top-0"
            style={{
              backgroundColor: "#fff",
              left:'0',
              height: "100%",
              width: "100%",
              zIndex: "1056",
            }}
          >
            <div
              style={{
                height: "40px",
                backgroundColor: "#111747",
                borderRadius: "12px 12px 0 0",
              }}
            />
            <div>
              <Row className="position-relative py-2">
                <H1 className="text-center m-0" style={{ color: "#111747" }}>
                  FAQ
                </H1>
                <div
                  // onClick={() => setScreeningFaqPopup(false)}
                  className="radius-6 position-absolute"
                  style={{
                    // backgroundColor: "#EBEBEB",
                    height: "36px",
                    width: "36px",
                    right: "10px",
                    top: "15px",
                    backgroundColor: "rgb(235, 235, 235)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    className="cursor"
                    icon="fa-regular fa-xmark"
                    onClick={() => {
                      setMobileFaqPopup(false);
                    }}
                  />
                </div>
              </Row>
              <Row className="pb-2">
                <H2 className="text-center m-0" style={{ color: "#111747" }}>
                  How can we help you?
                </H2>
              </Row>
              <Row>
                <div className="pb-2 paragraph1-regular">
                  <div className="d-flex flex-row justify-content-center">
                    Can’t find what you're looking for?
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <u
                      className="cursor"
                      onClick={() => {
                        // setMobileFaqPopup(false);
                        // setShow(true);
                        // setFAQChatbox(true);
                        setScreeningNeedHelpChatbox(true);

                      }}
                    >
                      Send us a message
                    </u>
                  </div>
                </div>
              </Row>
              <Row className="scrollable-container-x py-2">
                <div className="container-fluid">
                  <div className="row flex-nowrap">
                    {formCategories.map((categories, i) => (
                      <div
                        className="col-6 col-md-3"
                        key={categories?.tabName}
                        onClick={() => handleCategoryClick(i)}
                      >
                        <div
                          className={`card card-block ${
                            selectedCategory === i ? "selected" : ""
                          }`}
                          style={{
                            height: "116px",
                            width: "144px",
                            borderRadius: "12px",
                            background: "#CFD0DA",
                            padding: "20px",
                            boxShadow:
                              selectedCategory === i
                                ? "0px 5px 5px rgba(0, 0, 0, 0.5)"
                                : "none", // Adjust the shadow values as needed
                          }}
                        >
                          <div
                            className="d-flex radius-8"
                            style={{
                              background: "#777DA6",
                              padding: "15px",
                              width: "50px",
                              height: "50px",
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ width: "20px", height: "20px" }}
                              color="#111747"
                              icon={categories.icon}
                            />
                          </div>
                          <div className="fw-bold" style={{ color: "#252525" }}>
                            {categories.tabName}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Row>
              <Row>
                <H3 className="px-4 py-2">
                  {formCategories[selectedCategory]?.tabName}
                </H3>
                <Col>
                  <div className="screeningFAQicons">
                    <Accordion>
                      {formCategories[selectedCategory]?.queries?.map(
                        (qa, index) => (
                          <Accordion.Item
                            key={index}
                            eventKey={index.toString()}
                            className="mb-3 overflow-hidden radius-10"
                          >
                            <Accordion.Header>
                              <p
                                className="m-0 fw-bold"
                                style={{ color: "#000", whiteSpace:'normal' }}
                              >
                                {qa?.Q}
                              </p>
                            </Accordion.Header>

                            <Accordion.Body className="py-0">
                              {qa?.A}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      )}
                    </Accordion>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
      {(screeningNeedHelpChatbox || screeningNeedHelpChatbox2) && <ScreeningNeedHelpChatbox layoutStyle="bottomRight" onBackClick={() => {setScreeningNeedHelpChatbox(false);}}/>}
      {/* {(FAQChatbox || FAQChatbox2) && <ScreeningNeedHelp />} */}
    </div>
  );
}
