import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../../Components/widgets/modal/Modal";
import { useForm, useWatch } from "react-hook-form";
import { Col, InputGroup, Row } from "react-bootstrap";
import NormalButton from "../../../../Components/input/normalButton/NormalButton";
import { useEffect, useState } from "react";
import { H4 } from "../../../../styles/Common.style";
import PasswordValidation from "../../../create-user/PasswordValidation";

const EditPasswordlModal = ({modal, setModal}) => {
    const { register, getValues, clearErrors, formState: { errors }, setError, watch, handleSubmit } = useForm({});
    
    const [updateModal, setUpdateModal] = useState(false);
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPwd: false
    });
    const [isStrong, initRobustPassword] = useState(null);

    const initPwdInput = async (childData) => {
      initRobustPassword(childData);
    };

    const onSubmit = () => {
      setModal(false);
      setUpdateModal(true);
    }

    return(
        <>
        <Modal
        topborder={false}
        open={modal}
        ModalHeader={true}
        onHide={() => setModal(false)}
        title={
            <div className="d-flex justify-content-between p-3 border-bottom">
              <div className="d-flex justify-content-center align-items-center gap-3 px-1">
                <div>
                  <div className="fs-20">Edit password</div>
                </div>
              </div>
            </div>
          }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-3">
          <Row>
            <Col className="px-1">
                <p className="pe-2">It is important that your password is strong so we can keep your personal information and documents safe.</p>
                <Row>
                    <div className="primary-text-color px-0">
                        Enter old password
                    </div>
                    <Col className="pt-2 pb-4 px-0">
                        <div className="form-input">
                            <input
                            type="text"
                            placeholder="password"
                            id="text"
                            {...register("oldPassword", {
                                required: true,
                                // onChange: (e) => {
                                // },
                            })}
                            />
                            <label htmlFor="text">password</label>
                        </div>
                    {errors.oldPassword && (
                        <p className="error-msg">
                        Old password is required
                        </p>
                    )}
                    </Col>
                </Row>
                <Row>
                    <Col className="pt-2 pb-2 px-0">
                    <div className="form-group mb-1">
                          <H4>Enter new password</H4>
                        <InputGroup>
                          <input
                            className="d-block w-100 radius-6"
                            name="password"
                            placeholder="Enter password"
                            type={showPassword.password ? "text" : "password"}
                            {...register("password", {
                              required: true,
                            })}
                          />
                          <label className="input-headings mb-1">
                            Enter new password
                          </label>
                          <div
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={()=> setShowPassword({...showPassword, password: !showPassword.password})}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword.password ? (
                              <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                            ) : (
                              <FontAwesomeIcon icon="fa-regular fa-eye" />
                            )}
                          </div>
                        </InputGroup>
                        <PasswordValidation
                        type={'line'}
                          password={watch('password')}
                          actions={initPwdInput}
                          isStrong={isStrong}
                        />
                        {errors.password && 
                          <p className=" error-msg">Please enter new password</p>
                        }
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="pt-2 pb-2 px-0">
                    <div className="form-group mb-1">
                          <H4> Confirm new password</H4>
                        <InputGroup>
                          <input
                            className="d-block w-100 radius-6"
                            placeholder="Enter password
                            "
                            type={showPassword.confirmPwd ? "text" : "password"}
                            {...register("confirmPassword", {
                              required: true,
                              validate: (value, formValues) => value === watch('password') || "The password do not match"
                            })}
                          />
                          <label className="input-headings mb-1">
                            Confirm new password
                          </label>
                          <div
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={()=> setShowPassword({...showPassword, confirmPwd: !showPassword.confirmPwd})}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword.confirmPwd ? (
                              <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                            ) : (
                              <FontAwesomeIcon icon="fa-regular fa-eye" />
                            )}
                          </div>
                        </InputGroup>
                        { errors.confirmPassword && 
                          <p className="error-msg"> {
                            errors.confirmPassword?.message ?
                            errors.confirmPassword?.message
                            : "confirm password is required."
                          }</p>
                        }
                    </div>
                    </Col>
                </Row>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-between gap-2 py-2 px-4 border-top" style={{ background: '#F9FAFA', borderRadius: '0 0 10px 10px' }}>
          <div>
            <NormalButton
              type="submit"
              className="fw-900"
              textColor="black"
              backgroundColor="white"
              buttonText="CANCEL"
              border="1px solid #D1D1D1"
              padding="6px 16px"
              borderRadius="6"
              fontSize="14"
              fontWeight="500"
              onClick={() => {
                clearErrors(); setModal(false)
              }}
            />
          </div>
          <div>
            <NormalButton
              className="fw-900"
              textColor="white"
              backgroundColor="green"
              buttonText="SAVE"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="6"
              fontSize="14"
              fontWeight="500"
            />
          </div>
        </div>
        </form>
         </Modal>
         <PasswordUpdateMSGModal modal={updateModal} setModal={setUpdateModal} />
        </>
    )
}

export default EditPasswordlModal;


const PasswordUpdateMSGModal = ({modal, setModal}) => {
    return (
        <Modal
        topborder={false}
        open={modal}
        ModalHeader={true}
        onHide={() => setModal(false)}
        title={
            <div className="d-flex justify-content-between p-3 border-bottom">
                <div className="d-flex justify-content-center align-items-center gap-3">
                    <div>
                    <div className="fs-20">Password update</div>
                    </div>
                </div>
                <div onClick={() => setModal(false)}>
                    <FontAwesomeIcon
                    className="cursor"
                    icon="fa-regular fa-xmark"
                    />
                </div>
            </div>
          }
      >
        <div className="p-3" style={{ height: '120px' }}>
          <Row>
            <Col className="px-0">
                <p className="fs-14">Your password has been updated</p>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-end py-2 px-2 border-top" style={{ background: '#F9FAFA', borderRadius: '0 0 10px 10px' }}>
            <NormalButton
              onClick={() => setModal(false)}
              className="fw-900"
              textColor="white"
              backgroundColor="green"
              buttonText="CLOSE"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="6"
              fontSize="14"
              fontWeight="500"
            />
        </div>
        </Modal>
    )
}