import * as React                                        from 'react';
import PropTypes                                         from 'prop-types';
import { styled }                                        from '@mui/material/styles';
import Stack                                             from '@mui/material/Stack';
import Stepper                                           from '@mui/material/Stepper';
import Step                                              from '@mui/material/Step';
import StepLabel                                         from '@mui/material/StepLabel';
import Check                                             from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses }           from '@mui/material/StepConnector';
import { FontAwesomeIcon }                               from '@fortawesome/react-fontawesome';
import { useScreeningFormStore, useScreeningFormsNames } from '../../../hooks/stores/useScreeningFormStore';
import Box                                               from '@mui/material/Box';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: '#D0D1DE',
  zIndex         : 1,
  color          : '#fff',
  width          : 40,
  height         : 40,
  display        : 'flex',
  borderRadius   : '8px',
  justifyContent : 'center',
  alignItems     : 'center',
  ...(ownerState.completed && {
    background: '#54A64D',
  }),
  ...(ownerState.active && {
    boxShadow: '0px 3px 5px -1px #00000066'
  })
  
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon, clickFun, Screening_Form_State } = props;

  const status = {
    completed: 'stepper-step-active-color',
    active   : 'primary-text-color',
    pending  : 'primary-text-color',
  }

  const currentStepperStatus = {}
  for (const key in props) {
    if (props[key] === true) {
      currentStepperStatus[key] = props[key];
    }
  }
  
  const currentStepperStatusArr = Object.keys(currentStepperStatus).length ? Object.keys(currentStepperStatus)[0] : 'pending'

  return (
    <ColorlibStepIconRoot sx = {{ cursor: 'pointer' }} ownerState = {{ completed, active }} className = {className} onClick = {clickFun}>
      {
        currentStepperStatusArr === 'completed' ?
        <FontAwesomeIcon className                 = {`${status[currentStepperStatusArr]} screening-stepper-step-icon`} icon = "fa-solid fa-circle-check" />
        : 
        <FontAwesomeIcon className = {`${status[currentStepperStatusArr]} screening-stepper-step-icon`} icon = {icon} />
      }
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
      /**
   * Whether this step is active.
   * @default false
   */
  active   : PropTypes.bool,
  className: PropTypes.string,
      /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
      /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};


export default function ScreeningFormStepper({isMobile, skipFormCategory}) {
  const Screening_Form_State    = useScreeningFormStore((state) => state.Screening_Form_State);
  const setScreening_Form_State = useScreeningFormStore((state) => state.setScreening_Form_State);
  const screeningFormNames      = useScreeningFormsNames(
    (state) => state.screeningFormNames
  );
  


    // const steps              = Object.keys(Screening_Form_State?.steps).filter((s) => s !== 'Overview');
    // let screeningComponent = {
    //   "Information"           : "fa-solid fa-user",
    //   "Occupation"            : "fa-solid fa-briefcase",
    //   "Economy"               : "fa-solid fa-building-columns",
    //   "Household"             : "fa-solid fa-house-user",
    //   "Identification"        : "fa-solid fa-passport",
    //   "Confirmation"          : "fa-solid fa-circle-check",
    //   "Additional information": "fa-solid fa-circle-info",
    //   "Salary slip": "fak fa-salary-slip-svg-test2"
    // }; 

  if(Object.keys(Screening_Form_State ?? {}).length === 0) return null  // if the form question isn't there we won't be rendering anything
  return (
    <Stack   sx        = {{ width: '100%', px: { xs: 0, lg: 2 }, mt: 2 }} spacing = {4}>
    <Stepper sx        = {{ px: 2 }} alternativeLabel activeStep                  = {Screening_Form_State.currentFormIndex}
             connector = {<></>}
      >
        {screeningFormNames.map((label, i) => {
          const setProps      = {};
                setProps.icon = label.icon
          return (
            <Step      sx                 = {{position: 'relative'}} key = {label.formName} { ...setProps }>
            <StepLabel { ...setProps } sx = {{
                ".css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  fontSize : '0.7rem',
                  marginTop: '8px',
                } // here i have passed the dynamic form navitagtion on onclick to ColorlibStepIcon component
              }} StepIconProps={{clickFun: ()=> skipFormCategory(i, false), Screening_Form_State: Screening_Form_State}}  StepIconComponent={ColorlibStepIcon}>{label.formName}</StepLabel> 
            </Step>
          )
        })}
      </Stepper>
      { !isMobile && <Box sx = {{ marginTop: '15px !Important' }}>
      <Box sx = {{
                height      : "8px",
                position    : "relative",
                background  : "#D0D1DE",
                borderRadius: "10px",
                    // left: "88%",
                    // top: "26%",
                width: "100%",
                    // transform: "translateX(-50%)",
                transition: "0.5s",
                '::before': {
                  position    : 'absolute',
                  content     : '""',
                  height      : "8px",
                  borderRadius: "10px",
                  transition  : " 0.5s",
                  top         : 0,
                  width       : `${((Screening_Form_State.currentIndexIncludingFormSubWizard + 1)/Screening_Form_State.maxIndexIncludingFormSubWizard) * 100}%`,
                  background  : '#54A64D',
                }
      }}></Box>
      <p className = 'text-center fs-7 my-2'>{(Screening_Form_State.currentIndexIncludingFormSubWizard + 1 > Screening_Form_State.maxIndexIncludingFormSubWizard ? Screening_Form_State.maxIndexIncludingFormSubWizard  : Screening_Form_State.currentIndexIncludingFormSubWizard + 1   ) ?? 0}/{Screening_Form_State.maxIndexIncludingFormSubWizard ?? 0}</p>
      </Box>}
    </Stack>
  );
}
