import { useState } from "react";
import {
  Button,
  Col,
  Image,
  Row,
  Accordion,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import unitImg from "../../assets/images/unit-image.jpg";
import { reservationDummyImgData } from "../../utils/dummy-data/rervation-page-data/reservation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../../styles/reservations.css";

// dummy data have to remove after api done
import { leadPersonalDetailsData } from "../../utils/dummy-data/rervation-page-data/reservation";
import Faqcard from "../help/Faqcard";

// custom button for lead form -> accordion
function CustomToggle({ children, eventKey, status }) {
  let bgColr = "#d1d1d1b5";
  if (status === "edit") bgColr = "#D1D1D1";
  else if (status === "fill out") bgColr = "#ADBE9E";
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <Button
      type="button"
      className="fw-500 fs-8 d-block ms-auto text-uppercase"
      style={{ border: "none", width: "80px", background: bgColr }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

export const Reservations = () => {
  const [leadDetailsDropDown, setLeadDetailsDropDown] = useState({
    occupation: false,
  });
  const [activeAccordion, setActiveAccordion] = useState({});
  const [occupationDropDown, setOccupationDropDown] = useState({});

  // changing lead details particular accordion background color
  const onHandleAccordion = (e, btn, index) => {
    setActiveAccordion({
      ...activeAccordion,
      [leadPersonalDetailsData[index]?.type]: true,
    });

    if (activeAccordion?.[leadPersonalDetailsData[index]?.type]) {
      setActiveAccordion({
        ...activeAccordion,
        [leadPersonalDetailsData[index]?.type]: false,
      });
    }
  };

  // occupation dropdown handling
  const handleOccupationSelect = (e, btn, i) => {
    setOccupationDropDown({ ...occupationDropDown, [e]: btn.target.text });
    console.log(e, btn, i, btn.target.text, occupationDropDown);
  };

  return (
    <>
      <div className="card-box reservation-container">
        <Row className="mt-4 mx-3 mx-sm-0">
          <Col
            className="px-0 bottom-border-line"
            style={{ background: "white", overflow: "hidden" }}
          >
            <div className="d-flex fs-8 fw-400 p-3 align-items-center bottom-border-line">
              <div className="me-2" style={{ width: "20px" }}>
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: "#77C970",
                    textColor: "black",
                    textSize: "1.5rem",
                    strokeWidth: 1,
                  })}
                  value={76}
                  strokeWidth={15}
                />
              </div>
              <h3 className="fs-5 fw-700 mb-0">Finish Your Screening</h3>
            </div>
            <Row className="py-4 px-3 gap-3 gap-lg-0">
              <Col xs={12} xl={6} className="px-0">
                <div>
                  <div>
                    <Image
                      src={unitImg}
                      alt="property"
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <Row className="d-flex justify-content-start align-items-center py-3 gap-3 gap-sm-0">
                    <Col sm={6} className="ellipsis">
                      <h3 className="fw-500 fs-6 ellipsis">Blomsterbjerget</h3>
                      <span className="fw-300 fs-7 text-muted">
                        Blomstervangen 14, 2.tv <br />
                        2765 Smørum <br />
                        Denmark
                      </span>
                    </Col>
                    <Col lg={7} md={12} className="property-details p-0">
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fak fa-square-meter-light-3"
                              size="lg"
                            />
                          </span>{" "}
                          Size
                        </span>
                        <span className="paragraph2-regular">-</span>
                      </div>
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fa-regular fa-bed-front"
                              size="lg"
                            />
                          </span>{" "}
                          Rooms
                        </span>
                        <span className="paragraph2-regular">-</span>
                      </div>
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light nowrap">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fa-regular fa-piggy-bank"
                              size="lg"
                            />
                          </span>{" "}
                          Monthly rent
                        </span>
                        <span className="paragraph2-regular">-</span>
                      </div>
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fa-regular fa-house-water"
                              size="lg"
                            />
                          </span>{" "}
                          Move-in
                        </span>
                        <span className="paragraph2-regular">-</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} xl={6}>
                <Row className="align-items-center gap-3 gap-lg-0 justify-content-center">
                  <Col xs={12} sm={7}>
                    <Row>
                      {reservationDummyImgData?.map((data, i) => {
                        if (i < 5)
                          return (
                            <Col xs={6} lg={4} key={i} className="p-1">
                              <div className="radius-12">
                                <img
                                  className="d-block w-100"
                                  src={data.imgLink}
                                  alt="flats"
                                />
                              </div>
                            </Col>
                          );
                        else if (i === 5)
                          return (
                            <Col className="p-1" xs={6} lg={4} key={i}>
                              <div className="position-relative radius-12 overflow-hidden">
                                <img
                                  className="d-block w-100"
                                  src={data.imgLink}
                                  alt="flats"
                                />
                                <div
                                  className="position-absolute fw-700 fs-3 w-100 h-100 d-flex justify-content-center align-items-center color-white"
                                  style={{
                                    top: "0",
                                    left: "0",
                                    backgroundColor: "#11174773",
                                  }}
                                >
                                  + {reservationDummyImgData.length - 6}
                                </div>
                              </div>
                            </Col>
                          );
                        else return null;
                      })}
                    </Row>
                  </Col>
                  <Col xs={12} sm={5}>
                    <div>
                      <h5 className="fw-500 fs-6 text-center">
                        Screen fulfillment
                      </h5>
                      <div
                        className="mx-auto"
                        style={{ width: "60%", maxWidth: "140px" }}
                      >
                        <CircularProgressbar
                          styles={buildStyles({
                            pathColor: "#77C970",
                            textColor: "black",
                            textSize: "1.5rem",
                          })}
                          value={76}
                          text={`76%`}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* lead - user information form */}
        <Row className="px-3 py-2">
          <Col className="px-0">
            <form>
              {leadPersonalDetailsData?.map((data, i) => {
                return (
                  <Accordion
                    className="my-2"
                    key={i}
                    onSelect={(e, data) => onHandleAccordion(e, data, i)}
                  >
                    <Card className="border-0">
                      <Card.Header
                        className={`${
                          activeAccordion?.[leadPersonalDetailsData[i]?.type]
                            ? "background-primary"
                            : "bg-white"
                        } my-2 border-bottom-0`}
                      >
                        <Row className="align-items-center gap-2 gap-sm-0">
                          <Col
                            className="d-flex px-0 align-items-center flex-wrap"
                            sm={4}
                            lg={4}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center me-3 p-2 position-relative"
                              style={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#d5d7e3",
                                borderRadius: "5px",
                              }}
                            >
                              <FontAwesomeIcon icon={data.icon} />
                              {data.status === "edit" && (
                                <FontAwesomeIcon
                                  className="position-absolute lead-checkbox-color"
                                  icon="fa-solid fa-circle-check"
                                  style={{ top: "-5px", right: "-6px" }}
                                />
                              )}
                            </div>
                            <div className="lh-1">
                              <h4 className="mb-0 fw-500 fs-7">{data.type}</h4>
                              <span className="fw-500 fs-9 text-muted">
                                Done
                              </span>
                            </div>
                          </Col>
                          <Col className="px-0" sm={8} lg={4}>
                            <div>
                              <ProgressBar
                                className="lead-loader-color"
                                now={(data.taskDone / data.totalTask) * 100}
                              />
                              <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                  <p className="d-inline-block mb-0 me-2 fs-500 fs-8">
                                    {data.taskDone} /{data.totalTask}
                                  </p>
                                  <span className="fs-500 fs-8 text-muted">
                                    Total task
                                  </span>
                                </div>
                                <div>
                                  <p className="fs-500 fs-8 mb-0">
                                    {Math.floor(
                                      (data.taskDone / data.totalTask) * 100
                                    )}
                                    %
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="px-0" lg={4}>
                            <CustomToggle eventKey={i} status={data.status}>
                              {data.status}
                            </CustomToggle>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse
                        className={`${
                          activeAccordion?.[leadPersonalDetailsData[i]?.type]
                            ? "background-primary"
                            : ""
                        } mt-1 `}
                        eventKey={i}
                      >
                        <Card.Body>
                          {i === 2 && (
                            <>
                              <Row className="reservation-form">
                                <Col className="ps-lg-4" lg={10}>
                                  <div className="occupation-accordion-box">
                                    <Row className="occupation-accordion-form my-3">
                                      <Col xs={5}>
                                        <label className="fw-500 fs-6">
                                          Occupation
                                        </label>
                                      </Col>
                                      <Col xs={7}>
                                        <Dropdown
                                          className="occupation-dropdown"
                                          onSelect={(e, val) =>
                                            handleOccupationSelect(e, val, i)
                                          }
                                        >
                                          <Dropdown.Toggle
                                            className="w-100 dropdown-toggle text-start rounded pe-4 fs-8 fw-300 selected-field"
                                            style={{
                                              border:
                                                "1px solid rgba(17, 23, 71, 0.1)",
                                            }}
                                          >
                                            {leadDetailsDropDown.occupation ? (
                                              <div className="dropdown-caret">
                                                <FontAwesomeIcon
                                                  icon="fa-regular fa-angle-up"
                                                  size="sm"
                                                />
                                              </div>
                                            ) : (
                                              <div className="dropdown-caret">
                                                <FontAwesomeIcon
                                                  icon="fa-regular fa-angle-down"
                                                  size="sm"
                                                />
                                              </div>
                                            )}
                                            <p className="mb-0">
                                              {!occupationDropDown[
                                                `occupation` + i
                                              ]
                                                ? "Full Time"
                                                : occupationDropDown[
                                                    `occupation` + i
                                                  ]}
                                            </p>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="p-0 w-100 fs-8 fw-300 p-1">
                                            <Dropdown.Item
                                              className="rounded"
                                              eventKey={`occupation` + i}
                                            >
                                              Full time
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="rounded"
                                              eventKey={`occupation` + i}
                                            >
                                              Part time
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Col>
                                    </Row>
                                    <Row className="occupation-accordion-form my-3">
                                      <Col xs={5}>
                                        <label className="fw-500 fs-6 d-inline-block">
                                          Member of unemployment fund?
                                        </label>
                                      </Col>
                                      <Col xs={7}>
                                        <div
                                          className="btn-group occupation-btn-group w-100 gap-2 flex-wrap"
                                          role="group"
                                          aria-label="Basic radio toggle button group"
                                        >
                                          <input
                                            type="radio"
                                            className="btn-check"
                                            name={data?.type}
                                            id={`${data?.type}-1`}
                                            autoComplete="off"
                                          />
                                          <label
                                            className="btn btn-outline-primary flex-fill rounded fs-8 fw-300 text-body"
                                            htmlFor={`${data?.type}-1`}
                                            style={{
                                              border:
                                                "1px solid rgba(17, 23, 71, 0.1)",
                                            }}
                                          >
                                            Yes
                                          </label>
                                          <input
                                            type="radio"
                                            className="btn-check"
                                            name={data?.type}
                                            id={`${data?.type}-2`}
                                            autoComplete="off"
                                          />
                                          <label
                                            className="btn btn-outline-primary flex-fill rounded fs-8 fw-300 text-body"
                                            htmlFor={`${data?.type}-2`}
                                            style={{
                                              border:
                                                "1px solid rgba(17, 23, 71, 0.1)",
                                            }}
                                          >
                                            No
                                          </label>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="occupation-accordion-form my-3">
                                      <Col xs={5}>
                                        <label className="fw-500 fs-6">
                                          Job title
                                        </label>
                                      </Col>
                                      <Col xs={7}>
                                        <input
                                          type="text"
                                          className="w-100 rounded px-2 py-2 fs-8 fw-300"
                                          placeholder="Job title"
                                          style={{
                                            border:
                                              "1px solid rgba(17, 23, 71, 0.1)",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="occupation-accordion-form my-3">
                                      <Col xs={5}>
                                        <label className="fw-500 fs-6">
                                          Company
                                        </label>
                                      </Col>
                                      <Col xs={7}>
                                        <div className="d-flex gap-2 flex-wrap">
                                          {/* <label
                                      className="w-sm-50 fs-8 fw-300 rounded bg-white flex-fill"
                                      style={{
                                        color: "black",
                                        border:
                                          "1px solid rgba(17, 23, 71, 0.1)",
                                        padding: "0.4rem 0.4rem",
                                      }}
                                    >
                                      Company
                                    </label> */}
                                          <input
                                            type="text"
                                            className="rounded px-2 py-2 fs-8 fw-300 flex-fill"
                                            placeholder="Company"
                                            style={{
                                              border:
                                                "1px solid rgba(17, 23, 71, 0.1)",
                                            }}
                                          />
                                          <Dropdown
                                            className="occupation-dropdown flex-fill"
                                            onSelect={(e, val) =>
                                              handleOccupationSelect(e, val, i)
                                            }
                                          >
                                            <Dropdown.Toggle
                                              className="w-100 bg-white dropdown-toggle py-2 text-start rounded pe-4 fs-8 fw-300"
                                              style={{
                                                border:
                                                  "1px solid rgba(17, 23, 71, 0.1)",
                                              }}
                                            >
                                              {leadDetailsDropDown.occupation ? (
                                                <div className="dropdown-caret">
                                                  <FontAwesomeIcon
                                                    icon="fa-regular fa-angle-up"
                                                    size="sm"
                                                  />
                                                </div>
                                              ) : (
                                                <div className="dropdown-caret">
                                                  <FontAwesomeIcon
                                                    icon="fa-regular fa-angle-down"
                                                    size="sm"
                                                  />
                                                </div>
                                              )}
                                              <p className="mb-0">
                                                {!occupationDropDown[
                                                  `company` + i
                                                ]
                                                  ? "Employee Since"
                                                  : occupationDropDown[
                                                      `company` + i
                                                    ]}
                                              </p>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="p-0 w-100 fs-8 fw-300 p-1">
                                              <Dropdown.Item
                                                eventKey={`company` + i}
                                                className="rounded"
                                              >
                                                1
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                eventKey={`company` + i}
                                                className="rounded"
                                              >
                                                2
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          {/* </div> */}
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              <Button
                                type="button"
                                className="fw-500 fs-8 d-block ms-auto bg-green"
                                style={{
                                  border: "none",
                                  width: "80px",
                                }}
                              >
                                Save
                              </Button>
                            </>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              })}
            </form>
          </Col>
        </Row>
      </div>
      <Row className="mb-5">
        <Col>
          <Faqcard />
        </Col>
      </Row>
    </>
  );
};
