// lead home page recent activity
export const RecentActivityData = [
    // {
    //   dataTimeInfo: "14 Jan, 2023 | 12:19",
    //   info: `Perspiciatisomnis iste natus error sit voluptatem`,
    //   document: "",
    //   imgLink: "fa-solid fa-comment-lines",
    // },
    // {
    //   dataTimeInfo: "14 Jan, 2023 | 10:02",
    //   info: `Iste natus error sit voluptatem sed ut perspiciatis`,
    //   document: "",
    //   imgLink: "fa-solid fa-comment-lines",
    // },
];
export const RecentActivitySigninDocumentData = [
    // {
    //   dataTimeInfo: "14 Jan, 2023 | 12:19",
    //   info: `Perspiciatisomnis iste natus error sit voluptatem`,
    //   document: "Document.pdf",
    //   imgLink: "fa-solid fa-pencil",
    // },
    // {
    //   dataTimeInfo: "14 Jan, 2023 | 10:02",
    //   info: `Iste natus error sit voluptatem sed ut perspiciatis`,
    //   document: "",
    //   imgLink: "fa-solid fa-comment-lines",
    // },
];

// lead home page recent activity

// lead home page singin recent activity
export const signInRecentActivityData = [
  // {
  //   dataTimeInfo: "16 Jan, 2023 | 10:23",
  //   info: ` Sed ut perspiciatism Document.pdf omnis iste natus error
  //   sit voluptatem`,
  //   document: "pdf",
  //   imgLink: "fa-solid fa-pencil",
  // },
  // {
  //   dataTimeInfo: "14 Jan, 2023 | 12:19",
  //   info: `Iste natus error sit voluptatem sed ut perspiciatis`,
  //   document: "",
  //   imgLink: "fa-solid fa-comment-lines",
  // },
  // {
  //   dataTimeInfo: "14 Jan, 2023 | 10:02",
  //   info: `Iste natus error sit voluptatem sed ut perspiciatis`,
  //   document: "",
  //   imgLink: "fa-solid fa-comment-lines",
  // },
  // {
  //   dataTimeInfo: "12 Jan, 2023 | 08:13",
  //   info: `Sed ut perspiciatism Document.pdf omnis iste natus error
  //   sit voluptatem`,
  //   document: "pdf",
  //   imgLink: "fa-solid fa-pencil",
  // },
];



// lead home page document data
// user home page documents data
export const leadDocumentComponentData = [
  {
    icon: "fa-regular fa-file-lines",
    bgColor: "#e6f3f3",
    fileName: "Tenancy agreement - Blomsterbjerget 14",
    timeInfo: "Uploaded by: Max Wildernesss @Jan 23, 2023 | 19:45",
    fileType: "+4 Documents",
  }
];

// Lead home page messages data
export const MessageComponentData = [
  // {
  //   name: "Landlord Lisa",
  //   incomingMsg: true,
  //   heading: "Approval of keeping dog",
  //   headingInfo:
  //     "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...",
  //   timeInfo: "16 Jan, 2023 | 11:02",
  // }
];