import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import Modal                  from "../../../../Components/widgets/modal/Modal";
import { useForm }            from "react-hook-form";
import { Col, Row }           from "react-bootstrap";
import NormalButton           from "../../../../Components/input/normalButton/NormalButton";
import { useState }           from "react";
import EmailVerificationModal from "./profile.emailVerificationModal";

const EditEmailModal = ({modal, setModal}) => {
    const { formState: { errors }, register, handleSubmit } = useForm({});

    const [verificationModal, setVerificationModal] = useState(false);

    return(
        <>
        <Modal
        topborder={false}
        open={modal}
        ModalHeader={true}
        onHide={() => setModal(false)}
        title={
            <div className="d-flex justify-content-between p-3 border-bottom">
              <div className="d-flex justify-content-center align-items-center gap-3 px-1">
                <div>
                  <div className="fs-20">Change email</div>
                </div>
              </div>
            </div>
          }
      >
        <form onSubmit={handleSubmit(()=> {
          setModal(false);
          setVerificationModal(true);
        })}>
          <div className="p-3">
            <Row>
              <Col className="px-1">
                  <p className="fs-14">Enter your new email below.</p>
                  <p className="fs-14 mb-5">Please make sure that you have access to the email.</p>
                  <Row>
                      <div className="primary-text-color px-0">
                          Enter new e-mail
                      </div>
                      <Col className="pt-2 pb-2 px-0">
                          <div className="form-input">
                              <input
                              type="email"
                              placeholder="E-mail"
                              id="newEmail"
                              {...register("newEmail", {
                                  required: true,
                                  // onChange: (e) => {
                                  // },
                              })}
                              />
                              <label htmlFor="newEmail">E-mail</label>
                          </div>
                        {errors.newEmail && (
                          <p className="error-msg">
                          This field is required
                          </p>
                      )}
                      </Col>
                  </Row>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-between gap-2 py-2 px-4 border-top" style={{ background: '#F9FAFA', borderRadius: '0 0 10px 10px' }}>
            <div>
              <NormalButton
                type="submit"
                className="fw-900"
                textColor="black"
                backgroundColor="white"
                buttonText="CANCEL"
                border="1px solid #D1D1D1"
                padding="6px 16px"
                borderRadius="6"
                fontSize="14"
                fontWeight="500"
                onClick={() => setModal(false)}
              />
            </div>
            <div>
              <NormalButton
                type="submit"
                className="fw-900"
                textColor="white"
                backgroundColor="green"
                buttonText="GET VERIFICATION CODE"
                border="1px solid #F0F0F0"
                padding="6px 16px"
                borderRadius="6"
                fontSize="14"
                fontWeight="500"
              />
            </div>
          </div>
        </form>
       
         </Modal>
         <EmailVerificationModal modal={verificationModal} setModal={setVerificationModal} />
        </>
    )
}

export default EditEmailModal;
