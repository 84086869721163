import * as React from "react";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components/macro";
import {
  useScreeningMessageModal,
  useScreeningFaqPopup,
  useHomeChatbox,
  useHomeChatbox2,
} from "../../hooks/stores/useScreeningFormStore";
import { H4, H5 } from "../../styles/Common.style";
import { MessageComponentData as msgData } from "../../utils/dummy-data/home-page-data/user-home";
import { NavLink } from "react-router-dom";

export default function HomeChatbox() {
  const [inputValue, setInputValue] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const chatContainerRef = useRef(null);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const formattedDate = currentDateTime.format("D MMM, YYYY | HH:mm");

  const setScreeningMessageModal = useScreeningMessageModal(
    (state) => state.setScreeningMessageModal
  );
  const homeChatbox = useHomeChatbox(
    (state) => state.homeChatbox
    );
  const setHomeChatbox = useHomeChatbox(
    (state) => state.setHomeChatbox
  );
  const homeChatbox2 = useHomeChatbox2(
    (state) => state.homeChatbox2
    );
  const setHomeChatbox2 = useHomeChatbox2(
    (state) => state.setHomeChatbox2
  );
  const simulateBotResponse = (userMessage) => {
    return `You said "${userMessage}"`;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEnterPress = () => {
    if (inputValue.trim() !== "") {
      const userMessage = inputValue;
      const newMessages = [...dataArray, { text: userMessage, user: "You" }];

      // Add the user's message to the state
      setDataArray(newMessages);

      // Simulate a bot response
      // botResponse();
      const botResponse = simulateBotResponse(userMessage);
      const updatedMessages = [
        ...newMessages,
        { text: botResponse, user: "Bot" },
      ];

      // Add the bot's response to the state
      setDataArray(updatedMessages);

      setInputValue("");
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [dataArray]);
  // console.log("kp",homeChatbox,homeChatbox2)
  return (
    <>
      {(homeChatbox || homeChatbox2) && (
        <Backdrop className="w-100 h-100 position-fixed top-0">
          {homeChatbox2 && (
            <Layout>
              <Notifydiv>
                <Flexdiv
                  style={{
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <IconContainer
                      className="cursor d-flex align-items-center justify-content-center"
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => {
                        setHomeChatbox(true);
                        setHomeChatbox2(false);
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-left"
                        size="sm"
                        color="white"
                      />
                    </IconContainer>
                    <H4 className="text-white text-start mb-0 ms-2">
                      {msgData.heading}
                    </H4>
                  </div>
                  <IconContainer
                    className="cursor d-flex align-items-center justify-content-center"
                    style={{ width: "40px", height: "40px"}}
                  >
                    <CloseIcon
                      fontSize="small"
                      sx={{ color: "white" }}
                      onClick={() => {
                        setHomeChatbox(false);
                        setHomeChatbox2(false);
                        setScreeningMessageModal(false);
                      }}
                    />
                  </IconContainer>
                </Flexdiv>
              </Notifydiv>
              <Chatdiv2>
                {/* <Somediv className="p-2">
              <H5
                className="cursor"
                style={{ marginBottom: "0px", color: "#ADBE9E" }}
              >
                Go to all messages
              </H5>
            </Somediv> */}
                <Headlinediv>
                  <h4 style={{ marginBottom: "0px" }}>
                    Headline : {msgData.Headline}
                  </h4>
                </Headlinediv>
                <ChatMsgDisplay ref={chatContainerRef}>
                  <div className="w-100">
                    <Flexdiv className="fs-12" style={{ color: "#00000080" }}>
                      {formattedDate}
                    </Flexdiv>
                    {dataArray.map((message, index) => (
                      <div
                        key={index}
                        className={`message ${
                          message.user === "You" ? "sent" : "received"
                        } pb-3`}
                      >
                        {message.user === "You" ? (
                          <>
                            <div className="d-flex justify-content-end fw-bold">
                              {message.user}
                            </div>
                            <div className="d-flex justify-content-end text-break">
                              <div
                                className="p-2 radius-10 "
                                style={{
                                  backgroundColor: "#ADBE9E",
                                  color: "#fff",
                                }}
                              >
                               
                                {message.text}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <div className="d-flex flex-column justify-content-end pe-2">
                                <div
                                  className="rounded-circle d-flex justify-content-center align-items-center"
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    backgroundColor: "#97BFB5",
                                    color: "#fff",
                                  }}
                                >
                                  <p className="m-0">LL</p>
                                </div>
                              </div>
                              <div>
                                <div className="fw-bold">{message.user}</div>
                                <div className="d-flex justify-content-start text-break">
                                  <div
                                    className="p-2 radius-10 "
                                    style={{
                                      backgroundColor: "#E9EBF1",
                                      color: "#000",
                                    }}
                                  >
                                    {message.text}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </ChatMsgDisplay>
                <Msgdiv2 className="p-3">
                  {/* <Row className="h-100">
                <Col lg={12} className="h-100">
                  <div
                    className="screening-form-textarea-border position-relative h-100"
                    style={{ border: "1px solid #d1d1d1" }}
                  >
                    <div className="d-flex form-message-input h-100">
                      <textarea
                        type="text"
                        placeholder="Start Writing..."
                        id="Message"
                        className="Message"
                        style={{ resize: "none", height: "calc(100% - 38px)" }}
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={handleEnterPress}
                      />
                      <label htmlFor="Lastname">Message</label>
                    </div>
                    <Flexdiv
                      className="w-100 px-2"
                      style={{
                        justifyContent: "space-between",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        paddingBottom: "5px",
                      }}
                    >
                      <div>
                        <input
                          className="d-none"
                          type="file"
                          name="needdHelpUploadfile"
                          id="needdHelpUploadfile"
                        ></input>
                        <label htmlFor="needdHelpUploadfile">
                          <div
                            className="d-flex align-items-center justify-content-center cursor"
                            style={{ width: "25px", height: "25px" }}
                          >
                            <FontAwesomeIcon
                              icon="fa-regular fa-paperclip"
                              style={{ fontSize: "1.3rem", color: "#888888" }}
                            />
                          </div>
                        </label>
                      </div>
                      <div>
                        <button
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            border: "none",
                            backgroundColor: "#ADBE9E",
                            borderRadius: "4px",
                            width: "25px",
                            height: "25px",
                          }}
                          onClick={() => handleEnterPress()}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-paper-plane-top"
                            style={{ color: "white" }}
                          />
                        </button>
                      </div>
                    </Flexdiv>
                  </div>
                </Col>
              </Row> */}
                  <Row className="border rounded-3">
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <div>
                        <textarea
                          type="text"
                          rows="1"
                          cols="25"
                          style={{ resize: "none", outline: "none" }}
                          placeholder="Start writing..."
                          id="Message"
                          className="border-0"
                          value={inputValue}
                          onChange={handleInputChange}
                          onClick={handleEnterPress}
                        />
                        <label htmlFor="Lastname"></label>
                      </div>
                      <div>
                        <button
                          type="file"
                          style={{
                            border: "none",
                            backgroundColor: "#ADBE9E",
                            borderRadius: "6px",
                          }}
                          onClick={() => handleEnterPress()}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-paper-plane-top"
                            style={{ color: "white" }}
                          />
                        </button>
                      </div>
                    </div>
                  </Row>
                </Msgdiv2>
              </Chatdiv2>
            </Layout>
          )}
          {homeChatbox && <AllMessages setHomeChatbox={setHomeChatbox} setHomeChatbox2={setHomeChatbox2} />}
        </Backdrop>
      )}
    </>
  );
}
export function AllMessages({ setHomeChatbox, setHomeChatbox2 }) {
  function getInitials(name) {
    const words = name.split(" ");
    const initials = words.map((word) => word[0].toUpperCase()).join("");
    return initials;
  }
  return (
    <Layout>
      <Notifydiv2>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-center py-2">
            <H4 className="text-white text-start mb-0 ms-2">Messages</H4>
          </div>
        </div>
        <IconContainer
            className="cursor d-flex align-items-center justify-content-center"
            style={{ width: "40px", height: "40px", position:'absolute', right:'0', top:'0' }}
          >
            <CloseIcon
              fontSize="small"
              sx={{ color: "white" }}
              onClick={() => {
                setHomeChatbox2(false);
                setHomeChatbox(false);
              }}
            />
          </IconContainer>
      </Notifydiv2>
      <Chatdiv2>
        <Col className="overflow-scroll scrollable-container-y" style={{height:'calc(100% - 85px)'}}>
          {msgData.map((msgData, i) => (
            <div
              className="d-flex align-items-center border-bottom justify-content-around p-2 cursor-pointer"
              // style={show ? { backgroundColor: "#D4D4CE" } : {}}
              // onClick={() => {
              //   toggleSelection(i);
              //   handleMessageClick(i);
              // }}
              key={i}
              // style={
              //   msgData.isSelected ? { backgroundColor: "#D4D4CE" } : {} // Apply the background color when the message is selected
              // }
            >
              <div
                className="rounded-circle d-flex justify-content-center align-items-center"
                style={{
                  width: "36px",
                  height: "36px",
                  backgroundColor: "#97BFB5",
                  color: "#fff",
                }}
              >
                <p className="m-0">{getInitials(msgData.name)}</p>
              </div>
              <div className="fs-12">
                <p
                  className="my-0"
                  // style={
                  //   !msgData.isRead ? { fontWeight: "700" } : {} // Apply the fw when the message is selected
                  // }
                >
                  {msgData.name}
                  {msgData.timeInfo}
                </p>
                <p className="my-0">{msgData.heading}</p>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#fff",
                }}
                // onClick={(e) => {
                //   e.stopPropagation(); // Prevent the parent click event from firing
                //   handleDeleteChat(i);
                // }}
              >
                <FontAwesomeIcon
                  color="#000"
                  icon="fa-regular fa-angle-left"
                  style={{
                    width: "12px",
                    height: "16px",
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
            </div>
          ))}
        </Col>
        <div className="p-4">
        <NavLink to="/messages">
          <div className="d-flex justify-content-center">
            <Button
              style={{
                border: "none",
                backgroundColor: "#ADBE9E",
                borderRadius: "6px",
              }}
              onClick={() => {}}
            >
              GO TO ALL MESSAGES
            </Button>
          </div>
        </NavLink>
        </div>
      </Chatdiv2>
    </Layout>
  );
}

const Backdrop = styled.div`
  z-index: 100;
  left: 0;
  background-color: #11174780;
`;

const Layout = styled.div`
  background-color: white;
  width: 320px;
  position: fixed;
  top: 170px;
  right: 50px;
  z-index: 15;
  border-radius: 12px;
  // overflow: auto;
  @media (min-width: 1300px) {
    width : 350px;
  }
`;
const Notifydiv = styled.div`
background-color: #111747;
color: white;
height: auto; 
border-radius: 12px 12px 0px 0px;
@media (max-width: 576px) {
  display:none;
}
`;
const Topdiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 576px) {
    height:50%;
    position:relative;
    top:20%
  }
`;
const Topdiv2 = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 576px) {
    height:70%;
    position:relative;
    top:20%
  }
`;
const Flexdiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
`;
const IconContainer = styled.div`
  padding:18px;
  @media (max-width: 1400px) {
    padding:9px
  }
`;
const Heading = styled.p`
  margin:0px 0 8px 0;
  font-size:22px;
  @media (max-width: 1400px) {
    font-size:16px
  }
`;
const Para = styled.p`
  color:#ffffff80;
  font-size:16px;
  text-align:center;
  margin:0px;
  @media (max-width: 1400px) {
    font-size:12px
  }
  @media (max-width: 576px) {
    padding:0 40px;
    color:#ffffff;
    margin-top: 10px;
  }
`;
const Chatdiv = styled.div`
  background-color: #F7F8FA;
  border-top: 1px solid #1117471A;
  margin-top: 120px;
  // height:470px;
  // border-radius:0 0 12px 12px;
  // position:relative;
  // @media (max-width: 1400px) {
  //   height:321px
  // }
  // @media (max-width: 576px) {
  //   height:70%
  // }
`;
const FAQdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 576px) {
    display:none;
  }
`;
const Msgdiv = styled.div`
  // height:270px;
  // position:absolute;
  // bottom:0;
`;
const Notifydiv2 = styled.div`
  position:relative;
  background-color: #111747;
  color: white;
  height: auto; 
  border-radius: 12px 12px 0px 0px;
  @media (max-width: 576px) {
    display:none;
  }
`;
const NotifyMobdiv = styled.div`
  background-color: #111747;
  color: white;
  height: 15%; 
  border-radius: 12px 12px 0px 0px;
  @media (min-width: 576px) {
    display:none;
  }
`;
const Chatdiv2 = styled.div`
  background-color:white;
  height:566px;
  border-radius:0 0 12px 12px;
  position:relative;
  @media (max-width: 1400px) {
    height:386px
  }
  @media (max-width: 576px) {
    height:85%
  }
`;
const Somediv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 576px) {
    display:none;
  }
`;
const Headlinediv = styled.div`
  background-color: #9FA2B5;
  color:#ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 10px;
  @media (min-width: 576px) {
    display:none;
  }
`;
const Msgdiv2 = styled.div`
  background-color: #F7F8FA;
  border-top: 1px solid #1117471A;
  position:absolute;
  width: 100%;
  bottom:0;
  border-radius: 0 0 12px 12px;
  // height: 150px;
  // @media (max-width: 576px) {
  //   bottom:50px;
  // }
`;
const ChatMsgDisplay = styled.div`
  // height:350px;
  padding:0 10px 0 10px;
  overflow-y:auto;
  display:flex;
  flex-direction:row;
  height: calc(100% - 85px);
  width: 100%;
  justify-content: end;
  // @media (max-width: 1400px) {
  //   height:170px;
  // }
  // @media (max-width: 576px) {
  //   height:272px;
  // }
`;
const Msgtext = styled.div`
  background-color: #ADBE9E;
  color:#ffffff;
  border-radius: 6px;
  padding: 5px 10px;
  max-width:70%;
  display: flex;
  align-items: center;
`;
const Usernamediv = styled.div`
  display: flex;
  justify-content: end;
  font-weight: 400;
  font-size: 14px;
`;
const Msgcontainer = styled.div`
  display: flex;
  justify-content: end;
  overflow-wrap: anywhere;
}
`;
const Botdiv = styled.div`
  display: flex;
  justify-content: left;
  font-weight: 600;
`;
const BotMsgcontainer = styled.div`
  display: flex;
  justify-content: left;
  overflow-wrap: anywhere;
}
`;
const BotMsgtext = styled.div`
  background-color: #E9EBF1;
  color:#000000;
  border-radius: 6px;
  padding: 5px 10px;
  max-width:70%;
  display: flex;
  align-items: center;
`;
