import React from "react";
import profiledesign from "./icons/Profile-image.svg";
import profileicon from "./icons/profile-icon.svg";
import notificationicon from "./icons/notifications-icon.svg";
import profilegray from "./icons/profilegray.svg";
import profile_edit_icon from"./icons/profile_edit_icon.svg";
import notifactiongray from "./icons/notifactiongray.svg";
import modalhead from "./icons/modalheader.svg"
import { Image } from "react-bootstrap";

export const ProfileDesign = ({ height, width }) => {
  return (
    <>
      <Image
      fluid={true}
        src={profiledesign}
        height={height}
        width={width}
        alt="profile"
      />
    </>
  );
};

export const ProfileIcon = ({ height, width }) => {
  return (
    <>
      <img src={profileicon} height={height} width={width} alt="profile-icon" />
    </>
  );
};

export const ProfileGrayIcon = ({ height, width }) => {
  return (
    <>
      <img src={profilegray} height={height} width={width} alt="profile-icon" />
    </>
  );
};

export const ProfileEdit = ({ height, width }) => {
  return (
    <>
      <img src={profile_edit_icon} height={height} width={width} alt="profile-icon" />
    </>
  );
};

export const NotificationIcon = ({ height, width }) => {
  return (
    <>
      <img
        src={notificationicon}
        height={height}
        width={width}
        alt="notification-icon"
      />
    </>
  );
};
export const Notifactiongray = ({ height, width }) => {
  return (
    <>
      <img
        src={notifactiongray}
        height={height}
        width={width}
        alt="notification-icon"
      />
    </>
  );
};
export const ModalTopHeader = ({ height, width }) => {
  return (
    <>
      <img
        src={modalhead}
        height={height}
        width={width}
        alt="notification-icon"
      />
    </>
  );
};
