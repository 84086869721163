import { useQuery } from "react-query";
import { GetCountries, GetZipcodeByCountry } from "../../services/Location";

//Get countries
export const useGetCountries = (country_Id) => {
  return useQuery(
    ["qGetCountries", country_Id],
    () => GetCountries(country_Id),
    {
      enabled: !!country_Id,
    }
  );
};

//Get zipcode by country Id
export const useGetZipcodeByCountryId = (country_Id) => {
  return useQuery(
    ["qGetZipdoceByCountryId", country_Id],
    () => GetZipcodeByCountry(country_Id),
    {
      enabled: !!country_Id,
      refetchOnWindowFocus: false
    }
  );
};
