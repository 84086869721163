import { Col, Row } from "react-bootstrap"
import { H1 } from "../../../../styles/Common.style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Countdown from "react-countdown"

export const CustomerSelectPage = ({ getIndex, setIndex }) => {
    
    return(
        <div>
             <div className="mx-auto pt-4" style={{ background: '#f7f8fa' }}>
                <div className="mb-0 d-flex justify-content-center align-items-center">
                    <span className="m-0 text-light opacity-75 d-flex gap-2 text-nowrap">
                    <span className="primary-text-color fs-12">Time to finish your screening:</span>
                    <Countdown
                        date={1696075241000}
                        daysInHours
                        zeroPadTime={2}
                        onComplete={() => <>Screening expired</>}
                        className="primary-text-color fs-12"
                    />
                    </span>
                </div>
            </div>
            <Row className="pt-3 py-lg-5 justify-content-center" style={{ background: '#f7f8fa' }}>
                <Col className="px-sm-2 pb-3 me-0 me-xl-5 mt-2 bg-white text-dark" lg={6} xxl={5} style={{ borderRadius: "24px 24px 0px 0px", borderTop: "1px solid rgba(0, 0, 0, 0.10)"}}>
                <div className="text-center mt-3">
                    <H1>How many customers should be on the lease?</H1>
                </div>
                <div className="text-center mx-auto" style={{ maxWidth: '450px' }}>
                    <p>We need to know how many customers should be on the lease. Therefore, select below how many customers you want on the contract. All customers must then complete a screening and be approved based on our terms and conditions.</p>
                </div>
                <div className="mt-4">
                    <div className="mb-2 text-center mb-3">
                        <span className="fs-14">Number of customers on the contract</span>
                    </div>
                    <Row className="gap-3 justify-content-center">
                        {
                            cutomerList.map((cl, clIndex) => {
                                return(
                                    <Col key={cl.customerNo} md={3} className="position-relative customer-list-prescreening d-flex align-items-end justify-content-center cursor" style={{ maxWidth: '140px', background: clIndex + 1 === getIndex?.numberOfCustomer ? '#FFF' : '#F2F4F6' }} onClick={()=> {
                                        setIndex({
                                            ...getIndex,
                                            prevIndex: 1,
                                            numberOfCustomer: clIndex+1
                                        })
                                    }}>
                                        <div>
                                            <H1 className="text-center mb-0">{cl.customerNo}</H1>
                                            <p className="text-center">{cl.text}</p>
                                        </div>
                                        <div className="position-absolute d-flex gap-1" style={{
                                            top: '15px',
                                            left: '15px'
                                        }}>
                                            {
                                                cl.icons.map((c, ci) => {
                                                    return (
                                                        <FontAwesomeIcon key={ci+1} icon={c} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
                </Col>
            </Row>
        </div>
    )
}

export default CustomerSelectPage;


const cutomerList = [
    {
        icons: ["fa-solid fa-circle-user"],
        customerNo: 1,
        text: 'Customer'
    },
    {
        icons: ["fa-solid fa-circle-user", "fa-solid fa-circle-user"],
        customerNo: 2,
        text: 'Customers'
    },
    {
        icons: ["fa-solid fa-circle-user", "fa-solid fa-circle-user", "fa-solid fa-circle-user"],
        customerNo: 3,
        text: 'Customers'
    },
    {
        icons: ["fa-solid fa-circle-user", "fa-solid fa-circle-user", "fa-solid fa-circle-user", "fa-solid fa-circle-user"],
        customerNo: 4,
        text: 'Customers'
    }
]