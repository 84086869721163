import React from "react";
import { useState } from "react";
import managementLogo from "../../assets/images/management-logo.svg";
import loginImagePattern from "../../assets/images/login-image-pattern.svg";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/sessionexpired.css";
import LoginVerification from "../session-expired/LoginVerification";
import { H1, H4 } from "../../styles/Common.style";

const SessionExpired = () => {
  const [varifaction, setverifaction] = useState(true);

  const handleSendcode = () => {
    setverifaction(!varifaction);
  };
  const returnLoginPage = () => {
    navigate("/");
  };
  const navigate = useNavigate();
  return (
    <div>
      {varifaction ? (
        <div className="pt-4 pb-3 bg-login align-items-md-center px-4">
          <img
            src={managementLogo}
            alt="management-logo"
            className="management-logo d-block m-auto"
          />
          <Form>
            <Row className="m-0">
              <Col sm={12} md={11} lg={8} className="m-auto">
                <Row className="loginForm mt-3 m-auto">
                  <Col sm={6} md={6} className="login-right-section p-md-4 p-2">
                    <H1>Session expired</H1>
                    {/* <h2 className='fw-600'>Session expired</h2> */}
                    <div className="">
                      <div className="fw-300 mb-1 pt-4 pb-4">
                        To continue logging in, we need you to complete a
                        verification via SMS.
                      </div>

                      <div className="form-group">
                        <div className="pb-3 fw-500">
                          {" "}
                          <H4>Phone number</H4>
                        </div>

                        <div className="d-flex gap-2 ">
                          <div className="form-inpu-field-session-expire form-session input-value-color ">
                            <input
                              name="Country code"
                              id="Country code"
                              placeholder="Enter Country Code"
                              className="input-value-color"
                              value="+45"
                              style={{ width: "43%" }}
                            ></input>
                            <label className="fw-500 mb-1">Country code</label>
                          </div>

                          <div className=" form-inpu-field-session-expire">
                            <input
                              className="d-block w-100 radius-6 input-value-color"
                              name="Phone number"
                              placeholder="Enter Phone Number"
                              value="20 89 63 54"
                              id="Phone number"
                              readOnly
                            />
                            <label className="fw-500 mb-1">Phone</label>
                          </div>
                        </div>
                      </div>

                      <input
                        type="button"
                        value=" SEND CODE"
                        onClick={handleSendcode}
                        className="w-100 mt-3"
                        style={{
                          background: "#ADBE9E",
                          border: "none",
                          borderRadius: "6px",
                          color: "white",
                          padding: "6px",
                        }}
                      />

                      <div
                        className="ForgotPassword fs-16 m-0 fw-500 ft-16"
                        style={{
                          lineHeight: "18px",
                          color: "#ADBE9E",
                        }}
                      >
                        <p className="small mt-3 fw-500 cursor">
                          <span onClick={returnLoginPage}>Return to login</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} className="d-none d-sm-block">
                    <Image
                      className="w-100 h-100"
                      src={loginImagePattern}
                      alt="login-image-pattern"
                      style={{ objectFit: "cover", borderRadius: "8px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <LoginVerification />
      )}
    </div>
  );
};

export default SessionExpired;
