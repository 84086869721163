import "./App.css";
import "./styles/fonts/fonts.css";
import "./styles/fonts/common.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import "./styles/global.css";
import Main from "./views/Main";
import SessionExpired from "./views/session-expired/SessionExpired";
import CreateUser from "./views/create-user/CreateUser";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeviceStatus from "./views/device-status/DeviceStatus";
import ResetPassword from "./views/reset-password/ResetPassword";
import Theme from "./styles/Theme";
import ForgotPassword from "./views/login/forgotPassword/ForgotPassword";
import CreateNewPassword from "./views/login/createNewPassword/CreateNewPassword";
import { Login } from "./views/login/login/Login";
import CreateNewProfile from "./views/login/createNewProfile/CreateNewProfile";

function App() {
  const queryClient = new QueryClient();

  let { portfolio_id } = useParams();

    // get data to sessionStorage
  const jwt = sessionStorage.getItem("et-jwt");
    // console.log("GetSessionItem", jwt)

  return (
    <>
      <Theme>
        <div>
          <ToastContainer
            className = {"react-toastify"}
            position  = "top-center"
            autoClose = {2000}
            hideProgressBar
            newestOnTop = {false}
            closeOnClick
            rtl = {false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme      = "light"
            transition = {Slide}
          />
          <div>
            <QueryClientProvider client = {queryClient}>
              <ReactQueryDevtools
                initialIsOpen = {false}
                position      = {"bottom-left"}
              />
              <BrowserRouter>
                <Routes>
                  <Route path = "*" element                   = {<Main />} />
                  <Route path = "/" element                  = {<Login />} />
                  <Route path = "forgot-password" element     = {<ForgotPassword />} />
                  <Route path = "create-new-password" element = {<CreateNewPassword />} />
                  <Route path = "create-new-profile" element = {<CreateNewProfile />} />
                  <Route path = "session-expired" element     = {<SessionExpired />} />
                  <Route path = "create-user" element         = {<CreateUser />} />
                  <Route path = "device/status" element       = {<DeviceStatus />} />
                  <Route path = "reset/password" element      = {<ResetPassword />} />
                </Routes>
              </BrowserRouter>
            </QueryClientProvider>
          </div>
        </div>
      </Theme>
    </>
  );
}

export default App;
