// export default Checkdata

// import React, { useEffect, useState } from "react";

// const Checkdata = () => {

//   const [start, inputStart] = useState("");

//   const [errorStartEmpty, isErrorStartEmpty] = useState(false);

//   const validEmail = new RegExp(
//     /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/gm
//   );

//   return (
//     <div>

//       <form>
//         <label>Start date</label>
//         <input
//           type="date"
//           name="email"
//           value={start}
//           onChange={(e) => {
//             if (e.target.value === "") {
//               isErrorStartEmpty(true);
//             } else {
//               isErrorStartEmpty(false);
//             }
//             inputStart(e.target.value);
//           }}
//           onClick={(e) => {
//             if (e.target.value === "") {
//               isErrorStartEmpty(true);
//             }
//           }}
//         />
//         {errorStartEmpty ? <span>Start date can't be empty</span> : <></>}
//         <input type="submit" value="Submit" />
//       </form>
//     </div>
//   );
// };

// export default Checkdata;

// import {useState} from 'react';

// export default function Checkdata() {
//   const [message, setMessage] = useState('');
//  console.log(message.length)
//  console.log(message)

//   return (
//     <div>
//       <div>
//         <input
//           type="text"
//           id="message"
//           name="message"
//           value={message}
//           onChange={event => setMessage(event.target.value)}
//         />
//         <button disabled={!message} className={`${message.length >=1? "green":"bg-orange"}`}>Click</button>
//       </div>

//     </div>
//   );
// }

// import React, { useState } from "react";
// const Checkdata = () => {
//    const [password, setPassword] = useState('');
//    const [errorMessage, setErrorMessage] = useState("");
//    function handlePassword(event) {
//       let new_pass = event.target.value;
//       setPassword(new_pass);

//       // regular expressions to validate password
//       var lowerCase = /[a-z]/g;
//       var upperCase = /[A-Z]/g;
//       var numbers = /[0-9]/g;
//       var specialSymbol = /[@]/g;
//       if (!new_pass.match(lowerCase)) {
//          setErrorMessage("Password should contains lowercase letters!");
//       } else if (!new_pass.match(upperCase)) {
//          setErrorMessage("Password should contain uppercase letters!");
//       } else if (!new_pass.match(numbers)) {
//          setErrorMessage("Password should contains numbers also!");
//       }

//       else if (new_pass.length < 10) {
//          setErrorMessage("Password length should be more than 10.");

//       }
//       else if (specialSymbol){
//         setErrorMessage("starong");
//       }
//       else {
//          setErrorMessage("Password is strong!");
//       }
//    }
//    return (
//       <div>
//          <h2>
//             {" "}
//             Validate the password by creating the custom algorithm in ReactJS.{" "}
//          </h2>
//          <input type = "text" onChange = {handlePassword} />
//          <div style = {{ color: "red" }}> {errorMessage} </div>
//       </div>
//    );
// };
// export default Checkdata;
import React from "react";

// import StrongPassword from './StrongPassword'

import ImageCrop from "./ImageCrop";

const Checkdata = (props) => {
  return (
    <div>
      {/* <HookForm/> */}
      <ImageCrop />
    </div>
  );
};

export default Checkdata;
