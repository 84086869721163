import { useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "moment/locale/en-gb";
import moment from "moment/moment";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./events.css";
import { H1, H3, H4, H5 } from "../../styles/Common.style";
import EventDecline from "./EventDecline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EventConfirmation from "./EventConfirmation";
import { Paper } from "@mui/material";
import EventApproved from "./EventApproved";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import styled from "styled-components";
import Modal from "../../Components/widgets/modal/Modal";

const localizer = momentLocalizer(moment);

moment.locale("es-es", {
  week: {
    dow: 1, //Monday is the first day of the week.
  },
});

// Use to get get the name of the event of the respected date
const eventPropGetter = (event) => {
  // console.log("ecvent", event);
  if (event.isNew) {
    // Apply custom background for new events
    return {
      className: "new-event",
    };
  }
  return {}; // Return empty object for other events
};

const Flexdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const formats = {
  dateFormat: "D", // Used 'D' to display the date as a single digit
};

const EventCalendar = ({}) => {
  const calendarRef = useRef("");

  //-------------------state--------------------------//

  const [visibleMonth, setVisibleMonth] = useState("");

  // dummy event state
  const [events, setEvents] = useState([
    {
      title: "Dummy Event",
      start: new Date(2023, 10, 17, 10, 0),
      end: new Date(2023, 10, 17, 12, 0),
    },
  ]);

  // state and const for the Event Confirmation

  const [anchorElEventConfirmation, setAnchorElEventConfirmation] =
    useState(null);
  const [selectedEventConfirmation, setSelectedEventConfirmation] =
    useState(null);

  const handleEventClickEventConfirmation = (event, e) => {
    setSelectedEventConfirmation(event);
    setAnchorElEventConfirmation(e.currentTarget);
  };

  const handleCloseMenuEventConfirmation = () => {
    setAnchorElEventConfirmation(null);
  };

  const handleMenuItemClick = (menuItem) => {
    // Handle the menu item click
    console.log("Menu item clicked:", menuItem);
    // Optionally, you can perform additional actions here
    setAnchorElEventConfirmation(null);
  };

  // state and const for the Event Approved

  const [anchorElEventApproved, setAnchorElEventApproved] = useState(null);
  const [selectedEventEventApproved, setSelectedEventApproved] = useState(null);

  const handleEventClickEventApproved = (event, e) => {
    setSelectedEventApproved(event);
    setAnchorElEventApproved(e.currentTarget);
  };

  const handleCloseMenuEventApproved = () => {
    setAnchorElEventApproved(null);
  };

  // state and const for the Event Decline

  const [anchorElEventDecline, setAnchorElEventDecline] = useState(null);
  const [selectedEventEventDecline, setSelectedEventDecline] = useState(null);

  const handleEventClickEventDecline = (event, e) => {
    setSelectedEventDecline(event);
    setAnchorElEventDecline(e.currentTarget);
  };

  const handleCloseMenuEventDecline = () => {
    setAnchorElEventDecline(null);
  };

  const [openFollowUpEvent, setOpenFollowUpEvent] = useState(false);
  const [openHouseEventData, setOpenHouseEventData] = useState({
    eventId: "",
    eventCalendarEntryId: "",
  });
  const [openPrivateFollowUpEvent, setOpenPrivateFollowUpEvent] =
    useState(false);

  // message state and props
  const [openSendMessage, setopenSendMessage] = useState(false);

  const [selectedOptionTwo, setSelectedOptionTwo] = useState(false);
  const [deleteShowModal, setDeleteShowModal] = useState(false);

  //-------------------Zustand--------------------------//

  //   const propertyId = usePropertyId((state) => state.propertyId);
  //   const AgentuserDetails = useUserDetails((state) => state.userDetails);
  //   const AgentuserId = Number(AgentuserDetails?.user_id);
  //   const EventType = 0;

  //-------------------react query--------------------------//

  //   const { data: GetEventsByPropertyId, refetch: GetEventsByPropertyIdRefetch } =
  //     useGetEventsByPropertyId(propertyId, EventType, AgentuserId);
  //   const { data: GetEventsDetailByEventId } = useGetEventsDetailByEventId(
  //     propertyId,
  //     openHouseEventData?.eventId,
  //     openHouseEventData?.eventCalendarEntryId
  //   );

  //   function formatEventsForCalendar(GetEventsByPropertyId) {
  //     return GetEventsByPropertyId?.data?.map((event) => {
  //       const {
  //         event_date,
  //         time_start,
  //         time_end,
  //         event_type_name,
  //         event_type_id,
  //         event_calendar_entry_id,
  //         event_id,
  //         responsible_agent_name,
  //       } = event;

  //       // Split the startTime and endTime into hours and minutes
  //       const startTimeParts = time_start.split(":");
  //       const endTimeParts = time_end.split(":");

  //       // Parse the event date
  //       const eventDateParts = event_date.split("-");
  //       const year = parseInt(eventDateParts[0]);
  //       const month = parseInt(eventDateParts[1]) - 1; // Adjust for 0-based month
  //       const day = parseInt(eventDateParts[2]);

  //       // Create Date objects in the desired format
  //       const start = new Date(
  //         year,
  //         month,
  //         day,
  //         parseInt(startTimeParts[0]),
  //         parseInt(startTimeParts[1])
  //       );
  //       const end = new Date(
  //         year,
  //         month,
  //         day,
  //         parseInt(endTimeParts[0]),
  //         parseInt(endTimeParts[1])
  //       );
  //       const title = event_type_name;
  //       const eventTypeId = event_type_id;
  //       const eventCalendarEntryId = event_calendar_entry_id;
  //       const eventId = event_id;
  //       const responsibleAgentName = responsible_agent_name;

  //       return {
  //         title,
  //         start,
  //         end,
  //         eventTypeId,
  //         eventCalendarEntryId,
  //         eventId,
  //         responsibleAgentName,
  //       };
  //     });
  //   }

  // const formattedEvents = formatEventsForCalendar(GetEventsByPropertyId);

  const handleNavigate = (date, view) => {
    if (view === "month") {
      setVisibleMonth(date);
    }
  };
  const CustomToolbar = ({ label, date, onNavigate, view, onView }) => {
    const handleNext = () => {
      // Custom logic for the next button
      // For example, you can navigate to the next month
      if (view === "month") {
        onNavigate("next", moment(date).add(1, "month"));
      } else if (view == "week") {
        onNavigate("next", moment(date).add(1, "week"));
      } else if (view == "day") {
        onNavigate("next", moment(date).add(1, "day"));
      }
    };

    const handlePrev = () => {
      // Custom logic for the back button
      // For example, you can navigate to the previous month
      if (view === "month") {
        onNavigate("prev", moment(date).subtract(1, "month"));
      } else if (view == "week") {
        onNavigate("prev", moment(date).subtract(1, "week"));
      } else if (view == "day") {
        onNavigate("prev", moment(date).subtract(1, "day"));
      }
    };

    const handleViewChange = (view) => {
      onView(view);
    };

    return (
      <>
        <div className="rbc-toolbar pt-1">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center gap-2">
              <div className="dot bg-event-color"></div>
              <div className="caption-regular">Event</div>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div className="dot bg-private-view-color"></div>
              <div className="caption-regular">Private viewing</div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <button className="rbc-btn rbc-btn-nav-prev" onClick={handlePrev}>
              <FontAwesomeIcon
                icon="fa-regular fa-angle-left"
                size="lg"
                style={{ color: "#000000" }}
              />
            </button>
            <H3 className="mx-3 mt-2">{label}</H3>
            <button className="rbc-btn rbc-btn-nav-next" onClick={handleNext}>
              <FontAwesomeIcon
                icon="fa-regular fa-angle-right"
                size="lg"
                style={{ color: "#000000" }}
              />
            </button>
          </div>
          <div></div>
        </div>
      </>
    );
  };

  const CustomEvent = ({ event }) => {
    // Used to Customize the rendering of the event component
    const startTime = moment(event.start).format("HH:mm");

    const endTime = moment(event.end).format("HH:mm");
    return (
      <>
        <div
          className="d-flex align-items-center"
          onClick={() => {
            if (event.title === "Private view") {
              setOpenPrivateFollowUpEvent(true);
            } else {
              setOpenFollowUpEvent(true);
              setOpenHouseEventData((prev) => {
                return {
                  ...prev,
                  eventCalendarEntryId: event?.eventCalendarEntryId,
                  eventId: event?.eventId,
                };
              });
            }
          }}
        >
          <div className="light-font-text fs-12 me-1 ">
            {" "}
            {startTime} - {endTime}
          </div>

          <div className="light-font-text fs-13 me-1 ">{event.time}</div>

          <div className="event-section-title fs-13">{event.title}</div>
          {/* {
            <div className="ms-2">
              <FontAwesomeIcon
                icon="fa-regular fa-arrows-repeat"
                style={{ color: "#000000" }}
              />
            </div>
          } */}
        </div>
      </>
    );
  };

  //   const filteredAgent = selectedFilter // filter the agent form calender
  //     ? formattedEvents?.filter(
  //         (event) => event?.responsibleAgentName === selectedFilter?.value
  //       )
  //     : formattedEvents;

  //   const filteredEvents = selectedEventType // filter the event type form calender
  //     ? formattedEvents?.filter(
  //         (event) => event?.title === selectedEventType?.value
  //       )
  //     : formattedEvents;

  // const CustomEventWrapper = ({ event, children }) => {
  //   // Check if there are multiple events on the same date
  //   const isMultipleEvents = event.start === ComparedDate;

  //   // Apply specific styling or behavior for multiple events
  //   const wrapperStyle = isMultipleEvents ? { backgroundColor: "red" } : {};

  //   return (
  //     <div className="new" style={wrapperStyle}>
  //       {children}
  //     </div>
  //   );
  // };

  const calendarConfig = {
    components: {
      event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };

  return (
    <div className="px-3">
      <div className="mb-4">
        {" "}
        <H3 color="#111747">Events calendar</H3>
      </div>
      <div
        className="my-main-event-calendar p-5 radius-8"
        style={{ height: "747px", backgroundColor: "#fff" }}
      >
        <Calendar
          ref={calendarRef}
          eventPropGetter={eventPropGetter}
          toolbar={(toolbarProps) => <CustomToolbar {...toolbarProps} />}
          showToday={false}
          events={events}
          defaultView="month"
          views={["month", "week", "day"]}
          //   events={filteredAgent && filteredEvents}
          formats={formats}
          localizer={localizer}
          components={calendarConfig.components}
          onNavigate={handleNavigate}
          onSelectEvent={handleEventClickEventConfirmation}
        />
        {/* Event Confirmation */}
        <Menu
          sx={{
            ".css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderRadius: "8px",
            },
            ".MuiPaper-root": {
              border: "1px solid #999",
              // padding: 0,
              borderRadius: "8px !important",
            },
          }}
          anchorEl={anchorElEventConfirmation}
          open={Boolean(anchorElEventConfirmation)}
          onClose={handleCloseMenuEventConfirmation}
        >
          {/* <EventConfirmation
            handleEventClickEventDecline={handleEventClickEventDecline}
            handleEventClickEventApproved={handleEventClickEventApproved}
            handleCloseMenuEventConfirmation={handleCloseMenuEventConfirmation}
          /> */}
          <div className="private-view-card-container">
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div
                className="bg-private-view-color square"
                style={{ marginTop: "2px" }}
              ></div>
              <div>
                <H4 className="mb-1">Mads Johnson private viewing</H4>
                <div className="caption-regular">Private viewing</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-user" />
              </div>
              <div>
                <H5 className="mb-1">Agent</H5>
                <div className="paragraph2-light">Mads Johnson</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-clock" size="sm" />
              </div>
              <div>
                <H5 className="mb-1">Date and time</H5>
                <div className="regular fs-13 mb-1">
                  Sunday 5th February 2023
                </div>
                <div className="regular fs-13">10:00 to 11:00 (1 hours)</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-location-dot" size="sm" />
              </div>
              <div>
                <H5 className="mb-1">Unit address</H5>
                <div className="paragraph2-regular">Blomsterbjerget</div>
                <div className="regular fs-13">Blomstervej 14, 3. tv</div>
                <div className="regular fs-13">2765 Smørum</div>
                <div className="regular fs-13">7-W260</div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 p-3">
              <div>
                <NormalButton
                  icon={
                    // <FontAwesomeIcon
                    //   icon="fa-solid fa-xmark"
                    //   size="xl"
                    //   color="#E33716"
                    // />
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-check"
                      size="xl"
                      color="rgba(255, 255, 255, 1)"
                    />
                  }
                  iconbutton={true}
                  type="submit"
                  textColor=" white"
                  backgroundColor="green"
                  border="0.5px solid #D1D1D1"
                  padding="10px 24px"
                  borderRadius="6"
                  fontSize="14px"
                  buttonText={"APPROVE"}
                  onClick={(e) => {
                    handleEventClickEventApproved(e, e);
                    handleCloseMenuEventConfirmation();
                  }}
                />
              </div>
              <div>
                <NormalButton
                  icon={
                    <FontAwesomeIcon
                      icon="fa-solid fa-xmark"
                      size="xl"
                      color="rgba(255, 255, 255, 1)"
                    />
                  }
                  iconbutton={true}
                  type="submit"
                  textColor=" white"
                  backgroundColor="red"
                  border="0.5px solid #D1D1D1"
                  padding="10px 24px"
                  borderRadius="6"
                  fontSize="14px"
                  buttonText={"DECLINE"}
                  // onClick={handleEventClickEventDecline}
                  onClick={(e) => {
                    handleEventClickEventDecline(e, e);
                    handleCloseMenuEventConfirmation();
                  }}
                />
              </div>
            </div>
          </div>
        </Menu>

        {/* Event Approved */}
        <Menu
          sx={{
            ".css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderRadius: "8px",
            },
            ".MuiPaper-root": {
              border: "1px solid #999",

              borderRadius: "8px !important",
              // maxHeight: "none",
            },
          }}
          anchorEl={anchorElEventApproved}
          open={Boolean(anchorElEventApproved)}
          onClose={handleCloseMenuEventApproved}
        >
          {/* <EventApproved
            handleCloseMenuEventApproved={handleCloseMenuEventApproved}
            handleEventClickEventDecline={handleEventClickEventDecline}
            handleCloseMenuEventConfirmation={handleCloseMenuEventConfirmation}
          /> */}
          <div className="private-view-card-container">
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div
                className="bg-private-view-color square"
                style={{ marginTop: "2px" }}
              ></div>
              <div>
                <H4 className="mb-1">Mads Johnson private viewing</H4>
                <div className="caption-regular">Private viewing</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-user" />
              </div>
              <div>
                <H5 className="mb-1">Agent</H5>
                <div className="paragraph2-light">Mads Johnson</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-clock" size="sm" />
              </div>
              <div>
                <H5 className="mb-1">Date and time</H5>
                <div className="regular fs-13 mb-1">
                  Sunday 5th February 2023
                </div>
                <div className="regular fs-13">10:00 to 11:00 (1 hours)</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-location-dot" size="sm" />
              </div>
              <div>
                <H5 className="mb-1">Unit address</H5>
                <div className="paragraph2-regular">Blomsterbjerget</div>
                <div className="regular fs-13">Blomstervej 14, 3. tv</div>
                <div className="regular fs-13">2765 Smørum</div>
                <div className="regular fs-13">7-W260</div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center gap-2 pt-3 pb-4 events-headers-border">
              <div>
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-check"
                  size="xl"
                  color="#54A64D"
                />
              </div>
              <H4 className="mb-1">You have accepted this event</H4>
            </div>
            <div className="d-flex flex-column align-items-center gap-2 pt-3 pb-4">
              <NormalButton
                icon={
                  <FontAwesomeIcon
                    icon="fa-solid fa-xmark"
                    size="xl"
                    color="#E33716"
                  />
                }
                iconbutton={true}
                type="submit"
                textColor=" black"
                backgroundColor="gray"
                border="0.5px solid #D1D1D1"
                padding="10px 24px"
                borderRadius="6"
                fontSize="14px"
                onClick={(e) => {
                  handleEventClickEventDecline(e, e);
                  handleCloseMenuEventApproved();
                }}
                buttonText={"DECLINE"}
              />
            </div>
          </div>
        </Menu>
        {/* Event Decline */}
        <Menu
          sx={{
            ".css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderRadius: "8px",
            },
            ".MuiPaper-root": {
              border: "1px solid #999",

              borderRadius: "8px !important",
              // maxHeight: "none",
            },
          }}
          anchorEl={anchorElEventDecline}
          open={Boolean(anchorElEventDecline)}
          onClose={handleCloseMenuEventDecline}

          // PaperProps={{
          //   style: {
          //     maxHeight: "none", // Set a specific maxHeight if needed, or 'none' for no limit
          //   },
          // }}
        >
          {/* <EventDecline
            setAnchorElEventDecline={setAnchorElEventDecline}
            openSendMessage={openSendMessage}
            setopenSendMessage={setopenSendMessage}
          /> */}
          <div className="private-view-card-container">
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div
                className="bg-private-view-color square"
                style={{ marginTop: "2px" }}
              ></div>
              <div>
                <H4 className="mb-1">Mads Johnson private viewing</H4>
                <div className="caption-regular">Private viewing</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-user" />
              </div>
              <div>
                <H5 className="mb-1">Agent</H5>
                <div className="paragraph2-light">Mads Johnson</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-clock" size="sm" />
              </div>
              <div>
                <H5 className="mb-1">Date and time</H5>
                <div className="regular fs-13 mb-1">
                  Sunday 5th February 2023
                </div>
                <div className="regular fs-13">10:00 to 11:00 (1 hours)</div>
              </div>
            </div>
            <div className="d-flex gap-2 py-3 px-3 events-headers-border">
              <div>
                <FontAwesomeIcon icon="fa-regular fa-location-dot" size="sm" />
              </div>
              <div>
                <H5 className="mb-1">Unit address</H5>
                <div className="paragraph2-regular">Blomsterbjerget</div>
                <div className="regular fs-13">Blomstervej 14, 3. tv</div>
                <div className="regular fs-13">2765 Smørum</div>
                <div className="regular fs-13">7-W260</div>
              </div>
            </div>
            {/* <Row className="mb-3">
        <Col lg={12}> */}
            <div className="px-4 pt-2 pb-4">
              <H5>Why are you declining the event?</H5>
              <div className="d-flex form-message-input position-relative">
                <textarea
                  type="text"
                  style={{
                    resize: "none",
                    border: "1px solid #d1d1d1",
                  }}
                  placeholder="Start Writing..."
                  id="Message"
                  className="Message pe-5"
                  // {...register("Message", {
                  //   required: true,
                  //   onChange: (e) => {
                  //     setFormValue({
                  //       ...formValue,
                  //       Message: e.target.value,
                  //     });
                  //   },
                  // })}
                />
                {/* <label htmlFor="Lastname">Message</label> */}
                <Flexdiv
                  style={{
                    position: "absolute",
                    bottom: "12px",
                    right: "12px",
                  }}
                >
                  <button
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      border: "none",
                      backgroundColor: "#ADBE9E",
                      borderRadius: "4px",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={() => {
                      console.log("open");
                      setAnchorElEventDecline(null);
                      setopenSendMessage(true);
                      console.log("close");
                    }}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-paper-plane-top"
                      style={{ color: "white" }}
                    />
                  </button>
                </Flexdiv>
              </div>
              {/* {errors.Message && (
              <p className="error-msg" style={{ color: "red" }}>
                Message is Required.
              </p>
            )} */}
            </div>
            {/* </Col>
      </Row> */}
          </div>
        </Menu>
        <Modal
          size="sm"
          open={openSendMessage}
          className="modal-dialog-centered"
          onHide={() => setopenSendMessage(false)}
          title={
            <div className="d-flex justify-content-between px-4 py-3">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div>
                  <H3>Message sent</H3>
                </div>
              </div>
              <div onClick={() => setopenSendMessage(false)}>
                <FontAwesomeIcon
                  className="cursor"
                  icon="fa-regular fa-xmark"
                />
              </div>
            </div>
          }
          title_color="black"
          onCloseAction={() => {
            setopenSendMessage(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <div className="paragraph2-light p-5">Your message has been sent</div>

          <div
            className="d-flex justify-content-between p-2"
            style={{
              borderTop: "1px solid #d1d1d1",
              background: "#f7f8fa",
              borderRadius: "0 0 12px 12px",
            }}
          >
            <div></div>
            <div>
              <NormalButton
                type="submit"
                className="fw-900"
                textColor="white"
                border="none"
                onClick={() => setopenSendMessage(false)}
                backgroundColor="green"
                buttonText="CLOSE"
                //   disabled={PostShareDocumentIsLoading}
                padding="10px 24px"
                borderRadius="6"
                fontSize="14"
                fontWeight="500"
              />
            </div>
          </div>
        </Modal>

        {/* <Menu
          sx={{
            ".css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderRadius: "8px",
            },
            ".MuiPaper-root": {
              border: "1px solid #999",
              // padding: 0,
              borderRadius: "8px !important",
            },
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <EventApproved handleCloseMenu={handleCloseMenu} />
        </Menu> */}
      </div>

      {/* <FollowUpEventsPopup
        setShowOpenHousePropertyFollowUp={setShowOpenHousePropertyFollowUp}
        setOpenFollowUpEvent={setOpenFollowUpEvent}
        openFollowUpEvent={openFollowUpEvent}
        GetEventsByPropertyId={GetEventsByPropertyId}
        setSelectedOptionTwo={setSelectedOptionTwo}
        setShowModal={setDeleteShowModal}
        GetEventsDetailByEventId={GetEventsDetailByEventId}
      />

      <FollowUpPrivateEventPopup
        setOpenPrivateFollowUpEvent={setOpenPrivateFollowUpEvent}
        openPrivateFollowUpEvent={openPrivateFollowUpEvent}
        setShowPrivateEvent={setShowPrivateEvent}
      />

      <DeleteEvents
        selectedOptionTwo={selectedOptionTwo}
        close={setDeleteShowModal}
        open={deleteShowModal}
        GetEventsDetailByEventId={GetEventsDetailByEventId}
        propertyId={propertyId}
        openHouseEventData={openHouseEventData}
        setOpenFollowUpEvent={setOpenFollowUpEvent}
        GetEventsByPropertyIdRefetch={GetEventsByPropertyIdRefetch}
      /> */}
      {/* <EventDecline /> */}
    </div>
  );
};

export default EventCalendar;
