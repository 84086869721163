import { Endpoint, Site } from "./API/Endpoints";
import { Get, Post } from "./API/Method";

// GetScreeningTemplateQuestion
export const GetScreeningForm = (customerId, formId) => {

  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.GetScreeningForm,
      null,
      customerId + "/screening/form/" + formId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response?.data.message,
          code: error.response?.data.code,
        });
      });
  });
};

// Get Screening Template Question's Answer
export const GetScreeningTemplateQuestionAnswer = (prop_id, contract_id, actor_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.GetScreeningTemplateQuestionAnswer,
      null,
      prop_id + "/contract/" + contract_id + "/actor/" +  actor_id + "/answers"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};

// Post Screening Template Question's Answer
export const PostAddScreeningResponse = (screeningAnswerPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.PostAddScreeningResponse,
      { "Content-Type": "multipart/form-data" },
      screeningAnswerPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};


// Credit check PostAddRKI
export const PostAddRKI = (RKIPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.PostAddRKI,
      null,
      {
        "property_id": 923,
        "contract_id": 374,
        "actor_id": 100489929779842857,
      }
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
}

