import { Col, Row } from "react-bootstrap";
import {
  TypeDate,
  TypeTextarea,
  TypeNumber,
  TypeRadio,
  TypeText,
  TypeFile,
  TypeCheckbox,
  TypeSelect,
  TypeEmail,
} from "./FormFieldsComponent";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useIsFormValueChanged, useScreeningFormAnswerStore } from "../../../hooks/stores/useScreeningFormStore";
import { useScreeningFormStore } from "../../../hooks/stores/useScreeningFormStore";
import MultiSelect from "../../../Components/Dropdown/MultiSelect";
import { useEffect, useState } from "react";
import {
  useGetCountries,
  useGetZipcodeByCountryId,
} from "../../../hooks/queries/useLocationQueries";
import ReactSelect from "../../../Components/input/ReactSelect";
import CustomReactSelect from "../../../Components/Dropdown/CustomReactSelect";
import { useActorProfileStore } from "../../../hooks/stores/useActorDetails";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { usePostAddRKI } from "../../../hooks/queries/useScreeningQueries";
import {
  useUnitId,
  useUnitInformationStore,
} from "../../../hooks/stores/useUnitStore";
import { toast } from "react-toastify";
import ScreeningCreditCheck from '../ScreeningCreditCheck';
import { Flags } from './../Flags';

const DynamicScreenForm = ({
  data,
  index,
  parentSubmitRef,
  parentPrevRef,
  nextPage,
  prevPage,
  isConditionTrue
}) => {
  const actorProfile                            = useActorProfileStore((state) => state.actorDetail);
  const { unitInformation, setUnitInformation } = useUnitInformationStore(
    (state) => state
  );

  const { UnitId, setUnitId } = useUnitId((state) => state);

  const Screening_Form_State = useScreeningFormStore(
    (state) => state.Screening_Form_State
  );
  const setScreening_Form_State = useScreeningFormStore(
    (state) => state.setScreening_Form_State
  );

  const Screening_Form_Answer_State = useScreeningFormAnswerStore(
    (state) => state.screeningFormAnswerStore
  );
  const setScreening_Form_Answer_State = useScreeningFormAnswerStore(
    (state) => state.setScreeningFormAnswerStore
  );

  const [creditCheck, setCreditCheck]               = useState(null)
  const [creditCheckClicked, setCreditCheckClicked] = useState(false)

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    unregister,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: Screening_Form_Answer_State,
  });

  const {
    data   : getZipcodeByCountryIdData,
    refetch: getZipcodeByCountryIdDataRefetch,
  } = useGetZipcodeByCountryId(watch("11")); // country is coming from api which has question id 9

  const { mutateAsync: PostAddRKI, isLoading: PostAddRKIIsLoading } = 
    usePostAddRKI();

  const { data: getCountriesData } = useGetCountries(actorProfile?.country_id);
  const { setIsFormValueChanged } = useIsFormValueChanged();

  // This are the all components which will render as per fields coming from API
  const fieldTypeRender = {
    text: (data, field, fieldState, setValue) => (
      <TypeText
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    radio: (data, field, fieldState, setValue) => (
      <TypeRadio
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    number: (data, field, fieldState, setValue) => (
      <TypeNumber
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    textarea: (data, field, fieldState, setValue) => (
      <TypeTextarea
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    date: (data, field, fieldState, setValue) => (
      <TypeDate
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    file: (data, field, fieldState, setValue) => (
      <TypeFile
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    checkbox: (data, field, fieldState, setValue) => (
      <TypeCheckbox
      field      = {field}
      data       = {data}
      fieldState = {fieldState}
      setValue   = {setValue}
      />
    ),
    select: (data, field, fieldState, setValue) => (
      <TypeSelect
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
    ),
    email: (data, field, fieldState, setValue) =>  (
        <TypeEmail
        field      = {field}
        data       = {data}
        fieldState = {fieldState}
        setValue   = {setValue}
      />
      )
  };

  // question ID which we don't have to render in frontend as this are groups which field type is uniq
  const uniqField = ["name", "phone", "address"];

  // funcition which will check if the next form category should call or sub category form should call
  const nextForm = (hookFormData) => {
    setScreening_Form_Answer_State({
      ...Screening_Form_Answer_State,
      ...hookFormData,
    });

    setIsFormValueChanged(false);
    
    // Checking if any question have red/yellow flag answer
    let findFlagRaiseAnswer = data.section[Screening_Form_State?.currentFormSubIndex]?.question.filter((q) => q.screening_condition_rules?.rules?.length);
    const currentQueAnswer = hookFormData[findFlagRaiseAnswer?.[0]?.screening_question_id]
    if(findFlagRaiseAnswer?.length && !Screening_Form_State?.isFlagConditionActivate && currentQueAnswer?.length && Screening_Form_State.fieldConditionQueIndex === null) {
      const logic_type = findFlagRaiseAnswer[0]?.screening_condition_rules?.logic_type
      const isFlagValid = logic_type === 1 ? findFlagRaiseAnswer[0]?.screening_condition_rules?.rules?.every((any) => isConditionTrue(any?.operator, any?.value, currentQueAnswer)) : logic_type === 2 ? findFlagRaiseAnswer[0]?.screening_condition_rules?.rules?.some((any) => isConditionTrue(any?.operator, any?.value, currentQueAnswer)) : false;
      if(findFlagRaiseAnswer[0]?.screening_condition_rules?.show_message && isFlagValid) {
        return setScreening_Form_State({
          ...Screening_Form_State,
          isFlagConditionActivate: true,
          activeFlagData         : findFlagRaiseAnswer[0],
          isOverViewBackBtnTrue  : Screening_Form_State?.isOverViewBtnTrue ? true : Screening_Form_State?.isOverViewBackBtnTrue
        });
      }
    }
    
    let findNextQuestionId = [];

    for(let i=0; i<data.section[Screening_Form_State?.currentFormSubIndex]?.question?.length; i++) {
      const currenQuestion       = data.section[Screening_Form_State?.currentFormSubIndex]?.question[i]

      const currentQuestionRules = currenQuestion?.field_condition_rules?.rules
      if(currentQuestionRules?.length) {
        const currentQuestionAns = hookFormData[currenQuestion?.screening_question_id];
        findNextQuestionId = currentQuestionRules.filter(cqr => {
          if(isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns)) {
            return isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns)
          }
          unregister(cqr.screening_question_id);
          delete hookFormData[cqr.screening_question_id];
          return false;
        })
        break;
      }
    }

    setScreening_Form_Answer_State({
      ...hookFormData,
    });
    
    // field condition activate
    if(findNextQuestionId?.length && Screening_Form_State.fieldConditionQueIndex === null ) {
      let fieldConditionQueIndex = data.section[Screening_Form_State?.currentFormSubIndex]?.question?.findIndex((sq) => sq?.screening_question_id === findNextQuestionId[0]?.screening_question_id);
      return setScreening_Form_State({
        ...Screening_Form_State,
        isFieldConditionActivate: true,
        currentFieldCondition   : findNextQuestionId,
        fieldConditionQueIndex  : fieldConditionQueIndex,
        isFlagConditionActivate : false,
        activeFlagData          : {},
        lockedFlagCondition     : false,
        isOverViewBackBtnTrue   : Screening_Form_State?.isOverViewBtnTrue ? true : Screening_Form_State?.isOverViewBackBtnTrue
      });
    }
    
    if (Screening_Form_State.currentFormSubIndex < data.section.length - 1) {
      setScreening_Form_State({
        ...Screening_Form_State,
        currentFormSubIndex               : Screening_Form_State.currentFormSubIndex + 1,
        currentIndexIncludingFormSubWizard: !Screening_Form_State?.isOverViewBtnTrue ?
          Screening_Form_State.currentIndexIncludingFormSubWizard + 1 : Screening_Form_State.currentIndexIncludingFormSubWizard,
          isFlagConditionActivate : false,
          activeFlagData          : {},
          lockedFlagCondition     : false,
          isOverViewBackBtnTrue   : Screening_Form_State?.isOverViewBtnTrue ? true : Screening_Form_State?.isOverViewBackBtnTrue
      });
    } else {
      nextPage();
    }
  };

  // funcition which will check if the previews form category should call or sub category form should call
  const previewForm = (hookFormData) => {
    // populating all form data to form answer store to fetch it again and show it in the form
    setScreening_Form_Answer_State({
      ...Screening_Form_Answer_State,
      ...hookFormData,
    });

    
    setIsFormValueChanged(false);

    let findFlagRaiseAnswer = data?.section[Screening_Form_State?.currentFormSubIndex]?.question?.filter((q) => q.screening_condition_rules?.rules?.length)
    const currentQueAnswer = hookFormData[findFlagRaiseAnswer?.[0]?.screening_question_id]
    if(Screening_Form_State?.isFieldConditionActivate) {
      if(findFlagRaiseAnswer?.length && !Screening_Form_State?.isFlagConditionActivate && hookFormData[findFlagRaiseAnswer?.[0]?.screening_question_id].length && hookFormData[findFlagRaiseAnswer?.[0]?.screening_question_id]?.length && !Screening_Form_State.lockedFlagCondition) {
        const logic_type = findFlagRaiseAnswer[0]?.screening_condition_rules?.logic_type
        const isFlagValid = logic_type === 1 ? findFlagRaiseAnswer[0]?.screening_condition_rules?.rules?.every((any) => isConditionTrue(any?.operator, any?.value, currentQueAnswer)) : logic_type === 2 ? findFlagRaiseAnswer[0]?.screening_condition_rules?.rules?.some((any) => isConditionTrue(any?.operator, any?.value, currentQueAnswer)) : false;
        if(findFlagRaiseAnswer[0]?.screening_condition_rules?.show_message && isFlagValid) {
          return setScreening_Form_State({
            ...Screening_Form_State,
            isFieldConditionActivate: false,
            currentFieldCondition   : null,
            fieldConditionQueIndex  : null,
            isFlagConditionActivate : true,
            activeFlagData          : findFlagRaiseAnswer[0],
            isOverViewBackBtnTrue   : Screening_Form_State.currentFormSubIndex - 1 === 0 ? false : Screening_Form_State?.isOverViewBackBtnTrue
          })
        }else {
          return setScreening_Form_State({
            ...Screening_Form_State,
            isFieldConditionActivate: false,
            currentFieldCondition   : null,
            fieldConditionQueIndex  : null,
            isFlagConditionActivate : false,
            activeFlagData          : {},
            isOverViewBackBtnTrue   : Screening_Form_State.currentFormSubIndex - 1 === 0 ? false : Screening_Form_State?.isOverViewBackBtnTrue
          })
        }
      }else {
        return setScreening_Form_State({
          ...Screening_Form_State,
          isFieldConditionActivate: false,
          currentFieldCondition   : null,
          fieldConditionQueIndex  : null,
          isOverViewBackBtnTrue   : Screening_Form_State.currentFormSubIndex === 0 ? false : Screening_Form_State?.isOverViewBackBtnTrue
        })
      }
    }

    if(Screening_Form_State.currentFormSubIndex) {
      setScreening_Form_State({
        ...Screening_Form_State,
        currentFormSubIndex               : Screening_Form_State.currentFormSubIndex - 1,
        currentIndexIncludingFormSubWizard: 
          Screening_Form_State.currentIndexIncludingFormSubWizard - 1,
          isFieldConditionActivate: false,
          currentFieldCondition   : null,
          fieldConditionQueIndex  : null,
          isFlagConditionActivate : false,
          activeFlagData          : {},
          isOverViewBackBtnTrue   : Screening_Form_State.currentFormSubIndex === 0 ? false : Screening_Form_State?.isOverViewBackBtnTrue
      });
    }else {
      for(let i=0; i<data.section[Screening_Form_State?.currentFormSubIndex]?.question?.length; i++) {
        const currenQuestion       = data.section[Screening_Form_State?.currentFormSubIndex]?.question[i]
  
        const currentQuestionRules = currenQuestion?.field_condition_rules?.rules
        if(currentQuestionRules?.length) {
          const currentQuestionAns = hookFormData[currenQuestion?.screening_question_id];
          currentQuestionRules.forEach(cqr => {
            if(isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns)) {
              return isConditionTrue(cqr?.operator, cqr?.value, currentQuestionAns)
            }
            delete hookFormData[cqr.screening_question_id];
            return false;
          })
          break;
        }
      }
      setScreening_Form_Answer_State({
        ...hookFormData,
      });
      prevPage();
    }
  };

  const hidePrevFlagPage = () => {
     if(Screening_Form_State?.isFlagConditionActivate) {
      return setScreening_Form_State({
        ...Screening_Form_State,
        isFieldConditionActivate: false,
        currentFieldCondition   : null,
        fieldConditionQueIndex  : null,
        isFlagConditionActivate : false,
        activeFlagData          : {},
        lockedFlagCondition     : true,
      });
    }
  }

  // Check wheter a actor have credit check or not
  const handleCreditCheck = async () => {
    let id;
    if (!PostAddRKIIsLoading) {
      setCreditCheckClicked(true)
    }
    const payload = {
      property_id: 923,
      contract_id: 374,
      actor_id   : 100489929779842857,
    };
    PostAddRKI(payload).then(
      (response) => {
        if (response?.data?.bReturn) {
          setCreditCheck(true);
              // getScreeningTemplateQuestionAnswerRefetch();
              // toast.update(id, {
              //   render: response?.data?.message,
              //   isLoading: false,
              //   type: "success",
              //   autoClose: 2000,
              // });
        } else {
          setCreditCheck(false);
              // toast.update(id, {
              //   render: response?.data?.message,
              //   isLoading: false,
              //   type: "error",
              //   autoClose: 2000,
              // });
        }
      },
      (error) => {
        toast.update(id, {
          render   : "Failed to Submit answer",
          isLoading: false,
          type     : "error",
          autoClose: 2000,
        });
      }
    );
  };

  const GroupField = ({ groupData }) => {
    const parentGroupData = data.section[
      Screening_Form_State.currentFormSubIndex
    ].question.filter(
      (q) => q.screening_field_id === groupData[0].parent_screening_field_id
    );
    return (
      <Row className = "mt-3 mb-3">
      <div className = "text-start fs-14 fw-bold">
          {parentGroupData?.[0]?.placeholder}{" "}
          {Boolean(parentGroupData?.[0]?.is_required) && "*"}
        </div>
        {groupData.map((d) => {
          return (
            <Col key = {d.screening_question_id}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${d.screening_question_id}`}
                  // rules        = {{ required: d?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <div key = {d.screening_question_id}>
                      <div>
                        {fieldTypeRender[d.field_type](
                          d,
                          field,
                          fieldState,
                          setValue
                        )}
                      </div>
                      {errors?.[field.name] && field.value === "" && (
                        <p className = "error-msg text-start" style = {{ color: "red" }}>
                          This field is required.
                        </p>
                      )}
                    </div>
                  );
                }}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const SingleField = ({ data }) => {
    return (
      <div className = "mt-3">
      <div className = "text-start fs-14 fw-bold px-2 ms-1">
          {data[0]?.description} {Boolean(data[0]?.is_required) && "*"}
        </div>
        <Controller
          key          = {data[0].screening_question_id}
          defaultValue = ""
          control      = {control}
          name         = {`${data[0].screening_question_id}`}
            // rules        = {{ required: `${ data[0]?.is_required}`}}
          render={({ field, fieldState }) => {
            return (
              <Row key = {data[0].screening_question_id} className = "mb-3">
                <Col>
                  {fieldTypeRender[data[0].field_type](
                    data[0],
                    field,
                    fieldState,
                    setValue
                  )}
                </Col>
              </Row>
            );
          }}
        />
      </div>
    );
  };

  const AddressField = ({ addressData }) => {
    const parentGroupData = data.section[
      Screening_Form_State.currentFormSubIndex
    ].question.filter(
      (q) => q.screening_field_id === addressData[0].parent_screening_field_id
    );
    return (
      <Row className = "mt-3">
      <Col className = "px-0">
      <div className = "text-start fs-14 fw-bold px-2 ms-1">
            {parentGroupData?.[0]?.placeholder}{" "}
            {Boolean(parentGroupData?.[0]?.is_required) && "*"}
          </div>
          {/* <div className="screening-slide-animation">
          <div className = "text-start fw-bold px-1">{screeningQuestion?.newQuestionBank[Screening_Form_State?.currentActiveIndex]?.section_name}{screeningQuestion?.newQuestionBank[Screening_Form_State?.currentActiveIndex]?.is_mandatory && "*"}</div>
        </div> */}
          <Row>
            <Col xs = {6} md = {4}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[0]?.screening_question_id}`}
                  // rules        = {{ required: addressData[0]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className = "px-0">
                    <Col className = "px-0" md = {12}>
                        {fieldTypeRender[addressData[0].field_type](
                          addressData[0],
                          field,
                          fieldState,
                          setValue
                        )}
                        {errors?.[field.name] && field.value === "" && (
                        <p className = "error-msg text-start" style = {{ color: "red" }}>
                          This field is required.
                        </p>
                      )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs = {6} md = {2}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[1]?.screening_question_id}`}
                  // rules        = {{ required: addressData[1]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className = "px-0">
                    <Col className = "px-0" md = {12}>
                        {fieldTypeRender[addressData[1].field_type](
                          addressData[1],
                          field,
                          fieldState,
                          setValue
                        )}
                      {errors?.[field.name] && field.value === "" && (
                      <p className = "error-msg text-start" style = {{ color: "red" }}>
                        This field is required.
                      </p>
                      )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs = {4} md = {2}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[2]?.screening_question_id}`}
                  // rules        = {{ required: addressData[2]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterVal = addressData[3].possible_values?.filter(
                    (p) => p.value === field.value
                  );
                  const selectedVal = filterVal?.length
                    ? filterVal?.map((s) => ({
                        label: s.text,
                        value: s.value,
                      }))
                    :   "";

                  return (
                    <Row className = "px-0">
                      <Col
                        className={`px-0 mt-2 ${
                          errors?.[field.name] && "error"
                        } text-start`}
                      >
                        <ReactSelect
                          clearable    = {false}
                          placeholder  = {addressData[2]?.placeholder}
                          dropdownLine = {true}
                              // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
                          value    = {selectedVal}
                          onChange = {(e) => {
                                // clearErrors(name);
                            field.onChange(e);
                            setValue(field.name, e.value, {
                              shouldValidate: true,
                            });
                          }}
                          options={addressData[2].possible_values?.map((s) => ({
                            label: s.text,
                            value: s.value,
                          }))}
                        />
                        {errors?.[field.name] &&
                          field.value === "" && (
                            <p
                              className = "error-msg text-start"
                              style     = {{ color: "red" }}
                            >
                              This field is required.
                            </p>
                          )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs = {4} md = {2}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[3]?.screening_question_id}`}
                  // rules        = {{ required: addressData[3]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterVal = addressData[3].possible_values?.filter(
                    (p) => p.value === field.value
                  );
                  const selectedVal = filterVal?.length
                    ? filterVal?.map((s) => ({
                        label: s.text,
                        value: s.value,
                      }))
                    :   "";

                  return (
                    <Row className = "px-0">
                      <Col
                        className={`px-0 mt-2 ${
                          errors?.[field.name] && "error"
                        } text-start`}
                      >
                        <ReactSelect
                          clearable    = {false}
                          placeholder  = {addressData[3]?.placeholder}
                          dropdownLine = {true}
                              // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
                          value    = {selectedVal}
                          onChange = {(e) => {
                                // clearErrors(name);
                            field.onChange(e);
                            setValue(field.name, e.value, {
                              shouldValidate: true,
                            });
                                // if(name === '9') {
                                //   setValue('8', '');
                                //   setValue("7", '');
                                // }
                          }}
                          options={addressData[3].possible_values?.map((s) => ({
                            label: s.text,
                            value: s.value,
                          }))}
                        />
                        {errors?.[field.name] &&
                          field.value === "" && (
                            <p
                              className = "error-msg text-start"
                              style     = {{ color: "red" }}
                            >
                              This field is required.
                            </p>
                          )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs = {4} md = {2}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[4]?.screening_question_id}`}
                  // rules        = {{ required: addressData[4]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className = "px-0">
                    <Col className = "px-0" md = {12}>
                        {fieldTypeRender[addressData[4].field_type](
                          addressData[4],
                          field,
                          fieldState
                        )}
                        {errors?.[field.name] &&
                          field.value === "" && (
                            <p
                              className = "error-msg text-start"
                              style     = {{ color: "red" }}
                            >
                              This field is required.
                            </p>
                          )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs = {6} md = {4}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[5]?.screening_question_id}`}
                  // rules        = {{ required: addressData[5]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className = "px-0">
                    <Col className = "px-0" md = {12}>
                        {fieldTypeRender[addressData[5].field_type](
                          addressData[5],
                          field,
                          fieldState
                        )}
                        {errors?.[field.name] &&
                          field.value === "" && (
                            <p
                              className = "error-msg text-start"
                              style     = {{ color: "red" }}
                            >
                              This field is required.
                            </p>
                          )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs = {6} md = {4}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[6]?.screening_question_id}`}
                  // rules        = {{ required: addressData[6]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterVal = getZipcodeByCountryIdData?.data?.filter(
                    (p) => p.Id === field.value
                  );
                  const selectedVal = filterVal?.length
                    ? filterVal?.map((s) => ({
                        label: s.Id,
                        value: s?.Id,
                      }))
                    :   "";

                  return (
                    <Row className = "px-0">
                      <Col
                        className={`px-0 mt-2 ${
                          fieldState.error && "error"
                        } text-start`}
                      >
                        <ReactSelect
                          clearable    = {false}
                          placeholder  = {addressData[6]?.placeholder}
                          dropdownLine = {true}
                              // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
                          value    = {selectedVal}
                          onChange = {(e) => {
                            field.onChange(e);
                            setValue(field.name, e.value, {
                              shouldValidate: true,
                            });
                            setValue(
                              `${addressData[5]?.screening_field_id}`,
                              getZipcodeByCountryIdData?.data?.filter(
                                (el) => el.Id === e.value
                              )?.[0]?.Text,
                              { shouldValidate: true }
                            );
                          }}
                          options={getZipcodeByCountryIdData?.data?.map(
                            (item) => ({
                              label: item.Id,
                              value: item?.Id,
                                  // value:  `${item?.Id} ${item?.Text}`
                            })
                          )}
                        />
                        {errors?.[field.name] &&
                          field.value === "" && (
                            <p
                              className = "error-msg text-start"
                              style     = {{ color: "red" }}
                            >
                              This field is required.
                            </p>
                          )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs = {9} md = {4}>
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${addressData[7]?.screening_question_id}`}
                  // rules        = {{ required: addressData[7]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterValue = getCountriesData?.data?.filter(
                    (cd) => cd.Id === field.value
                  );
                 
                  const selectedVal = filterValue?.length
                  ? {
                    value: filterValue[0]?.Id,
                    label: (
                      <div
                        // key={i}
                      className = "d-flex align-items-center"
                      style     = {{
                              fontFamily: "Cerebri sans",
                            }}
                          >
                            <div className = "radius-4 me-2">
                              <img
                                src   = {filterValue[0].DataValue1}
                                style = {{
                                  width      : "20px",
                                  marginRight: "10px",
                                }}
                                alt = "country flag"
                              />
                            </div>
                            <div>
                              <div className = "fs-14 light-font-text ">
                                {filterValue[0]?.Text}
                              </div>
                            </div>
                          </div>
                        ),
                      }
                    :   "";
                  return (
                    <Row>
                      <Col className = "screening-custom-react-select mt-2 px-0 text-start">
                      <div className = {`${fieldState.error && "error"}`}>
                          <CustomReactSelect
                            setValue    = {setValue}
                            placeholder = {addressData[7].placeholder}
                            name        = {`${addressData[7]?.screening_question_id}`}
                            iserror     = {fieldState.error}
                            options     = {getCountriesData?.data?.map((item, i) => ({
                              value: item?.Id,
                              label: (
                                <div
                                      // key={i}
                                  className = "d-flex align-items-center"
                                  style     = {{
                                    fontFamily: "Cerebri sans",
                                  }}
                                >
                                  <div className = "radius-4 me-2">
                                    <img
                                      src   = {item.DataValue1}
                                      style = {{
                                        width      : "20px",
                                        marginRight: "10px",
                                      }}
                                      alt = "country flag"
                                    />
                                  </div>
                                  <div>
                                    <div className = "fs-14 light-font-text ">
                                      {item?.Text}
                                    </div>
                                  </div>
                                </div>
                              ),
                              searchText: item?.Text
                            }))}
                            value = {selectedVal}
                                // iserror={errors?.screeninginvitation}
                                // inputValue={inputValue}
                            isSearchable = {false}
                                // onInputChange={(val) => setInputValue(val)}
                          />
                        </div>
                        {errors?.[field.name] && (
                          <p className = "error-msg">This field is required</p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const PhoneField = ({ phoneData }) => {
    const parentGroupData = data.section[index].question.filter(
      (q) => q.screening_field_id === phoneData[0].parent_screening_field_id
    );

    return (
      <Row>
        <Col className = "mt-2 mx-2 px-1">
          <Row>
            <Col className = "text-start fs-14 fw-bold px-0 mb-2">
              {parentGroupData?.[0]?.placeholder}{" "}
              {Boolean(parentGroupData?.[0]?.is_required) && "*"}
            </Col>
          </Row>
          <Row className = {`screening-phone-number-container ${(errors?.[phoneData[1]?.screening_question_id] || errors?.[phoneData[0]?.screening_question_id]) && "error"}`}>
          <Col xs        = {4} className = "px-0">
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${phoneData[1]?.screening_question_id}`}
                  // rules        = {{ required: phoneData[1]?.is_required }}
                render={({ field: { onChange, value } }) => {
                  const filterValue = getCountriesData?.data?.filter(
                    (cd) => cd.Id === value
                  );
                  const selectedVal = filterValue?.length
                    ? {
                        value: filterValue[0]?.Id,
                        label: (
                          <div
                            className = "d-flex align-items-center"
                            style     = {{
                              fontFamily: "Cerebri sans",
                            }}
                          >
                            <div className = "radius-4 me-2">
                              <img
                                src   = {filterValue[0].DataValue1}
                                style = {{
                                  width      : "20px",
                                  marginRight: "10px",
                                }}
                                alt = "country flag"
                              />
                            </div>
                            <div>
                              <div className = "fs-14 light-font-text d-flex gap-1">
                                <span>{filterValue[0]?.Id}</span>
                                <span>{filterValue[0]?.DataValue2}</span>
                              </div>
                            </div>
                          </div>
                        ),
                      }
                    :   "";
                  return (
                    <CustomReactSelect
                      setValue    = {setValue}
                      name        = {`${phoneData[1]?.screening_question_id}`}
                      bgColor     = {"#f9fafa"}
                      border      = {"none"}
                      description = {"Search country code"}
                      placeholder = {phoneData[1]?.placeholder}
                      value       = {selectedVal}
                      options     = {getCountriesData?.data?.map((item, i) => ({
                        value: item?.Id,
                        label: (
                          <div
                                // key={i}
                            className = "d-flex align-items-center"
                            style     = {{
                              fontFamily: "Cerebri sans",
                            }}
                          >
                            <div className = "radius-4 me-2">
                              <img
                                src   = {item.DataValue1}
                                style = {{
                                  width      : "20px",
                                  marginRight: "10px",
                                }}
                                alt = "country flag"
                              />
                            </div>
                            <div>
                              <div className = "fs-14 light-font-text d-flex gap-1">
                                <span>{item?.Id}</span>
                                <span>{item?.DataValue2}</span>
                              </div>
                            </div>
                          </div>
                        ),
                        searchText: item?.DataValue2
                      }))}
                          // value={value}
                      dropDownIndicator = {false}
                          // iserror={errors?.screeninginvitation}
                          // inputValue={inputValue}
                      isSearchable = {false}
                          // onInputChange={(val) => setInputValue(val)}
                          // onInputChange={(e)=> {
                          //   console.log(value,'hi')
                          // }}
                    />
                  );
                }}
              />
            </Col>
            <Col xs = {8} className = "px-0">
              <Controller
                defaultValue = ""
                control      = {control}
                name         = {`${phoneData[0]?.screening_question_id}`}
                  // rules        = {{ required: phoneData[0]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <div className = "h-100">
                      <div
                        className={`screening-information-phone-number h-100 ${
                          fieldState.error?.[field.name] && "error"
                        }`}
                      >
                        <input
                          className = "w-100 h-100 border-0 rounded-end"
                          {...field}
                          type        = "number"
                          placeholder = {``}
                          id          = {phoneData[0].placeholder}
                        />
                        {/* <label htmlFor={ phoneData[0].placeholder}>{ phoneData[0].placeholder}</label> */}
                      </div>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
          {(errors?.[`${phoneData[1]?.screening_question_id}`] || errors?.[`${phoneData[0]?.screening_question_id}`]) && (
          <p className = "error-msg text-start" style = {{ color: "red" }}>
            This field is required.
          </p>
        )}
        </Col>
      </Row>
    );
  };

  const CreditCheck = ({ creditCheckData }) => {
    return (
      <>
      {
      !creditCheckClicked ? 
      <Row>
        <Col>
          <div className = {"mx-auto"} style = {{ maxWidth: "450px" }}>
            <p>
              Before we can continue the screening, it is a requirement to
              complete a credit check based on the information you have
              provided.
            </p>
          </div>
          <div className = "fs-14 mt-2">
            <span>Click below to start the credit check</span>
          </div>
          <div>
            <div>
              <NormalButton
                type            = "submit"
                className       = "fw-900"
                bstClassName    = {" mx-auto mt-3"}
                textColor       = " white"
                backgroundColor = "green"
                buttonText      = "START CREDIT CHECK"
                border          = "1px solid #F0F0F0"
                padding         = "6px 16px"
                borderRadius    = "6"
                fontSize        = "14"
                fontWeight      = "500"
                onClick         = {(e) => {
                  e.preventDefault();
                  handleCreditCheck();
                }}
              />
            </div>
          </div>
        </Col>
      </Row> 
      : <ScreeningCreditCheck data = {creditCheckData} creditCheck = {creditCheck} />
        }
      </>
    );
  };

    // This array will contain the parent field id as i wanted to render group field in one line but because of map the group fields rendering 2 times so i have made this array and it will check in map if the parent field id is present or not if present it will return null if that same id comes in map
  let fieldShouldRenderOneTime = [];
  return (
    <>
    {/* {console.log(data.section[Screening_Form_State?.currentFormSubIndex], 'render')} */}
      <form className = "screening-render-component pt-3">
        {
          Screening_Form_State?.isFlagConditionActivate ?
          <Flags />
          : 
          <> 
          {
            !Screening_Form_State?.isFieldConditionActivate &&
            data.section[Screening_Form_State?.currentFormSubIndex]?.question?.map((form, keyIndex) => {
              if(Screening_Form_State.conditionQuestionIds.includes(form.screening_question_id)) return null;
              const isGroupField = form.parent_screening_field_id > 0;
              
              const fieldToShow = isGroupField ? data.section[index]?.question.filter( sameParentID => sameParentID.parent_screening_field_id === form.parent_screening_field_id) : [form];
              if(!uniqField.includes(form.field_type)) {

                if(fieldShouldRenderOneTime.includes(form.parent_screening_field_id)) return null
                if(isGroupField) fieldShouldRenderOneTime.push(form.parent_screening_field_id)
                let switchCase = data?.section.length ? form.screening_field_id : data?.screening_category_id
                switch(switchCase) {
                  case    4      : return <AddressField key       = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} addressData = {fieldToShow}  />
                  case    14     : return <PhoneField key         = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} phoneData   = {fieldToShow} />
                  default : return isGroupField ? <GroupField key = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} groupData   = {fieldToShow} /> : <SingleField key = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} data = {fieldToShow} />
                }
              }
              return null
            })
          }
          { !Screening_Form_State?.isFieldConditionActivate &&
            Boolean(!data.section.length) && (()=> {
              switch(data?.screening_category_id) {
                case 2      : return <CreditCheck creditCheckData={data}  />
                     default: return null
              }
            })()
          } 
          {
            Screening_Form_State?.isFieldConditionActivate && [data.section[Screening_Form_State?.currentFormSubIndex]?.question?.[Screening_Form_State?.fieldConditionQueIndex]]?.map((form, keyIndex) => {
              const isGroupField = form.parent_screening_field_id > 0;
                
              const fieldToShow = isGroupField ? data.section[index]?.question.filter( sameParentID => sameParentID.parent_screening_field_id === form.parent_screening_field_id) : [form];
              if(!uniqField.includes(form.field_type)) {

                if(fieldShouldRenderOneTime.includes(form.parent_screening_field_id)) return null
                if(isGroupField) fieldShouldRenderOneTime.push(form.parent_screening_field_id)
                let switchCase = data?.section.length ? form.screening_field_id : data?.screening_category_id
                switch(switchCase) {
                  case    4      : return <AddressField key       = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} addressData = {fieldToShow}  />
                  case    14     : return <PhoneField key         = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} phoneData   = {fieldToShow} />
                  default : return isGroupField ? <GroupField key = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} groupData   = {fieldToShow} /> : <SingleField key = {data.section[Screening_Form_State?.currentFormSubIndex]?.name + `${keyIndex}`} data = {fieldToShow} />
                }
              }
              return null
            })
          }
          </>
        }
        {
          Screening_Form_State?.isFlagConditionActivate ?
          <>
            {/* I have passed the same function or error or success of the handle submit because i want to track of errors and also wanted to navigate user form to its previous form */}
            <button
            className = "d-none"
            ref       = {parentSubmitRef}
            onClick   = {handleSubmit(nextForm)}
            type      = "submit"
          >
            Submit form
          </button>
          <button
            className = "d-none"
            ref       = {parentPrevRef}
            onClick   = {handleSubmit(hidePrevFlagPage, hidePrevFlagPage)}
            type      = "submit"
          >
            back button
          </button>
          </>
        : 
        <>
            {/* I have passed the same function or error or success of the handle submit because i want to track of errors and also wanted to navigate user form to its previous form */}
            <button
            className = "d-none"
            ref       = {parentSubmitRef}
            onClick   = {handleSubmit(nextForm)}
            type      = "submit"
          >
            Submit form
          </button>
          <button
            className = "d-none"
            ref       = {parentPrevRef}
            onClick   = {handleSubmit(previewForm, previewForm)}
            type      = "submit"
          >
            back button
          </button>
          </>
        }
        {/* <button className="d-none" ref={parentPrevRef} onClick={(e)=>previewForm(e)} type="submit">back button</button> */}
      </form>
    </>
  );
};

export default DynamicScreenForm;
