import { useForm } from "react-hook-form";
import { H1 } from "../../../styles/Common.style";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { Col, Form, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useState } from "react";
import managementLogo      from "../../../assets/images/management-logo.svg";
import loginImagePattern   from "../../../assets/images/login-image-pattern.svg";
import { useMediaQuery } from "@mui/material";

const ForgotPassword = () => {
    const isMobile = useMediaQuery("(max-width:576px)")
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [emailConfirmation, setEmailConfirmation] = useState(false)

    const onFormSubmit = () => {
        setEmailConfirmation(true)
        console.log("email to reset password")
    }

    return(
        <>
            <div
                className = "pt-3 bg-login d-flex justify-content-center align-items-center px-md-4"
                style     = {{ position: "relative" }}
            >
                <div className = "mb-4 forgot-password-container">
                <img
                    src       = {managementLogo}
                    alt       = "management-logo"
                    className = "management-logo d-block m-auto"
                />
                    <Row className = "mb-1">
                    <Col sm        = {12}>
                    <Row className = "loginForm mt-3 mb-3 flex-column-reverse flex-sm-row position-relative">
                        <Col sm        = {6} className = "login-right-section">
                        {
                            !emailConfirmation ?
                                <Form className="h-100" onSubmit={handleSubmit(onFormSubmit)}>
                                <Row className="h-100">
                                    <Col sm={12} className="position-relative">
                                    <div>
                                        <H1 className="primary-text-color">
                                            Forgot your password?
                                        </H1>
                                        <p className="primary-text-color">
                                            No worries! Just enter your email below and we'll send you instructions to reset your password.
                                        </p>
                                        <p className="primary-text-color">
                                            If necessary, check your spam folder or unblock emails from no-reply@estatetool.com.
                                        </p>
                                    </div>
                                    <div>
                                        <div>
                                            <p className="primary-text-color mb-1">Email</p>
                                        </div>
                                        <div className="form-group mb-1">
                                            <input
                                                className="d-block w-100 text-dark"
                                                type="email"
                                                name="email"
                                                placeholder="Your email"
                                                {...register("email", {
                                                required: "Email is required.",
                                                pattern: {
                                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                                    message: "Email is not valid.",
                                                },
                                                })}
                                            />
                                            {errors.email ? (
                                                <p className="error-msg">{errors.email.message}</p>
                                            ) : null
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <NormalButton
                                            type="submit"
                                            className="fw-900"
                                            textColor="white"
                                            border="none"
                                            backgroundColor="green"
                                            buttonText="SEND ME INSTRUCTIONS"
                                            padding="10px 24px"
                                            borderRadius="6"
                                            fontSize="14"
                                            fontWeight="500"
                                            bstClassName="mb-5"
                                        />
                                    </div>
                                    <Link to={'/'} className="login-back cursor d-flex align-items-center fs-14">
                                        <FontAwesomeIcon className="me-1" icon="fa-regular fa-arrow-left" />
                                        <span>Go Back</span>
                                    </Link>
                                    </Col>
                                </Row>
                            </Form>
                            : 
                            <EmailConfirmation setEmailConfirmation={setEmailConfirmation} />
                            }
                        </Col>
                        <Col sm = {6} className = "d-sm-block">
                            <Image
                            fluid
                            className = "w-100 h-100"
                            src       = {loginImagePattern}
                            alt       = "login-image-pattern"
                            style     = {{ objectFit: "cover", borderRadius: "8px", maxHeight: isMobile ? '80px' : '100%' }}
                            />
                        </Col>
                        </Row>
                    </Col>
                    </Row>
                </div>
                <div className = "copyright copyright-text">
                Copyright 2013-2023 © Estatetool A/S
                </div>
            </div>
        </>
       
    )
}

export default ForgotPassword;



const EmailConfirmation = ({ setEmailConfirmation }) => {
    return(
        <Row className="h-100">
            <Col sm={12} className="position-relative">
            <H1 className="primary-text-color">
            Forgot your password?
            </H1>
            <p className="primary-text-color">
                If an account exists for (MERGE EMAIL), you will get an email with instructions on how to reset your password. If it dosen't arrive, be sure to check you spam folder.
            </p>
            <p className="primary-text-color mb-5">
                Need more help, please contact (MERGE SUPPORT EMAIL)
            </p>
            <div className="position-absolute login-back d-flex align-items-center cursor" onClick={()=> setEmailConfirmation(false)}>
                <FontAwesomeIcon className="me-1" icon="fa-regular fa-arrow-left" />
                <div>
                    <span>Go Back</span>
                </div>
            </div>
            </Col>
        </Row>
    )
}