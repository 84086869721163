import { useState } from "react";
import { Button, Col, Row, Image, Dropdown, Form } from "react-bootstrap";
import Modal from "../../Components/widgets/modal/Modal";
import "../../styles/home.css";
import wavingGuyImg from "../../assets/images/illustration-waving-guy.svg";
import unitImg from "../../assets/images/unit-image.jpg";
import { Card } from "../../Components/card/Card";
import UnitInfoCard from "../../Components/card/UnitInfoCard";
import desirehead from "../../assets/icons/modalheader.svg";
import reporthead from "../../assets/icons/reporthead.svg";
import upload from "../../assets/images/browse.svg";
import FilterComponent from "../../Components/input/searchField/SearchField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Area from "../../assets/images/Area.svg";

//Dummy Images
import {
  Apartments,
  Properties,
} from "../../utils/dummy-data/main-page-dummy-data/desireApartment";
import { Controller, useForm } from "react-hook-form";
import { DocumentComponent } from "./DocumentComponent";
import { RecentActivityComponent } from "./RecentActivityComponent";
import { MessagesComponent } from "./MessageComponent";

// dummy data have to remove after api done
import { RecentActivityData } from "../../utils/dummy-data/home-page-data/user-home";
import { MessageComponentData } from "../../utils/dummy-data/home-page-data/user-home";
import { NoticeBoardTableData } from "../../utils/dummy-data/notice-board-table-data/noticeTableData";
import { useGetDocumentDetailsByContactId } from "../../hooks/queries/useActorDocumentsQueries";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import { myPage } from "../../styles/Theme";
import PortalButton from "../../Components/input/normalButton/PortalButton";
import { Guides } from "./guides/Guides";
import { H2, H4, H5 } from "../../styles/Common.style";

const NoticeBoardComponent = () => {
  // Open Attached Pdf File

  const [openAttachedPdf, setOpenAttachedPdf] = useState(false);

  // Like the Post

  const [likeThePost, setLikeThePost] = useState(false);

  const [showPost, setShowPost] = useState(null);

  return (
    <div className="px-3 pt-3">
      {NoticeBoardTableData.length != 0 ? (
        NoticeBoardTableData?.map((data, i) => {
          return (
            <Row className="px-3 mb-3 py-2 rounded background-primary" key={i}>
              <Col sm={1} className="p-0">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: data?.bgColor,
                    borderRadius: "5px",
                  }}
                >
                  <FontAwesomeIcon
                    color={data?.iconColor}
                    icon={`${data?.icon}`}
                  />
                </div>
              </Col>
              <Col sm={7}>
                <H4 className="mb-0">{data?.heading}</H4>
                <p className="fs-7 mb-0 text-truncate">{data?.headingInfo}</p>
                <span className="fs-8 fw-400 cards-secondary-text-color">
                  {data?.timeInfo}
                </span>
              </Col>
              <Col
                sm={4}
                className="d-flex align-items-center justify-content-end"
              >
                {/* <Button
                className="portal_btn medium-text-12"
                onClick={() => setShowPost(i)}
              >
                SHOW POST
              </Button> */}
                <NormalButton
                  type="submit"
                  className="fw-900"
                  textColor=" white"
                  backgroundColor="green"
                  buttonText="SHOW POST"
                  border="none"
                  padding="6px 12px"
                  borderRadius="6"
                  fontSize="12px"
                  fontWeight="500"
                  onClick={() => setShowPost(i)}
                />
                <Modal
                  size="lg"
                  open={showPost === i}
                  className="modal-dialog-centered"
                  onHide={() => setShowPost(null)}
                  title={
                    <div className="d-flex justify-content-between p-4">
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <div>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "40px",
                              height: "40px",
                              backgroundColor: data?.bgColor,
                              borderRadius: "5px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={`${data?.icon}`}
                              color="#77AEE1"
                              size="lg"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="fs-18 Cerebri-sans-medium">
                            {data?.heading}
                          </div>
                          {/* <H4>{data?.heading}SS</H4> */}
                          <div
                            className="paragraph2-light"
                            style={{ color: "rgba(153, 153, 153, 1)" }}
                          >
                            {data?.timeInfo}
                          </div>
                        </div>
                      </div>
                      <div onClick={() => setShowPost(null)}>
                        <FontAwesomeIcon
                          className="cursor"
                          icon="fa-regular fa-xmark"
                          size="lg"
                        />
                      </div>
                    </div>
                  }
                  title_color="black"
                  onCloseAction={() => {
                    setShowPost(false);
                    alert("Action Peformed Successfuly.");
                  }}
                >
                  <div className="p-4 paragraph2-regular bottom-line">
                    {data?.headingInfo}
                  </div>
                  {/* <hr /> */}
                  <div>
                    <div className="p-4">
                      <div>
                        <H5 color="#202020">Attached file</H5>
                      </div>
                      <div>
                        <Button
                          onClick={() => setOpenAttachedPdf(!openAttachedPdf)}
                          className="border paragraph2-regular d-flex align-items-center"
                          style={{
                            backgroundColor: "#f7f8fa",
                            borderRadius: "100px",
                            color: "#000",
                            letterSpacing: "0.07rem",
                            lineHeight: "0",
                            padding : '0px 16px 0px 13px',
                            height : '28px'

                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center fs-6 radius-4 me-2">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "0.9rem",
                                color: "#d00404f0",
                              }}
                              icon="fa-solid fa-file-lines"
                              flip="horizontal"
                            />
                          </div>
                          <span className="paragraph2-regular">Newsletter-January_Blomsterbjerget.pdf</span>
                        </Button>
                        <Modal
                          open={openAttachedPdf}
                          topborder={false}
                          ModalHeader={false}
                          onHide={() => setOpenAttachedPdf(false)}
                          headerRequired={false}
                        >
                          <div className="p-3" style={{ height: "550px" }}>
                            <iframe
                              title="attached-file"
                              src="https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"
                              height="100%"
                              width="100%"
                              scrolling="no"
                            ></iframe>
                          </div>
                          <div
                            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                            style={{
                              backgroundColor: "#F9FAFA",
                              borderRadius: " 0px 0px 8px 8px",
                              border: "1px solid #F0F0F0",
                            }}
                          ></div>
                        </Modal>
                      </div>
                    </div>

                    <div className="px-4 pb-5">
                      <H5 color="#202020">Like the post</H5>
                      <div className="d-flex align-items-center">
                        <div
                          onClick={() => setLikeThePost(!likeThePost)}
                          className="d-flex align-items-center justify-content-center fs-6 p-2 radius-circle me-2 rounded-circle"
                          style={{
                            background: likeThePost
                              ? "rgba(247, 248, 250, 1)"
                              : "rgba(205, 230, 210, 1)",
                            border: "1px solid rgba(17, 23, 71, 0.1)",
                          }}
                        >
                          {likeThePost ? (
                            <FontAwesomeIcon
                              icon="fa-solid fa-thumbs-up"
                              style={{ color: "rgba(209, 209, 209, 1)" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="fa-solid fa-thumbs-up"
                              style={{ color: "rgba(53, 154, 75, 1)" }}
                            />
                          )}
                        </div>

                        <div className="fw-300 fs-7">
                          14 neighbours have liked this post
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </Col>
            </Row>
          );
        })
      ) : (
        <div className="d-flex justify-content-center pt-3 text-muted">
          No notice board data found
        </div>
      )}
    </div>
  );
};

export const Home = ({ getActorProfileData, unitInformation }) => {
  //-----------------------react query-------------------//
  const { data: getDocumentAllData, refetch: getDocumentAllDataIdRefetch } =
    useGetDocumentDetailsByContactId(
      unitInformation?.contract_id,
      getActorProfileData?.data?.[0]?.actor_id,
      0
    );

  // Report a problem Modal validation
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      headline: "",
      description: "",
    },
  });

  const onFormSubmit = (data) => {
    // console.log("onFormSubmit data", data);
  };
  // console.log("validation errs", errors);

  // report a problem modal action
  const [report, setReport] = useState(false);

  // apply to move modal action
  const [desire, setDesire] = useState(false);

  // select a property
  const [selectProperty, setSelectProperty] = useState(false);

  const handlePropertyDropZone = (e) => {
    e.stopPropagation();
    setSelectProperty(!selectProperty);
  };

  // select a units (move apartment)

  const [checkUnit, setCheckUnit] = useState("");

  const handleCheckUnit = (event) => {
    console.log("event", event);

    setCheckUnit(event.target.value);
  };

  // select a property  (move apartment)

  const [checkProperty, setCheckProperty] = useState("");

  const handleCheckProperty = (event) => {
    console.log("event", event);

    setCheckProperty(event.target.value);
  };

  // condition for showing and hiding unit or property

  const [showDesire, setShowDesire] = useState(false);

  const HandleShowDesire = () => {
    setShowDesire(!showDesire);
  };

  // file upload in modal 5mb limit (report problem)

  const [dataSize, setDataSize] = useState("");

  const handleFileUpload = (e) => {
    let file = e.target.files[0];

    let fileType = file.type; // image/jpeg
    let fileSize = file.size; // 3MB
    setDataSize(fileSize);
  };

  // move apartment charater Limit

  const [moveApartment, setMoveApartment] = useState("");

  // Report a problem Description

  const [descriptionLimit, setDescriptionLimit] = useState("");

  return (
    <div className="home-container">
      <Row className="gap-3 gap-xl-0">
        <Col xl={6} sm={12}>
          <Row>
            {/* user Home section  */}
            <Col>
              <Row className="pt-3 align-items-xl-start">
                <Col className="primary-text-color pt-4" lg={7} sm={12}>
                  <H2
                    color={myPage.primaryTextColor}
                    className="font-bold fs-22"
                    // style={{ color: myPage.primaryTextColor }}
                  >
                    Hi {getActorProfileData?.data?.[0]?.firstname || "User"},
                    Good to see you!
                  </H2>
                  <div
                    className="paragraph2-regular"
                    // style={{ color: myPage.primaryTextColor }}
                  >
                    <p style={{ color: myPage.primaryTextColor }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse
                      cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                </Col>
                <Col className="px-0" lg={5} sm={12}>
                  <Image
                    className="h-100 mx-auto d-block"
                    fluid
                    src={wavingGuyImg}
                    alt="waving guy"
                    style={{ objectFit: "cover" }}
                  ></Image>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* user Home section  */}
        {/* property details section  */}
        <Col xl={6} sm={12}>
          <UnitInfoCard unitInformation={unitInformation} />
        </Col>
        {/* property details section  */}
      </Row>

      {/* notice board and recent activity section  */}
      <Row className="gap-3 gap-xl-0">
        <Col xl={6} sm={12} className="primary-text-color">
          <Card
            label="Notice board"
            path="notice-board"
            Component={NoticeBoardComponent}
          />
        </Col>
        <Col xl={6} sm={12} className="primary-text-color">
          <Card
            label="Recent activity"
            path="recent-activity"
            Component={RecentActivityComponent}
            data={RecentActivityData}
          />
        </Col>
      </Row>
      {/* notice board and recent activity section  */}

      {/* document and message section  */}
      <Row>
        <Col xl={6} sm={12} className="primary-text-color">
          <Card
            label="Documents"
            path="documents"
            Component={DocumentComponent}
            data={getDocumentAllData?.data}
          />
        </Col>
        <Col xl={6} sm={12} className="primary-text-color">
          <Card
            label="Messages"
            path="messages"
            Component={MessagesComponent}
            data={MessageComponentData}
          />
        </Col>
      </Row>
      {/* document and message section  */}

      {/* guides section */}

      <Guides unitInformation={unitInformation} guidehead="Recent guides" />
      {/* guides section */}
    </div>
  );
};
