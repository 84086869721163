import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScreeningFormAnswerStore, useScreeningFormStore } from "../../hooks/stores/useScreeningFormStore";
import { convertNumericStringToNumber } from "../../utils/helpers";

const Overview = ({ skipFormCategory }) => {

  const Screening_Form_State = useScreeningFormStore((state) => state.Screening_Form_State);
  const Screening_Form_Answer_State = useScreeningFormAnswerStore(
    (state) => state.screeningFormAnswerStore
  );

  // question ID which we don't have to render in frontend as this are groups which field type is uniq
  const uniqField = ["name", "phone", "address"];
  // This array will contain the parent field id as i wanted to render group field in one line but because of map the group fields rendering 2 times so i have made this array and it will check in map if the parent field id is present or not if present it will return null if that same id comes in map
  let fieldShouldRenderOneTime = [];

  // console.log(Screening_Form_State, 'Screening_Form_State')
  return (
    <Row>
    <Col className="px-lg-5 pb-lg-4">
        <div>
          <div>
            <div>
              <h2>
                Overview
              </h2>
            </div>
            <div>
              <p className="fs-6">
                Please take a moment to review the details to ensure accuracy before submitting
              </p>
            </div>
          </div>
          <div className="screening-render-component py-3">
            {
              Screening_Form_State?.formData?.map((sc, index) => {
              return(
                <div key={index} className="mt-3">
                  <Row>
                  <Col>
                  <div className="fs-14 text-start ms-2 p-1 d-flex" style={{ height: '25px'}}>
                      <p className="fw-bold">{sc?.name}</p><span className="ms-3 cursor" style={{ color: '#ADBE9E' }} onClick={()=> skipFormCategory(index, true)}>Click here to edit</span>
                  </div>
                  <Row>
                    {
                      sc.section?.map((s) => {
                        return s?.question?.map((q) => {
                          // if(Screening_Form_State.conditionQuestionIds.includes(q.screening_question_id)) return null;
                          const isGroupField = q.parent_screening_field_id > 0;
                          const fieldToShow = isGroupField ? s?.question.filter( sameParentID => sameParentID.parent_screening_field_id === q.parent_screening_field_id) : [q];

                          if(!uniqField.includes(q.field_type)) {
                            if(fieldShouldRenderOneTime.includes(q.parent_screening_field_id)) return null
                            if(isGroupField) fieldShouldRenderOneTime.push(q.parent_screening_field_id)
                            
                            return fieldToShow?.map((fs) => {
                              if(Screening_Form_Answer_State[fs?.screening_question_id] === undefined) return null

                              let filtercheckboxVal = '';
                              let filterSelectVal = '';

                            if(fs?.field_type === "checkbox" && Screening_Form_Answer_State[fs?.screening_question_id]?.length) {
                              const checkBoxVal = Screening_Form_Answer_State[fs?.screening_question_id]?.split(',');
                              if(checkBoxVal)
                              checkBoxVal?.forEach((cv)=> {
                                const findVal = fs?.possible_values.find((element) => convertNumericStringToNumber(element.value) === convertNumericStringToNumber(cv))
                                if(Object.keys(findVal ?? {}).length) {
                                  filtercheckboxVal += `${findVal.text}`;
                                }
                              })
                            }

                            if(fs?.field_type === "select" && Screening_Form_Answer_State[fs?.screening_question_id]) {
                              const selectVal = fs.possible_values?.filter(fsp => fsp.value === Screening_Form_Answer_State[fs?.screening_question_id]);
                              if(selectVal?.length) {
                                filterSelectVal = selectVal[0].text;
                              }
                            }
                              return(
                                <Col key={fs?.screening_question_id} md={["file", "checkbox", "radio", "textarea"].includes(fs?.field_type) ? 12 : 6} >
                                  <div className={`d-flex ${fs?.field_type === "file" || fs?.field_type === "textarea"  ? "flex-column mt-2" : "flex-row"} ${fs?.field_type !== 'file' && 'overview-fields'} ${["file", "textarea"].includes(fs?.field_type) ? "justify-content-center" : "align-items-end lh-lg" } ${fs.field_type === 'file' && 'pb-2'}`} style={{ height: !["file", "textarea"].includes(fs?.field_type) && '40px'}}>
                                    <div className={`text-muted text-start fs-14  ${fs?.field_type === 'file' && 'mt-2'}`}>{fs?.placeholder}</div>
                                    <div>               
                                      {
                                        fs?.field_type === "file" ?
                                        Boolean(Screening_Form_Answer_State[fs?.screening_question_id]?.length) && <>
                                          {
                                            Screening_Form_Answer_State[fs?.screening_question_id].map((val, index) => {
                                              return(
                                                <Row key={index} className={`${index && 'my-2'}`}>
                                                  <Col className="px-0">
                                                    <div className="p-0 mt-2">
                                                      <div className="screening-overview-file d-flex align-items-center justify-content-between">
                                                        <div className="d-flex py-3 ps-3">
                                                          <div className="screening-remove-uploaded-img">
                                                            <div className="file-img-icon">
                                                              <FontAwesomeIcon
                                                                style={{ fontSize: "1.2rem", color: "#202020" }}
                                                                icon="fa-solid fa-file-lines"
                                                                flip="horizontal"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="fs-14 px-3 d-flex align-items-center">
                                                            {val?.name}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              )
                                            })
                                          }
                                        </>
                                        :
                                        fs?.field_type === "checkbox" ? 
                                        <div className="ms-2 text-start fs-14">
                                          {filtercheckboxVal}
                                        </div>
                                          :
                                        fs?.field_type === "textarea" ? 
                                        <div className="text-start">
                                          <div className="fs-14 fw-bold color-gray d-flex align-items-center">
                                                {Screening_Form_Answer_State[fs?.screening_question_id]?.name ?? Screening_Form_Answer_State[fs?.screening_question_id] }
                                              </div>
                                        </div>
                                        :
                                        fs?.field_type === "radio" ?
                                        <div className="ms-3 text-start fs-14">
                                          {
                                            fs?.possible_values.filter((ov) => ov?.value === Screening_Form_Answer_State[fs?.screening_question_id])?.[0]?.value
                                          }
                                        </div>
                                        :
                                        fs?.field_type === "select" ?
                                        <div className="ms-3 text-start fs-14">
                                         {
                                          filterSelectVal
                                         }
                                        </div>
                                        : <div className="ms-3 text-start fs-14" >{Screening_Form_Answer_State[fs?.screening_question_id]}</div>
                                      }
                                    </div>
                                  </div>
                                </Col>
                              )
                            })
                          }
                          return null
                        })
                      })
                    }
                    </Row>
                  </Col>
                </Row>
                </div>
              )}
              )}
          </div>
        </div>
    </Col>
  </Row>
  );
};

export default Overview;
