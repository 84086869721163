import { useQuery, useMutation } from "react-query";
import {
  GetActorBasicDetails,
  GetActorProfile,
  GetUnitListByActorId,
  PostAddActorLogin,
  PutResetActorPassword,
  PutUpdateActorProfile,
} from "../../services/Actor";
import { PostUpdateActorProfilePicture } from "../../services/Profile";

//Get actors basic details by inviteId
export const useGetActorBasicDetails = (invite_Id) => {
  return useQuery(
    ["qGetActorBasicDetails", invite_Id],
    () => GetActorBasicDetails(invite_Id),
    {
      enabled: !!invite_Id,
    }
  );
};
//Get Unit list by actor id
export const useGetUnitListByActorId = (actor_id) => {
  return useQuery(
    ["qGetUnitListByActorId", actor_id],
    () => GetUnitListByActorId(actor_id),
    {
      enabled: !!actor_id,
    }
  );
};

//Get actors profile
export const useGetActorProfile = () => {
  return useQuery(["qGetActorProfile"], () => GetActorProfile());
};

// Put Update actor profile
export const useUpdateActorProfile = () => {
  return useMutation(PutUpdateActorProfile);
};

// Post Add actor login
export const useActorLogin = () => {
  return useMutation(PostAddActorLogin);
};
// Post Update actor profile picture
export const useUpdateActorProfilePicture = () => {
  return useMutation(PostUpdateActorProfilePicture);
};

// Put reset actor password
export const useResetActorPassword = () => {
  return useMutation(PutResetActorPassword);
};
