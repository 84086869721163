import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal               from "../../../../Components/widgets/modal/Modal";
import { useForm }         from "react-hook-form";
import { Col, Row }        from "react-bootstrap";
import NormalButton        from "../../../../Components/input/normalButton/NormalButton";
import { useRef, useState }        from "react";

const EmailVerificationModal = ({modal, setModal}) => {
    const { formState: { errors }, register,setValue, handleSubmit } = useForm({});

    
    const [successModal, setSuccessModal] = useState(false);
    const [declinedModal, setDeclinedModal] = useState(false);
    const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);

    const otpFieldsRef = useRef([]);

    const handleInput = (index, value) => {
        if (value.length > 1) {
          return;
        }
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        setValue(`otp${index+1}`, value, { shouldValidate: true })
        if (value.length === 1 && index < otpValues.length - 1) {
          otpFieldsRef.current[index + 1].focus();
        }
    };

    const handleBackspace = (index) => {
        if (otpValues[index] !== "") {
          const newOtpValues = [...otpValues];
          newOtpValues[index] = "";
          setOtpValues(newOtpValues);
        } else if (index > 0) {
          otpFieldsRef.current[index - 1].focus();
        }
    };

    return(
        <>
        <Modal
        topborder   = {false}
        open        = {modal}
        ModalHeader = {true}
        onHide      = {() => setModal(false)}
        title       = {
            <div className = "d-flex justify-content-between p-3 border-bottom">
            <div className = "d-flex justify-content-center align-items-center gap-3 px-1">
                <div>
                  <div className = "fs-20">Change email</div>
                </div>
              </div>
            </div>
          }
      >
        <form onSubmit={handleSubmit(()=> {
            setModal(false);
            setSuccessModal(true);
        })}>
          <div className = "p-3">
            <Row>
              <Col className = "px-1">
              <p   className = "fs-14">We have send you an email with a six digit verification code. 
              </p>
              <p   className = "fs-14 mb-5">Please fill out the code below to verify your email.</p>
                  <Row>
                      <div className = "primary-text-color px-0">
                        Verification code
                      </div>
                      <Col className = "pb-2 px-0">
                        <div>
                            <div className="profile-otp-container gap-3 flex-wrap px-2
                            ">
                                {otpValues.map((value, index) => (
                                    <div className="d-flex flex-column" key={index}>
                                        <input
                                            type="number"
                                            maxLength="1"
                                            className="profile-otp-input"
                                            value={value}
                                            {...register(`otp${index+1}`, {
                                                required: true,
                                            })}
                                            onChange={(e) => handleInput(index, e.target.value)}
                                            onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                handleBackspace(index);
                                            }
                                            }}
                                            ref={(ref) => {
                                            otpFieldsRef.current[index] = ref;
                                            }}
                                        />
                                        <div className="otp-border"></div>
                                    </div>
                                ))}
                                {Boolean(Object.keys(errors).length) && (
                                    <p className="error-msg">
                                    This OTP is required
                                    </p>
                                )}
                            </div>
                            {console.log(errors,'errors')}
                            <div className="mt-3">
                                <span className="fs-14 me-1">Didn't get a code?</span>
                                <span className="fs-14" style={{ color: '#91AA7B' }}>Resend code</span>
                            </div>
                        </div>
                      </Col>
                  </Row>
              </Col>
            </Row>
          </div>
          <div className = "d-flex justify-content-between gap-2 py-2 px-4 border-top flex-wrap" style = {{ background: '#F9FAFA', borderRadius: '0 0 10px 10px' }}>
            <div>
              <NormalButton
                type            = "submit"
                className       = "fw-900"
                textColor       = "black"
                backgroundColor = "white"
                buttonText      = "CANCEL"
                border          = "1px solid #D1D1D1"
                padding         = "6px 16px"
                borderRadius    = "6"
                fontSize        = "14"
                fontWeight      = "500"
                onClick         = {() => setModal(false)}
              />
            </div>
            <div>
              <NormalButton
                type            = "submit"
                className       = "fw-900"
                textColor       = "white"
                backgroundColor = "green"
                buttonText      = "GET VERIFICATION CODE"
                border          = "1px solid #F0F0F0"
                padding         = "6px 16px"
                borderRadius    = "6"
                fontSize        = "14"
                fontWeight      = "500"
              />
            </div>
          </div>
        </form>
       
         </Modal>
         <EmailSuccessModal setPrevModal={setModal} modal = {successModal} setModal = {setSuccessModal} />
         <EmailDeclinedModal setPrevModal={setModal} modal = {declinedModal} setModal = {setDeclinedModal} />
        </>
    )
}

export default EmailVerificationModal;

// Success Modal
const EmailSuccessModal = ({modal, setModal, setPrevModal}) => {
    return (
        <Modal
        topborder   = {false}
        open        = {modal}
        ModalHeader = {true}
        onHide      = {() => setModal(false)}
        title       = {
            <div className = "d-flex justify-content-between p-3 border-bottom">
            <div className = "d-flex justify-content-center align-items-center gap-3">
                    <div>
                    <div className = "fs-20">Change email</div>
                    </div>
                </div>
                <div onClick = {() => setModal(false)}>
                    <FontAwesomeIcon
                    className = "cursor"
                    icon      = "fa-regular fa-xmark"
                    />
                </div>
            </div>
          }
      >
        <div className = "p-3" style = {{ height: '180px' }}>
          <Row>
            <Col className = "px-0">
            <p   className = "fs-16 fw-bold">Verification approved</p>
            </Col>
          </Row>
          <Row>
            <Col className = "px-0 mt-3">
            <p   className = "fs-16">The verification code was correct.</p>
            <p   className = "fs-16">Click finish and close to finalize the change of your email.</p>
            </Col>
          </Row>
        </div>
        <div className = "d-flex justify-content-between py-2 px-3 border-top" style = {{ background: '#F9FAFA', borderRadius: '0 0 10px 10px' }}>
            <NormalButton
                onClick         = {() => {
                    setModal(false);
                    setPrevModal(true);
                }}
                className       = "fw-900"
                textColor       = "black"
                backgroundColor = "white"
                buttonText      = "BACK"
                border          = "1px solid #F0F0F0"
                padding         = "6px 16px"
                borderRadius    = "6"
                fontSize        = "14"
                fontWeight      = "500"
            />
            <NormalButton
              onClick         = {() => setModal(false)}
              className       = "fw-900"
              textColor       = "white"
              backgroundColor = "green"
              buttonText      = "FINISH AND CLOSE"
              border          = "1px solid #F0F0F0"
              padding         = "6px 16px"
              borderRadius    = "6"
              fontSize        = "14"
              fontWeight      = "500"
            />
        </div>
        </Modal>
    )
}

// Declined Modal
const EmailDeclinedModal = ({modal, setModal, setPrevModal}) => {
    return (
        <Modal
        topborder   = {false}
        open        = {modal}
        ModalHeader = {true}
        onHide      = {() => setModal(false)}
        title       = {
            <div className = "d-flex justify-content-between p-3 border-bottom">
            <div className = "d-flex justify-content-center align-items-center gap-3">
                    <div>
                    <div className = "fs-20">Change email</div>
                    </div>
                </div>
                <div onClick = {() => setModal(false)}>
                    <FontAwesomeIcon
                    className = "cursor"
                    icon      = "fa-regular fa-xmark"
                    />
                </div>
            </div>
          }
      >
        <div className = "p-3" style = {{ height: '180px' }}>
          <Row>
            <Col className = "px-0">
            <p   className = "fs-16 fw-bold">Verification declined</p>
            </Col>
          </Row>
          <Row>
            <Col className = "px-0 mt-3">
            <p   className = "fs-16">Something went wrong. The verification code was declined.</p>
            <p   className = "fs-16">Please go back and try again.</p>
            </Col>
          </Row>
        </div>
        <div className = "d-flex justify-content-start py-2 px-3 border-top" style = {{ background: '#F9FAFA', borderRadius: '0 0 10px 10px' }}>
            <NormalButton
                onClick         = {() => {
                    setModal(false);
                    setPrevModal(true);
                }}
                className       = "fw-900"
                textColor       = "black"
                backgroundColor = "white"
                buttonText      = "BACK"
                border          = "1px solid #F0F0F0"
                padding         = "6px 16px"
                borderRadius    = "6"
                fontSize        = "14"
                fontWeight      = "500"
            />
        </div>
        </Modal>
    )
}