import React from "react";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { H2 } from "../../../styles/Common.style";

const SaveAndFinishLater = ({
  setOpenSaveFinishLater,
  setScreening_Form_Popup,
}) => {
  return (
    <div>
      <div
        className=" py-2 p-4 mb-4 mt-2"
        style={{ fontSize: "14px", fontWeight: "500" }}
      >
        <H2>Are you sure you want to exit the screening?</H2>
        <div className="mb-4 paragraph1-light grey-text mt-3">
          You have not completed the screening yet, and there is a time limit
          for it.
        </div>
        <div className="mb-3 paragraph1-light grey-text fs-16">
          If you are having trouble finishing, please see the FAQ by clicking
          the "?" icon at the bottom of the screening.
        </div>
      </div>
      <div
        className="d-flex justify-content-between px-4 py-2"
        style={{
          borderTop: "1px solid #d1d1d1 ",
          background: "#f7f8fa",
          borderRadius: "0 0 12px 12px",
        }}
      >
        <div>
          <NormalButton
            type="submit"
            className="fw-900"
            // borderColor="#F0F0F0"
            textColor=" black"
            backgroundColor="white"
            buttonText="CANCEL"
            border="1px solid #F0F0F0"
            padding="10px 24px"
            borderRadius="6px"
            fontSize="14"
            fontWeight="500"
            onClick={(e) => {
              e.preventDefault();
              setOpenSaveFinishLater(false);
            }}
          />
        </div>
        <div>
          <NormalButton
            type="submit"
            className="fw-900"
            textColor="white"
            border="none"
            backgroundColor="green"
            buttonText="SAVE AND FINISH"
            padding="11px 24px"
            borderRadius="6px"
            fontSize="14"
            fontWeight="500"
            onClick={(e) => {
              e.preventDefault();
              setScreening_Form_Popup(false);
              setOpenSaveFinishLater(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SaveAndFinishLater;
