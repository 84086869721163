import Welcome from './Welcome';
import ScreeningInfo from './ScreeningInfo';
import { useScreeningFormStore } from '../../../../hooks/stores/useScreeningFormStore';
import CustomerSelectPage from './CustomerSelectPage';
import { OtherScreeningConfirmation } from './OtherScreeningConfirmation';

const PreScreening = () => {
    const Screening_Form_State = useScreeningFormStore(
        (state) => state.Screening_Form_State
    );
    const setScreening_Form_State = useScreeningFormStore(
        (state) => state.setScreening_Form_State
    );

    const childProps = { getIndex: Screening_Form_State, setIndex: setScreening_Form_State }

     // prescreening component to render
     const components = {
        0: () => <CustomerSelectPage {...childProps} />,
        1: () => <OtherScreeningConfirmation {...childProps} />,
        2: () => <ScreeningInfo {...childProps} />,
    }
   
    if(!Object.keys(Screening_Form_State ?? {}).length) return null
    return (
        <>
        {
            Screening_Form_State.welcomePage ? 
            <Welcome {...childProps} />
            :
            components[Screening_Form_State?.prevIndex]()
        }
        </>
    )
}



export default PreScreening;
