import { create } from "zustand";

// initial state for the screening form
const SCREENING_FORM_STATE = {};

// Screening page forms state
export const useScreeningFormStore = create((set) => ({
	Screening_Form_State: SCREENING_FORM_STATE,
	setScreening_Form_State: (data) => set({ Screening_Form_State: data }),
}));

// Screening page forms popup state
export const useScreeningFormPopup = create((set) => ({
	Screening_Form_Popup: false,
	setScreening_Form_Popup: (data) => set({ Screening_Form_Popup: data }),
}));

// Screening page forms state answer
export const useScreeningFormAnswerStore = create((set) => ({
	screeningFormAnswerStore: {},
	setScreeningFormAnswerStore: (data) => set({ screeningFormAnswerStore: data }),
}));

// Screening page forms screeningFormNames
export const useScreeningFormsNames = create((set) => ({
	screeningFormNames: [],
	setScreeningFormNames: (data) => set({ screeningFormNames: data }),
}));

// Screening page forms screeningFormAnswerData
export const useScreeningFormsAnswerData = create((set) => ({
	screeningFormAnswerData: '',
	setScreeningFormAnswerData: (data) => set({ screeningFormAnswerData: data }),
}));

// screening FAQ popup State
export const useScreeningFaqPopup = create((set) => ({
  screeningFaqPopup: false,
  setScreeningFaqPopup: (data) => set({ screeningFaqPopup: data }),
}))
// screening Mobile FAQ popup State
export const useMobileFaqPopup = create((set) => ({
  mobileFaqPopup: false,
  setMobileFaqPopup: (data) => set({ mobileFaqPopup: data }),
}))


// screening Need help message Modal
export const useScreeningMessageModal = create((set) => ({
	screeningMessageModal: false,
	setScreeningMessageModal: (data) => set({ screeningMessageModal: data }),
  }))

// screening chatbox 
export const useScreeningNeedHelpChatbox = create((set) => ({
	screeningNeedHelpChatbox: false,
	setScreeningNeedHelpChatbox: (data) => set({ screeningNeedHelpChatbox: data }),
  }))
  
// screening chatbox2 
export const useScreeningNeedHelpChatbox2 = create((set) => ({
	screeningNeedHelpChatbox2: false,
	setScreeningNeedHelpChatbox2: (data) => set({ screeningNeedHelpChatbox2: data }),
  }))
// screening home chatbox 
export const useHomeChatbox = create((set) => ({
	homeChatbox: false,
	setHomeChatbox: (data) => set({ homeChatbox: data }),
  }))
  
// screening home chatbox2 
export const useHomeChatbox2 = create((set) => ({
	homeChatbox2: false,
	setHomeChatbox2: (data) => set({ homeChatbox2: data }),
  }))
// screening FAQ chatbox 
export const useFAQChatbox = create((set) => ({
	FAQChatbox: false,
	setFAQChatbox: (data) => set({ FAQChatbox: data }),
  }))
  
// screening FAQ chatbox2 
export const useFAQChatbox2 = create((set) => ({
	FAQChatbox2: false,
	setFAQChatbox2: (data) => set({ FAQChatbox2: data }),
  }))

// screening overview save button 
export const useIsFormValueChanged = create((set) => ({
	isFormValueChanged: false,
	setIsFormValueChanged: (data) => set({ isFormValueChanged: data }),
  }))