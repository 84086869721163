import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import managementLogo from "../../assets/images/management-logo.svg";
import loginImagePattern from "../../assets/images/login-image-pattern.svg";
import { Col, Row, Form, Button, Image, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import "../../styles/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordValidation from "./PasswordValidation";
import LoginVerification from "../session-expired/LoginVerification";
import { PostAddActorLogin } from "../../services/Actor";
import { PostSendOtp } from "../../services/SMS";
import { toast } from "react-toastify";
import { useGetActorBasicDetails } from "../../hooks/queries/useActorQueries";
import { H1, H4 } from "../../styles/Common.style";

const CreateUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inviteId = searchParams.get("invite_id");
  //------------------Validation----------------------//
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      enterPassword: "",
      confirmPassword: "",
    },
  });
  //---------------------States------------------------//
  const [showPassword, setShowPassword] = useState(false);
  const [formValue, setFormValue] = useState({
    enterPassword: getValues("enterPassword"),
    confirmPassword: getValues("confirmPassword"),
  });
  const [loginVerification, setLoginVerification] = useState(false);
  //---------------------React query-------------------//
  const { data: getActorBasicDetailsData } = useGetActorBasicDetails(inviteId);

  useEffect(() => {
    setValue("email", getActorBasicDetailsData?.data?.[0]?.email);
    setValue(
      "countryCode",
      getActorBasicDetailsData?.data?.[0]?.phone_calling_code_id
    );
    setValue("phone", getActorBasicDetailsData?.data?.[0]?.mobilenumber);
    return () => {};
  }, [getActorBasicDetailsData]);

  //----------------------handle sign in/ create actor------------------//
  const handleSignIn = (data) => {
    const addActorPayload = {
      actor_id: String(getActorBasicDetailsData?.data?.[0]?.actor_id),
      pass: data?.enterPassword,
    };
    PostAddActorLogin(addActorPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          setLoginVerification(!loginVerification);
          sendOTP();
          toast.success(response.data.message);
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    // navigate("/home");
  };

  //---------------handle send otp-----------------//
  const sendOTP = () => {
    const sendOtpPayload = {
      actor_id: String(getActorBasicDetailsData?.data?.[0]?.actor_id),
    };
    PostSendOtp(sendOtpPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  // strong password
  const [isStrong, initRobustPassword] = useState(null);
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };
  // end strong password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handelconfirmpassword = () => {
    setShowConfirmPassword(true);
  };

  return (
    <div
      className="pt-3 bg-login d-flex justify-content-center align-items-center px-4"
      style={{ position: "relative" }}
    >
      {!loginVerification ? (
        <div className="mb-4">
          <Form onSubmit={handleSubmit(handleSignIn)}>
            <Row className="mb-1">
              <Col sm={12}>
                <img
                  src={managementLogo}
                  alt="management-logo"
                  className="management-logo d-block m-auto"
                />
                <Row className="loginForm mt-3 mb-3">
                  <Col sm={6} className="login-right-section px-4 pt-2">
                    <H1>Create user</H1>
                    <div className="login-form mb-1">
                      <H4>Email address</H4>
                      <Row>
                        {" "}
                        <Col className="px-0">
                          <Controller
                            {...register(`email`)}
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                              <div className="form-input-grey mb-3">
                                <input
                                  name="email"
                                  placeholder="Email"
                                  value={value}
                                  id="email123"
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  readOnly
                                />
                                <label htmlFor="email123">Email</label>
                              </div>
                            )}
                          />
                        </Col>{" "}
                      </Row>
                      <div className="pb-13 medium-text">
                        {" "}
                        <H4>Phone number</H4>
                      </div>
                      <Row className="mb-3">
                        <Col className="px-sm-0 pe-lg-1 mb-sm-3 mb-lg-0" lg={3}>
                          <Controller
                            {...register(`countryCode`)}
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                              <div className="form-input-grey">
                                <input
                                  className="input-value-color"
                                  name="countryCode"
                                  placeholder="Country code"
                                  value={value ? value : "+"}
                                  id="cc"
                                  readOnly

                                  // style={{ width: "43%" }}
                                ></input>
                                <label htmlFor="cc">Country code</label>
                              </div>
                            )}
                          />
                        </Col>
                        <Col className="pe-lg-0 px-sm-0 ps-lg-2" lg={9}>
                          <Controller
                            {...register(`phone`)}
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                              <div className="form-input-grey">
                                <input
                                  className="d-block w-100 radius-6 input-value-color"
                                  name="phone"
                                  placeholder="phone"
                                  value={value}
                                  id="phone"
                                  readOnly
                                />
                                <label htmlFor="phone">Phone</label>
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                      <div className="form-group">
                        <label className="pb-2 medium-text">
                          <H4>Enter password</H4>
                        </label>
                        <InputGroup>
                          <input
                            style={{
                              color: !showPassword
                                ? "black"
                                : "rgba(17, 23, 71, 0.50)",
                            }}
                            className="d-block w-100 radius-6"
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            type={showPassword ? "text" : "password"}
                            {...register("enterPassword", {
                              required: true,
                              minLength: {
                                value: 8,
                                message: "min length is 8",
                              },
                              onChange: (e) => {
                                setFormValue({
                                  ...formValue,
                                  enterPassword: e.target.value,
                                });
                              },
                            })}
                          />
                          <div
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              border: "none",
                            }}
                            onClick={() => setShowPassword((prev) => !prev)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? (
                              <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                            ) : (
                              <FontAwesomeIcon icon="fa-regular fa-eye" />
                            )}
                          </div>
                        </InputGroup>
                        {formValue.enterPassword == "" ? (
                          <>
                            {errors.enterPassword ? (
                              <p className="error-msg pt-0 ps-3">
                                This field is required
                              </p>
                            ) : (
                              <p className="error-msg pt-0 ps-3 color-white">
                                This field is required
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {formValue.enterPassword.length >= 8 ? (
                              <p className="error-msg pt-0 ps-3 color-white">
                                Password should be minimum 8 characters
                              </p>
                            ) : (
                              <p className="error-msg pt-0 ps-3">
                                Password should be minimum 8 characters
                              </p>
                            )}
                          </>
                        )}
                      </div>{" "}
                      <div className="form-group mb-3">
                        <label className="pb-2 medium-text">
                          <H4>Confirm password</H4>
                        </label>

                        <InputGroup>
                          <input
                            style={{
                              color: !showConfirmPassword
                                ? "black"
                                : "rgba(17, 23, 71, 0.50)",
                            }}
                            className="d-block w-100 radius-6  "
                            id="password"
                            name="password"
                            placeholder="Confirm password"
                            type={showConfirmPassword ? "text" : "password"}
                            {...register("confirmPassword", {
                              required: true,
                              validate: (value) =>
                                value === getValues().enterPassword ||
                                "Password does not match",
                              onChange: (e) => {
                                setFormValue({
                                  ...formValue,
                                  confirmPassword: e.target.value,
                                });
                              },
                            })}
                          />
                          <div
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              border: "none",
                            }}
                            // onClick={handelconfirmpassword}
                            onClick={() =>
                              setShowConfirmPassword((prev) => !prev)
                            }
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showConfirmPassword ? (
                              <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                            ) : (
                              <FontAwesomeIcon icon="fa-regular fa-eye" />
                            )}
                          </div>
                        </InputGroup>
                        {formValue.confirmPassword == "" ? (
                          <>
                            {errors.confirmPassword ? (
                              <p className="error-msg pt-0 ps-3">
                                This field is required
                              </p>
                            ) : (
                              <p className="error-msg color-white pt-0 ps-3">
                                :
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {formValue.confirmPassword !==
                            formValue.enterPassword ? (
                              <p className="error-msg ps-3 pt-0">
                                Password and confirm password should be same
                              </p>
                            ) : (
                              <p className=" error-msg color-white ps-3 pt-0">
                                :
                              </p>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="ForgotPassword fw-500 fs-16 m-0"
                        style={{
                          lineHeight: "18px",
                          color: "#ADBE9E",
                        }}
                      ></div>
                      {formValue.enterPassword ? (
                        <PasswordValidation
                          password={formValue.enterPassword}
                          actions={initPwdInput}
                          isStrong={isStrong}
                        />
                      ) : (
                        <>
                          {" "}
                          <div
                            className="w-100 color-white radius-8"
                            style={{
                              marginBottom: "38.3px",
                            }}
                          >
                            :
                          </div>{" "}
                        </>
                      )}
                      <div>
                        <Button
                          // onClick={handelsetpassword}

                          disabled={!formValue.enterPassword}
                          className="w-100"
                          style={{
                            height: "44px",
                            fontFamily: "Cerebri sans-medium",
                            background: "#ADBE9E",
                            border: "none",
                            borderRadius: "6px",
                            cursor: "pointer",
                          }}
                          type="submit"
                        >
                          CREATE
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Image
                      fluid
                      className="w-100 h-100"
                      src={loginImagePattern}
                      alt="login-image-pattern"
                      style={{ objectFit: "cover", borderRadius: "8px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <LoginVerification
          getActorBasicDetailsData={getActorBasicDetailsData}
          sendOTP={sendOTP}
        />
      )}
      <div className="copyright copyright-text">
        Copyright 2013-2023 © Estatetool A/S
      </div>
    </div>
  );
};
export default CreateUser;
