import dashboardHeaderImage from "../assets/images/top-header-image-pattern.svg";
import avatarPerson from "../assets/icons/avatar-person.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "../styles/main.css";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./home/Home";
import { Sidebar } from "./sidebar/Sidebar";
import Profile from "./home/Profile/Profile";
import { Col, Row } from "react-bootstrap";
import Notification from "./home/Profile/Notification";
import NeedHelp from "./help/NeedHelp";
import { useNavigate } from "react-router-dom";
import Messages from "./messages/Messages";
import Documents from "./documents/Documents";
import { ContactBook } from "./contact-book/ContactBook";
import { Matching } from "./matching/Matching";
import { Settings } from "./settings/Settings";
import RecentActivity from "./recent-activity/RecentActivity";
import NoticeBoard from "./notice-board/NoticeBoard";
import Checkdata from "./home/Profile/Checkdata";
import { LogoutApi } from "../services/LogoutApi";
import jwt_decode from "jwt-decode";
import EventsCalendar from "../views/events-calendar/EventsCalendar";

// have to remove this dummy data import files
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadHome } from "./home/LeadHome";
import { Reservations } from "./reservation/Reservations";
import {
  useGetActorProfile,
  useGetUnitListByActorId,
} from "../hooks/queries/useActorQueries";
import { useGetDocumentDetailsByContactId } from "../hooks/queries/useActorDocumentsQueries";
import {
  useUnitId,
  useUnitInformationStore,
} from "../hooks/stores/useUnitStore";
import { useActorProfileStore } from "./../hooks/stores/useActorDetails";
import { myPage } from "../styles/Theme";
import GuideViews from "./home/guides/GuideViews";
import { Screening } from "./screening/Screening";
import { H5 } from "../styles/Common.style";
import FormerCustomer from "./home/FormerCustomer";
import PotentialCustomer from "./home/PotentialCustomer";
import SigningDocumentHome from "./home/SigningDocumentHome";
import ScreeningWizard from "./screening/ScreeningWizard";

const Main = () => {
  const myTabLocation = useLocation();
  const pathname = myTabLocation.pathname;

  const setActorProfileData = useActorProfileStore(
    (state) => state.setActorProfile
  );
  const actorProfile = useActorProfileStore((state) => state.actorDetail);

  // Remove the leading '/' character
  const formattedPathname = pathname.replace("/", "");

  // Capitalize the first letter
  const TopMenuBar =
    formattedPathname.charAt(0).toUpperCase() + formattedPathname.slice(1);

  //---------------------------States---------------------//
  const [propertyDropdown, setPropertyDropdown] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(null);
  // const [selectedUnitData, setSelectedUnitData] = useState([]);

  const { UnitId, setUnitId } = useUnitId((state) => state);

  const { unitInformation, setUnitInformation } = useUnitInformationStore(
    (state) => state
  );
  // console.log("unitInformation", unitInformation);

  //---------------------------react query----------------------//
  const { data: getActorProfileData, refetch: getActorProfileDataRefetch } =
    useGetActorProfile();

  const { data: getUnitListByActorIdData } = useGetUnitListByActorId(
    getActorProfileData?.data?.[0]?.actor_id
  );

  // console.log("getUnitListByActorIdData", getUnitListByActorIdData?.data);

  const { data: getDocumentDetailsByContactId } =
    useGetDocumentDetailsByContactId(unitInformation?.contract_id, 0);

  useEffect(() => {
    // setSelectedUnitData(getUnitListByActorIdData?.data);
    setUnitInformation(getUnitListByActorIdData?.data);
    return () => {};
  }, [getUnitListByActorIdData]);

  useEffect(() => {
    setActorProfileData(getActorProfileData?.data?.[0] ?? []);
  }, [getActorProfileData]);

  // property dropdown selected change on Select
  const handlePropertySelect = (e) => {
    setUnitId(e);
    setPropertyDropdown(false);
  };

  const logoutHandler = () => {
    LogoutApi();
    sessionStorage.clear();
  };
  const navigate = useNavigate();

  const jwt = sessionStorage.getItem("et-jwt");

  if (!jwt) {
    // No token found, navigate to the login page
    navigate("/"); // Replace with your actual login page URL
    return;
  }

  const decodedToken = jwt_decode(jwt);

  const currentTime = Date.now() / 1000; // current value will get the value in miliseconds to  seconds
  // console.log("currentTime- value in seconds", currentTime);
  if (decodedToken.exp < currentTime) {
    // Token has expired, navigate to the login page
    navigate("/");
  }

  return (
    <>
      <div>
        <div
          className="w-100 position-fixed"
          style={{ zIndex: "100", left: "0" }}
        >
          {/* portal header img */}
          <img
            className="w-100 h-100"
            src={dashboardHeaderImage}
            alt="dashboard top header"
            style={{ objectFit: "cover", minHeight: "40px" }}
          />
          {/* portal header img */}
          <div>
            {/* portal header Navbar */}
            <header
              className="w-100 my-portal-header"
              style={{
                boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.04)",
                backgroundColor: "white",
              }}
            >
              <div className="d-flex px-2 justify-content-between align-items-center flex-wrap">
                <p className="current-tab mb-0 ps-md-4 fs-7">
                  {TopMenuBar === "Profile" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home
                      </span>{" "}
                      / Profile
                    </span>
                  ) : TopMenuBar === "Reservation" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home
                      </span>{" "}
                      / Reservation
                    </span>
                  ) : TopMenuBar === "Screening" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                       Screening
                    </span>
                  ) : TopMenuBar === "Signin-customer" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Signin-customer
                    </span>
                  ) : TopMenuBar === "Notice-board" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Notice-board
                    </span>
                  ) : TopMenuBar === "Contact-book" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Contact-book
                    </span>
                  ) : TopMenuBar === "Matching" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Matching
                    </span>
                  ) : TopMenuBar === "Settings" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Settings
                    </span>
                  ) : TopMenuBar === "Lead-home" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Lead-home
                    </span>
                  ) : TopMenuBar === "Messages" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Messages
                    </span>
                  ) : TopMenuBar === "Documents" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Documents
                    </span>
                  ) : TopMenuBar === "Guides" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home
                      </span>{" "}
                      / Guides
                    </span>
                  ) : TopMenuBar === "Events-calendar" ? (
                    <span>
                      <span style={{ color: "#000000", opacity: "25%" }}>
                        Home /
                      </span>{" "}
                      Events calendar
                    </span>
                  ) : (
                    TopMenuBar
                  )}

                  {/* {TopMenuBar == "Profile" ? `${<span style={{color : '#000000', opacity : '25%'}}>Home</span>}  /  Profile` : TopMenuBar } */}
                </p>
                <nav className="d-flex py-3 gap-2 gap-lg-5 flex-wrap">
                  {unitInformation?.length > 1 ? (
                    <Dropdown
                      show={propertyDropdown}
                      className="dropup"
                      onClick={() => {
                        setPropertyDropdown(!propertyDropdown);
                      }}
                      onBlur={() =>
                        setTimeout(() => setPropertyDropdown(false), 500)
                      }
                      onSelect={handlePropertySelect}
                    >
                      <Dropdown.Toggle
                        className={`dropdown-toggle ${
                          propertyDropdown ? "border border-dark" : ""
                        } bg-transparent text-start rounded pe-4 fs-9 fw-500`}
                        style={{ border: "1px solid rgba(17, 23, 71, 0.1)" }}
                      >
                        {propertyDropdown ? (
                          <div className="dropdown-caret d-flex justify-content-center align-item-center ">
                            <FontAwesomeIcon
                              icon="fa-solid fa-angle-up"
                              size="sm"
                            />
                          </div>
                        ) : (
                          <div className="dropdown-caret d-flex justify-content-center align-item-center opacity-25">
                            <FontAwesomeIcon
                              icon="fa-solid fa-angle-down"
                              size="sm"
                            />
                          </div>
                        )}
                        <p className="mb-0 label-medium">
                          {unitInformation?.[UnitId]?.property_name}
                        </p>
                        <span className="label-regular grey-text">
                          {unitInformation?.[UnitId]?.street_name}
                          &nbsp;
                          {unitInformation?.[UnitId]?.house_number}
                          ,&nbsp;
                          {unitInformation?.[UnitId]?.floor_number}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
                        }}
                        className="p-0 text-start rounded fs-9 w-100 "
                      >
                        <div
                          className="scrollable-container-y m-1"
                          style={{
                            maxHeight: "200px", // Set a maximum height for the dropdown
                            // overflowY: "auto",  Add a vertical scrollbar when content exceeds the height
                          }}
                        >
                          {getUnitListByActorIdData?.data?.map(
                            (item, index) => {
                              return (
                                <Dropdown.Item
                                  className="fs-10 fw-500 radius-8"
                                  key={index}
                                  eventKey={index}
                                >
                                  <p className="mb-0 font-medium">
                                    {item?.property_name}
                                  </p>
                                  <span className="dropdown-secondary-color">
                                    {item?.street_name} {item?.house_number},{" "}
                                    {item?.floor_number}
                                  </span>
                                </Dropdown.Item>
                              );
                              // );
                            }
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}

                  <div
                    className="position-relative log-user d-flex align-items-center pe-md-5 gap-3 cursor"
                    onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                    onBlur={() => setShowProfileDropdown(false)}
                    tabIndex="0"
                  >
                    <div>
                      <img
                        className="rounded-circle"
                        src={
                          getActorProfileData?.data?.[0]?.profile_photo !== ""
                            ? getActorProfileData?.data?.[0]?.profile_photo
                            : avatarPerson
                        }
                        alt="User_img"
                        height={"40px"}
                        width={"40px"}
                      />
                    </div>

                    <div className="mt-1">
                      <H5>{getActorProfileData?.data?.[0]?.firstname}</H5>
                      {/* <span className="fw-400 fs-8 dropdown-secondary-color">
                        User
                      </span> */}
                    </div>
                    {showProfileDropdown && (
                      <div
                        className="position-absolute radius-6 w-100"
                        style={{
                          backgroundColor: "white",
                          filter:
                            "drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))",
                          border: "1px solid rgba(17, 23, 71, 0.1)",
                          top: "50px",
                        }}
                      >
                        <ul className="fw-400 fs-8 m-0 px-1">
                          <li
                            className="d-flex align-items-center gap-2 radius-6 p-2 my-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate("/profile");
                              setShowProfileDropdown(false);
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-regular fa-user"
                              size="lg"
                            />
                            Profile
                          </li>
                          <li
                            className="d-flex align-items-center gap-2 radius-6 p-2 my-1"
                            onClick={logoutHandler}
                          >
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-right-from-bracket"
                              size="lg"
                            />
                            Log out
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </nav>
              </div>
            </header>
            {/* portal header Navbar */}
          </div>
        </div>
        {/* sidebar */}
        <div
          className="px-4 pb-5 mt-4 mb-3 position-fixed d-none d-md-block scrollable-container-y "
          style={{
            borderRadius: "12px 12px 0px 0px",
            height: "100%",
            boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.04)",
            zIndex: "100",
            left: "15px",
            // backgroundColor: (props) => props.theme.primaryColor,
            backgroundColor: myPage.primarySidebarColor, // bg-slider - classname
          }}
        >
          <Sidebar role="user" />
        </div>

        {/* sidebar */}

        <div className="dashboard-container position-relative">
          <Row>
            {/* Main section where all pages will appear on tab change - Routes */}
            <Col
              className="pt-5 pb-3"
              style={{ backgroundColor: myPage.PortalThemeColor }}
            >
              <Routes>
                <Route
                  path="lead-home"
                  element={
                    <LeadHome
                      getActorProfileData={getActorProfileData}
                      unitInformation={unitInformation?.[UnitId]}
                    />
                  }
                />
                <Route
                  path="home"
                  element={
                    <Home
                      getActorProfileData={getActorProfileData}
                      unitInformation={unitInformation?.[UnitId]}
                    />
                  }
                />
                <Route
                  path="guides"
                  element={
                    <GuideViews unitInformation={unitInformation?.[UnitId]} />
                  }
                />
                <Route
                  path="profile"
                  element={
                    <Profile
                      getActorProfileData={getActorProfileData}
                      getActorProfileDataRefetch={getActorProfileDataRefetch}
                    />
                  }
                />
                <Route path="notification" element={<Notification />} />
                <Route path="notice-board" element={<NoticeBoard />} />
                <Route path="messages" element={<Messages />} />
                <Route path="events-calendar" element={<EventsCalendar />} />
                <Route
                  path="documents"
                  element={
                    <Documents
                      unitInformation={unitInformation?.[UnitId]}
                      getActorProfileData={getActorProfileData}
                    />
                  }
                />
                {/* <Route path="contact-book" element={<ContactBook />} /> */}
                {/* <Route path="matching" element={<Matching />} /> */}
                {/* <Route path="settings" element={<Settings />} /> */}
                <Route path="help" element={<NeedHelp />} />
                <Route
                  path="screening"
                  element={
                    <Screening
                      getActorProfileData={getActorProfileData}
                      unitInformation={unitInformation?.[UnitId]}
                    />
                  }
                />
                <Route path="recent-activity" element={<RecentActivity />} />

                <Route path="Checkdata" element={<Checkdata />} />
                {/* <Route path="former-customer" element={<FormerCustomer />} /> */}
                {/* <Route
                  path="potential-customer"
                  element={<PotentialCustomer />}
                /> */}
                {/* <Route
                  path="signin-customer"
                  element={<SigningDocumentHome />}
                /> */}

                {/* <Route path="session-expired" element={<SessionExpired/>}/> */}
              </Routes>

              {/* Popups */}
              <ScreeningWizard  unitInformation={unitInformation?.[UnitId]} />
            </Col>
            {/* Main section where all pages will appear on tab change - Routes */}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Main;
