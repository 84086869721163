import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialReactTable from "material-react-table";
import { Button, InputAdornment } from "@mui/material";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import Modal from "../../Components/widgets/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, Overlay, Row, Tooltip } from "react-bootstrap";
import moment from "moment";
import {
  useGetDocumentDetailsByContactId,
  useUpdateRestoreArchiveDocuments,
  usePostActorDocumentViewDetail,
  usePostActorDocumentShare,
} from "../../hooks/queries/useActorDocumentsQueries";
import {
  DeleteArchiveDocument,
  DeleteArchiveDocumentPermanent,
} from "../../services/Documents";
import { toast } from "react-toastify";
import UploadDocument from "./UploadDocument";
import ShowTooltip from "../../Components/Tooltip/ShowTooltip";
import { H3, H5 } from "../../styles/Common.style";

const FileSaver = require("file-saver");

const Documents = ({ unitInformation, getActorProfileData }) => {
  //--------------states----------------//
  const [tooltip, setTooltip] = useState([]);
  const target = useRef([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [deleteDocumentPermanentModal, setDeleteDocumentPermanentModal] =
    useState(false);
  const [share, setShare] = useState(false);
  const [allArchivedStyle, setAllArchivedStyle] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [documentDetailData, setDocumentDetailData] = useState([]);

  const [documentArchiveDetailData, setDocumentArchiveDetailData] = useState(
    []
  );

  // console.log("rowSelection", rowSelection != undefined);

  const [documenType, setDocumenType] = useState(0);
  const [documentDetails, setDocumentDetails] = useState({
    documentUrl: "",
    documentName: "",
    documentId: "",
  });
  const [currentRow, setCurrentRow] = useState({});
  // const [checked, setChecked] = useState("");

  const [uploadDocument, setUploadDocument] = useState(false);

  const [shareDocument, setShareDocument] = useState([]);

  const [copiedUrl, setCopiedUrl] = useState({});

  const [selectedDocumentRowData, setSelectedDocumentRowData] = useState();
  // console.log("selectedDocumentRowData", selectedDocumentRowData);
  //------------------------react query------------------------------//

  const { data: getDocumentAllData, refetch: getDocumentAllDataIdRefetch } =
    useGetDocumentDetailsByContactId(
      unitInformation?.contract_id,
      getActorProfileData?.data?.[0]?.actor_id,
      0
    );
  const {
    data: getDocumentArchivedData,
    refetch: getDocumentArchivedDataIdRefetch,
  } = useGetDocumentDetailsByContactId(
    unitInformation?.contract_id,
    getActorProfileData?.data?.[0]?.actor_id,
    1
  );

  // console.log("getDocumentAllData", getDocumentAllData?.data);

  // Showing the count for "All" the "Archived" data

  const ArchiveDocument = getDocumentArchivedData?.data?.length;
  const AllDocument = getDocumentAllData?.data?.length;

  const { mutateAsync: PutUpdateRestoreArchiveDocument } =
    useUpdateRestoreArchiveDocuments();

  const { mutateAsync: doumentSeenMutate } = usePostActorDocumentViewDetail();
  const { mutateAsync: doumentReadMutate } = usePostActorDocumentViewDetail();

  // Share Document
  const {
    mutateAsync: DocumentShareMutate,
    isLoading: PostShareDocumentIsLoading,
  } = usePostActorDocumentShare();

  // Share Document validation
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      subject: "",
      message: "",
    },
  });

  useEffect(() => {
    setDocumentDetailData(getDocumentAllData?.data);
    setDocumentArchiveDetailData(getDocumentArchivedData?.data);

    return () => {
      setDocumentDetailData([]);
    };
  }, [getDocumentAllData, getDocumentArchivedData]);

  // useEffect(() => {
  //   if (currentRow?.original?.read_on !== "") {
  //     setChecked(true);
  //   }
  // }, []);

  const handleSetShare = () => {
    setShare(false);
    setArchiveModal(false);
  };
  // // styling the All Data and Archived Data

  const handleAllData = () => {
    setDocumenType(0);
    setAllArchivedStyle(false);
    setDocumentDetailData(getDocumentAllData?.data);
  };
  const handleArchiveData = () => {
    setDocumenType(1);
    setAllArchivedStyle(true);
    setDocumentDetailData(getDocumentArchivedData?.data);
  };

  // ---------------Seen document----------------//
  const handleSeenDocument = async (row) => {
    setPreviewModal(true);

    if (row.original?.document_view_actor?.length == "") {
      const seenPayload = {
        actor_id: getActorProfileData?.data[0]?.actor_id,
        document_id: row?.original?.document_id,
        view_type: "S",
      };
      const response = await doumentSeenMutate(seenPayload);
      try {
        if (response?.status === "success" && response?.data?.bReturn) {
          toast.success(response?.data?.message);
          getDocumentAllDataIdRefetch();
          getDocumentArchivedDataIdRefetch();
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  //-------------------Read document---------------//

  const handleReadDocument = async () => {
    const readPayload = {
      actor_id: getActorProfileData?.data[0]?.actor_id,
      document_id: currentRow?.original?.document_id,
      view_type: "R",
    };
    const response = await doumentReadMutate(readPayload);
    try {
      if (response?.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        getDocumentAllDataIdRefetch();
        getDocumentArchivedDataIdRefetch();
        setPreviewModal(false);
      } else {
        toast.error(response?.data?.message);
        setPreviewModal(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  //--------------download document------------//
  const handleDownloadDocument = (docRowData) => {
    let docId =
      Object.keys(rowSelection).length > 0
        ? Object.keys(rowSelection)
        : [docRowData?.document_id?.toString()];

    let newArray = documentDetailData?.filter((el) =>
      docId?.includes(el?.document_id?.toString())
    );

    for (let i = 0; i < newArray?.length; i++) {
      FileSaver.saveAs(newArray?.[i]?.file_url, newArray?.[i]?.title);
    }
  };
  const handleSingleDownloadDocument = (docRowData) => {
    let docId = [docRowData?.document_id?.toString()];

    let newArray = documentDetailData?.filter((el) =>
      docId?.includes(el?.document_id?.toString())
    );

    for (let i = 0; i < newArray?.length; i++) {
      FileSaver.saveAs(newArray?.[i]?.file_url, newArray?.[i]?.title);
    }
  };

  const handleSendDocument = () => {
    let docId = Object.keys(rowSelection);

    let selectedShareDocument = documentDetailData?.filter((el) =>
      docId?.includes(el?.document_id?.toString())
    );
    setShareDocument(selectedShareDocument);

    setCopiedUrl({});
  };

  //---------------handle delete document to archive-------------//
  const handleDeleteDocument = () => {
    // console.log("handleDeleteDocument");
    // console.log("selectedDocumentRowData", selectedDocumentRowData);
    // for (let i = 0; i < Object.keys(rowSelection)?.length; i++) {
    DeleteArchiveDocument(
      unitInformation?.contract_id,
      selectedDocumentRowData?.[0]?.document_id, // document id
      selectedDocumentRowData?.[0]?.actor_id,
      0
    )
      .then((response) => {
        if (response?.data?.bReturn) {
          // if (i == Object.keys(rowSelection)?.length - 1) {
          toast.success(response.data.message);
          setArchiveModal(false);
          getDocumentAllDataIdRefetch();
          getDocumentArchivedDataIdRefetch();

          setRowSelection({});
          // }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    // }
  };

  //---------------handle delete document permanently-------------//
  const handleDeleteDocumentPermanently = () => {
    // console.log("handleDeleteDocumentPermanently");
    // for (let i = 0; i < Object.keys(rowSelection)?.length; i++) {
    DeleteArchiveDocumentPermanent(
      unitInformation?.contract_id,
      selectedDocumentRowData?.[0]?.document_id
    )
      .then((response) => {
        if (response?.data?.bReturn) {
          // if (i == Object.keys(rowSelection)?.length - 1) {
          toast.success(response.data.message);
          setArchiveModal(false);
          getDocumentAllDataIdRefetch();
          getDocumentArchivedDataIdRefetch();
          setRowSelection({});
          // }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    // }
  };

  //---------------handle restore archive document-------------//
  const handleRestoreArchiveDocument = (restoreRowId) => {
    const selectedRows = Object.keys(rowSelection);

    if (restoreRowId && restoreRowId.document_id) {
      // Case 1: If a particular row is clicked
      const restorePayload = {
        document_id: restoreRowId.document_id,
        contract_id: unitInformation?.contract_id,
      };

      PutUpdateRestoreArchiveDocument(restorePayload)
        .then((response) => {
          if (response?.data?.bReturn) {
            toast.success(response.data.message);
            // console.log("response.data.message", response.data.message);
            getDocumentAllDataIdRefetch();
            getDocumentArchivedDataIdRefetch();
            setRowSelection({});
          } else {
            toast.error(response.data.message);
            // console.log("response.data.message", response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else if (selectedRows.length > 0) {
      // Case 2: If one or more rows are selected
      const requests = selectedRows.map((documentId) => {
        const restorePayload = {
          document_id: documentId,
          contract_id: unitInformation?.contract_id,
        };

        return PutUpdateRestoreArchiveDocument(restorePayload);
      });

      Promise.all(requests)
        .then((responses) => {
          const isSuccess = responses.every(
            (response) => response?.data?.bReturn
          );
          if (isSuccess) {
            toast.success("All selected documents restored successfully.");
            getDocumentAllDataIdRefetch();
            getDocumentArchivedDataIdRefetch();
            setRowSelection({});
          } else {
            toast.error(
              "Some documents failed to restore. Please check the error messages."
            );
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleShareTheDocument = async (sharedata) => {
    const selectedDocumentId = Object.keys(rowSelection) || [
      shareDocument?.[0]?.document_id?.toString(),
    ];

    if (Array.isArray(selectedDocumentId)) {
      const selectedDocumentData = shareDocument.filter((el) =>
        selectedDocumentId.includes(el?.document_id?.toString())
      );

      const modifiedDocs = selectedDocumentData.map((item) => ({
        doc_url: item.file_url,
        doc_name: item.title,
      }));

      const combinedDocs = [...modifiedDocs];

      const documentSharePayload = {
        recipient_email: sharedata?.email,
        subject: sharedata?.subject,
        message: sharedata?.message,
        docs: combinedDocs,
      };

      try {
        for (let i = 0; i < combinedDocs.length; i++) {
          let id;
          if (!PostShareDocumentIsLoading) {
            id = toast.loading("Sending document");
          }

          const response = await DocumentShareMutate(documentSharePayload);

          if (response?.status === "success" && response?.data?.bReturn) {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: "success",
              autoClose: 2000,
            });
            reset();
            setShare(false);
            setRowSelection("");
          } else {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: "error",
              autoClose: 2000,
            });
            setShare(false);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("No documents selected");
    }
  };

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopiedUrl((prevCopiedUrls) => ({
          ...prevCopiedUrls,
          [url]: true,
        }));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const resetCopiedUrl = (url) => {
    // Create a new object that copies the current state of copiedUrl
    const newCopiedUrl = { ...copiedUrl };

    // Reset the URL to its initial value (e.g., false)
    newCopiedUrl[url] = false;

    // Update the state with the new object
    setCopiedUrl(newCopiedUrl);
  };

  const handleCancelButtonClick = (url) => {
    resetCopiedUrl(url); // Reset the copiedUrl state for a specific URL
    // Add your cancel button logic here
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Document name",
        size: 240,
        Cell: ({ cell }) => {
          return <div>{cell?.row?.original?.title || "-"}</div>;
        },
        muiTableBodyCellProps: {
          sx: { cursor: "pointer !important" },
        },
      },
      {
        accessorKey: "document_type_name",
        header: "Type",
        size: 160,
        Cell: ({ cell }) => {
          return <div>{cell?.row?.original?.document_type_name || "-"}</div>;
        },
      },
      {
        accessorKey: "created_on",
        header: "Date of upload",
        size: 50,
        Cell: ({ cell }) => {
          return (
            <div>
              {cell?.row?.original?.created_on !== ""
                ? moment(cell?.row?.original?.created_on).format("DD MMM, YYYY")
                : "-"}
            </div>
          );
        },
      },
      {
        accessorKey: "created_by",
        header: "Uploaded by",

        size: 110,
        Cell: ({ cell }) => {
          return <div>{cell?.row?.original?.created_by || "-"}</div>;
        },
      },
      {
        // accessorKey: "city",
        header: "Seen",
        enableSorting: false,
        size: 165,
        Cell: ({ cell }) => {
          return (
            <div>
              {cell?.row.original?.document_view_actor?.length === 0
                ? "-"
                : cell?.row?.original?.document_view_actor.map(
                    (data, index) => (
                      <div key={index}>
                        {data?.seen_on === null
                          ? "-"
                          : moment(data?.seen_on).format("DD MMM, YYYY | H:mm")}
                      </div>
                    )
                  )}
            </div>
          );
        },
      },

      {
        // accessorKey: "state",
        header: "Read",
        enableSorting: false,

        size: 165,
        Cell: ({ cell }) => {
          return (
            <>
              <div>
                {cell?.row.original?.document_view_actor?.length === 0
                  ? "-"
                  : cell?.row?.original?.document_view_actor.map(
                      (data, index) => (
                        <div key={index}>
                          {data?.read_on === null
                            ? "-"
                            : moment(data?.read_on).format(
                                "DD MMM, YYYY | H:mm"
                              )}
                        </div>
                      )
                    )}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "table_menu", //normal accessorKey
        header: "Actions",
        size: 50,
        enableSorting: false,
        Cell: ({ cell }) => {
          return (
            <div
            // className="myDiv"
            // style={{
            //   pointerEvents:
            //     rowSelection === undefined ||
            //     Object.keys(rowSelection).length != 0
            //       ? "none"
            //       : "auto",
            // }}
            >
              <div className="d-flex gap-2">
                {documenType === 0 ? (
                  <>
                    {/* {console.log(
                      "s",
                      rowSelection == undefined || {} ? " undefined" : rowSelection
                    )} */}
                    {/* {rowSelection != undefined ? "defined": "undefined"} */}
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="document-property-table-btn d-flex justify-content-center align-items-center"
                          onClick={() => {
                            // setShareDocument();
                            setShare(true);
                            setShareDocument([cell.row.original]);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-paper-plane"
                            className="fs-6"
                          />
                        </button>
                      }
                      tooltopWidth={80}
                      tooltipContent={<div className="fs-10">Share</div>}
                    />

                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="document-property-table-btn d-flex justify-content-center align-items-center"
                          onClick={() => {
                            setArchiveModal(true);
                            setDocumenType(0);
                            setSelectedDocumentRowData([cell.row.original]);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-trash"
                            className="fs-6"
                          />
                        </button>
                      }
                      tooltopWidth={150}
                      tooltipContent={<div className="fs-10">Delete</div>}
                    />

                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="document-property-table-btn d-flex justify-content-center align-items-center"
                          onClick={() => {
                            handleSingleDownloadDocument(cell.row.original);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-arrow-down-to-line"
                            className="fs-6"
                          />
                        </button>
                      }
                      tooltopWidth={80}
                      tooltipContent={<div className="fs-10">Download</div>}
                    />
                  </>
                ) : (
                  <>
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="document-property-table-btn d-flex justify-content-center align-items-center"
                          onClick={() => {
                            setArchiveModal(true);
                            setSelectedDocumentRowData([cell.row.original]);
                            // console.log("[cell.row.original]", [
                            //   cell.row.original,
                            // ]);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-trash"
                            className="fs-6"
                          />
                        </button>
                      }
                      tooltopWidth={150}
                      tooltipContent={<div className="fs-10">Delete</div>}
                    />
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="document-property-table-btn d-flex justify-content-center align-items-center"
                          onClick={() => {
                            handleRestoreArchiveDocument(cell.row.original);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-rotate-left"
                            className="fs-6"
                          />
                        </button>
                      }
                      tooltopWidth={150}
                      tooltipContent={<div className="fs-10">Restore</div>}
                    />
                  </>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [documenType, documentDetailData, documentArchiveDetailData, rowSelection]
  );

  return (
    <div className="px-2">
      {/* <div className="container-title-18 px-2 color-black">Documents</div> */}
      <H3 color="#111747">Documents</H3>
      <div className="px-2">
        <MaterialReactTable
          columns={columns}
          data={
            (documenType === 0
              ? documentDetailData
              : documentArchiveDetailData ?? []) || []
          }
          showAlertBanner={false}
          // muiSearchTextFieldProps={{
          //   placeholder: `Search`,
          //   variant: "outlined",
          //   size: "small",
          //   style: {
          //     width: "100% !important",
          //     color : "#000 !important"
          //   },

          //   InputProps: {
          //     endAdornment: (
          //       <InputAdornment position="end">
          //         <FontAwesomeIcon
          //           icon="fa-regular fa-magnifying-glass"
          //           size="sm"
          //           style={{ color: "#000000" }}
          //         />
          //       </InputAdornment>
          //     ),
          //   },
          // }}
          muiSelectAllCheckboxProps={{
            sx: {
              marginLeft: "0.3rem",
            },
            icon: (
              <FontAwesomeIcon
                icon="fa-regular fa-square"
                style={{ width: "23px", height: "23px", color: "#D1D1D1" }}
              />
            ),
            checkedIcon: (
              <FontAwesomeIcon
                icon="fa-solid fa-square-check"
                style={{ width: "23px", height: "23px", color: "#2A39AF" }}
              />
            ),
          }}
          muiSelectCheckboxProps={{
            sx: {
              marginLeft: "0.7rem",
            },
            icon: (
              <FontAwesomeIcon
                icon="fa-regular fa-square"
                style={{ width: "23px", height: "23px", color: "#D1D1D1" }}
              />
            ),
            checkedIcon: (
              <FontAwesomeIcon
                icon="fa-solid fa-square-check"
                style={{ width: "23px", height: "23px", color: "#2A39AF" }}
              />
            ),
          }}
          muiTableContainerProps={{
            sx: {
              borderRadius: "12px !important",
              background: "#FFF !important",
              boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06) !important",
              border: "none !important",
            },
          }}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "transparent",
              table: {
                // borderCollapse: "separate",
                // borderSpacing: "0 5px",
                // height: "200px !important",
                boxShadow: "0px 6px 18px 0px #00000012",
                "& td": {
                  padding: "0.6rem",
                },
              },
              "& td:nth-last-of-type": {
                border: "none !important",
              },
              // "& td:nth-last-child(n)": {
              //   border: "none !important",
              // },
              borderRadius: "8",
              ".MuiBox-root.css-di3982": {
                // display: "none",
              },
              ".css-g2tram-MuiToolbar-root": {
                display: "none !important",
              },
              ".css-1kpq11i": {
                display: "none !important",
              },

              ".css-1vlry5a-MuiTableContainer-root": {
                // border: "1px solid #000 !important",
                // borderRadius: "12px !important",
              },

              ".css-1omuo8w-MuiToolbar-root": {
                minHeight: "auto !important",
              },

              // ".css-12lx6p8-MuiTable-root" : {
              //   border : '1px solid red !important',
              //   borderRadius : '12px !important'

              // },
              ".css-i4bv87-MuiSvgIcon-root": {
                height: "0.95em !important",
                width: "0.95em !important",
              },
              // ".css-vnf2p3-MuiTableCell-root":{
              //   padding : '25px 0px 17px 0px  !important'

              // },
              ".css-18w3a48-MuiCollapse-wrapper": {
                marginLeft: "16 rem",
              },
              ".css-v4u5dn-MuiInputBase-root-MuiInput-root ": {
                // search input design
                border: "1px solid rgba(17, 23, 71, 0.1) !important",
                height: "38px !important",
                borderRadius: "6px !important",
                padding: "0 0px 0 7px !important",
                backgroundColor: "#fff !important",
                color: "#000000 !important",
                width: "79%",
                marginLeft: "auto",
              },
              ".css-v4u5dn-MuiInputBase-root-MuiInput-root:before": {
                // search bar

                borderBottom: "none !important",
              },

              "::placeholder": {
                color: "red !important",
              },

              ".MuiInput-underline:after": {
                borderBottom: "none !important",
              },
              ".css-sq9qdz": {
                // header styling

                background: "#f7f8fa",
                padding: "0px  !important",
                paddingBottom: "16px !important",
              },
              ".css-1sszw6i-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
                color: "#A2A2A3",
                background: "white",
                boxShadow: "none !important",
              },
              ".css-1f2qhs8": {
                fontFamily: "Cerebri sans !important",
              },
              ".css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover": {
                background: "white",
              },
              ".css-kjirbw-MuiButtonBase-root-MuiButton-root": {
                background: "#ADBE9E !important",
                color: "#fff !important",
                boxShadow: "none !important",
              },
              ".css-ke5b6m-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
                // Delete and Delete Permentaly Class
                background: "#ADBE9E !important",
                color: "#fff !important",
                boxShadow: "none !important",
                opacity: "50% !important",
              },
              ".css-5zrdtn.Mui-disabled": {
                // Delete and Delete Permentaly Class
                background: "#ADBE9E !important",
                color: "#fff !important",
                boxShadow: "none !important",
                opacity: "50% !important",
              },
              ".css-kjirbw-MuiButtonBase-root-MuiButton-root:hover": {
                background: "#ADBE9E !important",
                color: "#fff !important",

                boxShadow: "none !important",
              },
              ".css-ke5b6m-MuiButtonBase-root-MuiButton-root": {
                // Delete and Delete Permentaly Class
                background: "#ADBE9E !important",
                color: "#fff !important",

                boxShadow: "none !important",
              },
              ".css-ke5b6m-MuiButtonBase-root-MuiButton-root :hover": {
                // Delete and Delete Permentaly Class
                background: "#ADBE9E !important ",
                color: "#fff !important",

                boxShadow: "none !important",
              },
              ".css-i44wyl": {
                border: "1px solid rgba(17, 23, 71, 0.1) !important",
                height: "38px !important",
                borderRadius: "6px !important",
                padding: "0 0px 0 7px !important",
                backgroundColor: "#fff !important",
                color: "#000000 !important",
              },
              ".css-zrqpq1::before": {
                borderBottom: "none !important",
              },
              ".css-zrqpq1": {
                padding: "3px 0",
              },

              ".css-zrqpq1::after": {
                borderBottom: "none !important",
              },
              ".css-1laqsz7-MuiInputAdornment-root": {
                display: "none !important",
              },

              ".css-1j7qk7u.Mui-disabled": {
                display: "none !important",
              },
              ".css-1j7qk7u": {
                display: "none !important",
              },
              ".css-5zrdtn:hover": {
                backgroundColor: "rgba(173, 190, 158, 1) !important",
                color: "#fff !important",
              },
              ".css-5zrdtn": {
                backgroundColor: "rgba(173, 190, 158, 1) !important",
                color: "#fff !important",
                boxShadow: "none !important",
              },
              ".css-hv6h5i-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
                // checkbox
                color: "#2a39af !important",
              },
              ".css-1uidvcu.Mui-checked": {
                // checkbox
                color: "#2a39af !important",
              },
              ".css-1uidvcu.MuiCheckbox-indeterminate": {
                // checkbox
                color: "#2a39af !important",
              },
              ".css-hv6h5i-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                // checkbox
                {
                  color: "#2a39af !important",
                },

              ".css-16fgrtb-MuiTableRow-root.Mui-selected": {
                // backgroundColor: "pink !important",
                backgroundColor: "rgba(42, 57, 175, 0.1) !important",
              },
              ".css-1betbz1.Mui-selected": {
                backgroundColor: "rgba(42, 57, 175, 0.1) !important",
              },
              ".css-16fgrtb-MuiTableRow-root.Mui-selected:hover": {
                backgroundColor: "rgba(42, 57, 175, 0.1) !important",
                // backgroundColor: "rgba(42, 57, 175, 0.1) !important",
              },
              "Mui-selected:hover": {
                backgroundColor: "rgba(42, 57, 175, 0.1) !important",
              },
              ".css-1betbz1.Mui-selected:hover": {
                backgroundColor: "rgba(42, 57, 175, 0.1) !important",
              },

              ".css-c4sutr": {
                display: "none !important",
              },
            },
          }}
          muiTableBodyCellProps={({ cell, row }) => ({
            onClick: (event) => {
              if (cell.id.includes("title") || cell.id.includes("27_title")) {
                handleSeenDocument(row);
                setCurrentRow(row);
                setDocumentDetails({
                  documentUrl: row.original?.file_url,
                  documentName: row.original?.title,
                });
              }
            },
          })}
          muiTableHeadCellProps={{
            sx: {
              padding: "25px 16px 17px 16px  !important",
            },
          }}
          icons={{
            ArrowDownwardIcon: (props) => (
              <FontAwesomeIcon icon="fa-solid fa-sort" />
            ),
          }}
          enableColumnFilters={false}
          enablePagination={false}
          enableHiding={false}
          enableRowSelection
          getRowId={(row) => row.document_id}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          positionToolbarAlertBanner={null} //top alert rowBar disable
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          initialState={{
            showGlobalFilter: true,
            showAlertBanner: false,
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <div className="d-flex align-items-center mt-2">
                <div>
                  <div
                    style={{
                      fontWeight: allArchivedStyle ? "300" : "600",
                      color: allArchivedStyle ? "rgba(17, 23, 71, 1)" : "",
                      opacity: allArchivedStyle ? "0.5" : "initial",
                    }}
                    className="all-archive-text cursor all-archive-graytext cursor"
                    onClick={() => {
                      handleAllData();
                    }}
                  >
                    All ({AllDocument == null ? 0 : AllDocument})
                  </div>
                </div>

                <div className="px-2 all-archive-graytext"> | </div>
                <div>
                  <div
                    style={{
                      fontWeight: allArchivedStyle ? "600" : "300",
                      color: allArchivedStyle ? "rgba(17, 23, 71, 1)" : "",
                      opacity: allArchivedStyle ? "initial" : "0.5",
                    }}
                    className="all-archive-text cursor all-archive-graytext cursor"
                    onClick={() => {
                      handleArchiveData();
                    }}
                  >
                    Archived ({ArchiveDocument == null ? 0 : ArchiveDocument})
                  </div>
                </div>
              </div>
            );
          }}
          renderToolbarInternalActions={({ table }) => (
            <>
              <div className="p-0">
                {allArchivedStyle ? (
                  <div className="d-flex gap-2">
                    <div
                      className="ms-2 contained-btn cursor-not-allowed"
                      style={{ background: "white" }}
                    >
                      <Button
                        onClick={() => handleRestoreArchiveDocument()}
                        className="light"
                        sx={{
                          fontSize: "14px !important",
                          textTransform: "initial !important",
                          border: " 1px solid rgba(17, 23, 71, 0.10)",
                          fontFamily: "Cerebri sans !important",
                          fontWeight: 600,
                        }}
                        color="error"
                        disabled={
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                        }
                        variant="contained-btn"
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-arrow-rotate-left"
                          className="pe-2"
                        />
                        RESTORE
                      </Button>
                    </div>
                    {/* <div className="cursor">
                      <Button
                        onClick={() => setDeleteDocumentPermanentModal(true)}
                        className="medium-text"
                        color="success"
                        disabled={
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                        }
                        variant="contained"
                      >
                        DELETE PERMANENTLY
                      </Button>
                    </div> */}
                  </div>
                ) : (
                  <>
                    <div className="d-flex gap-2 mb-2 mb-lg-0">
                      {!table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                        ? ""
                        : ""}
                      <div
                        className="ms-2 contained-btn cursor-not-allowed"
                        onClick={
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                            ? handleSetShare
                            : setShare
                        }
                      >
                        <Button
                          onClick={handleSendDocument}
                          sx={{
                            fontSize: "14px !important",
                            textTransform: "initial !important",
                            border: " 1px solid rgba(17, 23, 71, 0.10)",
                            fontFamily: "Cerebri sans !important",
                            fontWeight: 600,
                          }}
                          // className="light"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          // onClick={setShare}
                          variant="contained-btn"
                        >
                          {!table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected() ? (
                            <FontAwesomeIcon
                              icon="fa-regular fa-paper-plane"
                              className="pe-2"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="fa-regular fa-paper-plane"
                              className=" pe-2"
                              style={{ color: "#000000" }}
                            />
                          )}
                          SHARE
                        </Button>
                      </div>
                      {/* <div
                        className="contained-btn cursor"
                        onClick={
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                            ? handleSetShare
                            : setArchiveModal
                        }
                      >
                        <Button
                          sx={{
                            fontSize: "14px !important",
                            textTransform: "initial !important",
                            border: " 1px solid rgba(17, 23, 71, 0.10)",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          // onClick={setArchiveModal}
                          variant="contained-btn"
                        >
                          {!table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected() ? (
                            <FontAwesomeIcon
                              icon="fa-regular fa-trash"
                              className="pe-2 cursor"
                              style={{ color: "gray" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="fa-regular fa-trash"
                              className="pe-2 cursor"
                              style={{ color: "#000000" }}
                            />
                          )}
                          Deletee
                        </Button>
                      </div> */}
                      <div>
                        <Button
                          className="medium-text-14"
                          color="success"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          variant="contained"
                          onClick={handleDownloadDocument}
                        >
                          DOWNLOAD
                        </Button>
                      </div>
                      {/* <div>
                        <Button
                          className="medium-text-14"
                          color="primary"
                          // disabled={
                          // 	!table.getIsSomeRowsSelected() &&
                          // 	!table.getIsAllRowsSelected()
                          // }
                          variant="contained"
                          onClick={() => {
                            setUploadDocument(true);
                          }}
                        >
                          UPLOAD DOCUMENT
                        </Button>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        />
      </div>
      <div>
        {/* <form> */}
        <Modal
          open={share}
          topborder={false}
          className="modal-dialog-centered"
          onHide={() => {
            setShare(false);
            clearErrors();
            reset();
            setCopiedUrl({});
          }}
          title={
            <div className="d-flex justify-content-between px-4 pt-4 pb-3 bottom-border-line ">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div>
                  <div>
                    <H3> Share this document</H3>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  setShare(false);
                  clearErrors();
                  reset();
                  setCopiedUrl({});
                }}
              >
                <FontAwesomeIcon
                  className="cursor"
                  icon="fa-regular fa-xmark"
                />
              </div>
            </div>
          }
          title_color="black"
          onCloseAction={() => {
            setShare(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <Form onSubmit={handleSubmit(handleShareTheDocument)}>
            <div>
              <div className="px-4 pt-4 pb-1">
                <H5>Share document by link</H5>
              </div>
              <div
                className="form-input py-2 px-4 d-flex flex-column gap-3 scrollable-container-y me-4"
                style={{ height: shareDocument.length > 1 ? "120px" : "auto" }}
              >
                {shareDocument.map((d, i) => (
                  <div className="form-input">
                    <input
                      // onBlur={onBlur}
                      // onChange={onChange}
                      // value={value}
                      // {...register("subject", { required: true })}
                      // className="input-bg-gray"
                      value={d.title}
                      type="text"
                      placeholder="subject"
                      id="text"
                    />

                    <label htmlFor="Link">Link</label>
                    <Overlay
                      target={target.current[i]}
                      show={tooltip[i]}
                      placement="right"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                          Link Copied
                        </Tooltip>
                      )}
                    </Overlay>
                    <div
                      ref={(el) => (target.current[i] = el)}
                      className="d-flex align-items-center gap-1 copy-link position-absolute douments-share-copy-link cursor me-2"
                    >
                      <FontAwesomeIcon icon="fa-regular fa-link" size="xs" />

                      <div
                        className="caption-regular"
                        onClick={() => {
                          copyToClipboard(d.file_url);
                        }}
                      >
                        <span className="copy-link ">
                          {copiedUrl[d.file_url] ? "Link copied" : "Copy link"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className="py-2 p-4 pb-1"
                style={{ fontSize: "14px", fontWeight: "500" }}
              >
                <div className="mb-3">
                  <div className="mb-3">
                    <H5>Send document by e-mail</H5>
                  </div>
                  <div className="form-input">
                    <input
                      // className="input-bg-gray"
                      type="text"
                      name="email"
                      placeholder="email"
                      id="email"
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          message: "Email is not valid.",
                        },
                      })}
                    />

                    <label htmlFor="Link">E-mail</label>
                  </div>
                  {errors.email && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <div className="form-input">
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <input
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          {...register("subject", { required: true })}
                          // className="input-bg-gray"
                          type="text"
                          placeholder="subject"
                          id="text"
                        />
                      )}
                      name="subject"
                    />

                    <label htmlFor="Link">Subject</label>
                  </div>
                  {errors.subject && (
                    <p className="error-msg" style={{ color: "red" }}>
                      Subject is Required.
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <Controller
                    control={control}
                    {...register("message")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-float openhouse-des">
                        <textarea
                          style={{ resize: "none" }}
                          name="message"
                          className="inputText"
                          cols="20"
                          rows="3"
                          id="message"
                          placeholder=""
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                        <label htmlFor="description" className="floating-label">
                          Message
                        </label>
                      </div>
                    )}
                  />
                  {errors.message && (
                    <p className="error-msg" style={{ color: "red" }}>
                      Message is required.
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div
                  className="d-flex justify-content-between p-4 pb-3 pt-3"
                  style={{
                    borderTop: "1px solid #d1d1d1",
                    background: "#f7f8fa",
                    borderRadius: "0 0 12px 12px",
                  }}
                >
                  <div>
                    <NormalButton
                      type="button"
                      className="fw-900"
                      textColor="black"
                      backgroundColor="white"
                      buttonText="CANCEL"
                      border="1px solid #F0F0F0"
                      padding="10px 24px"
                      borderRadius="4"
                      fontSize="14"
                      fontWeight="500"
                      onClick={() => {
                        setShare(false);
                        reset();
                        clearErrors();
                        setCopiedUrl({});
                        // Assuming shareDocument is an array, you can loop through it to call the function for each item.
                        shareDocument?.forEach((d) => {
                          handleCancelButtonClick(d.file_url);
                        });
                      }}
                    />
                  </div>
                  <div>
                    <NormalButton
                      type="submit"
                      className="fw-900"
                      textColor="white"
                      border="none"
                      backgroundColor="green"
                      buttonText="SEND DOCUMENT"
                      disabled={PostShareDocumentIsLoading}
                      padding="10px 24px"
                      borderRadius="6"
                      fontSize="14"
                      fontWeight="500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
        {/* </form> */}
      </div>

      {/* ------------------------ Document preview Pop Up
			----------------------- */}
      {/* <CustomPopup
        open={addDocumentPackagePopup}
        onHide={() => setAddDocumentPackagePopup(false)}
        modalHeaderText={true}
        title={allDocumentDetails?.allDocumentPackageFileName}
        size="xl"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        subTitle={allDocumentDetails?.documentName}
        titleTooltip={false}
        // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
      >
        <div>
          <Row>
            <Col className="">
              <div style={{ height: "540px" }}>
                <iframe
                  src={allDocumentDetails?.allDocumentPackageNameUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        ></div>
      </CustomPopup> */}

      <Modal
        open={previewModal}
        topborder={false}
        ModalHeader={true}
        size="xl"
        className="modal-dialog-centered"
        onHide={() => {
          setPreviewModal(false);
          // setChecked(true);
        }}
        title={
          <div className="d-flex justify-content-between p-3">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <div className="Cerebri-sans-medium fs-14">
                  {documentDetails?.documentName}
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setPreviewModal(false);
                // setChecked(true);
              }}
            >
              <span
                className="fs-20 d-flex justify-content-center align-items-center cursor"
                aria-hidden="true"
                style={{
                  height: "44px",
                  width: "44px",
                  borderRadius: "8px",
                  position: "relative",
                  backgroundColor: "rgb(244, 245, 245)",
                  border: "none",
                }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-xmark"
                  style={{ color: "#000000" }}
                />
              </span>
            </div>
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setPreviewModal(false);
        }}
      >
        <div>
          <Row>
            <Col className="p-0">
              <div style={{ height: "500px" }}>
                <iframe
                  src={documentDetails?.documentUrl}
                  height="100%"
                  width="100%"
                ></iframe>
                <div></div>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="d-flex align-items-center">
          {currentRow?.original?.read_on == "" ? (
            <>
              <CustomCheckBox
                value={checked}
                id={currentRow?.original?.document_id}
                onChange={() => setChecked(!checked)}
              />
              <div>I have read this document.</div>
            </>
          ) : (
            ""
          )}
        </div> */}
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <NormalButton
            className="fw-900"
            textColor=" black"
            border="none"
            backgroundColor="white"
            padding="6px 16px"
            borderRadius="6"
            fontSize="14"
            fontWeight="500"
            buttonText="CANCEL"
            onClick={() => setPreviewModal(false)}
          />
          <NormalButton
            className="fw-900"
            textColor="white"
            border="none"
            cursor={currentRow?.original?.read_on !== "" ? "pointer" : "auto"}
            backgroundColor="green"
            disabled={
              currentRow?.original?.document_view_actor?.[0]?.read_on !== null
                ? true
                : false
            }
            padding="6px 16px"
            borderRadius="6"
            fontSize="14"
            fontWeight="500"
            buttonText={
              currentRow?.original?.document_view_actor?.[0]?.read_on !== null
                ? "YOU HAVE READ THIS DOCUMENT"
                : " YOU HAVEN'T READ THIS DOCUMENT"
            }
            onClick={handleReadDocument}
          />
        </div>
      </Modal>
      {/* ------------------delete document to archive modal---------------- */}
      <Modal
        topborder={false}
        open={archiveModal}
        size={"md"}
        ModalHeader={false}
        headerBottomBorder={true}
        className="modal-dialog-centered"
        onHide={() => {
          setArchiveModal(false);
          // setChecked(!checked);
        }}
        title={<div className="mt-4"></div>}
        title_color="black"
        onCloseAction={() => {
          setArchiveModal(false);
          // setChecked(!checked);
        }}
      >
        <div className="px-4 pt-4 pb-3 border-bottom">
          <H3>
            {documenType == 0
              ? "Are you sure you want to archive"
              : "Are you sure you want to permanently delete"}
          </H3>
          <H3>this file?</H3>
          {/* <div className="del-btn-text">to archive this file?</div> */}
        </div>

        <div className="paragraph1-regular px-4 pt-4">
          <span className="del-text ">
            {documenType == 0
              ? "The file will permanently be deleted after 6 months"
              : "This file will permanently deleted"}
          </span>
        </div>
        <div className="p-4">
          <div className="delete-document-box fs-14 pt-3 pb-5 px-2 ">
            {
              // documentDetailData
              //   ?.filter((el) =>
              //     Object?.keys(rowSelection)?.includes(
              //       el?.document_id?.toString()
              //     )
              //   )
              selectedDocumentRowData?.map((item, i) => (
                <div
                  className="py-1 px-3"
                  key={i}
                  // style={{ border: "1px solid rgba(17, 23, 71, 0.10)" }}
                >
                  {item?.title}
                </div>
              ))
            }
          </div>
        </div>
        <div
          className="d-flex justify-content-between py-3 px-4"
          style={{
            borderTop: "1px solid #d1d1d1 ",
            background: "#f7f8fa",
            borderRadius: "0 0 12px 12px",
          }}
        >
          <div>
            <NormalButton
              type="submit"
              className="fw-900"
              textColor=" black"
              backgroundColor="white"
              buttonText="CANCEL "
              border="1px solid #F0F0F0"
              padding="10px 16px"
              borderRadius="6px"
              fontSize="14"
              fontWeight="500"
              onClick={() => setArchiveModal(false)}
            />
          </div>
          <div>
            <NormalButton
              onClick={
                documenType == 0
                  ? handleDeleteDocument
                  : handleDeleteDocumentPermanently
              }
              className="fw-900"
              textColor="white"
              border="1px solid #F1664B"
              backgroundColor="estateOrange"
              buttonText="DELETE"
              padding="10px 16px"
              borderRadius="6px"
              fontSize="14"
              fontWeight="500"
            />
          </div>
        </div>
      </Modal>
      {/* ------------------delete document permanently---------------- */}
      {/* --------------------- Upload document ----------------------  */}
      <UploadDocument
        uploadDocument={uploadDocument}
        setUploadDocument={setUploadDocument}
        getActorProfileData={getActorProfileData}
        selectedUnitData={unitInformation}
        getDocumentAllDataIdRefetch={getDocumentAllDataIdRefetch}
        // handleActorUploadDocument={handleActorUploadDocument}
      />
    </div>
  );
};

export default Documents;
