import * as React from "react";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import styled from "styled-components/macro";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import {
  useFAQChatbox,
  useFAQChatbox2,
  useMobileFaqPopup,
} from "../../../hooks/stores/useScreeningFormStore";

export default function ScreeningNeedHelp() {
  // console.log("kp")
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:13 ~ ScreeningNeedHelpChatbox ~ height:", height)
  // const [show, setShow] = useState(true)
  // const [show2, setShow2] = useState(false)
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:12 ~ ScreeningNeedHelpChatbox ~ show2:", show2)
  const FAQChatbox = useFAQChatbox((state) => state.FAQChatbox);
  const setFAQChatbox = useFAQChatbox((state) => state.setFAQChatbox);
  const FAQChatbox2 = useFAQChatbox2((state) => state.FAQChatbox2);
  // console.log("🚀 ~ file: ScreeningNeedHelp.js:25 ~ ScreeningNeedHelp ~ FAQChatbox2:", FAQChatbox2)
  const setFAQChatbox2 = useFAQChatbox2((state) => state.setFAQChatbox2);
  const setMobileFaqPopup = useMobileFaqPopup(
    (state) => state.setMobileFaqPopup
  );
  const {
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Headline: "",
      Message: "",
    },
  });
  const [formValue, setFormValue] = useState({
    Headline: getValues("Headline"),
    Message: getValues("Message"),
  });

  const handleFileChange = (event) => {};
  const onSubmit = (data) => {
    setFAQChatbox(false);
    setFAQChatbox2(true);
    reset();
  }; // your form subm

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {FAQChatbox && (
        <Layout>
          <Notifydiv>
            <Topdiv>
              <IconContainer>
                <FontAwesomeIcon
                  icon="fa-regular fa-angle-left"
                  size="sm"
                  color="white"
                  onClick={() => {
                    setFAQChatbox(false);
                    setMobileFaqPopup(true);
                  }}
                />
              </IconContainer>
              <h5>Need help?</h5>
              <IconContainer>
                <CloseIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  onClick={() => {
                    setFAQChatbox(false);
                  }}
                />
              </IconContainer>
            </Topdiv>
            <div>
              <Flexdiv>
                <Heading>We will answer within 12 hours</Heading>
              </Flexdiv>
              <div>
                <Para>
                  We come back to you as soon as possible! Please note that we
                  do not offer live support, but we promise that you would not
                  wait too long 😊
                </Para>
              </div>
            </div>
          </Notifydiv>
          <Chatdiv>
            <Msgdiv>
              <Row>
                <Col lg={11}>
                  <div>
                    <div className="form-input input-bg-gray mt-3 ms-2">
                      <input
                        type="text"
                        placeholder="e.g. Problem with upload a document"
                        id="Headline"
                        className="input-bg-gray"
                        {...register("Headline", {
                          required: true,
                          onChange: (e) => {
                            setFormValue({
                              ...formValue,
                              Headline: e.target.value,
                            });
                          },
                        })}
                      />
                      <label htmlFor="Headline">Headline</label>
                    </div>
                    {errors.Headline && (
                      <p className="error-msg" style={{ color: "red" }}>
                        Headline is Required.
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={11}>
                  <div>
                    <div className="form-message-input mt-3 ms-2">
                      <textarea
                        type="text"
                        rows="10"
                        cols="50"
                        style={{ resize: "none" }}
                        placeholder="Start Writing..."
                        id="Message"
                        className="Message input-bg-gray"
                        {...register("Message", {
                          required: true,
                          onChange: (e) => {
                            setFormValue({
                              ...formValue,
                              Message: e.target.value,
                            });
                          },
                        })}
                      />
                      <label htmlFor="Lastname">Message</label>
                      {errors.Message && (
                        <p className="error-msg" style={{ color: "red" }}>
                          Message is Required.
                        </p>
                      )}
                      <Flexdiv
                        style={{
                          justifyContent: "end",
                          position: "relative",
                          bottom: "45px",
                          right: "6px",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "#ADBE9E",
                            borderRadius: "6px",
                          }}
                          onClick={() => {}}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-paper-plane-top"
                            style={{ color: "white" }}
                          />
                        </button>
                      </Flexdiv>
                    </div>
                  </div>
                </Col>
              </Row>
              <FAQdiv>
                <NormalButton
                  type="submit"
                  textColor=" white"
                  backgroundColor="green"
                  buttonText="Find help in our FAQ"
                  border="none"
                  padding="6px 24px"
                  borderRadius="6"
                  fontSize="12px"
                />
              </FAQdiv>
            </Msgdiv>
          </Chatdiv>
        </Layout>
      )}
      {FAQChatbox2 && (
        <ScreeningNeedHelpChatbox2
          setFAQChatbox={setFAQChatbox}
          setFAQChatbox2={setFAQChatbox2}
          data={formValue}
        />
      )}
    </form>
  );
}

export function ScreeningNeedHelpChatbox2({
  setFAQChatbox,
  setFAQChatbox2,
  data,
}) {
  const [inputValue, setInputValue] = useState("");
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:142 ~ ScreeningNeedHelpChatbox2 ~ inputValue:", inputValue)
  const [dataArray, setDataArray] = useState([
    { text: data.Message, user: "You" },
  ]);
  console.log("dataArray", dataArray);
  const chatContainerRef = useRef(null);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const formattedDate = currentDateTime.format("D MMM, YYYY | HH:mm");

  // const BOT_MSGS = [
  //   "Hi, how are you?",
  //   "Ohh... I can't understand what you trying to say. Sorry!",
  //   "I like to play games... But I don't know how to play!",
  //   "Sorry if my answers are not relevant. :))",
  //   "I feel sleepy! :("
  // ];

  // function botResponse() {
  //   const r = Math.floor(Math.random() * BOT_MSGS.length); // Generate a random index
  //   const msgText = BOT_MSGS[r];
  //   const delay = msgText.split(" ").length * 100;

  //   setTimeout(() => {
  //     // Handle the bot's response here, you might want to add it to the state.
  //     // For example, you can use the setDataArray function to add the bot's message.
  //     const botMessage = { text: msgText, user: 'Bot' };
  //     setDataArray([...dataArray, botMessage]);
  //   }, delay);
  // }
  const simulateBotResponse = (userMessage) => {
    // You can implement your bot logic here
    // For example, you can use a chatbot library or API to generate bot responses.
    // For this example, let's just echo the user's message.
    return `You said "${userMessage}"`;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEnterPress = () => {
    if (inputValue.trim() !== "") {
      const userMessage = inputValue;
      const newMessages = [...dataArray, { text: userMessage, user: "You" }];

      // Add the user's message to the state
      setDataArray(newMessages);

      // Simulate a bot response
      // botResponse();
      const botResponse = simulateBotResponse(userMessage);
      const updatedMessages = [
        ...newMessages,
        { text: botResponse, user: "Bot" },
      ];

      // Add the bot's response to the state
      setDataArray(updatedMessages);

      setInputValue("");
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [dataArray]);

  return (
    <Layout>
      <NotifyMobdiv>
        <Topdiv2
          style={{ alignItems: "baseline", justifyContent: "space-between" }}
        >
          <IconContainer>
            <FontAwesomeIcon
              icon="fa-regular fa-angle-left"
              size="sm"
              color="white"
              onClick={() => {
                setFAQChatbox(true);
                setFAQChatbox2(false);
              }}
            />
          </IconContainer>
          <h5>Need help?</h5>
          <IconContainer>
            <CloseIcon
              fontSize="small"
              sx={{ color: "white" }}
              onClick={() => {
                setFAQChatbox2(false);
              }}
            />
          </IconContainer>
        </Topdiv2>
        <div>
          <Flexdiv>
            <Heading>We will answer within 12 hours</Heading>
          </Flexdiv>
        </div>
      </NotifyMobdiv>
      <Notifydiv2>
        <Flexdiv
          style={{ alignItems: "baseline", justifyContent: "space-between" }}
        >
          <IconContainer>
            <FontAwesomeIcon
              icon="fa-regular fa-angle-left"
              size="sm"
              color="white"
              onClick={() => {
                setFAQChatbox(true);
                setFAQChatbox2(false);
              }}
            />
          </IconContainer>
          <h5>{data.Headline}</h5>
          <IconContainer>
            <CloseIcon
              fontSize="small"
              sx={{ color: "white" }}
              onClick={() => {
                setFAQChatbox2(false);
                setFAQChatbox(false);
              }}
            />
          </IconContainer>
        </Flexdiv>
      </Notifydiv2>
      <Chatdiv2>
        <Somediv>
          <h4 style={{ marginBottom: "0px", color: "#ADBE9E" }}>
            Go to all messages
          </h4>
        </Somediv>
        <Headlinediv>
          <h4 style={{ marginBottom: "0px" }}>Headline : {data.Headline}</h4>
        </Headlinediv>
        <ChatMsgDisplay ref={chatContainerRef}>
          <div style={{ width: "400px" }}>
            <Flexdiv style={{ color: "#00000080" }}>{formattedDate}</Flexdiv>
            {dataArray.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.user === "You" ? "sent" : "received"
                }`}
              >
                {/* <Usernamediv>{message.user}</Usernamediv>
                      <Msgcontainer>
                        <Msgtext>{message.text}</Msgtext>
                      </Msgcontainer> */}
                {message.user === "You" ? (
                  <>
                    <Usernamediv>{message.user}</Usernamediv>
                    <Msgcontainer>
                      <Msgtext>{message.text}</Msgtext>
                    </Msgcontainer>
                  </>
                ) : (
                  <>
                    <Botdiv>{message.user}</Botdiv>
                    <BotMsgcontainer>
                      <BotMsgtext>{message.text}</BotMsgtext>
                    </BotMsgcontainer>
                  </>
                )}
              </div>
            ))}
          </div>
        </ChatMsgDisplay>
        <Msgdiv2>
          <hr style={{ margin: "0px" }} />
          <Row>
            <Col lg={11}>
              <div>
                <div className="form-message-input mt-3 ms-2">
                  <textarea
                    type="text"
                    rows="10"
                    cols="50"
                    style={{ resize: "none" }}
                    placeholder="Start Writing..."
                    id="Message"
                    className="Message input-bg-gray"
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={handleEnterPress}
                  />
                  <label htmlFor="Lastname">Message</label>
                </div>
                <Flexdiv
                  style={{
                    justifyContent: "space-between",
                    position: "relative",
                    bottom: "45px",
                    right: "6px",
                  }}
                >
                  <input
                    className="d-none"
                    type="file"
                    name="needdHelpUploadfile"
                    id="needdHelpUploadfile"
                  ></input>
                  <label htmlFor="needdHelpUploadfile">
                    <div style={{ paddingLeft: "25px" }}>
                      <FontAwesomeIcon
                        icon="fa-regular fa-paperclip"
                        style={{ fontSize: "1.3rem", color: "black" }}
                      />
                    </div>
                  </label>
                  <button
                    type="file"
                    style={{
                      border: "none",
                      backgroundColor: "#ADBE9E",
                      borderRadius: "6px",
                    }}
                    onClick={() => handleEnterPress()}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-paper-plane-top"
                      style={{ color: "white" }}
                    />
                  </button>
                </Flexdiv>
              </div>
            </Col>
          </Row>
        </Msgdiv2>
      </Chatdiv2>
    </Layout>
  );
}

const Layout = styled.div`
  background-color: white;
  height : 626px;
  width: 401px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  border-radius : 12px 12px 0 0;
  @media (max-width: 1400px) {
    height: 428px; 
    width : 300px;
  }
  @media (max-width: 576px) {
    z-index: 1000;
    height: 100%; 
    width : 100%;
    bottom: 0px;
    right: 0px;
  }
`;
const Notifydiv = styled.div`
  background-color: #111747;
  color: white;
  height: auto; 
  border-radius: 12px 12px 0px 0px;
  @media (max-width: 576px) {
    height:30%
  }
`;
const Topdiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 576px) {
    height:50%;
    position:relative;
    top:20%
  }
`;
const Topdiv2 = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 576px) {
    height:70%;
    position:relative;
    top:20%
  }
`;
const Flexdiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
`;
const IconContainer = styled.div`
  padding:18px;
  @media (max-width: 1400px) {
    padding:9px
  }
`;
const Heading = styled.p`
  margin:0px;
  font-size:22px;
  @media (max-width: 1400px) {
    font-size:16px
  }
`;
const Para = styled.p`
  color:#ffffff80;
  font-size:16px;
  text-align:center;
  margin:0px;
  @media (max-width: 1400px) {
    font-size:12px
  }
  @media (max-width: 576px) {
    padding:0 40px;
    color:#ffffff;
    margin-top: 10px;
  }
`;
const Chatdiv = styled.div`
  background-color:white;
  height:470px;
  border-radius:0 0 12px 12px;
  position:relative;
  @media (max-width: 1400px) {
    height:321px
  }
  @media (max-width: 576px) {
    height:70%
  }
`;
const FAQdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  bottom: 20px;
  @media (max-width: 576px) {
    display:none;
  }
`;
const Msgdiv = styled.div`
  background-color:#ffffff80;
  height:270px;
  border-radius:0 0 12px 12px;
  position:absolute;
  bottom:0;
`;
const Notifydiv2 = styled.div`
  background-color: #111747;
  color: white;
  height: auto; 
  border-radius: 12px 12px 0px 0px;
  @media (max-width: 576px) {
    display:none;
  }
`;
const NotifyMobdiv = styled.div`
  background-color: #111747;
  color: white;
  height: 15%; 
  border-radius: 12px 12px 0px 0px;
  @media (min-width: 576px) {
    display:none;
  }
`;
const Chatdiv2 = styled.div`
  background-color:white;
  height:566px;
  border-radius:0 0 12px 12px;
  position:relative;
  @media (max-width: 1400px) {
    height:386px
  }
  @media (max-width: 576px) {
    height:85%
  }
`;
const Somediv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;
  @media (max-width: 576px) {
    display:none;
  }
`;
const Headlinediv = styled.div`
  background-color: #9FA2B5;
  color:#ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 10px;
  @media (min-width: 576px) {
    display:none;
  }
`;
const Msgdiv2 = styled.div`
  background-color:#ffffff80;
  height:167px;
  border-radius:0 0 12px 12px;
  position:absolute;
  bottom:0;
  @media (max-width: 576px) {
    bottom:50px;
  }
`;
const ChatMsgDisplay = styled.div`
  height:350px;
  padding:0 10px 0 10px;
  overflow-y:auto;
  display:flex;
  flex-direction:row;
  justify-content: end;
  @media (max-width: 1400px) {
    height:170px;
  }
  @media (max-width: 576px) {
    height:272px;
  }
`;
const Msgtext = styled.div`
  background-color: #ADBE9E;
  color:#ffffff;
  border-radius: 6px;
  padding: 10px;
  max-width:70%;
`;
const Usernamediv = styled.div`
  display: flex;
  justify-content: end;
  font-weight: 600;
`;
const Msgcontainer = styled.div`
  display: flex;
  justify-content: end;
  overflow-wrap: anywhere;
}
`;
const Botdiv = styled.div`
  display: flex;
  justify-content: left;
  font-weight: 600;
`;
const BotMsgcontainer = styled.div`
  display: flex;
  justify-content: left;
  overflow-wrap: anywhere;
}
`;
const BotMsgtext = styled.div`
  background-color: #E9EBF1;
  color:#000000;
  border-radius: 6px;
  padding: 10px;
  max-width:70%;
`;
