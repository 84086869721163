import { Col, Container, Row, Image } from "react-bootstrap";
import {
  useGetCountries,
  useGetZipcodeByCountryId,
} from "../../../hooks/queries/useLocationQueries";
import {
  useUpdateActorProfile,
  useUpdateActorProfilePicture,
} from "../../../hooks/queries/useActorQueries";
import { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notifications from "./Notifications";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import Modal from "../../../Components/widgets/modal/Modal";
import ReactSelect from "../../../Components/input/ReactSelect";
import PasswordField from "../../../Components/input/searchField/textfiled/PasswordField";
import Changeprofilepicture from "./Changeprofilepicture";
import profile from "../../../assets/images/Profile Pic.png";
import avatarPerson from "../../../assets/icons/avatar-person.svg";
import { ProfileDesign } from "../../../assets/icons";
import "../../../styles/Profile.css";
import Pictureremove from "./Pictureremove";
import { useActorProfileStore } from "../../../hooks/stores/useActorDetails";
import { PostSendOtp, PostValidateOtp } from "../../../services/SMS";
import { H1, H2, H3, H4 } from "../../../styles/Common.style";
import CustomReactSelect from "../../../Components/Dropdown/CustomReactSelect";
import EditEmailModal from "./profileModals/profile.emailModal";
import EditPasswordlModal from "./profileModals/profile.passwordlModal";
import InputField from "../../../Components/TextField/InputField.jsx";

const Profile = ({ getActorProfileData, getActorProfileDataRefetch }) => {
  //------------------Validation-----------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const actorProfile = useActorProfileStore((state) => state.actorDetail);
  const {
    mutateAsync: PostUpdateActorProfilePicture,
    isLoading: PostUpdateActorProfilePictureIsLoading,
  } = useUpdateActorProfilePicture();

  //--------------------states------------------------//
  const [selectedPage, setSelectedPage] = useState("Profile");
  const [editProfilePictureModal, setEditProfilePictureModal] = useState(null);
  const [openChangePictureModal, setOpenChangePictureModal] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [verifed, setVerified] = useState(true);
  const [otpModal, setOtpModal] = useState(false);
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [editPasswordlModal, setEditPasswordModal] = useState(false);
  const [openProfileModalName, setProfileModalName] = useState("SelectionPage");

  const otpFieldsRef = useRef([]);

  //-------------------React query---------------//
  const { data: getCountriesData } = useGetCountries(
    getActorProfileData?.data?.[0]?.country_id
  );

  const displayItem = (selected) => {
    return {
      value: selected?.value,
      label: (
        <div>
          <div className="fs-14 fw-300">
            {
              getCountriesData?.data?.filter(
                (item, i) => item.Id === selected?.value
              )?.[0]?.Text
            }
          </div>
        </div>
      ),
    };
  };

  const {
    data: getZipcodeByCountryIdData,
    refetch: getZipcodeByCountryIdDataRefetch,
  } = useGetZipcodeByCountryId(getValues("country")?.value);
  const {
    mutateAsync: PutUpdateActorProfile,
    isLoading: PutUpdateActorProfileIsLoading,
  } = useUpdateActorProfile();

  useEffect(() => {
    setValue("first_name", getActorProfileData?.data?.[0]?.firstname);
    setValue("last_name", getActorProfileData?.data?.[0]?.lastname);
    setValue("street", getActorProfileData?.data?.[0]?.street_name);
    setValue("house_number", getActorProfileData?.data?.[0]?.house_number);
    setValue("floor", getActorProfileData?.data?.[0]?.floor_no);
    setValue("sideDoor", getActorProfileData?.data?.[0]?.side_door);
    setValue("city", getActorProfileData?.data?.[0]?.city);
    setValue("ssn", getActorProfileData?.data?.[0]?.social_security_number);
    setValue("email", getActorProfileData?.data?.[0]?.email);
    setValue("phone", getActorProfileData?.data?.[0]?.mobilenumber);
    setValue(
      "dateOfBirth",
      moment(getActorProfileData?.data?.[0]?.birthdate).format("YYYY-MM-DD")
    );
    setValue("countryCode", {
      value: getCountriesData?.data?.filter(
        (el) =>
          el.DataValue2 == getActorProfileData?.data?.[0]?.phone_calling_code_id
      )?.[0]?.Id,
      label: getActorProfileData?.data?.[0]?.phone_calling_code_id,
    });

    setValue("country", {
      value: getActorProfileData?.data?.[0]?.country_id,
      label: getActorProfileData?.data?.[0]?.country,
    });
    setValue("zipCode", {
      value: getActorProfileData?.data?.[0]?.zipcode_id,
      label: getActorProfileData?.data?.[0]?.zipcode_id,
    });
    return () => {};
  }, [getActorProfileData, getCountriesData]);

  useEffect(() => {
    if (Object.keys(actorProfile ?? {}).length) {
      isMobileVerified();
      isEmailVerified();
    }
  }, [actorProfile, getCountriesData]);

  // check mobile verified or not
  const isMobileVerified = () => {
    let clientMobileVerification =
      getValues("countryCode")?.label + getValues("phone") ===
      actorProfile?.phone_calling_code_id + "" + actorProfile?.mobilenumber;
    let ApiMobileVerification = actorProfile?.is_mobilenumber_validated;
    setMobileVerified(clientMobileVerification && ApiMobileVerification);
  };

  // check email verified or not
  const isEmailVerified = () => {
    let clientEmailVerification = getValues("email") === actorProfile?.email;
    let ApiEmailVerification = actorProfile?.is_email_validated;
    setEmailVerified(clientEmailVerification && ApiEmailVerification);
  };

  //-------------handle update actor details---------------//
  const handleSaveChanges = (data) => {
    // console.log(data, "data");
    const updateActorPayload = {
      actor_id: String(getActorProfileData?.data?.[0]?.actor_id),
      email: data?.email,
      firstname: data?.first_name,
      mobilenumber: data?.phone,
      lastname: data?.last_name,
      country_id_nationality: data?.countryCode?.value,
      phone_calling_code_id: data?.countryCode?.label,
      street_name: data?.street,
      house_number: data?.house_number,
      floor_no: data?.floor,
      side_door: data?.sideDoor,
      country_id: data?.country?.value,
      zipcode_id: data?.zipCode?.value,
      social_security_number: data?.ssn,
      send_reservation: "0",
      screening_invitation: "0",
      birthdate: data?.dateOfBirth,
    };
    PutUpdateActorProfile(updateActorPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
          getActorProfileDataRefetch();
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((errors) => {
        toast.error(errors.message);
      });
  };

  const HandleMobileVerify = () => {
    setOtpModal(true);
    sendOTP();
    // setVerified(false);
  };

  const HandleEmailVerify = () => {
    toast.error("email verification is not ready yet");
    // setEmailModal(true);
    // sendOTP();
    // setVerified(false);
  };

  const handleInput = (index, value) => {
    if (value.length > 1) {
      return;
    }
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
  };

  const handleBackspace = (index) => {
    if (otpValues[index] !== "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
  };

  // Send OTP
  const sendOTP = () => {
    const sendOtpPayload = {
      actor_id: String(actorProfile?.actor_id),
    };
    PostSendOtp(sendOtpPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  //-----------handle mobile otp verify / validate otp-----------//
  const handleMobileVerification = () => {
    const validateOtpPayload = {
      actor_id: actorProfile?.actor_id,
      mobile_no: getValues("countryCode")?.label + getValues("phone"),
      otp_code: otpValues?.join(""),
    };
    PostValidateOtp(validateOtpPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
          resetOTPmodal();
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went error");
      });
  };

  const resetOTPmodal = () => {
    setOtpModal(false);
    setOtpValues(["", "", "", "", "", ""]);
  };

  // remove profile
  const handleSaveProfilePicture = () => {
    let id;
    if (!PostUpdateActorProfilePictureIsLoading) {
      id = toast.loading("Updating profile");
    }
    let formData = new FormData();
    formData.append("files[]", "");
    formData.append(
      "actor_id",
      getActorProfileData?.data?.[0]?.actor_id?.toString()
    );
    PostUpdateActorProfilePicture(formData)
      .then((response) => {
        if (response.data.bReturn) {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "success",
            autoClose: 2000,
          });
          setOpenChangePictureModal(false);
          getActorProfileDataRefetch();
          setProfileModalName("SelectionPage");
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(id, {
          render: error.message,
          isLoading: false,
          type: "error",
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      <Container fluid>
        <div className="Main-div">
          <H3 className="pb-2">Profile</H3>

          <div className="form-div pt-4 px-4">
            <div className="d-flex profile-header ps-sm-4  d-flex flex-wrap flex-sm-nowrap ps-md-3 py-xl-0 py-2">
              <div
                className="d-flex flex-wrap flex-lg-nowrap justify-content-center justify-content-lg-start align-items-center gap-3"
                style={{
                  margin_top: "10px",
                }}
              >
                <div className="profile-div image d-flex justify-content-between">
                  <div className="position-relative">
                    <Image
                      src={
                        getActorProfileData?.data?.[0]?.profile_photo !== ""
                          ? getActorProfileData?.data?.[0]?.profile_photo
                          : avatarPerson
                      }
                      roundedCircle
                      height={"153px"}
                      width={"153px"}
                      alt="user_img"
                    />

                    <div className="ms-auto mt-auto position-absolute profile-edit">
                      <FontAwesomeIcon
                        onClick={() => setEditProfilePictureModal(true)}
                        icon="fa-regular fa-pen"
                        style={{
                          color: "#202020",
                          rotate: "4deg",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className=" ps-md-4 d-flex"
                  style={{ flexDirection: "column", gap: "5px" }}
                >
                  <H1>
                    {getActorProfileData?.data?.[0]?.firstname}&nbsp;
                    {getActorProfileData?.data?.[0]?.lastname}
                  </H1>
                  <div>
                    <span className="fs-16 fw-500">E-mail:&nbsp;</span>
                    <span className="fs-16 font-light ms-2">
                      {getActorProfileData?.data?.[0]?.email}{" "}
                    </span>
                  </div>
                  <div>
                    <span className="fs-16 fw-500">Phone:&nbsp;</span>
                    <span className="fs-16 font-light ms-2">
                      {getActorProfileData?.data?.[0]?.phone_calling_code_id}
                    </span>
                    &nbsp;
                    <span className="fs-16 font-light">
                      {getActorProfileData?.data?.[0]?.mobilenumber}{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="profile-image">
                <ProfileDesign />
              </div>
            </div>
            {/* form body  */}

            <form onSubmit={handleSubmit(handleSaveChanges)}>
              <div className="form-body">
                <Row className="mt-4">
                  <Col xl={3} className="ps-0 pb-3">
                    <div
                      onClick={() => setSelectedPage("Profile")}
                      className={`d-flex p-2 rounded cursor align-items-center mb-2 ${
                        selectedPage === "Profile"
                          ? "background-primary profile-text"
                          : "disable-profile-text"
                      }`}
                    >
                      <div
                        className={`mb-2 mt-2 px-2 p-1 ${
                          selectedPage === "Profile"
                            ? "bg-light-blue"
                            : "bg-light-gray"
                        }`}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-user"
                          style={{
                            color:
                              selectedPage === "Profile"
                                ? "#2a39af"
                                : "#999999",
                          }}
                          className="mt-1"
                        />
                      </div>
                      <div className="px-2 Cerebri-sans-bold">Profile</div>
                    </div>
                    <div
                      onClick={() => setSelectedPage("Notifications")}
                      className={`d-flex p-2 rounded cursor align-items-center ${
                        selectedPage === "Notifications"
                          ? "background-primary profile-text"
                          : "disable-profile-text"
                      }`}
                    >
                      <div
                        className={`mb-2 mt-2 px-2 p-1 ${
                          selectedPage === "Notifications"
                            ? "bg-light-blue"
                            : "bg-light-gray"
                        }`}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-bell"
                          style={{
                            color:
                              selectedPage === "Notifications"
                                ? "#2a39af"
                                : "#999999",
                          }}
                          className="mt-1"
                        />
                      </div>
                      <div className="px-2 Cerebri-sans-bold">
                        Notifications
                      </div>
                    </div>
                  </Col>

                  {selectedPage === "Profile" ? (
                    <Col className="ps-0 pe-0" xl={9}>
                      <Row>
                        <Col xxl={10} className="px-0">
                          <div className="pe-lg-5 mb-1 gray-bg pb-4">
                            <div className="profile-info px-4 pt-5 medium-text-18">
                              Profile information
                            </div>

                            <div className="profile-title px-4 pt-1">
                              Here you can edit public information about
                              yourself.
                            </div>
                          </div>
                          <div className="p-3 gray-bg pe-lg-5">
                            <Row>
                              <Col lg={12}>
                                <div className=" pt-3 medium-text-14">
                                  Full name
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={6} className="mt-3 pe-lg-0">
                                <div className="form-input ">
                                  <input
                                    type="text"
                                    placeholder="First name"
                                    id="Firstname"
                                    {...register("first_name", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("first_name");
                                        // setFormValue({
                                        //   ...formValue,
                                        //   firstname: e.target.value,
                                        // });
                                      },
                                    })}
                                  />

                                  <label htmlFor="Firstname">First name</label>
                                </div>
                                {errors.first_name && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>

                              <Col lg={6} className="mt-3 pe-lg-0 ">
                                <div>
                                  <div className="form-input ">
                                    <input
                                      type="text"
                                      placeholder="Last name"
                                      id="Lastname"
                                      {...register("last_name", {
                                        required: true,
                                        onChange: (e) => {
                                          clearErrors("last_name");
                                          // setFormValue({
                                          //   ...formValue,
                                          //   lastname: e.target.value,
                                          // });
                                        },
                                      })}
                                    />
                                    <label htmlFor="Lastname">Last name</label>
                                  </div>
                                </div>
                                {errors.last_name && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="medium-text-14 pt-4">
                                  Address
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={6} className="mt-3 pe-lg-0">
                                <div className="form-input ">
                                  <input
                                    type="text"
                                    placeholder="Street"
                                    id="Street"
                                    {...register("street", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("street");
                                        // setFormValue({
                                        //   ...formValue,
                                        //   lastname: e.target.value,
                                        // });
                                      },
                                    })}
                                    // className="ms-0"
                                  />
                                  <label htmlFor="Street">Street</label>
                                </div>
                                {errors.street && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={2} className="pe-lg-0 mt-3 ">
                                <div className="form-input ">
                                  <input
                                    type="text"
                                    placeholder="Number"
                                    id="number"
                                    {...register("house_number", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("house_number");
                                      },
                                    })}
                                    // className="ms-0"
                                  />
                                  <label htmlFor="number">Number</label>
                                </div>
                                {errors.house_number && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={2} className="mt-3 pe-lg-0">
                                <div className="form-input  ">
                                  <input
                                    type="text"
                                    placeholder="Floor"
                                    id="Floor"
                                    className="px-2"
                                    {...register("floor", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("floor");
                                        // setFormValue({
                                        //   ...formValue,
                                        //   lastname: e.target.value,
                                        // });
                                      },
                                    })}
                                  />
                                  <label htmlFor="floor">Floor</label>
                                </div>
                                {errors.floor && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={2} className=" mt-3 pe-lg-0">
                                <div className="form-input">
                                  <input
                                    type="text"
                                    placeholder="Side/Door"
                                    id="Sidedoor"
                                    // className="px-5"
                                    {...register("sideDoor", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("sideDoor");
                                        // setFormValue({
                                        //   ...formValue,
                                        //   lastname: e.target.value,
                                        // });
                                      },
                                    })}
                                  />
                                  <label htmlFor="Sidedoor">Side/Door</label>
                                </div>
                                {errors.sideDoor && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>

                            <Row className=" pt-4">
                              <Col lg={4} className="pb-3 pe-lg-0">
                                <Controller
                                  control={control}
                                  {...register("country")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      clearable={false}
                                      placeholder="Country"
                                      // value={value}
                                      value={displayItem(value)}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("country");
                                        setValue("zipCode", "");
                                        setValue("city", "");
                                      }}
                                      options={getCountriesData?.data?.map(
                                        (item, i) => ({
                                          value: item?.Id,
                                          label: (
                                            <div
                                              // key={i}
                                              className="d-flex align-items-center"
                                              style={{
                                                fontFamily: "Cerebri sans",
                                              }}
                                            >
                                              <div className="radius-4 me-2">
                                                <img
                                                  src={item.DataValue1}
                                                  style={{
                                                    width: "20px",
                                                    marginRight: "10px",
                                                  }}
                                                  alt="country flag"
                                                />
                                              </div>
                                              <div>
                                                <div className="fs-14 light-font-text ">
                                                  {" "}
                                                  {item?.Text}
                                                </div>
                                              </div>
                                            </div>
                                          ),
                                        })
                                      )}
                                    />
                                  )}
                                />
                                {errors.country && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={4} className=" pb-3 pe-lg-0">
                                <Controller
                                  control={control}
                                  {...register("zipCode")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      placeholder="Zip Code"
                                      value={
                                        value?.value
                                          ? {
                                              value: value?.value,
                                              label: value?.value,
                                            }
                                          : ""
                                      }
                                      onChange={(e) => {
                                        onChange(e);
                                        setValue(
                                          "city",
                                          getZipcodeByCountryIdData?.data?.filter(
                                            (el) => el.Id == e.value
                                          )?.[0]?.Text
                                        );
                                        clearErrors("zipCode");
                                      }}
                                      options={getZipcodeByCountryIdData?.data?.map(
                                        (item, index) => ({
                                          value: item?.Id,
                                          label: `${item?.Id} ${item?.Text}`,
                                        })
                                      )}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.zipCode && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                                {/* </div> */}
                              </Col>
                              <Col lg={4} className="pb-3 pe-lg-0">
                                <div className="form-input">
                                  <input
                                    type="text"
                                    placeholder="City"
                                    id="City"
                                    disabled
                                    {...register("city", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("city");
                                      },
                                    })}
                                  />
                                  <label htmlFor="City">City</label>
                                </div>
                                {errors.city && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <div className="medium-text-14  pt-4">
                                  Birthday
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={6} className="pt-3 pe-lg-0">
                                <Controller
                                  control={control}
                                  {...register("dateOfBirth")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="form-input ">
                                      <input
                                        type="date"
                                        min="1901-01-01"
                                        max="2005-12-31"
                                        placeholder="Date of birth"
                                        id="birthday"
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          clearErrors("dateOfBirth");
                                        }}
                                      />
                                      <label htmlFor="birthday">
                                        Date of birth
                                      </label>
                                    </div>
                                  )}
                                />
                                {errors.dateOfBirth && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={6} className="pe-lg-0 mt-3">
                                <Controller
                                  control={control}
                                  {...register("ssn")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      <PasswordField
                                        id={"ssn"}
                                        placeholder={"Social Security Number"}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          clearErrors("ssn");
                                        }}
                                        maxlength={15}
                                      />
                                    </>
                                  )}
                                />
                                {errors.ssn && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="medium-text-14  pt-4">
                                  Phone number
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={3} className="pt-3 pe-lg-0">
                                <Controller
                                  control={control}
                                  {...register("countryCode")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      placeholder={"Country code"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        isMobileVerified();
                                        clearErrors("countryCode");
                                      }}
                                      options={getCountriesData?.data?.map(
                                        (item, index) => ({
                                          value: item?.Id,
                                          label: item?.DataValue2,
                                        })
                                      )}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.countryCode && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={9} className="pt-3 pe-lg-0">
                                <div style={{ position: "relative" }}>
                                  <Controller
                                    control={control}
                                    {...register("phone")}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <div className="form-input ">
                                        <input
                                          type="number"
                                          placeholder="Phone"
                                          id="phone"
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e);
                                            isMobileVerified();
                                            clearErrors("phone");
                                          }}
                                        />

                                        <label htmlFor="phone">Phone</label>
                                        {!mobileVerified ? (
                                          <div
                                            onClick={HandleMobileVerify}
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "20px",
                                            }}
                                            className="ms-3 medium-text fs-8 cursor-pointer"
                                          >
                                            Verify
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "9px",
                                              right: "20px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon="fa-solid fa-badge-check"
                                              style={{ color: "#619130" }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>

                                {errors.phone && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="medium-text-14 pt-4">
                                  E-mail address
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={7} lg={5} className="pt-3 pb-4 pe-lg-0">
                                <div className="form-input">
                                  <input
                                    type="email"
                                    placeholder="E-mail"
                                    id="email"
                                    className="caret-color-transparent"
                                    value={
                                      getActorProfileData?.data?.[0]?.email
                                    }
                                    {...register("email", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("email");
                                        isEmailVerified();
                                        // setFormValue({
                                        //   ...formValue,
                                        //   birthday: e.target.value,
                                        // });
                                      },
                                    })}
                                  />
                                  <label htmlFor="email">E-mail</label>
                                  {!emailVerified ? (
                                    <div
                                      onClick={HandleEmailVerify}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                      }}
                                      className="ms-3 medium-text fs-8 cursor-pointer"
                                    >
                                      {/* Verify */}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "9px",
                                        right: "20px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-badge-check"
                                        style={{ color: "#619130" }}
                                      />
                                    </div>
                                  )}
                                </div>
                                {errors.email && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col
                                xs={2}
                                className="pt-3 pb-4 pe-lg-0 d-flex align-items-center"
                              >
                                <div>
                                  <NormalButton
                                    type="button"
                                    className="fw-900"
                                    textColor=" black"
                                    backgroundColor="white"
                                    buttonText="CHANGE EMAIL"
                                    border="1px solid #D1D1D1"
                                    padding="4px 16px"
                                    borderRadius="6"
                                    fontSize="12"
                                    fontWeight="500"
                                    onClick={() => setEditEmailModal(true)}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <div className="medium-text-14">Password</div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={7} lg={5} className="pt-3 pb-4 pe-lg-0">
                                <div className="form-input">
                                  <input
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    value={"password"}
                                    className="caret-color-transparent"
                                    {...register("Password", {
                                      required: true,
                                      onChange: (e) => {
                                        clearErrors("Password");
                                        isEmailVerified();
                                      },
                                    })}
                                  />
                                  <label htmlFor="Password">Password</label>
                                </div>
                                {errors.email && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col
                                xs={2}
                                className="pt-3 pb-4 pe-lg-0 d-flex align-items-center"
                              >
                                <div>
                                  <NormalButton
                                    type="button"
                                    className="fw-900"
                                    textColor=" black"
                                    backgroundColor="white"
                                    buttonText="CHANGE PASSWORD"
                                    border="1px solid #D1D1D1"
                                    padding="4px 16px"
                                    borderRadius="6"
                                    fontSize="12"
                                    fontWeight="500"
                                    onClick={() => setEditPasswordModal(true)}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <div className="medium-text-14">Language</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={5} className="pt-3 pb-4 pe-lg-0">
                                <Controller
                                  control={control}
                                  {...register("language")}
                                  // rules={{ required: true }}
                                  render={({ field: { onChange } }) => (
                                    <CustomReactSelect
                                      placeholder={"language"}
                                      description={"Search language"}
                                      // options={colourOptions}

                                      // iserror={errors?.screeninginvitation}
                                      // inputValue={inputValue}
                                      isSearchable={false}
                                      // onInputChange={(val) => setInputValue(val)}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  )}
                                />
                                {errors.countryCode && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                              <Col lg={5} className="pt-3 pb-4 pe-lg-0">
                                <Controller
                                  control={control}
                                  {...register("currency")}
                                  // rules={{ required: true }}
                                  render={({ field: { onChange } }) => (
                                    <CustomReactSelect
                                      placeholder={"Choose currency"}
                                      description={"Search currency"}
                                      // options={colourOptions}

                                      // iserror={errors?.screeninginvitation}
                                      // inputValue={inputValue}
                                      isSearchable={false}
                                      // onInputChange={(val) => setInputValue(val)}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  )}
                                />
                                {errors.currency && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Notifications />
                  )}
                  <div
                    className="mb-5 mt-3"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <NormalButton
                      type="submit"
                      textColor="white"
                      border="none"
                      backgroundColor="green"
                      buttonText="SAVE CHANGES"
                      padding="6px 16px"
                      borderRadius="6"
                      fontSize="14"
                      fontWeight="500"
                      // onClick={() => { }}
                    />
                  </div>
                </Row>
              </div>
            </form>
          </div>
        </div>
      </Container>

      {/* Change Email Modal */}
      <EditEmailModal modal={editEmailModal} setModal={setEditEmailModal} />

      {/* edit profile picture modal */}
      <Modal
        size="md"
        open={editProfilePictureModal}
        className="modal-dialog-centered"
        onHide={() => setEditProfilePictureModal(false)}
        title={
          <div className="d-flex justify-content-between p-3">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <div className="Cerebri-sans-medium fs-18">Profile picture</div>
              </div>
            </div>
            <div onClick={() => setEditProfilePictureModal(false)}>
              <FontAwesomeIcon className="cursor" icon="fa-regular fa-xmark" />
            </div>
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setEditProfilePictureModal(false);
        }}
      >
        <div
          className="pt-3 pb-2 d-flex justify-content-center"
          style={{ fontSize: "16px" }}
        >
          <div style={{ width: "180px", height: "180px" }}>
            <Image
              src={
                getActorProfileData?.data?.[0]?.profile_photo !== ""
                  ? getActorProfileData?.data?.[0]?.profile_photo
                  : avatarPerson
              }
              roundedCircle
              height={"100%"}
              width={"100%"}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2 p-4">
          <NormalButton
            type="submit"
            textColor="black"
            backgroundColor="white"
            buttonText="CHANGE"
            iconbutton={true}
            icon={<FontAwesomeIcon icon="fa-regular fa-pen" />}
            border="1px solid #D1D1D1"
            padding="2px 16px"
            borderRadius="6px"
            fontSize="14"
            onClick={() => {
              setSelectedPage("SelectionPage");
              setOpenChangePictureModal(true);
              setEditProfilePictureModal(false);
            }}
          />

          <NormalButton
            type="submit"
            textColor="black"
            backgroundColor="white"
            buttonText="REMOVE"
            border="1px solid #D1D1D1"
            padding="6px 16px"
            borderRadius="6px"
            fontSize="14"
            iconbutton={true}
            icon={
              <FontAwesomeIcon icon="fa-regular fa-trash" className=" cursor" />
            }
            onClick={handleSaveProfilePicture}
          />

          {/* <Pictureremove /> */}
        </div>
      </Modal>
      {/* edit modal */}

      <Modal
        // size="sm"
        open={openChangePictureModal}
        className="modal-dialog-centered"
        onHide={() => {
          setProfileModalName("SelectionPage");
          setOpenChangePictureModal(false);
        }}
        title={
          <div className="d-flex justify-content-between p-3">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div className="Cerebri-sans-medium fs-18">
                {openProfileModalName === "SelectionPage"
                  ? "Change profile picture"
                  : "Profile picture"}
              </div>
            </div>
            <div
              onClick={() => {
                setProfileModalName("SelectionPage");
                setOpenChangePictureModal(false);
              }}
            >
              <FontAwesomeIcon className="cursor" icon="fa-regular fa-xmark" />
            </div>
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setProfileModalName("SelectionPage");
          setOpenChangePictureModal(false);
        }}
      >
        <>
          <Changeprofilepicture
            // picture={picture}
            // setPicture={setPicture}
            // cropData={cropData}
            // setCropData={setCropData}
            // cropImage={cropImage}
            // setCropImage={setCropImage}
            openProfileModalName={openProfileModalName}
            setProfileModalName={setProfileModalName}
            setOpenChangePictureModal={setOpenChangePictureModal}
            setEditProfilePictureModal={setEditProfilePictureModal}
            getActorProfileData={getActorProfileData}
            getActorProfileDataRefetch={getActorProfileDataRefetch}
          />
        </>
      </Modal>

      {/* OTP modal */}
      <Modal
        topborder={false}
        open={otpModal}
        headerBottomBorder={true}
        className="modal-dialog-centered "
        onHide={() => resetOTPmodal()}
        title={
          <div className="mt-4">
            <FontAwesomeIcon
              className="cursor radius-6"
              icon="fa-regular fa-xmark"
              onClick={() => {
                resetOTPmodal();
              }}
              style={{
                position: "absolute",
                backgroundColor: "#fff",
                height: "30px",
                width: "30px",
                right: "10px",
                top: "10px",
                zIndex: "5",
              }}
            />
          </div>
        }
        center={true}
        title_color="black"
        onCloseAction={() => resetOTPmodal()}
      >
        <div className=" py-2 p-4 fw-500" style={{ fontWeight: "300" }}>
          <div className="fs-4 text-center">Enter OTP Code</div>
        </div>
        <div className="p-4">
          <div className="profile-otp-container">
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="number"
                maxLength="1"
                className="profile-otp-input"
                value={value}
                onChange={(e) => handleInput(index, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    handleBackspace(index);
                  }
                }}
                ref={(ref) => {
                  otpFieldsRef.current[index] = ref;
                }}
              />
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2 p-4 pb-3 pt-4">
          <div>
            <NormalButton
              type="submit"
              className="fw-900"
              textColor="white"
              // bgColor="#0c1356"
              backgroundColor="green"
              buttonText="Resend otp "
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="4"
              fontSize="14"
              fontWeight="500"
              onClick={() => sendOTP()}
            />
          </div>
          <div>
            <NormalButton
              onClick={() => handleMobileVerification()}
              className="fw-900"
              textColor="white"
              // backgroundColor="green"
              bgColor="#0c1356"
              buttonText="Verify otp"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="6"
              fontSize="14"
              fontWeight="500"
              disabled={!(otpValues?.join("")?.length === 6)}
              opacity={!(otpValues?.join("")?.length === 6) ? 0.3 : 1}
            />
          </div>
        </div>
      </Modal>
      <EditPasswordlModal
        modal={editPasswordlModal}
        setModal={setEditPasswordModal}
      />
    </>
  );
};
export default Profile;
