import guideImg from "../../../assets/images/guidesImg.jpg";

// user home page recent activity data
export const RecentActivityData = [
  // {
  //   dataTimeInfo: "16 Jan, 2023 | 10:23",
  //   info: ` Sed ut perspiciatism Document.pdf omnis iste natus error
  //     sit voluptatem`,
  //   document: "pdf",
  //   imgLink: "fa-solid fa-pencil",
  // },
  // {
  //   dataTimeInfo: "14 Jan, 2023 | 12:19",
  //   info: `Iste natus error sit voluptatem sed ut perspiciatis`,
  //   document: "",
  //   imgLink: "fa-solid fa-comment-lines",
  // },
  // {
  //   dataTimeInfo: "14 Jan, 2023 | 10:02",
  //   info: `Iste natus error sit voluptatem sed ut perspiciatis`,
  //   document: "",
  //   imgLink: "fa-solid fa-comment-lines",
  // },
  // {
  //   dataTimeInfo: "12 Jan, 2023 | 08:13",
  //   info: `Sed ut perspiciatism Document.pdf omnis iste natus error
  //     sit voluptatem`,
  //   document: "pdf",
  //   imgLink: "fa-solid fa-pencil",
  // },
];

// user home page recent activity data

// user home page notice board data
// export const NoticeBoardData = [
//     {
//       icon: "fa-regular fa-screwdriver-wrench",
//       bgColor: "#FFEA9F",
//       heading: "Close of water",
//       headingInfo: "We turn off the hot water from 8:00 - 10:00",
//       timeInfo: "Max Wildernesss @Jan 24, 2023 | 16:45",
//     },
//     {
//       icon: "fa-regular fa-bell",
//       bgColor: "#DDEBF9",
//       heading: "Parking permit",
//       headingInfo: "Remember to validate and renew your parkin...",
//       timeInfo: "Max Wildernesss @Jan 22, 2023 | 10:22",
//     },
//     {
//       icon: "fa-regular fa-newspaper",
//       bgColor: "#CACFD5",
//       heading: "Newsletter January",
//       headingInfo: "Update on the renovation of the facade and b...",
//       timeInfo: "Max Wildernesss @Jan 1, 2023 | 08:17",
//     },
// ];
// user home page notice board data

// user home page documents data

// user home page documents data

// user home page messages data
export const MessageComponentData = [
  // {
  //   name: "Landlord Lisa",
  //   incomingMsg: true,
  //   heading: "Approval of keeping dog",
  //   headingInfo:
  //     "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...",
  //   timeInfo: "16 Jan, 2023 | 11:02",
  // },
  // {
  //   name: "Landlord Lisa",
  //   incomingMsg: false,
  //   heading: "Follow up on missing payment",
  //   headingInfo:
  //     "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...",
  //   timeInfo: "2 Jan, 2023 | 08:28",
  // },
  // {
  //   name: "Landlord Lisa",
  //   incomingMsg: false,
  //   heading: "Welcome to Blomsterbjerget!",
  //   headingInfo:
  //     "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...",
  //   timeInfo: "1 Nov, 2022 | 09:12",
  // },
];
// user home page messages data

// user home page guides data
export const guideData = [
  // {
  //   heading: "Guide to XXXX",
  //   info: "Lorem ipsum dolor sit",
  //   pdflink: "",
  //   imgLink: guideImg,
  // },
  // {
  //   heading: "Guide to XXXX",
  //   info: "Lorem ipsum dolor sit",
  //   pdflink: "",
  //   imgLink: guideImg,
  // },
  // {
  //   heading: "Guide to XXXX",
  //   info: "Lorem ipsum dolor sit",
  //   pdflink: "",
  //   imgLink: guideImg,
  // },
  // {
  //   heading: "Guide to XXXX",
  //   info: "Lorem ipsum dolor sit",
  //   pdflink: "",
  //   imgLink: guideImg,
  // },
];
// user home page guides data
