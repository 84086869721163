import { Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// Actor - Get actor basic detail by Invite Id
export const GetActorBasicDetails = (invite_Id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_Actor_BasicDetails,
      null,
      `basicinfo?invite_id=${invite_Id}`
    )
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        reject({ error: true, message: error.response.data.error });
      });
  });
};
// Actor - Post Add actor login
export const PostAddActorLogin = (addActorPayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Actor_Post_Add_Actor_Login, null, addActorPayload)
      .then((response) => {
        resolve(response.data);
      })

      .catch((error) => {
        reject({ error: true, message: error.response.data.error });
      });
  });
};

// Actor - Get actor profile
export const GetActorProfile = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Actor_Get_Actor_Profile, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response?.data.message,
          code: error.response?.data.code,
        });
      });
  });
};

// Actor - update actor profile
export const PutUpdateActorProfile = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Actor_UpdateActorProfile, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};

// Actor - Get Unit list by actor Id
export const GetUnitListByActorId = (actor_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_GetUnitList_By_ActorId,
      null,
      actor_id + "/units"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};

// Actor - reset actor password
export const PutResetActorPassword = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Actor_PutResetActorPassword, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};
