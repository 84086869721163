import React from "react";
import { H2 } from "../../../styles/Common.style";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { REQUIRED_ERR } from "../../../utils/validationConstants";

const CancelScreeningModal = ({ setOpenCancelScreening }) => {
  // Cancel screening Modal validation
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      headline: "",
      message: "",
    },
  });

  console.log("errors",errors)

  const onFormSubmit = (data) => {
    console.log("onFormSubmit data", data);
  };
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div
        className=" py-2 p-4 mb-4 mt-2"
        style={{ fontSize: "14px", fontWeight: "500" }}
      >
        <H2>Do you want to cancel your screening?</H2>
        <div className="mb-4 paragraph1-regular  grey-text mt-3 ">
          Please tell us why below
        </div>
        <Row>
          {" "}
          <Col className="px-0 mb-2">
            <Controller
              {...register(`headline`)}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div className="form-input mb-1">
                  <input
                    name="headline"
                    placeholder="headline"
                    value={value}
                    id="headline"
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  <label htmlFor="headline">Headline</label>
                </div>
              )}
            />
            {errors?.headline && <p className="error-msg">{REQUIRED_ERR}</p>}
          </Col>{" "}
        </Row>
        <Row>
          {" "}
          <Col className="p-0">
            <Controller
              control={control}
              {...register("message")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div className="form-float openhouse-des">
                  <textarea
                    style={{
                      resize: "none",
                      backgroundColor: "#fff !important",
                    }}
                    name="message"
                    className="inputText"
                    cols="20"
                    rows="3"
                    id="message"
                    placeholder=""
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  <label htmlFor="message" className="floating-label">
                    Message
                  </label>
                </div>
              )}
            />
            {errors?.message && <p className="error-msg">{REQUIRED_ERR}</p>}
          </Col>
        </Row>
      </div>
      <div
        className="d-flex justify-content-between px-4 py-2"
        style={{
          borderTop: "1px solid #d1d1d1 ",
          background: "#f7f8fa",
          borderRadius: "0 0 12px 12px",
        }}
      >
        <div>
          <NormalButton
            className="fw-900"
            // borderColor="#F0F0F0"
            textColor=" black"
            backgroundColor="white"
            buttonText="CANCEL"
            border="1px solid #F0F0F0"
            padding="6px 16px"
            borderRadius="4"
            fontSize="14"
            fontWeight="500"
            onClick={(e) => {
              e.preventDefault();
              //   setOpenSaveFinishLater(false);
            }}
          />
        </div>
        <div>
          <NormalButton
            type="submit"
            className="fw-900"
            textColor="white"
            border="none"
            backgroundColor="red"
            buttonText="CANCEL SCREENING"
            padding="7px 16px"
            borderRadius="6"
            fontSize="14"
            fontWeight="500"
            // onClick={(e) => {
            //   //   setOpenCancelScreening(false);
            // }}
          />
        </div>
      </div>
    </form>
  );
};

export default CancelScreeningModal;
