export const NoticeBoardTableData = [
  {
    id: "1",
    headline: "Close of water",
    icon: "fa-solid fa-screwdriver-wrench",
    author: "Max Wildernesss",
    likes: "4",
    seen: "8",
    iconColor: "#E5BF5D",
    uploaded: "Jan 24, 2023 | 16:45",
    file: "",
    bgColor: "#FFEA9F",
    islike: false,

    // home data e5bf5d

    heading: "Close of water",
    headingInfo:
      "Update on the renovation of the facade and Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
    timeInfo: "Max Wildernesss @Jan 24, 2023 | 16:45",
  },
  {
    id: "2",
    headline: "Parking permit",
    icon: "fa-solid fa-bell",
    author: "Claus",
    likes: "5",
    iconColor: "#77AEE1",
    seen: "9",
    uploaded: "Jan 21, 2023 | 16:45",
    file: "",
    bgColor: "#DDEBF9",
    // home data

    heading: "Parking permit",
    headingInfo: "Remember to validate and renew your parkin...",
    timeInfo: "Max Wildernesss @Jan 22, 2023 | 10:22",
  },
  {
    id: "3",
    headline: "Newsletter January",
    icon: "fa-solid fa-newspaper",
    author: "Allan",
    likes: "6",
    seen: "10",
    iconColor: "#697580",
    uploaded: "Jan 20, 2023 | 16:45",
    file: "",
    bgColor: "#CACFD5",
    // home data

    heading: "Newsletter January",
    headingInfo: "Update on the renovation of the facade and b...",
    timeInfo: "Max Wildernesss @Jan 1, 2023 | 08:17",
  },
];
