import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const PasswordField = ({ placeholder, id, value, onChange, maxlength }) => {
  const [show, setShow] = useState(true);
  return (
    <div className="d-flex w-100">
      <div style={{ position: "relative", width: "100%" }}>
        <div className="form-input ">
          <input
            type={show == true ? "password" : "text"}
            placeholder={placeholder}
            id={id}
            value={value}
            onChange={onChange}
            maxLength={maxlength}
          />
          <label htmlFor={id}>{placeholder}</label>
        </div>
      </div>
      <div className="cursor-pointer" style={{ position: "relative" }}>
        <FontAwesomeIcon
          onClick={() => setShow(!show)}
          icon={show == true ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"}
          style={{
            color: "#000000",
            position: "absolute",
            top: "12px",
            right: "15px",
          }}
        />
      </div>
    </div>
  );
};
export default PasswordField;
