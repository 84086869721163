const unitInfo = [
    {
        'name': 'Size',
        'data': '-',
        'postfix_data': '',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Rooms',
        'data': '4 rooms',
        'postfix_data': '',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Monthly rent',
        'data': '12.500 DKK',
        'postfix_data': '',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Deposit',
        'data': '37.500',
        'postfix_data': '(3 months)',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Prepaid rent',
        'data': '12.500 DKK',
        'postfix_data': '(1 month)',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Rent regulation',
        'data': 'Netto prize index',
        'postfix_data': '',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Move-in',
        'data': '1 April, 2023',
        'postfix_data': '',
        'icon': 'fa-regular fa-calendar'
    },
    {
        'name': 'Pets allowed',
        'data': 'Yes',
        'postfix_data': '(application required)',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Parking',
        'data': 'Yes',
        'postfix_data': '(Possible to rent)',
        'icon': 'fa-regular fa-square-parking'
    },
    {
        'name': 'Orientation',
        'data': 'South',
        'postfix_data': '',
        'icon': 'fa-regular fa-compass'
    },
    {
        'name': 'Outdoor space',
        'data': 'Balcony',
        'postfix_data': '',
        'icon': 'fa-regular fa-compass'
    },
    
  ]

export default unitInfo;