import React from "react";
import Modal from "../../Components/widgets/modal/Modal";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Controller, set, useForm, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REQUIRED_ERR } from "../../utils/validationConstants";

import {
  DropZoneBorder,
  GreenSpan,
  PrimarySelectedTag,
} from "../../styles/Common.style";
import ReactSelect from "../../Components/input/ReactSelect";
import {
  useGetDocumentType,
  usePostActorDocumentShare,
  usePostActorDocumentUpload,
} from "../../hooks/queries/useActorDocumentsQueries";
import NormalButton from "../../Components/input/normalButton/NormalButton";

const UploadDocument = ({
  uploadDocument,
  setUploadDocument,
  getActorProfileData,
  selectedUnitData,
  getDocumentAllDataIdRefetch,
  // handleActorUploadDocument,
}) => {
  // Share Document validation
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  // dropzone
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        // console.log("file",file)
        append({ name: file.name, document_type: "", file: file });
      });
      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    },
  });

  const {
    mutateAsync: DocumentUploadMutate,
    isLoading: PostUploadDocumentIsLoading,
  } = usePostActorDocumentUpload();

  const {
    mutateAsync: DocumentShareMutate,
    isLoading: PostShareDocumentIsLoading,
  } = usePostActorDocumentShare();

  // usefields  array for uploading documents

  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });
  const handleActorUploadDocument = async (data) => {
    let files = [];
    for (let i = 0; i < data?.droppedPdf?.length; i++) {
      let id = null; // Initialize id with a default value
      try {
        if (!PostUploadDocumentIsLoading) {
          id = toast.loading("Uploading file");
        }
        let formData = new FormData();
        formData.append("files[]", data?.droppedPdf?.[i]?.file);
        formData.append("actor_id", getActorProfileData?.data?.[0]?.actor_id);
        formData.append("contract_id", selectedUnitData?.contract_id);
        formData.append(
          "document_type_id",
          data?.droppedPdf?.[i]?.document_type?.value
        );
        formData.append("is_identification_document", "0");

        const response = await DocumentUploadMutate(formData);

        // Some task on success
        files[i] = response?.data?.data?.[0];
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: "success",
          autoClose: 2000,
        });
        getDocumentAllDataIdRefetch();
        setUploadDocument(false);
        remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
      } catch (error) {
        if (id !== null) {
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
        // Handle the error here
      }
    }
  };

  // const handleShareTheDocument = async () => {
  //   const readPayload = {
  //     // actor_id: getActorProfileData?.data[0]?.actor_id,
  //     // document_id: currentRow?.original?.document_id,
  //     // view_type: "R",
  //   };
  //   const response = await DocumentShareMutate(readPayload);
  //   try {
  //     if (response?.status === "success" && response?.data?.bReturn) {
  //       toast.success(response?.data?.message);
  //       getDocumentAllDataIdRefetch();
  //       // setPreviewModal(false);
  //     } else {
  //       toast.error(response?.data?.message);
  //       // setPreviewModal(false);
  //     }
  //   } catch (error) {}
  // };

  // const handleActorUploadDocument = async (data) => {
  //   let files = [];
  //   for (let i = 0; i < data?.droppedPdf?.length; i++) {
  //     let id;
  //     if (!PostUploadDocumentIsLoading) {
  //       id = toast.loading("Uploading file");
  //     }
  //     let formData = new FormData();
  //     formData.append("files[]", data?.droppedPdf?.[i]?.file);
  //     formData.append("actor_id", getActorProfileData?.data?.[0]?.actor_id);
  //     formData.append("contract_id", selectedUnitData?.contract_id);
  //     formData.append(
  //       "document_type_id",
  //       data?.droppedPdf?.[i]?.document_type?.value
  //     );
  //     formData.append("is_identification_document", "0");

  //     DocumentUploadMutate(formData).then(
  //       (response) => {
  //         // Some task on success
  //         files[i] = response?.data?.data?.[0];
  //         toast.update(id, {
  //           render: response?.data?.message,
  //           isLoading: false,
  //           type: "success",
  //           autoClose: 2000,
  //         });
  //         getDocumentAllDataIdRefetch();
  //         setUploadDocument(false);
  //         remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
  //       },
  //       (error) => {
  //         // setError(true);
  //         // Some task on failure
  //         toast.update(id, {
  //           render: "Failed to upload",
  //           isLoading: false,
  //           type: "error",
  //           autoClose: 2000,
  //         });
  //       }
  //     );
  //   }
  // };

  const { data: documentTypeData } = useGetDocumentType("DOCAC");

  const handleRemoveDocument = (index) => {
    remove(index);
  };

  return (
    <Modal
      open={uploadDocument}
      topborder={true}
      size="lg"
      className="modal-dialog-centered"
      onHide={() => {
        setUploadDocument(false);
        remove();
      }}
      title={
        <div className="d-flex justify-content-between p-3">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <div>
              <div className="Cerebri-sans-medium fs-14">
                Upload document manually
              </div>
            </div>
          </div>
        </div>
      }
      title_color="black"
    >
      <form onSubmit={handleSubmit(handleActorUploadDocument)}>
        <div className="p-3">
          <div className="px-2">
            <div className="light-font-text my-1">
              Drag and drop documents directly from your computer. It is
              possible to change the name of the document and select a document
              type. Once you have selected and customized the documents, click
              add and they will be added to the package.
            </div>
          </div>
          <Row className="mt-3 px-2 upload-manually-document-table">
            <Col md={{ span: 12 }}>
              <div className="pt-2 pb-4">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                    <div>
                      {/* <FontAwesomeIcon icon="fa-solid fa-file" /> */}
                      <FontAwesomeIcon
                        icon="fa-solid fa-file-pdf"
                        style={{ color: "#000000" }}
                      />
                    </div>
                    <div className="mt-2 mb-1 form-label-title">
                      Drop your file here, or&nbsp;
                      <GreenSpan
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={open}
                      >
                        browse your computer
                      </GreenSpan>
                    </div>
                    <div className="created-by">Supports only PDF</div>
                  </DropZoneBorder>
                </div>
              </div>
              <div>
                {/* {droppedPdf?.length !== 0 && ( */}
                <div className="mb-4">
                  <Row className="m-0 p-0">
                    {fields.length !== 0 && (
                      <Row className="m-0 p-0 mb-2">
                        <Col
                          className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                          xs={7}
                        >
                          Name
                          {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                        </Col>
                        <Col
                          className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                          xs={4}
                        >
                          Document type
                          {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                        </Col>
                        <Col xs={2}></Col>
                      </Row>
                    )}

                    <div
                      className="p-0"
                      style={{
                        maxHeight: "160px",
                        overflow: "auto",
                      }}
                    >
                      {fields.map((data, i) => (
                        <div key={data.id}>
                          <Row
                            className="m-0 p-0 mb-2"
                            style={{
                              borderRadius: "8px",
                              backgroundColor: "#F9FAFA",
                            }}
                          >
                            <Col
                              className="d-flex align-items-start py-2 pe-4"
                              xs={7}
                            >
                              <div
                                className="bg-white radius-6 ms-md-1 w-100 ellipsis "
                                style={{
                                  padding: "0.3rem 0.5rem",
                                  border: "1px solid #D1D1D1",
                                  marginTop: "2px",
                                  height: "38px",
                                }}
                              >
                                <p className="mb-0 fs-300 fs-14">
                                  <Controller
                                    name={`droppedPdf[${i}].name`}
                                    control={control}
                                    defaultValue={data.name}
                                    render={({ field }) => (
                                      <input
                                        disabled
                                        className="standard-input"
                                        {...field}
                                      />
                                    )}
                                  />
                                </p>
                              </div>
                            </Col>
                            <Col
                              className="d-flex align-items-start py-2 pe-4 ps-0"
                              xs={4}
                            >
                              <div className="w-100 fs-300 fs-14">
                                <Controller
                                  {...register(
                                    `droppedPdf[${i}].document_type`
                                  )}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      placeholder=""
                                      value={value}
                                      clearable={false}
                                      isSearchable
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors(
                                          `droppedPdf[${i}].document_type`
                                        );
                                      }}
                                      error={errors.document_type}
                                      options={documentTypeData?.data?.map(
                                        (item) => ({
                                          value: item?.Id,
                                          label: item?.Text,
                                        })
                                      )}
                                    />
                                  )}
                                  name={`droppedPdf[${i}].document_type`}
                                  control={control}
                                />

                                {errors?.droppedPdf?.[i]?.document_type && (
                                  <p className="error-msg">{REQUIRED_ERR}</p>
                                )}
                              </div>
                            </Col>
                            <Col
                              className="d-flex align-items-start py-2 justify-content-end"
                              xs={1}
                            >
                              <div>
                                <button
                                  className="added-document-delete-btn"
                                  onClick={() => handleRemoveDocument(i)}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-regular fa-trash"
                                    className="fs-6"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <div
            className="d-flex justify-content-between py-2 px-3"
            style={{
              borderTop: "1px solid #d1d1d1 ",
              background: "#f7f8fa",
              borderRadius: "0 0 12px 12px",
            }}
          >
            <div>
              <NormalButton
                type="button"
                className="fw-900"
                textColor=" black"
                backgroundColor="white"
                buttonText="CANCEL "
                border="1px solid #F0F0F0"
                padding="6px 16px"
                borderRadius="4"
                fontSize="14"
                fontWeight="500"
                onClick={() => {
                  setUploadDocument(false);
                  remove();
                }}
              />
            </div>
            <div>
              <NormalButton
                type="submit"
                className="fw-900"
                textColor="white"
                border="none"
                backgroundColor="green"
                buttonText="ADD"
                padding="6px 16px"
                borderRadius="6"
                fontSize="14"
                fontWeight="500"
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default UploadDocument;
