import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCrop = (props) => {
  const defaultSrc = props.picture;
  const [image, setImage] = useState(defaultSrc);
  // const useDefaultImage = () => {
  //   setImage(defaultSrc);
  // };

  return (
    <div>
      <div className="splitdiv" id="leftdiv">
        <div id="leftdivcard">
          <Cropper
            className="cropper"
            zoomTo={0.5}
            initialAspectRatio={1}
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
            	props.setCropper(instance);
            }}
            guides={true}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center "></div>
    </div>
  );
};
export default ImageCrop;
