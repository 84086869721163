import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { GetDocumentByPropertyId } from "../../../services/Documents";
import { H2, H3 } from "../../../styles/Common.style";
import { myPage } from "../../../styles/Theme";

export const Guides = ({ unitInformation, guidehead }) => {
  //------------------React query-------------------//
  const navigate = useNavigate();

  const {
    data: getDocumentsByPropertyId,
    refetch: getDocumentsByPropertyIdRefetch,
    isLoading,
  } = useQuery(
    ["getDocumentsByPropertyId", unitInformation?.property_id],
    () => GetDocumentByPropertyId(unitInformation?.property_id),
    { enabled: !!unitInformation?.property_id }
  );
  // State Management for Guides

  const [guide, setGuide] = useState([]);

  // console.log("guide", guide);

  useEffect(() => {
    setGuide(getDocumentsByPropertyId?.data);
    // setGuide(
    //   getDocumentsByPropertyId?.data?.filter(
    //     (data) =>
    //       data?.document_type_id == 109 || data?.document_type_name == "Guide"
    //   )
    // );
    getDocumentsByPropertyIdRefetch();
  }, [getDocumentsByPropertyId]);

  const handleViewAllGuides = () => {
    navigate("/guides");
    // navigate("/profile");
  };

  guide?.sort((a, b) => {
    const dateA = new Date(b.created_on);
    const dateB = new Date(a.created_on);
    return dateA - dateB;
  });

  return (
    <Row className="mt-4">
      {/* <h3 className="container-title-20">Guides</h3> */}
      {/* <Row className="px-0 d-flex justify-content-center align-items-center">
        <Col lg={11}>
          <H2 color={myPage.primaryTextColor}>Recent guides</H2>
        </Col>
        <Col>
          <div
            className="fs-14 cursor"
            style={{ color: "#111747" }}
            onClick={handleViewAllGuides}
          >
            View all
          </div>
        </Col>
      </Row> */}
      <div className="d-flex justify-content-between align-items-center px-3">
        <div>
          <H2 color={myPage.primaryTextColor}>
            {guidehead ? guidehead : "Guides"}
          </H2>
        </div>
        <div>
          <div
            className="fs-14 cursor"
            style={{ color: "#111747" }}
            onClick={handleViewAllGuides}
          >
            View all
          </div>
        </div>
      </div>
      {guide?.length != 0 ? (
        <Row className="px-0">
          {guide?.slice(0, 4).map((data, i) => {
            return (
              <Col
                key={i}
                lg={3}
                md={6}
                xl={2}
                sm={3}
                className="position-relative d-flex d-sm-block my-2"
                style={{
                  width: "240px",
                }}
              >
                <div
                // onClick={() => window.open(data?.file_url)}
                >
                  <div
                    className="d-inline-block position-relative guide-img radius-12 overflow-hidden mx-auto"
                    style={{ width: "100%", height: "150px" }}
                  >
                    <img
                      height="100%"
                      width="100%"
                      className="w-100 position-relative"
                      src={
                        data?.image_url === ""
                          ? unitInformation?.image
                          : data?.image_url
                      }
                      alt="guide"
                    />
                  </div>
                  <div
                    className="position-absolute px-3 color-white"
                    style={{ bottom: "20px" }}
                  >
                    <div
                      className="medium-text mb-0 text-truncate"
                      style={{
                        width: "190px",
                        fontSize: "16px",
                      }}
                    >
                      {data?.title}
                      {/* {data?.title?.replace(".pdf", "")} */}
                    </div>
                    <div
                      className="fs-7 mb-0 text-grey text-truncate"
                      style={{
                        width: "190px",
                      }}
                    >
                      {data?.description}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row className="p-0">
          <Col>
            <div
              className="d-flex justify-content-center align-items-center radius-4"
              style={{ height: "150px" }}
            >
              No guide available
            </div>
          </Col>
        </Row>
      )}
    </Row>
  );
};
