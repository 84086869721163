const GetSessionItem = (key) => {
    // get data to sessionStorage
    return sessionStorage.getItem(key);
  };
  
  const SetSessionItem = (key, value) => {
    // set data from sessionStorage
    return sessionStorage.setItem(key, value);
  };
  
  export { GetSessionItem, SetSessionItem };
  