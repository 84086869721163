import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// unit Id
export const useUnitId = create(
  persist(
    (set, get) => ({
      UnitId: 0,
      setUnitId: (data) => set({ UnitId: data }),
    }),
    {
      name: "UnitId", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);


export const useUnitInformationStore = create((set, get) => ({
	unitInformation: "",
	setUnitInformation: (data) => set({ unitInformation: data }),
}));
