import { Col, Image, Row, useAccordionButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupButtons from "../../../Components/GroupButton/GroupButton";
import CustomCheckBox from "../../../Components/input/Checkbox/Checkbox";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import NormalGroupText from "../../../Components/GroupText/NormalGroupText";
import ReactSelect from "../../../Components/input/ReactSelect";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { DropZoneBorder, GreenSpan } from "../../../styles/Common.style";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { useGetCountries, useGetZipcodeByCountryId } from "../../../hooks/queries/useLocationQueries";
import { useActorProfileStore } from "../../../hooks/stores/useActorDetails";
import { Controller, useForm } from "react-hook-form";
import Modal from "../../../Components/widgets/modal/Modal";
import FileUploadModal from "../screeningModal/FileUploadModal";
import { convertNumericStringToNumber } from "../../../utils/helpers";

// All the form components are here which will required in the screening form

export const TypeText = ({ data, field, fieldState }) => {
  return (
    <Row>
      <Col sm={6} className="pe-lg-0 w-100 px-0">
        <div className={`form-input ${fieldState.error && "error"} mt-2`}>
          <input key={data.screening_question_id} {...field} type="text" placeholder={``} id={data.placeholder} />
          <label htmlFor={data.placeholder}>{data.placeholder}</label>
        </div>
        {/* {fieldState.error?.[field.name] && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            {console.log(fieldState,'render')}
            This field is required.
          </p>
        )} */}
      </Col>
    </Row>
  );
};

export const TypeEmail = ({field, data, fieldState}) => {
  return (
    <Row>
      <Col className="pe-lg-0 w-100 px-0">
        <div className={`form-input ${fieldState.error && "error"} mt-2`}>
          <input key={data.screening_question_id} {...field} type="email" placeholder={``} id={data.placeholder} />

          <label htmlFor={data.placeholder}>{data.placeholder}</label>
        </div>
        {/* {fieldState.error && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )} */}
      </Col>
    </Row>
  )
}

export const TypeRadio = ({ data, field: { value, onChange, name, ...field }, fieldState, setValue }) => {
  return (
    <Row>
      <Col className="px-0">
        {/* {type !== "multi" && (
          <div className="text-start fw-bold">
            <p className="mb-1">
              {data.label_name}
              {data.is_mandatory && "*"}
            </p>
          </div>
        )} */}
        <div className={"mt-2"}>
          <GroupButtons
            error={fieldState.error}
            key={data.screening_question_id}
            tabs={data?.possible_values?.map((o) => {
              return {
                id: o?.value,
                tabTitle: o?.text,
              };
            })}
            currentTab={value}
            handleTabClick={(tab) =>
              setValue(name, tab, { shouldValidate: true , shouldDirty: true })
            }
          />
        </div>
        {fieldState?.error?.[name] && value === "" && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )}
      </Col>
    </Row>
  );
};

export const TypeNumber = ({ data, field, fieldState }) => {
  // const { control, handleSubmit, watch } = useForm();

  // const actorProfile = useActorProfileStore((state) => state.actorDetail);
  // const { data: getCountriesData } = useGetCountries(actorProfile?.country_id);

  // if(data?.suffix === "DKK/Monthly") return <TypeDKK data={data} field={field} errors={fieldState.error}
  // />

  // if(!getCountriesData?.data?.length && data?.label_name === "Phone number") return null;

  return (
    // <Row>
    //   {
    //   data?.label_name !== "Phone number" ?
    //   <Col lg={6} className="pe-lg-0 px-0 w-100">
    //     { type !=="multi" && <div className="text-start fw-bold">
    //           <p className="mb-1">
    //             {data.label_name}
    //             {data.is_mandatory && "*"}
    //           </p>
    //       </div>
    //     }
       
    //     <div className={`form-input ${errors?.[name] && 'error'} mt-2 position-relative`}>
    //       <input
    //         {...field}
    //         type="number"
    //         placeholder={`${data.screening_question_id === 14 ? "XXXXXX-XXXX" : ''}`}
    //         id={data.label_name}
    //         className={`${data.screening_question_id === 14 && "ps-5"} `}
    //       />
    //        { data.screening_question_id === 14 && <FontAwesomeIcon className="position-absolute" style={{ left: '13px', top: '50%', transform: 'translateY(-50%'}} icon="fa-regular fa-address-card" />}
    //        {data.screening_question_id !== 14 && <label htmlFor={data.label_name}>{ data.label_name }</label>}
    //     </div>
    //     {errors?.[name] && (
    //       <p className="error-msg text-start" style={{ color: "red" }}>
    //         This field is required.
    //       </p>
    //     )}
    //   </Col>
    //   :
    //   <>
    //   <Col lg={6} className="pe-lg-0 px-0 w-100">
    //   { type !=="multi" && <div className="text-start fw-bold">
    //         <p className="mb-1">
    //           {data.label_name}
    //           {data.is_mandatory && "*"}
    //         </p>
    //     </div>
    //   }
    //  <Row className="gap-2 gap-md-0">
    //   <Col md={4} className="px-0 pe-md-2">
    //   <Controller
    //     name="countryCode"
    //     control={control}
    //     rules={{required: true}}
    //     render={({ field: {onChange, name, ...field}}) => {
    //       return(
    //         <>
    //         <ReactSelect
    //         placeholder={"Country code"}
    //         dropdownLine={true}
    //         searchable={false}
    //         value={
    //           getValues(name) ? (()=> {
    //           const selectedDropdownValue = getCountriesData?.data.filter((o) => o?.DataValue2 === getValues(name))[0]
             
    //           return {
    //           value: selectedDropdownValue?.DataValue2,
    //           label: (
    //             <div
    //               className="d-flex align-items-center"
    //               style={{
    //                 fontFamily: "Cerebri sans",
    //               }}
    //             >
    //               <div className="radius-4 me-2">
    //                 <img
    //                   src={selectedDropdownValue?.DataValue1}
    //                   style={{
    //                     width: "20px",
    //                     marginRight: "10px",
    //                   }}
    //                   alt="country flag"
    //                 />
    //               </div>
    //               <div>
    //                 <div className="fs-14 light-font-text ">
    //                   {selectedDropdownValue?.DataValue2}
    //                 </div>
    //               </div>
    //             </div>
    //           )
    //         }
    //       })()
    //         :
    //         ''}
    //         options={getCountriesData?.data?.map(
    //           (item, i) => ({
    //             value: item?.Id,
    //             label: (
    //               <div
    //                 key={i}
    //                 className="d-flex align-items-center"
    //                 style={{
    //                   fontFamily: "Cerebri sans",
    //                 }}
    //               >
    //                 <div className="radius-4 me-2">
    //                   <img
    //                     src={item.DataValue1}
    //                     style={{
    //                       width: "20px",
    //                       marginRight: "10px",
    //                     }}
    //                     alt="country flag"
    //                   />
    //                 </div>
    //                 <div>
    //                   <div className="fs-14 light-font-text ">
    //                     {item?.DataValue2}
    //                   </div>
    //                 </div>
    //               </div>
    //             ),
    //             innerLabel: item?.DataValue2
    //           })
    //         )}
    //         onChange={(e) => {
    //           setValue(name, e?.innerLabel)
    //           onChange(e)
    //           // clearErrors("countryCode");
    //         }}
    //       />
         
    //         {errors?.[name] && (
    //           <p className="error-msg text-start" style={{ color: "red" }}>
    //             This field is required.
    //           </p>
    //         )}
    //         </>
    //       )
    //     }}
    //   />
    //   </Col>
    //   <Col md={8} className="px-0">
    //   <div className={`form-input ${errors?.[name] && 'error'} position-relative`}>
    //     <input
    //       {...field}
    //       type="number"
    //       placeholder={`${data.screening_question_id === 14 ? "XXXXXX-XXXX" : ''}`}
    //       id={data.label_name}
    //       className={`${data.screening_question_id === 14 && "ps-5"} `}
    //     />
    //      { data.screening_question_id === 14 && <FontAwesomeIcon className="position-absolute" style={{ left: '13px', top: '50%', transform: 'translateY(-50%'}} icon="fa-regular fa-address-card" />}
    //      {data.screening_question_id !== 14 && <label htmlFor={data.label_name}>{ data.label_name }</label>}
    //   </div>
    //   </Col>
    //  </Row>
    //   {errors?.[name] && (
    //     <p className="error-msg text-start" style={{ color: "red" }}>
    //       This field is required.
    //     </p>
    //   )}
    // </Col>
    //   </>
    //   }
    // </Row>
    <Row>
      <Col className="pe-lg-0 w-100 px-0">
        <div className={`form-input ${fieldState.error && "error"} mt-2`}>
          <input key={data.screening_question_id} {...field} type="number" placeholder={``} id={data.placeholder} />
          <label htmlFor={data.placeholder}>{data.placeholder}</label>
        </div>
        {/* {fieldState.error && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )} */}
      </Col>
    </Row>
  );
};


export const TypeTextarea = ({ field, data, fieldState, setValue }) => {
  const [allComments, setAllComments] = useState([]) 

  const handleComment = () => {
    if (field?.value?.length) {
      setAllComments([...allComments, field.value]);
      setValue(field.name, "");
    }
  };

  const handleCommentDelete = (index) => {
    setAllComments(allComments.filter((_, i) => i !== index));
  };

  return (
    <Row>
      <Col lg={6} className="pe-lg-0 w-100 px-0">
        {Boolean(allComments.length) && (
          <>
            <div className="mt-3">
              <div className="text-start fw-normal">Added comments</div>
              {allComments.map((comment, index) => {
                return (
                  <div
                    key={index}
                    className="commentedBox position-relative mt-3"
                  >
                    <div className="commented-container">
                      <div className="text-start ps-3 py-3 pe-4">{comment}</div>
                      <div
                        className="comment-delete-btn cursor"
                        onClick={() => handleCommentDelete(index)}
                      >
                        <FontAwesomeIcon icon="fa-regular fa-trash" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div className={`form-input ${fieldState.error && "error"} mt-2`}>
          {/* <input type="text" placeholder={`${ data.placeholder }`} id={ data.label_name } /> */}
          <textarea
          key={data.screening_question_id}
            {...field}
            placeholder={`${data.placeholder}`}
            id={data.label_name}
            style={{ height: "130px" }}
          ></textarea>
          {/* <label htmlFor="fName">{ data.label_name }</label> */}
        </div>
        {fieldState.error?.[field.name] && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )}

        <div className="d-flex justify-content-end mt-2">
          <NormalButton
            type="button"
            className="fw-900"
            textColor=" white"
            backgroundColor="green"
            buttonText="ADD COMMENT"
            border="1px solid #F0F0F0"
            padding="6px 16px"
            borderRadius="4"
            fontSize="14"
            fontWeight="500"
            onClick={() => handleComment()}
          />
        </div>
      </Col>
    </Row>
  );
};

export const TypeFile = ({
  data,
  field: { value, onChange, name, ...field },
  fieldState,
  setValue
}) => {

  // dropzone
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/png": [],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxSize: 5242880,
    maxFiles: 2,
    onDrop: (acceptedFiles, fileRejections) => {
      const selectedFileLength = value.length + acceptedFiles.length;

      if(value.length && selectedFileLength < 3) {
        setValue(name, [value, acceptedFiles], { shouldValidate: true, shouldDirty: true });
      }else if(!value.length) {
        setValue(name,acceptedFiles, { shouldValidate: true, shouldDirty: true });
      }else {
        return toast.error("You can only select 3 file")
      }
      // acceptedFiles.forEach((file) => {
      // setValue(name, file, { shouldValidate: true });
      // });
      fileRejections.map(({ file, errors }) => {
        return errors.map((e) =>
          toast.error(
            e.code === "file-too-large" ? "File is larger than 5MB" : e.code === "too-many-files" ? "You can only select 3 file" :  e.message
          )
        );
      });
    },
  });


  const [fileUploadModal, setFileUploadModal] = useState(false);

  return (
    <>
  {/* {console.log(value, 'value')} */}
      {value?.length? <>
      {
        value.map((val, index) => {
          return(
            <Row key={index}>
              <Col className="px-0">
                {/* {type !== "multi" && (
                  <div className="text-start fw-bold mb-3">
                    <p className="mb-1">
                      {data.label_name}
                      {data.is_mandatory && "*"}
                    </p>
                  </div>
                )} */}
                <div className="p-0 mt-2">
                  <div className="screening-remove-bg d-flex align-items-center justify-content-between">
                    <div className="d-flex py-3 ps-3">
                      <div className="screening-remove-uploaded-img">
                        <div className="file-img-icon">
                          <FontAwesomeIcon
                            style={{ fontSize: "1.2rem", color: "#202020" }}
                            icon="fa-solid fa-file-lines"
                            flip="horizontal"
                          />
                        </div>
                      </div>
                      <div className="fs-14 px-3 d-flex align-items-center">
                        {val?.name}
                      </div>
                    </div>
                    <div className="d-flex me-2 me-sm-5 align-items-center">
                      <span
                        onClick={() => {
                          setFileUploadModal(true);
                        }}
                      >
                        <FontAwesomeIcon
                          className="me-2 me-sm-4 fs-5 cursor"
                          icon="fa-regular fa-magnifying-glass-plus"
                        />
                      </span>
                      <div
                        className="mb-2 mt-2 px-3 cursor browse-btn py-1"
                        onClick={() => setValue(name, value.filter((_, i) => i !== index), { shouldDirty: true })}
                      >
                        <label className="cursor fs-7 fw-bold">Remove</label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )
        })
      }
      {/* {fieldState.error?.[name] && (
        <p className="error-msg text-start" style={{ color: "red" }}>
          This field is required.
        </p>
      )} */}
      </> : (
        <Row>
          <Col className="px-0">
            {/* {type !== "multi" && (
              <div className="text-start fw-bold mb-3">
                <p className="mb-1">
                  {data.label_name}
                  {data.is_mandatory && "*"}
                </p>
              </div>
            )} */}
            <div className="p-0 mt-2">
              {/* <div className={`d-flex align-items-center justify-content-between upload-bg`} {...getRootProps({ className: "dropzone" })}> */}
              {/* <div className="d-flex">
                  <div className="screening-upload-img">
                    <div className="file-img-icon">
                      <FontAwesomeIcon
                        style={{ fontSize: "1.2rem", color: "#111747" }}
                        icon="fa-solid fa-file-lines"
                        flip="horizontal"
                      />
                    </div>
                  </div>

                  <div className="fs-14 px-3 fw-400 color-gray">
                    Drop your file here, or browse your computer
                    <div>PNG, JPG,or PDF (Max. file size 5MB)</div>
                  </div>
                </div>
                <div className="mb-2 rectangle mt-2 px-3 cursor browes-border">
                  <input
                    className="cursor"
                    type="file"
                    style={{ display: "none" }}
                    {...field}
                    accept="image/png, image/jpeg"
                    id={name}
                    value={value?.name}
                    {...getInputProps()}
                    onChange={(e) => onChange(e.target.files[0])}
                  />
                  <label className="cursor fw-400 fs-7" htmlFor={name}>
                    Browse
                  </label>
                </div> */}
              <div className="pt-2 pb-4">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div
                    className={`file-select ${
                      fieldState.error && "error"
                    } py-1 ps-3 d-flex align-items-center justify-content-between flex-wrap upload-bg`}
                  >
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <div className="screening-upload-img">
                          <div className="file-img-icon">
                            <FontAwesomeIcon
                              style={{ fontSize: "1.2rem", color: "#888888" }}
                              icon="fa-solid fa-file-lines"
                              flip="horizontal"
                            />
                          </div>
                        </div>

                        <div className="fs-14 px-3 fw-400 color-gray text-start py-2 lh-sm">
                          <div className="fw-bold">
                            Drop your file here,
                            <br /> or browse your computer
                          </div>
                          <div>JPG, PNG or PDF (Max. file size 5MB)</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mb-2 browse-btn mt-2 px-3 py-1 cursor me-2 me-sm-5"
                      onClick={open}
                    >
                      <label className="cursor fs-7 fw-bold" htmlFor={name}>
                        Browse
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {fieldState.error?.[name] && (
              <p className="error-msg text-start" style={{ color: "red" }}>
                This field is required.
              </p>
            )}
          </Col>
        </Row>
      )}
      <Modal
        open={fileUploadModal}
        topborder={false}
        ModalHeader={true}
        size="xl"
        className="modal-dialog-centered"
        onHide={() => {
          setFileUploadModal(false);
          // setChecked(true);
        }}
        title={
          <div className="d-flex justify-content-between p-3">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <div className="Cerebri-sans-medium fs-14">{value?.name}</div>
              </div>
            </div>
            <div
              onClick={() => {
                setFileUploadModal(false);
                // setChecked(true);
              }}
            >
              <span
                className="fs-20 d-flex justify-content-center align-items-center cursor"
                aria-hidden="true"
                style={{
                  height: "44px",
                  width: "44px",
                  borderRadius: "8px",
                  position: "relative",
                  backgroundColor: "rgb(244, 245, 245)",
                  border: "none",
                }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-xmark"
                  style={{ color: "#000000" }}
                />
              </span>
            </div>
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setFileUploadModal(false);
        }}
      >
        <div>
          <FileUploadModal value={value} />
          {/* <Row>
            <Col className="p-0">
              <div
                style={{
                  height: "500px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {value?.type?.includes("image") ? (
                  <div style={{ width: "100%", height: "100%" }}>
                    <Image
                      rounded
                      src={value?.preview}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ) : (
                  <iframe
                    src={value?.preview}
                    title="Embedded PDF"
                    style={{ width: "100%", height: "100%", border: "none" }}
                  ></iframe>
                )}
              </div>
            </Col>
          </Row> */}
        </div>
        {/* <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <NormalButton
            className="fw-900"
            textColor=" black"
            border="none"
            backgroundColor="white"
            padding="6px 16px"
            borderRadius="6"
            fontSize="14"
            fontWeight="500"
            buttonText="CANCEL"
            onClick={() => setFileUploadModal(false)}
          />
          <NormalButton
            className="fw-900"
            textColor="white"
            border="none"
            // cursor={currentRow?.original?.read_on !== "" ? "pointer" : "auto"}
            backgroundColor="green"
            // disabled={
            //   currentRow?.original?.document_view_actor?.[0]?.read_on !== null
            //     ? true
            //     : false
            // }
            padding="6px 16px"
            borderRadius="6"
            fontSize="14"
            fontWeight="500"
            buttonText={"File  Preview "}
            // onClick={handleReadDocument}
          />
        </div> */}
      </Modal>
    </>
  );
};

export const TypeDate = ({ data, field, fieldState, setValue }) => {
  return ( 
    <Row>
      <Col lg={6} className="pe-lg-0 w-100 px-0">
        <div className={`form-input ${fieldState.error && "error"} mt-2`}>
          <input
            {...field}
            key={data.screening_question_id}
            className="text-uppercase"
            type="date"
            value={moment(field.value, "DD/MM/YYYY").format("YYYY-MM-DD")} //specifying date formate in moment to know its type eg., dd-mm-yyyy
            onChange={(e) =>
              setValue(field.name, moment(e.target.value).format("DD/MM/YYYY"), {
                shouldValidate: true,
              })
            }
            placeholder={`${data.placeholder}`}
            id={data.label_name}
          />
        </div>
        {/* {fieldState.error && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )} */}
      </Col>
    </Row>
  );
};

export const TypeCheckbox = ({ data, field, fieldState, setValue }) => {

  return (
    <Row>
      <Col className="px-0">
        {data?.possible_values?.map((opt) => {
        const selectedVal = field.value?.length ? field.value.split(',')?.filter(fv => convertNumericStringToNumber(fv) === convertNumericStringToNumber(opt?.value)) : '';
          return (
            <div
              key={opt?.value}
              className="d-flex align-items-center gap-2 px-2"
            >
              <div>
                <CustomCheckBox
                key={data.screening_question_id}
                  checked={Boolean(selectedVal.length)}
                  onChange={(e) => {
                    if(field.value.length === 0) {
                      setValue(field.name, `${opt?.value}`)
                    }else if(field.value.length > 0) {
                      const changeVal = field.value.split(',')?.filter(fv => convertNumericStringToNumber(fv) === convertNumericStringToNumber(opt?.value));
                      if(changeVal.length) {
                        const newValue = field.value?.split(',').filter(fv => convertNumericStringToNumber(fv) !== convertNumericStringToNumber(opt?.value)).join(',')
                        setValue(field.name, `${newValue}`);
                      }else {
                        setValue(field.name, `${field.value}, ${opt.value}`);
                      }
                    }
                  }}
                />
              </div>
              <div className="medium-text-14">{opt.text}</div>
            </div>
          );
        })}
        {/* {fieldState.error?.[field.name] && field.value === "" && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )} */}
      </Col>
    </Row>
  );
};

export const TypeDKK = ({ data, field: { value, onChange, ...field }, setValue, errors }) => {

  return (
    <Row>
      <Col className="px-0">
        {/* <div>
          <p className="text-start">{data.label_name} {data.is_mandatory && "*"}</p>
        </div> */}
        {/* {type !== "multi" && (
          <div className="text-start fw-bold">
            <p className="mb-1">
              {data.label_name}
              {data.is_mandatory && "*"}
            </p>
          </div>
        )} */}
        <div
          className={`normal-group-text ${
            errors?.[field.name] && "error"
          } form-input mt-2`}
        >
          <NormalGroupText
            key={data.screening_question_id}
            inputheight={"40px"}
            textheight={"40px"}
            lable="0,00"
            graylabel="DKK/mthly."
            textWidth="30%"
            type="number"
            value={value}
            onChange={(e) => onChange(e)}
          />
        </div>
        {errors?.[field.name] && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )}
      </Col>
    </Row>
  );
};

export const TypeSelect = ({  data, field, fieldState, setValue }) => {
  // const actorProfile = useActorProfileStore((state) => state.actorDetail);
  // const { data: getCountriesData } = useGetCountries(
  //   actorProfile?.country_id
  // );
  // const {
  //   data: getZipcodeByCountryIdData,
  //   refetch: getZipcodeByCountryIdDataRefetch,
  // } = useGetZipcodeByCountryId(getValues("9")); // country is coming from api which has question id 9

  // let selectOption = [];
  // const[selectedValue, setSelectedValue] = useState({});

  // // screening_question_id === 8 means zip code, screening_question_id === 9 means country code else normal option which will get from API
  // if(data?.screening_question_id === 8) { 
  //   selectOption = getZipcodeByCountryIdData?.data?.map(
  //     (item, index) => ({
  //       value: item?.Id,
  //       label: `${item?.Id} ${item?.Text}`,
  //     })
  //   )
  // }else if(data?.screening_question_id === 9) {
  //   selectOption = getCountriesData?.data?.map(
  //     (item, i) => ({
  //       value: item?.Id,
  //       label: (
  //         <div
  //           // key={i}
  //           className="d-flex align-items-center"
  //           style={{
  //             fontFamily: "Cerebri sans",
  //           }}
  //         >
  //           <div className="radius-4 me-2">
  //             <img
  //               src={item.DataValue1}
  //               style={{
  //                 width: "20px",
  //                 marginRight: "10px",
  //               }}
  //               alt="country flag"
  //             />
  //           </div>
  //           <div>
  //             <div className="fs-14 light-font-text ">
  //               {item?.Text}
  //             </div>
  //           </div>
  //         </div>
  //       ),
  //     })
  //   )
  // }else {
  //   selectOption = data?.option_value.map((item, i) => ({
  //     value: item?.screening_question_option_id,
  //     label: item?.option_value,
  //   }))
  // }

  // if else for using selected value in diffrence scenarios for eg. country or zip code
  // useEffect(()=> {
  //   if(value) {
  //     if(data?.screening_question_id === 8) {
  //       setSelectedValue({
  //         value: value,
  //         label: value,
  //       })
  //       // 7 is for city as its coming from API 
  //       setValue(
  //         "7",
  //         getZipcodeByCountryIdData?.data?.filter(
  //           (el) => el.Id === value
  //         )?.[0]?.Text,
  //         { shouldValidate: true }
  //       );
  //     }else if(data?.screening_question_id === 9){
  //       setSelectedValue({
  //         value: getCountriesData?.data?.filter((o) => o?.Id === value)[0]?.Id,
  //         label: (
  //           <div
  //             // key={i}
  //             className="d-flex align-items-center"
  //             style={{
  //               fontFamily: "Cerebri sans",
  //             }}
  //           >
  //             <div className="radius-4 me-2">
  //               <img
  //                 src={getCountriesData?.data?.filter((o) => o?.Id === value)[0]?.DataValue1}
  //                 style={{
  //                   width: "20px",
  //                   marginRight: "10px",
  //                 }}
  //                 alt="country flag"
  //               />
  //             </div>
  //             <div>
  //               <div className="fs-14 light-font-text ">
  //                 {getCountriesData?.data?.filter((o) => o?.Id === value)[0]?.Text}
  //               </div>
  //             </div>
  //           </div>
  //         ),
  //       });
  //     }else {
  //       setSelectedValue({
  //         value: data?.option_value.filter((o) => +o?.screening_question_option_id === +value)[0]?.screening_question_option_id,
  //         label: (
  //           <div
  //             // key={i}
  //             className="d-flex align-items-center"
  //             style={{
  //               fontFamily: "Cerebri sans",
  //             }}
  //           >
  //             <div className="radius-4 me-2">
  //               <img
  //                 src={data?.option_value.filter((o) => +o?.screening_question_option_id === +value)[0].DataValue1}
  //                 style={{
  //                   width: "20px",
  //                   marginRight: "10px",
  //                 }}
  //                 alt="country flag"
  //               />
  //             </div>
  //             <div>
  //               <div className="fs-14 light-font-text ">
  //                 {data?.option_value.filter((o) => +o?.screening_question_option_id === +value)[0]?.Text}
  //               </div>
  //             </div>
  //           </div>
  //         ),
  //       })
  //     }
  //   }

  //   if(!getValues(`${data?.screening_question_id}`)) {
  //     setSelectedValue({})
  //   }
  
  //   if(![6,7,8,9].includes(data?.screening_question_id) && getValues(`${data?.screening_question_id}`)) {
  //     setSelectedValue({
  //       value: value,
  //       label: data?.option_value?.filter(o => +o.screening_question_option_id === +value)[0]?.option_value
  //     })
  //   }
  // }, [getValues(`${data?.screening_question_id}`)])

  const filterVal = data.possible_values?.filter(p => p.value === field.value)
  const selectedVal = filterVal?.length ? filterVal?.map((s) => ({
    // label: s.value,
    // value: s?.Id
    label: s.text,
    value: s.value,
  })) : '';

  return (
    <Row>
      <Col className={`px-0 mt-2 ${fieldState.error && 'error'} text-start`}>
        <ReactSelect
          clearable={false}
          key={data.screening_question_id}
          placeholder={data?.placeholder}
          dropdownLine={true}
          // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
          value={selectedVal}
          onChange={(e) => {
            // clearErrors(name);
            field.onChange(e)
            setValue(field.name, e.value,  { shouldValidate: true });
          }}
          options={data.possible_values?.map((s) => ({
            label: s.text,
            value: s.value
          }))}
        />
        {fieldState.error && field.value === "" && (
          <p className="error-msg text-start" style={{ color: "red" }}>
            This field is required.
          </p>
        )}
      </Col>
    </Row>
  );
};
