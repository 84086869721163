import { useMutation, useQuery } from "react-query";
import {
  GetScreeningForm,
  GetScreeningTemplateQuestionAnswer,
  PostAddRKI,
  PostAddScreeningResponse,
} from "../../services/Screening";

// Get Screening template question
export const useGetScreeningForm = (customerId, formId) => {
  return useQuery(
    ["getTemplate_id", customerId, formId],
    () => GetScreeningForm(customerId, formId),
    
    { enabled: !!customerId && !!formId, refetchOnWindowFocus: false }
  );
};

// Get Screening template question's answer
export const useGetScreeningTemplateQuestionAnswer = (
  prop_id,
  contract_id,
  actor_id
) => {
  return useQuery(
    ["GetScreeningTemplateQuestionAnswer", prop_id, contract_id, actor_id],
    () => GetScreeningTemplateQuestionAnswer(prop_id, contract_id, actor_id),
    { enabled: !!prop_id && !!contract_id && !!actor_id }
  );
};

// Post screening question answer
export const usePostAddScreeningResponse = () => {
  return useMutation(PostAddScreeningResponse);
};

// Post credit check
export const usePostAddRKI = () => {
  return useMutation(PostAddRKI);
};