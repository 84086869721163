import React, { useState } from "react";
import Webcam from "react-webcam";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import ImageCrop from "./ImageCrop";
import { Col, Row } from "react-bootstrap";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
	width: 400,
	height: 400,
	facingMode: "user",
};
const Takeapicture = ({ setFiles, setUploadProfilePictureFlow }) => {
	//--------------states----------------//
	const [picture, setPicture] = useState(null);
	const [cropData, setCropData] = useState("");

	const webcamRef = React.useRef(null);
	const capture = React.useCallback(async () => {
		const pictureSrc = webcamRef.current.getScreenshot();
		setPicture(pictureSrc);
	}, [webcamRef]);

	const [cropper, setCropper] = useState(null);

	const getCropData = async () => {
		if (typeof cropper !== "undefined") {
			setCropData(cropper.getCroppedCanvas().toDataURL());

			fetch(cropper.getCroppedCanvas().toDataURL())
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], "image.jpeg");
					Object.assign(file, {
						preview: cropper.getCroppedCanvas().toDataURL(),
					});
					setFiles([file]);
				});

			setUploadProfilePictureFlow("PreviewAndUpload");
		}
	};

	return (
		<div className="m-3">
			<div>
				{picture ? (
					<div>
						{/* <img src={props.picture} alt='img'  /> */}
						<ImageCrop
							className="w-100"
							height={200}
							width={200}
							picture={picture}
							cropData={cropData}
							setCropData={setCropData}
							cropper={cropper}
							setCropper={setCropper}
						/>
					</div>
				) : (
					<Webcam
						className="w-100"
						audio={false}
						height={400}
						ref={webcamRef}
						width={400}
						screenshotFormat="image/jpeg"
						videoConstraints={videoConstraints}
					/>
				)}
			</div>
			<div className="d-flex justify-content-center">
				{picture ? (
					<div className="">
						<div className="d-flex gap-2 mt-3">
									<NormalButton
										type="submit"
										className="fw-900"
										// borderColor="#F0F0F0"
										textColor="#ADBE9E"
										backgroundColor="white"
										buttonText="RETAKE  "
										border="1px solid #F0F0F0"
										padding="6px 16px"
										borderRadius="4"
										fontSize="14"
										fontWeight="500"
										onClick={() => setPicture(null)}
									/>
									<NormalButton
										type="submit"
										className="fw-900"
										// borderColor="#F0F0F0"
										textColor="#ADBE9E"
										backgroundColor="white"
										buttonText="DONE  "
										border="1px solid #F0F0F0"
										padding="6px 16px"
										borderRadius="4"
										fontSize="14"
										fontWeight="500"
										onClick={getCropData}
									/>
						</div>
					</div>
				) : (
					<div className="d-flex ">
						<NormalButton
							type="submit"
							className="fw-900"
							// borderColor="#F0F0F0"
							textColor="#ADBE9E"
							backgroundColor="white"
							buttonText="CAPTURE "
							border="1px solid #F0F0F0"
							padding="6px 16px"
							borderRadius="4"
							fontSize="14"
							fontWeight="500"
							onClick={capture}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
export default Takeapicture;
