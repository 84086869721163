import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import reporthead from "../../../assets/icons/reporthead.svg";
import { Url } from "../../../services/API/Endpoints";

export default function Popup({
  open,
  onHide,
  title,
  title_color,
  onCloseAction,
  action_button_label,
  action_button_label_color,
  action_button_color,
  cancel_button,
  cancel_button_bordercolor,
  cancel_button_label,
  cancel_button_label_color,
  cancel_button_color,
  footer_color,
  children,
  borderRadius,
  HeaderImage,
  size,
  height,
  BorderBottom,
  action_button_type,
  topborder,
  ModalHeader,
  center,
  isFullscreen,
  headerBg,
  modalBg,
  modalPosition,
  bodyStyle,
  bodyClassName,
  modalClassName,
  backdropClassName
}) {
  
  return (
    <>
      <Modal
        show={open}
        onHide={onHide}
        size={size}
        className="d-flex align-items-center justify-content-center"
        style={{ overflow: "auto"}}
        centered={modalPosition}
        fullscreen={isFullscreen}
        dialogClassName={modalClassName}
        backdropClassName={backdropClassName}
      >
        {ModalHeader == false ? (
          ""
        ) : (
          <Modal.Header
            className="p-0"
            style={{
              height: height,
              border:
                topborder === false ? "none" : "1px solid rgb(240,240,240)",
              // backgroundImage: `url(${reporthead})`,
              // backgroundImage: HeaderImage ? `url(${reporthead})` : "none",
              // background: headerBg,
            }}
          >
            <Modal.Title
              style={{
                fontWeight: "500",
                fontSize: "18px",
                lineheight: "24px",
                color: title_color,
                width: "100%",
              }}
            >
              {title}
            </Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className={bodyClassName} style={{ ...bodyStyle }}>
          {children}
        </Modal.Body>
        {cancel_button ? (
          <Row>
            <Modal.Footer
              style={{
                backgroundColor: footer_color,
                border: BorderBottom === false ? "none" : "1px solid #f7f8fa",
              }}
              className="py-1 px-3 "
            >
              <Col>
                <Button
                  className="fs-6"
                  onClick={onHide}
                  style={{
                    backgroundColor: cancel_button_color,
                    border: "none",
                    borderRadius: borderRadius,
                    width: "100%",
                    // fontSize: "18px",
                    fontWeight: "500",
                    color: cancel_button_label_color,
                    "&:hover": {
                      color: cancel_button_label_color,
                    },
                  }}
                >
                  {cancel_button_label}
                </Button>
              </Col>
              <Col>
                <Button
                  type={action_button_type}
                  onClick={onCloseAction}
                  style={{
                    backgroundColor: action_button_color,
                    border: "1px solid #F4F5F5",
                    borderRadius: "6px",
                    width: "100%",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: action_button_label_color,
                    "&:hover": {
                      color: action_button_label_color,
                    },
                  }}
                >
                  {action_button_label}
                </Button>
              </Col>
            </Modal.Footer>
          </Row>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
}

Popup.defaultProps = {
  // title: "Are you sure you want to remind?",
  // title_color: "black",
  // action_button_label: "YES",
  // action_button_label_color: "white",
  // action_button_color: "#FF8A1F",
  // cancel_button: true,
  // cancel_button_bordercolor: "none",
  // cancel_button_label: "NO",
  // cancel_button_label_color: "black",
  // cancel_button_color: "#fff",
  // footer_color: "#F9FAFA",
  bodyClassName: "m-0 p-0",
  // modalClassName: "my-modal" 
};
