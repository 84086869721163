import React from "react";
// import "../../styles/Profile.css";
import'./switch.css'
import { useState } from "react";


const ToggleSwitch = () => {
  const[check , setCheck]=useState(false);
  const handelcheck=()=>{
    setCheck(!check)
  }
  return (
    <div className="container">
      <label className="switch mt-1" onClick={handelcheck}>
        <input type="checkbox" value="click"/>
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
