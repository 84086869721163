import { Col, Row } from "react-bootstrap";
import Congratulation from "../../Congratulation";
import Overview from "../../Overview";
import DynamicScreenForm from "../../screeningComponent/DynamicScreenForm";

const ScreeningForm = ({ formData: { currentFormIndex, ...data }, prevPage, nextPage, parentPrevRef, parentSubmitRef, isConditionTrue, skipFormCategory }) => {
    const componentToRender = {
      [data?.maxIndex]: (
        <Overview skipFormCategory={skipFormCategory} />
      ),
      [data.maxIndex+1]: (
        <Congratulation />
      )
    };
  
    return (
      <Row>
        <Col className="px-lg-5 text-center">
          {currentFormIndex < data?.maxIndex ? (
            <div>
              <div>
                <div>
                  <h2 className="fw-bolder">
                    {data?.formData?.[currentFormIndex]?.name ?? ""}
                  </h2>
                </div>
                <div>
                  { Boolean(data?.formData?.[currentFormIndex].section.length) && <p className="fs-6">
                    {data?.formData?.[currentFormIndex]?.description ??
                      ""}
                  </p>}
                </div>
                <div>
               { Boolean(data?.formData?.[currentFormIndex].section.length) && <span className="fs-7">(*) means they are mandatory</span>}
                </div>
              </div>
              <div className="screening-render-component">
                <DynamicScreenForm
                  index={currentFormIndex}
                  data={data?.formData?.[currentFormIndex]}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  isConditionTrue={isConditionTrue}
                  parentSubmitRef={parentSubmitRef}
                  parentPrevRef={parentPrevRef}
                />
              </div>
            </div>
        )
          : currentFormIndex === data?.maxIndex ? (
          componentToRender[data?.maxIndex]
        ) : currentFormIndex === data?.maxIndex + 1 ? (
          componentToRender[data?.maxIndex + 1]
        ) : null
      }
          {/* {formIndex >= formData?.maxIndex - 2 &&
            formIndex < formData?.maxIndex && <>{componentToRender[formIndex]}</>} */}
        </Col>
      </Row>
    );
};

export default ScreeningForm;