import React from "react";
import { GreenDiv } from "../../styles/Common.style";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ShowTooltip = ({
  tooltipContent,
  tooltopWidth,
  tooltipLabel = "Show",
}) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#000",
      width: tooltopWidth ? tooltopWidth : 220,
      fontFamily: "Cerebri sans",
      fontSize: "14px",
      border: "1px solid #D1D1D1",
      borderRadius: "8px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
      "&::before": {
        backgroundColor: "#fff",
        border: "1px solid #D1D1D1",
      },
    },
  }));

  return (
    <HtmlTooltip title={<div>{tooltipContent}</div>} arrow>
      {/* <GreenDiv> */}
      <span className="cursor-pointer"> {tooltipLabel} </span>
      {/* </GreenDiv> */}
    </HtmlTooltip>
  );
};

export default ShowTooltip;
