import React from "react";
import "./normalGroupText.css";

const NormalGroupText = ({
  onChange,
  lable,
  value,
  type,
  graylabel,
  textWidth,
  disabled,
  error,
  inputheight,
  textheight,
  squarelabel,
}) => {
  let divWidth = 100 - parseInt(textWidth);
  return (
    <div className="d-flex ">
      <div style={{ width: `${divWidth}%` }}>
        <div>
          <input
            style={{ height: inputheight }}
            type={type}
            placeholder={lable}
            id={lable + graylabel}
            value={value}
            onChange={onChange}
            disabled={disabled}
            error={error}
            className="normalGroupTextStyle"
          />
        </div>
      </div>
      <div
        className="form-input-group-gray"
        style={{ width: textWidth, height: textheight }}
      >
        <span
          style={{
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          {graylabel}
          <sup>{squarelabel}</sup>
        </span>
      </div>
    </div>
  );
};

export default NormalGroupText;

NormalGroupText.defaultProps = {
  inputheight: "40px",
  textWidth: "20",
  type: "text",
};
