import { Button, Col, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { H4 } from "../../styles/Common.style";
import { useEffect, useState } from "react";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import Modal from "../../Components/widgets/modal/Modal";

const FileSaver = require("file-saver");

export const DocumentComponent = ({ componentData, showBtn ,noDataStatus}) => {
  const handleDownloadDocument = (fileUrl, fileName) => {
    FileSaver.saveAs(fileUrl, fileName);
  };

  const [documentDetails, setDocumentDetails] = useState({
    documentUrl: "",
    documentName: "",
    documentId: "",
  });

  useEffect(() => {
    if (Object.keys(componentData ?? {}).length) {
      setDocumentDetails({
        documentUrl: componentData[0]?.file_url,
        documentName: componentData[0]?.title,
        documentId: componentData[0]?.document_id,
      });
    }
  }, [componentData]);

  const [previewModal, setPreviewModal] = useState(false);
  return (
    <>
      <Row className="h-100 position-relative">
        <Col className="p-0">
          <div className="ps-4 py-4 pe-3 h-100">
            {componentData?.length > 0 ? (
              <div className="d-flex flex-column justify-content-between gap-3">
                {componentData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="radius-12 ps-3 py-2"
                      style={{ backgroundColor: "#F7F8FA" }}
                    >
                      <Row className="d-flex justify-content-center align-items-center py-2">
                        <Col sm={1} className="p-0">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "35px",
                              height: "35px",
                              backgroundColor: "#e6f3f3",
                              borderRadius: "5px",
                            }}
                          >
                            <FontAwesomeIcon
                              color="#ADD2D2"
                              size="lg"
                              icon={"fa-solid fa-file-lines"}
                            />
                          </div>
                        </Col>
                        <Col sm={showBtn ? "8" : "9"}>
                          <H4 className="mb-0 ellipsis">{item?.title}</H4>
                          <span
                            className="caption-light grey-text"
                            // className="fs-8 fw-400 mb-0 text-muted"
                          >
                            Uploaded by: {item?.created_by} @
                            {moment(item?.created_on).format(
                              "MMM DD, YYYY | hh:mm "
                            )}
                          </span>
                          <div className="d-flex gap-2 align-items-center fs-10 fw-400 pb-2 pt-1 ">
                            <div
                              className={`radius-4  d-inline-block fs-8 cursor `}
                              style={{
                                backgroundColor: "#E3E5F8",
                                padding: "0.1rem 0.5rem",
                                color: "#2A39AF",
                                border: "1px solid #2A39AF",
                              }}
                              onClick={() => setPreviewModal(true)}
                            >
                              {/* {item?.document_type_name} */}
                              Document
                            </div>
                            <div className="fs-12 ">Size: 3.1 MB</div>
                          </div>
                        </Col>
                        {showBtn && (
                          <Col
                            sm={3}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <Button
                              onClick={() =>
                                handleDownloadDocument(
                                  item?.file_url,
                                  item?.title
                                )
                              }
                              className="portal_btn medium-text-12"
                            >
                              DOWNLOAD
                            </Button>
                          </Col>
                        )}
                      </Row>
                      <Modal
                        open={previewModal}
                        topborder={false}
                        ModalHeader={true}
                        size="xl"
                        className="modal-dialog-centered"
                        onHide={() => {
                          setPreviewModal(false);
                          // setChecked(true);
                        }}
                        title={
                          <div className="d-flex justify-content-between p-3">
                            <div className="d-flex justify-content-center align-items-center gap-3">
                              <div>
                                <div className="Cerebri-sans-medium fs-14">
                                  {documentDetails?.documentName}
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setPreviewModal(false);
                                // setChecked(true);
                              }}
                            >
                              <span
                                className="fs-20 d-flex justify-content-center align-items-center cursor"
                                aria-hidden="true"
                                style={{
                                  height: "44px",
                                  width: "44px",
                                  borderRadius: "8px",
                                  position: "relative",
                                  backgroundColor: "rgb(244, 245, 245)",
                                  border: "none",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-xmark"
                                  style={{ color: "#000000" }}
                                />
                              </span>
                            </div>
                          </div>
                        }
                        title_color="black"
                        onCloseAction={() => {
                          setPreviewModal(false);
                        }}
                      >
                        <div>
                          <Row>
                            <Col className="p-0">
                              <div style={{ height: "500px" }}>
                                <iframe
                                  src={documentDetails?.documentUrl}
                                  height="100%"
                                  width="100%"
                                  title="doument-preview"
                                ></iframe>
                                <div></div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="d-flex align-items-center">
          {currentRow?.original?.read_on == "" ? (
            <>
              <CustomCheckBox
                value={checked}
                id={currentRow?.original?.document_id}
                onChange={() => setChecked(!checked)}
              />
              <div>I have read this document.</div>
            </>
          ) : (
            ""
          )}
        </div> */}
                      </Modal>
                    </div>
                  );
                })}

                {!showBtn && (
                  <div className="p-0">
                    <Button className="bg-light-green border-0 w-100 py-2 fs-4">
                      SIGN DOCUMENT
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center paragraph2-regular">
                {noDataStatus ? noDataStatus : "No documents found"}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

DocumentComponent.defaultProps = {
  showBtn: true,
};
