import React from "react";
import { Col, Image, Row } from "react-bootstrap";

const FileUploadModal = ({ value }) => {

  return (
    <div>
      <Row>
        <Col className="p-0">
          <div
            style={{
              height: "500px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {value?.type?.includes("image") ? (
              <div style={{ width: "100%", height: "100%" }}>
                <Image
                  rounded
                  src={value?.preview}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            ) : (
              <iframe
                src={URL?.createObjectURL(value)}
                title="Embedded PDF"
                style={{ width: "100%", height: "100%", border: "none" }}
              ></iframe>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FileUploadModal;
