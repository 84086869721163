import {
  Button,
  Col,
  Row,
  Image,
  Accordion,
  useAccordionButton,
  ProgressBar,
  Card as accordionCard,
} from "react-bootstrap";
import "../../styles/leadhome.css";
import unitImg from "../../assets/images/unit-image.jpg";
import { Card } from "../../Components/card/Card";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import leadHomePageReadingGuy from "../../assets/images/lead-homepage-reading-guy.svg";
import { DocumentComponent } from "./DocumentComponent";
import { RecentActivityComponent } from "./RecentActivityComponent";
import { MessagesComponent } from "./MessageComponent";
import Faqcard from "../help/Faqcard";
import wavingGuyImg from "../../assets/images/illustration-waving-guy.svg";

// dummy data have to remove after api done
import { RecentActivityData } from "../../utils/dummy-data/home-page-data/lead-homepage";
import { signInRecentActivityData } from "../../utils/dummy-data/home-page-data/lead-homepage";
import { leadDocumentComponentData } from "../../utils/dummy-data/home-page-data/lead-homepage";
import { MessageComponentData } from "../../utils/dummy-data/home-page-data/lead-homepage";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import { Guides } from "./guides/Guides";
import { useGetScreeningTemplateQuestionAnswer } from "../../hooks/queries/useScreeningQueries";
import cancelHeaderImage from "../../assets/icons/cancel-screening.svg";
import {
  useScreeningFormPopup,
  useScreeningFormStore,
  useScreeningFormsAnswerData,
  useScreeningFormsNames,
} from "../../hooks/stores/useScreeningFormStore";
import Countdown from "react-countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomCheckBox from "../../Components/input/Checkbox/Checkbox";
import ShowTooltip from "./../../Components/Tooltip/ShowTooltip";
import FileSaver from "file-saver";
import { H2, H3, H4 } from "../../styles/Common.style";
import { myPage } from "../../styles/Theme";
import PortalButton from "../../Components/input/normalButton/PortalButton";
import Modal from "../../Components/widgets/modal/Modal";
import {
  useUnitId,
  useUnitInformationStore,
} from "../../hooks/stores/useUnitStore";
import { useState } from "react";
import CancelScreeningModal from "../screening/screeningModal/CancelScreeningModal";
import moment from "moment";

export const LeadHome = ({
  leadType,
  getActorProfileData,
  selectedUnitData,
}) => {
  const screeningFormAnswerData = useScreeningFormsAnswerData(
    (state) => state.screeningFormAnswerData
  );

  const screeningFormNames = useScreeningFormsNames(
    (state) => state.screeningFormNames
  );

  const setScreeningFormPopup = useScreeningFormPopup(
    (state) => state.setScreening_Form_Popup
  );

  const { UnitId } = useUnitId((state) => state);

  const { unitInformation } = useUnitInformationStore((state) => state);

  // console.log(getActorProfileData, unitInformation[UnitId],'unitInformation')

  const [openCancelScreening, setOpenCancelScreening] = useState(false);

  const {
    data: GetScreeningTemplateQuestionAnswerData,
    refetch: getScreeningTemplateQuestionAnswerRefetch,
  } = useGetScreeningTemplateQuestionAnswer(
    selectedUnitData?.property_id,
    selectedUnitData?.contract_id,
    getActorProfileData?.data?.[0]?.actor_id
  );

  const screeningFormIcons = {
    Information: "fa-solid fa-user",
    Occupation: "fa-solid fa-briefcase",
    Economy: "fa-solid fa-building-columns",
    Household: "fa-solid fa-house-user",
    Identification: "fa-solid fa-passport",
    "Additional information": "fa-solid fa-circle-info",
    Overview: "",
    Confirmation: "",
  };

  // custom button for lead form -> accordion
  function CustomToggle({ children, eventKey, status }) {
    let bgColr = "#d1d1d1b5";
    if (status === "edit") bgColr = "#D1D1D1";
    else if (status === "fill out") bgColr = "#ADBE9E";
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <Button
        type="button"
        className="fw-500 fs-8 d-block ms-auto text-uppercase"
        style={{ border: "none", width: "80px", background: "#ADBE9E" }}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    );
  }

  return (
    <div className="home-container">
      <Row className="gap-3 gap-xl-0">
        <Col xl={6} sm={12}>
          <Row>
            {/* lead Home section  */}
            <Col>
              <Row>
                {/* user Home section  */}
                <Col>
                  <Row className="pt-3 align-items-xl-start">
                    <Col className=" pt-4 primary-text-color" lg={7} sm={12}>
                      <H2
                        color={myPage.primaryTextColor}
                        className="font-bold fs-22"
                        // style={{ color: myPage.primaryTextColor }}
                      >
                        Hi {"User"}, Good to see you!
                      </H2>
                      <div className="paragraph2-regular mb-4 screening-navy-text">
                        <div className="mb-2">
                          You still have time left to finish the screening for
                        </div>
                        <div className="mb-2">Blomsterbjerget</div>
                        <div className="mb-2">
                          made it easy for you! Click the button below to finish
                          the screening.
                        </div>
                      </div>
                    </Col>
                    <Col className="order-1 order-lg-2 px-0" lg={5} sm={12}>
                      <Image
                        className="h-100 mx-auto d-block"
                        fluid
                        src={wavingGuyImg}
                        alt="waving guy"
                        style={{ objectFit: "cover" }}
                      ></Image>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* lead Home section  */}

        {/* recent activity section  */}
        <Col xl={6} sm={12}>
          <Card
            path="recent-activity"
            label="Recent activity"
            Component={RecentActivityComponent}
            data={
              leadType !== "signin"
                ? RecentActivityData
                : signInRecentActivityData
            }
          />
        </Col>
        {/* recent activity section  */}
      </Row>

      {/* finish screening */}
      {leadType !== "signin" && (
        <Row className="mt-4 card-box mx-sm-2 mx-md-3">
          <Col className="px-0">
            <div
              className="radius-8"
              style={{ background: "white", overflow: "hidden" }}
            >
              <div className="d-flex fs-8 fw-400 p-3 align-items-center bottom-border-line">
                <div className="me-2" style={{ width: "20px" }}>
                  <CircularProgressbar
                    styles={buildStyles({
                      pathColor: "#77C970",
                      textColor: "black",
                      textSize: "1.5rem",
                      strokeWidth: 1,
                    })}
                    value={76}
                    strokeWidth={15}
                  />
                </div>
                <H3 className="mb-0" color={myPage.primaryTextColor}>
                  Start your screening
                </H3>
              </div>
              <Row className="py-4 px-3 gap-3 gap-lg-0 d-flex justify-content-start align-items-center">
                <Col xs={12} xl={6} className="px-0 ">
                  <div>
                    {unitInformation?.[UnitId]?.image == "" ? (
                      <div
                        style={{ height: "150px", border: "1px solid #888" }}
                        className=" radius-4 grey-text d-flex justify-content-center align-items-center"
                      >
                        Property image is not available
                      </div>
                    ) : (
                      <div
                        className="radius-8"
                        style={{
                          backgroundImage: `url(${unitInformation?.[UnitId]?.image})`,
                          height: "150px",
                          width: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          boxSizing: "border-box",
                        }}
                      ></div>
                    )}
                    <Row className="my-3 grey-text">
                      <Col lg={5} md={12}>
                        <H4 className="mb-0">
                          {unitInformation?.[UnitId]?.property_name}
                        </H4>

                        <p className="mb-0 paragraph2-light grey-text">
                          {unitInformation?.[UnitId]?.street_name}{" "}
                          {unitInformation?.[UnitId]?.house_number},{" "}
                          {unitInformation?.[UnitId]?.floor_number}
                        </p>
                        <p className="paragraph2-light grey-text mb-0">
                          {unitInformation?.[UnitId]?.zipcode_id}{" "}
                          {unitInformation?.[UnitId]?.city}
                        </p>
                        <p className="paragraph2-light grey-text mb-0">
                          {unitInformation?.[UnitId]?.country}
                        </p>
                      </Col>

                      <Col lg={7} md={12} className="property-details p-0">
                        <div className="d-flex justify-content-between screening-border py-1">
                          <span className="paragraph2-light">
                            <span className="pe-3 ps-1 py-1">
                              <FontAwesomeIcon
                                icon="fak fa-square-meter-light-3"
                                size="lg"
                              />
                            </span>{" "}
                            Size
                          </span>
                          <div className="paragraph2-regular">
                            {unitInformation?.[UnitId]?.area ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${unitInformation?.[UnitId]?.area} ${unitInformation?.[UnitId]?.square_measure_unit}`,
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between screening-border py-1">
                          <span className="paragraph2-light">
                            <span className="pe-3 ps-1 py-1">
                              <FontAwesomeIcon
                                icon="fa-regular fa-bed-front"
                                size="lg"
                              />
                            </span>{" "}
                            Rooms
                          </span>
                          <span className="paragraph2-regular">
                            {unitInformation?.[UnitId]?.no_of_rooms
                              ? unitInformation?.[UnitId]?.no_of_rooms +
                                " " +
                                (unitInformation?.[UnitId]?.no_of_rooms > 1
                                  ? "rooms"
                                  : "room")
                              : "-"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between screening-border py-1">
                          <span className="paragraph2-light nowrap">
                            <span className="pe-3 ps-1 py-1">
                              <FontAwesomeIcon
                                icon="fa-regular fa-piggy-bank"
                                size="lg"
                              />
                            </span>{" "}
                            Monthly rent
                          </span>
                          <span className="paragraph2-regular">
                            {unitInformation?.[UnitId]?.rent
                              ? unitInformation?.[UnitId]?.rent +
                                " " +
                                unitInformation?.[UnitId]?.currency_id
                              : "-"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between screening-border py-1">
                          <span className="paragraph2-light">
                            <span className="pe-3 ps-1 py-1">
                              <FontAwesomeIcon
                                icon="fa-regular fa-house-water"
                                size="lg"
                              />
                            </span>{" "}
                            Move-in
                          </span>
                          <span className="paragraph2-regular">
                            {unitInformation?.[UnitId]?.move_in_date
                              ? moment(unitInformation?.[UnitId]?.move_in_date).format(
                                  "DD.MM.YY"
                                )
                              : "-"}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} xl={6}>
                  <Row className="align-items-center gap-3 gap-lg-0 justify-content-center">
                    <Col xs={12} sm={5}>
                      <div>
                        <H4 className="text-center">Screen fulfillment</H4>
                        <div
                          className="mx-auto"
                          style={{ width: "60%", maxWidth: "140px" }}
                        >
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: "#77C970",
                              textColor: "black",
                              textSize: "1.5rem",
                            })}
                            value={
                              GetScreeningTemplateQuestionAnswerData?.data?.[0]
                                ?.completion_percentage ?? 0
                            }
                            text={`${
                              GetScreeningTemplateQuestionAnswerData?.data?.[0]
                                ?.completion_percentage ?? 0
                            }%`}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      sm={7}
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      {/* <NavLink to="/screening"> */}
                      {/* <Button
                        className="d-block mx-auto fw-500 finish-screen-btn fs-7 px-2 px-md-5 py-2 py-md-3"
                        onClick={() => {
                          // if(GetScreeningTemplateQuestionAnswerData?.data?.[0]?.completion_percentage === 100) {
                          //   setScreening_Form_State({
                          //     ...Screening_Form_State,
                          //     selectedIndex: Screening_Form_State.maxIndex - 2,
                          //     currentActiveIndex: 0,
                          //     filledQuestion: Screening_Form_State.totalQuestion,
                          //   })
                          // }
                          // if (
                          //   GetScreeningTemplateQuestionAnswerData?.data?.[0]
                          //     ?.completion_percentage !== 100
                          // )
                            setScreeningFormPopup(true);
                        }}
                      >
                        {GetScreeningTemplateQuestionAnswerData?.data?.[0]
                          ?.completion_percentage === 100
                          ? "COMPLETED"
                          : GetScreeningTemplateQuestionAnswerData?.data?.[0]
                              ?.completion_percentage > 60
                          ? "FINISH SCREENING"
                          : "START SCREENING"}
                      </Button> */}
                      <PortalButton
                        fontSize="16px"
                        width="fit-content"
                        buttonText="START SCREENING"
                        variant="primary"
                        onClick={() => {
                          // if(GetScreeningTemplateQuestionAnswerData?.data?.[0]?.completion_percentage === 100) {
                          //   setScreening_Form_State({
                          //     ...Screening_Form_State,
                          //     selectedIndex: Screening_Form_State.maxIndex - 2,
                          //     currentActiveIndex: 0,
                          //     filledQuestion: Screening_Form_State.totalQuestion,
                          //   })
                          // }isMobile
                          if (
                            GetScreeningTemplateQuestionAnswerData?.data?.[0]
                              ?.completion_percentage !== 100
                          ) {
                            setScreeningFormPopup(true);
                          }
                        }}
                        // disabled={
                        //   GetScreeningTemplateQuestionAnswerData?.data?.[0]
                        //     ?.completion_percentage !== 100
                        // }
                        // disabled={true}
                      />
                      <div className="m-0 d-flex justify-content-center flex-wrap fs-8 mt-2">
                        <span className="gray paragraph2-regular">
                          Time to finish your screening:
                        </span>
                        <span className="ms-1 text-dark paragraph2-regular">
                          <Countdown
                            date={1696075241000}
                            daysInHours
                            zeroPadTime={2}
                            onComplete={() => <>Screening expired</>}
                            className="d-flex"
                          />
                        </span>
                      </div>
                      <div
                        // onClick={() => setOpenCancelScreening(true)}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCancelScreening(true);
                        }}
                        className="
                        justify-content-center d-flex gap-2 mt-3 
                         cursor paragraph2-regular"
                      >
                        <span className="screening-green-text">
                          Cancel screening
                        </span>
                      </div>
                      <Modal
                        open={openCancelScreening}
                        topborder={false}
                        modalPosition="top"
                        className="modal-dialog-centered"
                        onHide={() => setOpenCancelScreening(false)}
                        size={"md"}
                        title={
                          <div>
                            <div onClick={() => setOpenCancelScreening(false)}>
                              <FontAwesomeIcon
                                className="p-2 cursor radius-6 m-3"
                                icon="fa-regular fa-xmark"
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  backgroundColor: "#fff",
                                  height: "24px",
                                  width: "24px",
                                }}
                              />
                            </div>
                            <img
                              style={{
                                borderRadius: "8px 8px 0  0 ",
                                // width: "100%",
                                // height: "92px",
                                width: "100%",
                                objectFit: "none",
                              }}
                              alt=""
                              src={cancelHeaderImage}
                            />
                          </div>
                        }
                        // title={
                        //   <div className="d-flex justify-content-between p-3">
                        //     <div className="d-flex justify-content-center align-items-center gap-3">
                        //       <div>
                        //         <div className="font-weight-bold fs-16">
                        //           Your screening has been saved
                        //         </div>
                        //       </div>
                        //     </div>
                        //   </div>
                        // }
                        title_color="black"
                        onCloseAction={() => {
                          setOpenCancelScreening(false);
                          alert("Action Peformed Successfuly.");
                        }}
                      >
                        <CancelScreeningModal
                          setOpenCancelScreening={setOpenCancelScreening}
                        />
                      </Modal>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          {/* finish screening */}
        </Row>
      )}

      <div>
        {leadType === "signin" && (
          <Row>
            {/* document sign in */}
            <Col
              xl={6}
              sm={12}
              className="primary-text-color position-relative"
            >
              <Card
                label="Documents ready for signing"
                Component={DocumentComponent}
                leadType={leadType}
                data={leadDocumentComponentData}
                showBtn={false}
              />
              <div className="px-0 lead-home-guy position-absolute d-none d-xl-block">
                <Image
                  className="d-block"
                  fluid
                  src={leadHomePageReadingGuy}
                  alt="welcome page guy"
                  style={{ objectFit: "cover" }}
                ></Image>
              </div>
            </Col>
            {/* document sign in */}

            {/* message card */}
            <Col xl={6} sm={12} className="primary-text-color">
              <Card
                label="Messages"
                path="messages"
                Component={MessagesComponent}
                data={MessageComponentData}
              />
            </Col>
            {/* message card */}
          </Row>
        )}

        {GetScreeningTemplateQuestionAnswerData?.data?.[0]
          ?.completion_percentage === 100 && (
          <Row className="py-2 mt-5">
            <completion_percentage className="px-0">
              {/* <form> */}
              {Object.values(screeningFormNames ?? {})?.map((data, i) => {
                let currentFormData;
                let totalQuestion;
                let answeredQuestion;
                if (screeningFormAnswerData?.data?.length) {
                  currentFormData = screeningFormAnswerData?.data.filter(
                    (d) => d?.category_name === data
                  );
                  totalQuestion = screeningFormAnswerData?.data.filter(
                    (d) => d?.category_name === data
                  ).length;
                  answeredQuestion = currentFormData.filter(
                    (c) => c?.answer_value.length
                  ).length;
                }
                return (
                  <div key={i}>
                    {i < Object.keys(screeningFormNames ?? {}).length - 2 && (
                      <Accordion
                        className="my-2"
                        // onSelect={(e, data) => onHandleAccordion(e, data, i)}
                      >
                        <accordionCard className="border-0">
                          <accordionCard.Header
                            className={`${
                              // activeAccordion?.[leadPersonalDetailsData[i]?.type]
                              // ? "background-primary"
                              // :
                              "bg-white"
                            } my-2 border-bottom-0 py-2 px-3`}
                          >
                            <Row className="align-items-center gap-2 gap-sm-0">
                              <Col
                                className="d-flex px-0 align-items-center flex-wrap"
                                sm={4}
                                lg={4}
                              >
                                <div
                                  className="d-flex justify-content-center align-items-center me-3 p-2 position-relative"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#d5d7e3",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={screeningFormIcons[data]}
                                  />
                                  {/* {data.status === "edit" && (
                                    <FontAwesomeIcon
                                      className="position-absolute lead-checkbox-color"
                                      icon="fa-solid fa-circle-check"
                                      style={{ top: "-5px", right: "-6px" }}
                                    />
                                  )} */}
                                  {answeredQuestion === totalQuestion && (
                                    <FontAwesomeIcon
                                      className="position-absolute lead-checkbox-color"
                                      icon="fa-solid fa-circle-check"
                                      style={{ top: "-5px", right: "-6px" }}
                                    />
                                  )}
                                </div>
                                <div className="lh-1">
                                  <h4 className="mb-0 fw-500 fs-7">{data}</h4>
                                  <span className="fw-500 fs-9 text-muted">
                                    Done
                                  </span>
                                </div>
                              </Col>
                              <Col className="px-0" sm={8} lg={4}>
                                <div>
                                  <ProgressBar
                                    className="lead-loader-color"
                                    now={
                                      (answeredQuestion / totalQuestion) * 100
                                    }
                                  />
                                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                      <p className="d-inline-block mb-0 me-2 fs-500 fs-8">
                                        {answeredQuestion} /{totalQuestion}
                                      </p>
                                      <span className="fs-500 fs-8 text-muted">
                                        Total task
                                      </span>
                                    </div>
                                    <div>
                                      <p className="fs-500 fs-8 mb-0">
                                        {Math.floor(
                                          (answeredQuestion / totalQuestion) *
                                            100
                                        )}
                                        %
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col className="px-0" lg={4}>
                                <CustomToggle
                                  style={{ background: "red" }}
                                  eventKey={i}
                                  status={data.status}
                                >
                                  View
                                </CustomToggle>
                              </Col>
                            </Row>
                          </accordionCard.Header>
                          <Accordion.Collapse
                            className={`bg-white mt-1 `}
                            eventKey={i}
                          >
                            <accordionCard.Body>
                              <Row className="reservation-form">
                                <Col className="ps-lg-4" lg={12}>
                                  {currentFormData?.map((cf) => {
                                    return (
                                      <div
                                        className="occupation-accordion-box"
                                        key={cf?.screening_question_id}
                                      >
                                        {cf.question_type_name === "text" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input d-flex align-items-center gap-2">
                                                    <input
                                                      type="text"
                                                      placeholder={`${cf.placeholder}`}
                                                      id={cf.label_name}
                                                      value={cf.answer_value}
                                                    />
                                                    {/* <span className="text-body">{cf.answer_value}</span>
                                                <span className="d-flex flex-row gap-2">
                                                <ShowTooltip
                                                  tooltipLabel={
                                                    <Button
                                                      className="p-0 screening-accordion-icons"
                                                      onClick={() => {
                                                        FileSaver.saveAs(cf.file_url, cf.answer_value)
                                                      }}
                                                    >
                                                      <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                                                    </Button>
                                                  }
                                                  tooltopWidth={75}
                                                  tooltipContent={
                                                    <div className="fs-12">Download</div>
                                                  }
                                                />
                                              </span> */}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}
                                        {cf.question_type_name === "file" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input d-flex align-items-center gap-2">
                                                    {/* <input
                                                  type="text"
                                                  placeholder={`${cf.placeholder}`}
                                                  id={cf.label_name}
                                                  value={cf.answer_value}
                                                /> */}
                                                    <span className="text-body">
                                                      {cf.answer_value}
                                                    </span>
                                                    <span className="d-flex flex-row gap-2">
                                                      <ShowTooltip
                                                        tooltipLabel={
                                                          <Button
                                                            className="p-0 screening-accordion-icons"
                                                            onClick={() => {
                                                              FileSaver.saveAs(
                                                                cf.file_url,
                                                                cf.answer_value
                                                              );
                                                            }}
                                                          >
                                                            <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                                                          </Button>
                                                        }
                                                        tooltopWidth={75}
                                                        tooltipContent={
                                                          <div className="fs-12">
                                                            Download
                                                          </div>
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}
                                        {cf.question_type_name === "radio" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input">
                                                    <input
                                                      type="text"
                                                      placeholder={`${cf.placeholder}`}
                                                      id={cf.label_name}
                                                      value={cf?.answer_text}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}

                                        {cf.question_type_name === "number" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input">
                                                    <input
                                                      type="text"
                                                      placeholder={`${cf.placeholder}`}
                                                      id={cf.label_name}
                                                      value={cf.answer_value}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}
                                        {cf.question_type_name === "date" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input">
                                                    <input
                                                      type="text"
                                                      placeholder={`${cf.placeholder}`}
                                                      id={cf.label_name}
                                                      value={cf.answer_value}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}
                                        {cf.question_type_name === "select" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input">
                                                    <input
                                                      type="text"
                                                      placeholder={`${cf.placeholder}`}
                                                      id={cf.label_name}
                                                      value={cf.answer_text}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}
                                        {cf.question_type_name ===
                                          "textarea" && (
                                          <Row className="occupation-accordion-form my-3">
                                            <Col xs={5}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={7}>
                                              <Row>
                                                <Col
                                                  lg={6}
                                                  className="pe-lg-0 w-100"
                                                >
                                                  <div className="form-input">
                                                    <input
                                                      type="text"
                                                      placeholder={`${cf.placeholder}`}
                                                      id={cf.label_name}
                                                      value={cf.answer_value}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        )}
                                        {cf.question_type_name ===
                                          "checkbox" && (
                                          <Row>
                                            <Col xs={7}>
                                              <label className="fw-500 fs-7 d-inline-block text-dark">
                                                {cf?.label_name}
                                              </label>
                                            </Col>
                                            <Col xs={5}>
                                              {cf.option_value.map((opt) => {
                                                return (
                                                  <div
                                                    key={
                                                      opt?.screening_question_option_id
                                                    }
                                                    className="d-flex align-items-center gap-2 px-2"
                                                  >
                                                    <div>
                                                      {/* <CustomCheckBox value={value} {...field} onChange={(e)=> setValue(name, e.target.checked)} id={data?.screening_question_id}/> */}
                                                      <CustomCheckBox
                                                        checked={Boolean(
                                                          cf?.answer_value
                                                        )}
                                                        //  checked={Boolean(cf?.answer_value)}
                                                        //  onChange={(e) => {
                                                        //    // if(e.target.checked)
                                                        //    setValue(name, opt?.screening_question_option_id);
                                                        //  }}
                                                      />
                                                    </div>
                                                    <div className="medium-text-14 text-dark">
                                                      {opt.option_value}
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </Col>
                                          </Row>
                                        )}
                                      </div>
                                    );
                                  })}
                                </Col>
                              </Row>

                              {/* <Button
                                type="button"
                                className="fw-500 fs-8 d-block ms-auto bg-green"
                                style={{
                                  border: "none",
                                  width: "80px",
                                }}
                              >
                                Save
                              </Button> */}
                            </accordionCard.Body>
                          </Accordion.Collapse>
                        </accordionCard>
                      </Accordion>
                    )}
                  </div>
                );
              })}
              {/* </form> */}
            </completion_percentage>
          </Row>
        )}

        <Row className="mb-5">
          <Col>
            <Faqcard />
          </Col>
        </Row>

        {leadType === "signin" && (
          <Row>
            <Col>
              <Guides />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
