import styled from "styled-components";
import unitInfo from "../../../../utils/dummy-data/screening-dummy-data/unit-info";
import { Button, Col, Image } from "react-bootstrap";
import unitImage from "../../../../assets/images/unit-square-image.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useScreeningFaqPopup, useScreeningFormStore } from "../../../../hooks/stores/useScreeningFormStore";

const ScreeningInfo = ({ getIndex, setIndex }) => {
    const Screening_Form_State = useScreeningFormStore(
    (state) => state.Screening_Form_State
    );
    const setScreening_Form_State = useScreeningFormStore(
    (state) => state.setScreening_Form_State
    );
    const setScreeningFaqPopup = useScreeningFaqPopup(
        (state) => state.setScreeningFaqPopup
    );

    return (
        <Layout>
            {/* <Header/> */}
            <Main >
            <Section1 className="mt-2">
                <div style={{width:'90%', textAlign:'center'}}>
                    <Heading className="mb-3 mt-4">
                    Welcome to the screening
                    </Heading>
                    <Image
                    src={unitImage}
                    alt='unit-square-image'
                    style={{ objectFit: "cover", borderRadius: "8px", width:'100%' }}
                    />
                    <Para className="my-3">
                    Below, you will find the apartment you wish to rent and that we will screen based on. 
                    This is also the property you will be approved to rent if we can approve the screening.
                    </Para>
                </div>
            </Section1>
            <Section2>
            <div style={{width:'90%'}}>
                <Col className="mt-5 mb-5">
                    <div>
                        <div className="bg-body rounded p-xl-5">
                            <div>
                                <div className="fw-normal fs-20">
                                    Blomsterbjerget
                                </div>
                                <div>
                                    <span className="text-black-50 w-75 d-inline-block">
                                    Blomstervangen 14, 2.tv
                                    2765 Smørum
                                    Denmark
                                    </span>
                                </div>
                            </div>
                            <div className="mt-4 pb-5">
                                {
                                    unitInfo.map((ui, uiIndex) => {
                                        return (
                                            <div key={uiIndex} className={`${!uiIndex && 'border-top'} border-bottom d-flex justify-content-between flex-wrap py-2`}>
                                                <div className="d-flex">
                                                    <div className="d-flex me-2 me-xl-3 justify-content-center align-items-center">
                                                        <div style={{ width: '20px', height: '20px'}}>
                                                            <FontAwesomeIcon className="w-100 h-100" icon={ui.icon} />
                                                        </div>
                                                    </div>
                                                    <div className="fs-6">
                                                        {ui.name}
                                                    </div>
                                                </div>
                                                <div className="d-flex fs-14">
                                                    <p className="mb-0 fw-bold">{ui.data}</p>
                                                    { Boolean(ui.postfix_data.length) && <span className="ms-1 fw-light"> {ui.postfix_data}</span>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
            </Section2>
            </Main>
            <Footer>
                {/* <p className = 'text-center fs-7 my-2'>{(Screening_Form_State.currentFormCategorySubIndex + 1 > Screening_Form_State.totalQuestion ? Screening_Form_State.totalQuestion  : Screening_Form_State.currentFormCategorySubIndex + 1   ) ?? 0}/{Screening_Form_State.totalQuestion ?? 0}</p> */}
                
                <div>
                    {/* <p className = 'text-center fs-7'>3/21</p> */}
                    <NeedHelpdiv onClick={() => setScreeningFaqPopup(true)}>
                    <FontAwesomeIcon
                        color="#BB9F83"
                        icon="fa-solid fa-circle-question"
                        />
                    </NeedHelpdiv>
                </div>
                <InnerSection>
                    <div style={{width:'80%'}}>
                    {/* <div style={{color:'white', display:'flex', flexDirection:'row', justifyContent:'center', padding:'10px 0'}}>
                        <FontAwesomeIcon
                        color="#ffffff"
                        icon="fa-regular fa-bookmark"
                        style={{ width: "20px", height: "20px" }}
                        />
                        Save and finish later
                    </div> */}
                    <Box sx={{
                            height      : "8px",
                            position    : "relative",
                            background  : "#D0D1DE",
                            borderRadius: "10px",
                            // left: "88%",
                            // top: "26%",
                            width: "100%",
                            // transform: "translateX(-50%)",
                            transition: "0.5s",
                            '::before': {
                            position    : 'absolute',
                            content     : '""',
                            height      : "8px",
                            borderRadius: "10px",
                            transition  : " 0.5s",
                            top         : 0,
                            width       : '5%',
                            // width       : `${((Screening_Form_State.currentFormCategorySubIndex + 1)/Screening_Form_State.totalQuestion) * 100}%`,
                            background  : '#54A64D',
                            }
                        }}>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'space-between', padding:'20px 0 15px 0'}}>
                        <Button style={{width:'30%', border:'none', backgroundColor:'#fff', borderRadius:'6px', color: '#000', fontWeight: '600' }} onClick={() => {setIndex({
                            ...getIndex,
                            prevIndex: 1
                        })}}>
                            Back
                        </Button>
                        <Button style={{width:'60%', border:'none', backgroundColor:'#ADBE9E', borderRadius:'6px'}} onClick={() => {
                               setScreening_Form_State({
                                ...Screening_Form_State,
                                screeningIndex: 1
                            })
                        }}>
                            Start Screening
                        </Button>
                    </Box>
                    </div>
                </InnerSection>
            </Footer>  
    </Layout>
    )
}

const Layout = styled.div`
  position:absolute;
  z-index: 1000;
  background:#F7F8FA;
  // height: 100%; 
  // width : 100%;
  top: 0px;
  right: 0px;
  // height: 100vh;
`

// const Header = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 40px;
//   background: #111747;
//   border-radius: 10px 10px 0 0;
// `
const Main = styled.div`
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  height:100%;
  margin:10px 0 130px 0;
`
const Section1 = styled.div`
  // height:55%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`
const Heading = styled.h1`
    color:black;
    font-weight: 700;
    width: 300px;
    margin: auto;
`
const Para = styled.p`
    color:black;
    font-size: 14px;
    margin: 5px 0 0 0;
`
const InnerSection = styled.div`
  // height:65%;
  // width:100%;
  padding-top: 3rem;
  padding-bottom: 1rem;
   background: #0C1356;
   border-top-right-radius: 30px;
   border-top-left-radius: 30px;
  // position: absolute;
  // bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`
const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height:130px;
  background: #ffff;
`

const ButtonDiv = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  width: 80%;
`
const Section2 = styled.div`
  width: 100%;
    border-top-right-radius: 30px;
   border-top-left-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: #fff;
  border-top: 1px solid #D1D1D1;
`
const NeedHelpdiv = styled.div`
  position: absolute;
  right: 15px;
  top: -50px;
  width: 40px;
  height: 40px;

  & svg {
    width: 100%;
    height: 100%;
  }
`

export default ScreeningInfo;