import * as React from "react";
import { useState, useEffect, useRef } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chatGuyImg from "../../assets/images/chatGuyImg.png";
import moment from "moment";
import ScreeningNeedHelpChatbox from "../screening/screeningModal/ScreeningNeedHelpChatbox";
import ScreeningNeedHelp from "../screening/screeningModal/ScreeningNeedHelp";
import {
  useScreeningMessageModal,
  useScreeningNeedHelpChatbox,
} from "../../hooks/stores/useScreeningFormStore";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import ScreeningMobileFAQ from "../screening/screeningModal/ScreeningMobileFAQ";

export default function Messages() {
  const [fpHash, setFpHash] = useState("");
  const current = new Date();
  const prior = new Date().setDate(current.getDate() + 14);
  // console.log(new Date(prior).toDateString());
  // console.log(new Date(current).toDateString());

  const expdate = new Date(prior).toDateString();
  const logdate = current.toDateString();
  const [expiredate, setExpireDate] = useState({ expdate });
  // console.log("expiredate", expiredate);
  const [logindate, setLoginDate] = useState({ logdate });
  // console.log("logindate", logindate);
  const [timezone, setTimeZone] = useState("");

  const userLinks = [
    {
      icon: "fa-solid fa-comment-lines",
      tabName: "DMs",
    },
    {
      icon: "fa-solid fa-comment-lines",
      tabName: "Unread",
    },
    {
      icon: "fa-solid fa-user-group",
      tabName: "Groups",
    },
    {
      icon: "fa-regular fa-headphones",
      tabName: "Help",
    },
    {
      icon: "fa-solid fa-comment-lines",
      tabName: "Activity",
    },
  ];

  const [msg, setMsg] = useState([
    {
      name: "Lisa Linda",
      issue: "Update: kuchbhi connectivity",
      time: "21 May, 2023 | 08:28",
      isSelected: false,
      isRead: false,
    },
    {
      name: "John Doe",
      issue: "Update: Internet connectivity",
      time: "22 May, 2023 | 10:15",
      isSelected: false,
      isRead: false,
    },
    {
      name: "Alice Johnson",
      issue: "Update: HVAC maintenance",
      time: "23 May, 2023 | 14:45",
      isSelected: false,
      isRead: false,
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:142 ~ ScreeningNeedHelpChatbox2 ~ inputValue:", inputValue)
  const [dataArray, setDataArray] = useState([]);
  // console.log("dataArray", dataArray);
  const chatContainerRef = useRef(null);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const formattedDate = currentDateTime.format("D MMM, YYYY | HH:mm");
  const [selectedFile, setSelectedFile] = useState(null);
  const [hideSearch, setHideSearch] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [showChatbox, setShowChatbox] = useState(false);
  const [showRecipients, setShowRecipients] = useState(true);
  const isAllMessagesRead = msg.every((message) => message.isRead);

  const setScreeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.setScreeningNeedHelpChatbox
  );
  const screeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.screeningNeedHelpChatbox
  );
  const setScreeningMessageModal = useScreeningMessageModal(
    (state) => state.setScreeningMessageModal
  );
  const handleClick = (tabName) => {
    if (selectedIcon === tabName) {
      // If the clicked icon is already selected, deselect it
      setSelectedIcon(null);
    } else {
      // If a different icon is clicked, select it
      setSelectedIcon(tabName);
    }
  };

  const simulateBotResponse = (userMessage) => {
    return `You said "${userMessage}"`;
  };

  const toggleSelection = (index) => {
    // Create a copy of the messages array
    const updatedMsg = [...msg];

    // Unselect all messages first
    updatedMsg.forEach((message) => {
      message.isSelected = false;
    });

    // Select the clicked message
    updatedMsg[index].isSelected = true;

    // Set the updated messages back to the state
    setMsg(updatedMsg);
  };

  const handleDeleteChat = (index) => {
    // Use the filter method to exclude the selected chat
    const updatedMsg = msg.filter((message, i) => i !== index);

    // Set the updated messages back to the state
    setMsg(updatedMsg);
  };
  const isMedium = window.innerWidth >= 768;

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setSelectedFile(file);
    // Display the file name in the textarea
    // setInputValue(file ? file.name : '');
    const userMessage = inputValue;
    const newMessages = [
      ...dataArray,
      { text: userMessage, user: "You", file: file },
    ];

    // Add the user's message to the state
    setDataArray(newMessages);
  };

  const handleEnterPress = () => {
    if (inputValue.trim() !== "") {
      const userMessage = inputValue;
      const newMessages = [
        ...dataArray,
        { text: userMessage, user: "You", file: selectedFile },
      ];

      // Add the user's message to the state
      setDataArray(newMessages);

      // Simulate a bot response
      // botResponse();
      const botResponse = simulateBotResponse(userMessage);
      const updatedMessages = [
        ...newMessages,
        { text: botResponse, user: "Bot" },
      ];

      // Add the bot's response to the state
      setDataArray(updatedMessages);

      setInputValue("");
      setSelectedFile(null);
    }
  };

  const handleMessageClick = (index) => {
    toggleSelection(index);

    // Set the isRead property to true when the message is clicked
    const updatedMsg = [...msg];
    updatedMsg[index] = { ...updatedMsg[index], isRead: true };
    setMsg(updatedMsg);
  };

  function getInitials(name) {
    const words = name.split(" ");
    const initials = words.map((word) => word[0].toUpperCase()).join("");
    return initials;
  }

  useEffect(() => {
    // Scroll to the bottom of the chat container when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [dataArray]);

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };

    setFp();
  }, []);

  //   useEffect(() => {
  //     const setTm = async () => {
  //       const tm = await FingerprintJS.load();
  //       const { components } = await tm.get();
  //       setTimeZone(components);
  //     };

  //     setTm();
  //   }, []);
  // console.log("timezone" ,timezone)
  return (
    <div>
      {/* <h1>Messages</h1> */}
      {/* <ScreeningMobile/> */}
      {/* <ScreeningNeedHelpChatbox/> */}
      {/* <PreScreeningMobilepage/> */}
      {/* <ScreeningNeedHelp/> */}
      {/* <ScreeningMobileFAQ/> */}
      <Container>
        <Row className="py-3">
          <Col className="d-flex justify-content-end p-0">
            <NormalButton
              icon={
                <FontAwesomeIcon
                  color="#ffffff"
                  icon="fa-solid fa-plus"
                  style={{ width: "15px", height: "15px" }}
                />
              }
              iconbutton={true}
              type="submit"
              textColor=" white"
              backgroundColor="green"
              border="none"
              padding="8px 15px"
              borderRadius="6"
              fontSize="12px"
              buttonText={"CREATE NEW MESSAGE"}
              // style={{
              //   border: "none",
              //   backgroundColor: "#ADBE9E",
              //   borderRadius: "6px",
              // }}
              onClick={() => {
                setShowChatbox(true);
                setScreeningNeedHelpChatbox(true);
              }}
            />
            {showChatbox && (
              <ScreeningNeedHelpChatbox
                showRecipients={showRecipients}
                layoutStyle="center"
                onBackClick={() => setScreeningNeedHelpChatbox(false)} 
              />
            )}
          </Col>
        </Row>
        <Row className="border radius-6" style={{ backgroundColor: "#ffffff" }}>
          <Col className="border-end p-0" lg={5} md={12} sm={12}>
            <div className="border-bottom d-flex justify-content-between p-3 mt-1">
              <h5>Messages</h5>
              <div
                className="rounded-circle border d-flex justify-content-center align-items-center"
                style={{ width: "32px", height: "32px" }}
                onClick={() => {
                  setHideSearch(!hideSearch);
                }}
              >
                <FontAwesomeIcon
                  color="#000"
                  icon="fa-regular fa-magnifying-glass"
                  style={{ width: "12px", height: "12px" }}
                />
              </div>
            </div>
            <div className="d-flex">
              {/* <div
                className="d-flex flex-column justify-content-between"
                style={{ width: "20%" }}
              > */}
              <Col className="border-end" md={2} sm={2} xs={2}>
                <div className="tab-lists">
                  <ul className="p-0">
                    {userLinks.map((link, i) => (
                      <li
                        key={link?.tabName}
                        className="d-flex flex-column align-items-center my-2 rounded fw-400 fs-14 sidebar-tabs-color"
                      >
                        <div
                          className={`d-flex radius-8 ${
                            selectedIcon === link.tabName ? "selected" : ""
                          }`}
                          style={{
                            backgroundColor: "#D9D9D9",
                            padding: "10px 10px",
                            border:
                              selectedIcon === link.tabName
                                ? "2px solid #00000080"
                                : "none",
                            position: "relative",
                          }}
                          onClick={() => handleClick(link.tabName)}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              width: "10px",
                              height: "10px",
                              backgroundColor: "blue",
                              borderRadius: "50%",
                              transform: "translate(50%, -50%)",
                              display: isAllMessagesRead ? "none" : "block",
                            }}
                          />
                          <FontAwesomeIcon
                            style={{ width: "20px", height: "20px" }}
                            color="#fff"
                            icon={link.icon}
                          />
                        </div>
                        <div
                          style={{
                            color: "black",
                            fontWeight:
                              selectedIcon === link.tabName ? 600 : 300,
                            fontSize: "8px",
                            lineHeight: "10px",
                            paddingTop: "5px",
                          }}
                        >
                          {link.tabName}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              {/* </div> */}
              {/* <div
                className="border-start overflow-y-auto scrollable-container-y"
                style={{ width: "80%", height: "401px" }}
              > */}
              <Col
                className="overflow-y-auto scrollable-container-y"
                style={{ height: "404px" }}
                md={10}
                sm={10}
                xs={10}
              >
                {hideSearch && (
                  <div className="p-3 border-bottom">
                    <div className="border rounded-3 p-2 position-relative">
                      <input
                        placeholder="Search in all messages"
                        className="border-0"
                        style={{
                          outline: "none",
                          border: "none",
                          // padding: "0 20px", // Add padding for the search icon
                        }}
                      />
                      <FontAwesomeIcon
                        icon="fa-regular fa-magnifying-glass"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "15px", // Adjust the left position as needed
                          width: "12px",
                          height: "12px",
                          color: "#000",
                        }}
                      />
                    </div>

                    {/* <div className="border rounded-3 p-2">
                      <input
                        placeholder="Search in all messages"
                        className="border-0"
                        style={{
                          outline: "none", // Remove the default focus outline (not recommended for accessibility)
                          border: "none", // Remove the border on focus
                          // Add any other styling you want when the input is in focus
                        }}
                      />
                      <FontAwesomeIcon
                        color="#000"
                        icon="fa-regular fa-magnifying-glass"
                        style={{ width: "12px", height: "12px" }}
                      />
                    </div> */}
                    {/* <FilterComponent placeholder={"Search in all messages"}/> */}
                  </div>
                )}
                {msg.map((msg, i) => (
                  <div
                    className="d-flex align-items-center border-bottom justify-content-between p-2 cursor-pointer"
                    // style={showChatbox ? { backgroundColor: "#D4D4CE" } : {}}
                    onClick={() => {
                      toggleSelection(i);
                      handleMessageClick(i);
                    }}
                    key={i}
                    style={
                      msg.isSelected ? { backgroundColor: "#D4D4CE" } : {} // Apply the background color when the message is selected
                    }
                  >
                    <div className="d-flex">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="pe-2">
                          <div
                            className=" radius-10"
                            style={{
                              height: "8px",
                              width: "8px",
                              backgroundColor: !msg.isRead ? "blue" : "initial",
                            }}
                          />
                        </div>
                        <div
                          className="rounded-circle d-flex justify-content-center align-items-center"
                          style={{
                            width: "36px",
                            height: "36px",
                            backgroundColor: "#97BFB5",
                            color: "#fff",
                          }}
                        >
                          <p className="m-0">{getInitials(msg.name)}</p>
                        </div>
                      </div>
                      <div className="ps-2">
                        <p
                          className="my-0 fs-16"
                          style={{
                            fontWeight: !msg.isRead ? "600" : "300",
                          }}
                        >
                          {msg.name}
                        </p>
                        <p className="my-0 fs-14">{msg.issue}</p>
                        <p className="my-0 fs-12">{msg.time}</p>
                      </div>
                    </div>
                    <div className="pe-3">
                    <div
                      className="border radius-4 d-flex justify-content-center align-items-center"
                      style={{
                        width: "28px",
                        height: "28px",
                        backgroundColor: "#fff",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the parent click event from firing
                        handleDeleteChat(i);
                      }}
                    >
                      <FontAwesomeIcon
                        color="#000"
                        icon="fa-regular fa-trash"
                        style={{
                          width: "12px",
                          height: "16px",
                        }}
                      />
                    </div>
                    </div>
                  </div>
                ))}
              </Col>
              {/* </div> */}
            </div>
          </Col>
          {msg.some((message) => message.isSelected) ? (
            // Render the selected message data
            msg.map((message, index) => {
              if (message.isSelected) {
                return (
                  <Col
                    className="d-flex flex-column justify-content-between p-0"
                    lg={7}
                    md={12}
                  >
                    <Row>
                      <div
                        className={`d-flex align-items-center gap-2 py-3 border-bottom ${
                          !isMedium ? "border-top" : ""
                        }`}
                      >
                        <div
                          className="rounded-circle d-flex justify-content-center align-items-center"
                          style={{
                            width: "36px",
                            height: "36px",
                            backgroundColor: "#97BFB5",
                            color: "#fff",
                          }}
                        >
                          <p className="m-0">{getInitials(message.name)}</p>
                        </div>
                        <p className="my-0 fw-bold">{message.name}</p>
                        <p className="my-0">{message.issue}</p>
                      </div>
                    </Row>
                    <Row
                      className="scrollable-container-y overflow-y-auto"
                      style={{ height: "347px" }}
                      ref={chatContainerRef}
                    >
                      <div>
                        <div
                          className="d-flex justify-content-center"
                          style={{ color: "#00000080" }}
                        >
                          {formattedDate}
                        </div>
                        {dataArray.map((message, index) => (
                          <div
                            key={index}
                            className={`message ${
                              message.user === "You" ? "sent" : "received"
                            }`}
                          >
                            {/* <Usernamediv>{message.user}</Usernamediv>
                        <Msgcontainer>
                          <Msgtext>{message.text}</Msgtext>
                        </Msgcontainer> */}
                            {message.user === "You" ? (
                              <>
                                <div className="d-flex justify-content-end fw-bold">
                                  {message.user}
                                </div>
                                <div className="d-flex justify-content-end text-break">
                                  <div
                                    className="p-2 radius-10 "
                                    style={{
                                      backgroundColor: "#ADBE9E",
                                      color: "#fff",
                                      maxWidth: "70%",
                                    }}
                                  >
                                    {!message.text && (
                                      <FontAwesomeIcon
                                        icon="fa-regular fa-file-lines"
                                        style={{
                                          color: "#000000",
                                          height: "16px",
                                          width: "16px",
                                          paddingRight: "10px",
                                        }}
                                      />
                                    )}
                                    {message.text}
                                    {message?.file?.name}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="d-flex">
                                  <div className="d-flex flex-column justify-content-end pe-2">
                                    <div
                                      className="rounded-circle d-flex justify-content-center align-items-center"
                                      style={{
                                        width: "36px",
                                        height: "36px",
                                        backgroundColor: "#97BFB5",
                                        color: "#fff",
                                      }}
                                    >
                                      <p className="m-0">LL</p>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="fw-bold">
                                      {message.user}
                                    </div>
                                    <div className="d-flex justify-content-start text-break">
                                      <div
                                        className="p-2 radius-10 "
                                        style={{
                                          backgroundColor: "#E9EBF1",
                                          color: "#000",
                                          width: "70%",
                                        }}
                                      >
                                        {message.text}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </Row>
                    <Row>
                      <div className="border-top d-flex justify-content-between align-items-center py-3">
                        <div>
                          <input
                            className="d-none"
                            type="file"
                            name="needdHelpUploadfile"
                            id="Uploadfile"
                            onChange={handleFileChange}
                          ></input>
                          <label htmlFor="Uploadfile">
                            <FontAwesomeIcon
                              icon="fa-regular fa-paperclip"
                              style={{
                                fontSize: "1.3rem",
                                color: "#00000080",
                                transform: "rotate(316deg)",
                              }}
                            />
                          </label>
                        </div>
                        <Col md={10} sm={10}>
                          <textarea
                            type="text"
                            rows="1"
                            // cols="55"
                            style={{
                              resize: "none",
                              outline: "none",
                              width: "100%",
                              overflow: "hidden",
                            }}
                            placeholder="Write your message..."
                            id="Message"
                            className="border-0"
                            value={inputValue}
                            onChange={handleInputChange}
                            onClick={handleEnterPress}
                          />
                          <label htmlFor="Lastname"></label>
                        </Col>
                        <div>
                          <button
                            type="file"
                            style={{
                              border: "none",
                              backgroundColor: "#ADBE9E",
                              borderRadius: "6px",
                            }}
                            onClick={() => handleEnterPress()}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-paper-plane-top"
                              style={{ color: "white" }}
                            />
                          </button>
                        </div>
                      </div>
                    </Row>
                  </Col>
                );
              }
              return null; // Hide the data for non-selected messages
            })
          ) : (
            <Col className="d-flex flex-column justify-content-end align-items-center">
              <div>
                <h6 className="fw-bolder">No chats selected</h6>
                <Image
                  className=""
                  fluid
                  src={chatGuyImg}
                  alt="chatting guy"
                  style={{ objectFit: "cover" }}
                ></Image>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      {/* {fpHash === fpHash && expiredate ? "yes " : "no"} */}
    </div>
  );
}
