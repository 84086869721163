import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import managementLogo from "../../../assets/images/management-logo.svg";
import loginImagePattern from "../../../assets/images/login-image-pattern.svg";
import { Col, Row, Form, Button, Image, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../../../styles/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetSessionItem } from "../../../utils/SessionStorage";
import { Snackbar, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { RefreshToken } from "../../../services/RefreshToken";
import { useLogin } from "../../../hooks/queries/useLoginQueries";
import { H1, H4 } from "../../../styles/Common.style";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { profileData } from './../../../utils/dummy-data/login-dummy-data/profile-data';
import noProfileUser from '../../../assets/icons/no-profile-user.svg'

export const Login = () => {
  
  const [openSnack, setOpenSnack] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hostname, sethostName] = useState("");
  const { mutateAsync: loginMutate } = useLogin();
  const [fpHash, setFpHash] = useState("");
  const [chooseProfile, setChooseProfile] = useState(false);

  const isMobile = useMediaQuery("(max-width:576px)")

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };
    setFp();
  }, []);

  const navigate = useNavigate();

  const HomePage = () => {
    navigate("/home");
    // sessionStorage.clear();
  };
  const Loginpage = () => {
    navigate("/");
    // sessionStorage.clear();
  };
  const handleClickSnackbar = () => {
    setOpenSnack(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const loginHandler = async (loginPayload) => {
    // console.log("inside loginHandler loginPayload", loginPayload);

    try {
      const result = await loginMutate(loginPayload);

      // console.log("login mutate res", result);

      if (result.jwt !== "") {
        SetSessionItem("et-jwt", result.jwt);
        SetSessionItem("et-rt", result.rt);

        HomePage();
      } else {
        Loginpage();
      }
    } catch (error) {
      // console.log("catch login error", error.message);
      handleClickSnackbar();
    }
  };

  useEffect(() => {
    sethostName(window.location.origin);
  }, []);
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onFormSubmit = (data) => {
    // setChooseProfile(true)
    console.log("data", data);
    setEmail(data?.email);
    setPassword(data?.password);
    const loginPayload = {
      username: data?.email,
      password: data?.password,
      visitorId: fpHash,
      // username,
      // password,
      // aud: "https://my-page-staging.netlify.app",   // staging URL
      aud: "https://mypage.preproduction.estatetool.net", // production URL
      website_type: "my-portal",
    };
    loginHandler(loginPayload);
  };

  // const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const jwt = sessionStorage.getItem('et-jwt')
  // console.log("GetSessionItem" , jwt)

  return (
    <>
      <div
        className = "pt-3 bg-login d-flex justify-content-center align-items-center px-md-4"
        style     = {{ position: "relative" }}
      >
        <div className = "mb-4">
          <img
            src       = {managementLogo}
            alt       = "management-logo"
            className = "management-logo d-block m-auto"
          />
            <Row className = "mb-1">
            <Col sm        = {12}>
            <Row className = "loginForm mt-3 mb-3 flex-column-reverse flex-sm-row position-relative">
                  <Col sm        = {6} className = "login-right-section">
                  {
                  !chooseProfile ?
                  <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Row className="mb-1">
                      <Col sm={12}>
                        <Row className="mt-3 mb-3 flex-column-reverse flex-sm-row position-relative">
                          <Col className="px-0">
                            <H1 className="primary-text-color">Sign in</H1>
                            <div className="login-form">
                              <div className="form-group mb-1 ">
                                  <H4 className="primary-text-color">Email</H4>
                                  <div className="position-relative">
                                  <input
                                  className="d-block w-100"
                                  type="email"
                                  name="email"
                                  placeholder="Your email address"
                                  {...register("email", {
                                    required: "Email is required.",
                                    pattern: {
                                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                      message: "Email is not valid.",
                                    },
                                  })}
                                />
                                 <label className="input-headings">
                                  Email
                                </label>
                                  </div>
                                {errors.email ? (
                                  <p className="error-msg">{errors.email.message}</p>
                                ) : null
                                }
                              </div>
                              <div className="form-group mb-2 mt-3 position-relative">
                                  <H4>Password</H4>
                                <InputGroup>
                                  <input
                                    className="d-block w-100 radius-6"
                                    name="password"
                                    placeholder="Enter password"
                                    type={showPassword ? "text" : "password"}
                                    {...register("password", {
                                      required: true,
                                    })}
                                  />
                                <label className="input-headings mb-1">
                                  Password
                                </label>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClickShowPassword}
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        showPassword
                                          ? "fa-regular fa-eye"
                                          : "fa-regular fa-eye-slash"
                                      }
                                    />
                                  </div>
                                </InputGroup>
                                <div className="d-flex justify-content-between mt-1 flex-wrap">
                                {errors.password?.type === "required" ? (
                                  <p className="error-msg">Password is required.</p>
                                ) : null}
                                <Link
                                to={'/forgot-password'}
                                      className="ms-auto"
                                      style={{
                                        textDecoration: "none",
                                        // color: "#ADBE9E !important",
                                      }}

                                    >
                                      <div className="fs-12" color="#ADBE9E">Forgot password?</div>
                                </Link>
                                </div>
                              </div>

                              <NormalButton
                                type="submit"
                                className="fw-900"
                                textColor=" white"
                                border="none"
                                backgroundColor="green"
                                padding="9px 18px"
                                borderRadius="6"
                                fontSize="14"
                                fontWeight="600"
                                buttonText="SIGN IN"
                              />
                            </div>
                            <Snackbar
                              open={openSnack}
                              autoHideDuration={6000}
                              onClose={handleCloseSnack}
                              message="Wrong username or password"
                              action={action}
                            />
                            <div className="mb-3">
                              <div>
                              <Form.Check type={"checkbox"}>
                                <Form.Check.Input checkbox={"checkbox"} isValid />
                                <Form.Check.Label className="fs-14 primary-text-color">
                                  Click here to accept our <u>terms & conditions</u>
                                </Form.Check.Label>
                              </Form.Check>
                              </div>
                              <div>
                              <Form.Check type={"checkbox"}>
                                <Form.Check.Input checkbox={"checkbox"} isValid />
                                <Form.Check.Label className="fs-14 primary-text-color">
                                  Remember me
                                </Form.Check.Label>
                              </Form.Check>
                              </div>
                            </div>
                            <div className="">
                              <span className="fs-14 me-1">Don't have an account?</span> 
                              <Link to={"create-new-profile"}>
                                <span className="fs-14" style={{ color: '#ADBE9E' }}>Create profile</span>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                  :
                  <ChooseProfile />
                  }
                  </Col>
                  <Col sm = {6} className = "d-sm-block">
                    <Image
                      fluid
                      className = "w-100 h-100"
                      src       = {loginImagePattern}
                      alt       = "login-image-pattern"
                      style     = {{ objectFit: "cover", borderRadius: "8px", maxHeight: isMobile ? '80px' : '100%' }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
        </div>
        <div className = "copyright copyright-text">
          This solution is powered by estatetool | © 2023 - All rights reserved
        </div>
      </div>
    </>
  );
};


const ChooseProfile = () => {
  const [selectedProfile, setSelectedProfile] = useState('')
  return(
    <div className="px-3">
      <div>
        <H1 className="primary-text-color">Choose your profile</H1>
        <p className="primary-text-color">We have found two profiles using this email.</p>
        <p className="primary-text-color">Please choose your profile below</p>
      </div>
      <div className="d-flex justify-content-center gap-2 mb-4 choose-profile-container">
      {profileData.map((tab, i) => (
          <div
            key={i}
            id={i}
            className={`py-1 cursor px-3 ${selectedProfile === tab.name && 'active'}`}
            onClick={() => setSelectedProfile(tab.name)}
          >
            <div>
              <img style={{ width: '140px' }} src={noProfileUser} alt="profile" />
            </div>
            <div className="text-center mt-2 lh-1">
              <p className="fs-16 mb-0">{tab.name}</p>
              <span className="fs-12">{tab.type}</span>
            </div>
          </div>
        ))}
      </div>
      <NormalButton
          className="fw-900"
          textColor=" white"
          border="none"
          backgroundColor="green"
          padding="9px 18px"
          borderRadius="6"
          fontSize="14"
          fontWeight="600"
          buttonText="SIGN IN"
          disabled={!selectedProfile.length}
          // onClick={()=> }
        />
    </div>
  )
} 