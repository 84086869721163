import * as React from "react";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { useForm } from "react-hook-form";
import moment from "moment";
import styled from "styled-components/macro";
import {
  useScreeningMessageModal,
  useScreeningFaqPopup,
  useScreeningNeedHelpChatbox,
  useScreeningNeedHelpChatbox2,
  useMobileFaqPopup,
} from "../../../hooks/stores/useScreeningFormStore";
import { H4, H5 } from "../../../styles/Common.style";

export default function ScreeningNeedHelpChatbox({ showRecipients, layoutStyle, onBackClick }) {
  // console.log('lo mai aa gaya')
  const setScreeningFaqPopup = useScreeningFaqPopup(
    (state) => state.setScreeningFaqPopup
  );
  const setScreeningMessageModal = useScreeningMessageModal(
    (state) => state.setScreeningMessageModal
    );
  const screeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.screeningNeedHelpChatbox
    );
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:30 ~ ScreeningNeedHelpChatbox ~ screeningNeedHelpChatbox:", screeningNeedHelpChatbox)
  const setScreeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.setScreeningNeedHelpChatbox
  );
  const screeningNeedHelpChatbox2 = useScreeningNeedHelpChatbox2(
    (state) => state.screeningNeedHelpChatbox2
  );
  const setScreeningNeedHelpChatbox2 = useScreeningNeedHelpChatbox2(
    (state) => state.setScreeningNeedHelpChatbox2
  );
  const setMobileFaqPopup = useMobileFaqPopup(
    (state) => state.setMobileFaqPopup
  );
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:13 ~ ScreeningNeedHelpChatbox ~ height:", height)
  // const [show, setShow] = useState(true);
  // const [show2, setShow2] = useState(false);
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:12 ~ ScreeningNeedHelpChatbox ~ show2:", show2)
  const {
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Headline: "",
      Message: "",
    },
  });
  const [formValue, setFormValue] = useState({
    Headline: getValues("Headline"),
    Message: getValues("Message"),
  });

  const handleFileChange = (event) => {};
  const onSubmit = (data) => {
    setScreeningNeedHelpChatbox(false);
    setScreeningNeedHelpChatbox2(true);
    console.log(data);
    reset();
  }; // your form subm

  // console.log(screeningNeedHelpChatbox , screeningNeedHelpChatbox2 ,'log')
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="screening-msg-contai/ner">
      {(screeningNeedHelpChatbox || screeningNeedHelpChatbox2 ) && (
        <Backdrop className="w-100 h-100 position-fixed top-0">
          {screeningNeedHelpChatbox && (
            <Layout layoutStyle={layoutStyle}>
              {/* {console.log('lo mai aa gaya')} */}
              <Notifydiv>
                <Topdiv className="d-flex align-items-center">
                  <IconContainer
                    className="cursor d-flex align-items-center justify-content-center"
                    style={{ width: "40px", height: "40px" }}
                    // onClick={() => {
                    //   setScreeningMessageModal(false);
                    //   setMobileFaqPopup(true);
                    //   setScreeningFaqPopup(true);
                    //   // setMobileFaqPopup(true);
                    // }}
                    onClick={() => onBackClick()}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-angle-left"
                      size="sm"
                      color="white"
                    />
                  </IconContainer>
                  <h5 className="mt-2">Need help?</h5>
                  <IconContainer
                    className="cursor d-flex align-items-center justify-content-center"
                    style={{ width: "40px", height: "40px" }}
                  >
                    <CloseIcon
                      fontSize="small"
                      sx={{ color: "white" }}
                      onClick={() => {
                        setScreeningMessageModal(false);
                        setScreeningNeedHelpChatbox(false);
                        // setShowChatbox(false);
                      }}
                    />
                  </IconContainer>
                </Topdiv>
                <div>
                  <Flexdiv className="px-4 text-center">
                    <Heading>We will answer within 12 hours</Heading>
                  </Flexdiv>
                  <div>
                    <Para className="px-3 pb-3 text-wrap">
                      We come back to you as soon as possible!
                      <br />
                      Please note that we do not offer live support, but we
                      promise that you would not wait too long 😊
                    </Para>
                  </div>
                </div>
              </Notifydiv>
              <div>
                <Chatdiv className="px-2 py-3 d-flex align-items-center justify-conetent-end">
                  <Msgdiv className="w-100">
                    {showRecipients && (
                      <Row className="mb-3">
                        <Col lg={12}>
                          <div>
                            <div className="form-input">
                              <input
                                type="text"
                                placeholder="e.g. Problem with upload a document"
                                id="Recipents"
                                {...register("Recipents", {
                                  required: true,
                                  onChange: (e) => {
                                    setFormValue({
                                      ...formValue,
                                      Recipents: e.target.value,
                                    });
                                  },
                                })}
                              />
                              <label htmlFor="Recipents">Recipents</label>
                            </div>
                            {errors.Recipents && (
                              <p className="error-msg" style={{ color: "red" }}>
                                Recipents is Required.
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="mb-3">
                      <Col lg={12}>
                        <div>
                          <div className="form-input">
                            <input
                              type="text"
                              placeholder="e.g. Problem with upload a document"
                              id="Headline"
                              {...register("Headline", {
                                required: true,
                                onChange: (e) => {
                                  setFormValue({
                                    ...formValue,
                                    Headline: e.target.value,
                                  });
                                },
                              })}
                            />
                            <label htmlFor="Headline">Headline</label>
                          </div>
                          {errors.Headline && (
                            <p className="error-msg" style={{ color: "red" }}>
                              Headline is Required.
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={12}>
                        <div>
                          <div className="d-flex form-message-input position-relative">
                            <textarea
                              type="text"
                              style={{
                                resize: "none",
                                border: "1px solid #d1d1d1",
                              }}
                              placeholder="Start Writing..."
                              id="Message"
                              className="Message pe-5"
                              {...register("Message", {
                                required: true,
                                onChange: (e) => {
                                  setFormValue({
                                    ...formValue,
                                    Message: e.target.value,
                                  });
                                },
                              })}
                            />
                            <label htmlFor="Lastname">Message</label>
                            <Flexdiv
                              style={{
                                position: "absolute",
                                bottom: "12px",
                                right: "12px",
                              }}
                            >
                              <button
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                  border: "none",
                                  backgroundColor: "#ADBE9E",
                                  borderRadius: "4px",
                                  width: "25px",
                                  height: "25px",
                                }}
                                onClick={() => {}}
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-paper-plane-top"
                                  style={{ color: "white" }}
                                />
                              </button>
                            </Flexdiv>
                          </div>
                          {errors.Message && (
                            <p className="error-msg" style={{ color: "red" }}>
                              Message is Required.
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <FAQdiv>
                      <NormalButton
                        type="submit"
                        textColor=" white"
                        backgroundColor="green"
                        buttonText="Find help in our FAQ"
                        border="none"
                        padding="6px 24px"
                        borderRadius="6"
                        fontSize="12px"
                      />
                    </FAQdiv>
                  </Msgdiv>
                </Chatdiv>
              </div>
            </Layout>
          )}
          {screeningNeedHelpChatbox2 && (
            <ScreeningNeedHelpChatbox2
            setScreeningNeedHelpChatbox={setScreeningNeedHelpChatbox}
            setScreeningNeedHelpChatbox2={setScreeningNeedHelpChatbox2}
              data={formValue}
              setScreeningMessageModal={setScreeningMessageModal}
              layoutStyle={layoutStyle}
            />
          )}
        </Backdrop>
       )}
    </form>
  );
}

export function ScreeningNeedHelpChatbox2({
  setScreeningNeedHelpChatbox,
  setScreeningNeedHelpChatbox2,
  data,
  setScreeningMessageModal,
  layoutStyle,
}) {
  const [inputValue, setInputValue] = useState("");
  const [dataArray, setDataArray] = useState([
    { text: data.Message, user: "You" },
  ]);
  // console.log("🚀 ~ file: ScreeningNeedHelpChatbox.js:263 ~ dataArray:", dataArray)
  const chatContainerRef = useRef(null);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const formattedDate = currentDateTime.format("D MMM, YYYY | HH:mm");

  // const BOT_MSGS = [
  //   "Hi, how are you?",
  //   "Ohh... I can't understand what you trying to say. Sorry!",
  //   "I like to play games... But I don't know how to play!",
  //   "Sorry if my answers are not relevant. :))",
  //   "I feel sleepy! :("
  // ];

  // function botResponse() {
  //   const r = Math.floor(Math.random() * BOT_MSGS.length); // Generate a random index
  //   const msgText = BOT_MSGS[r];
  //   const delay = msgText.split(" ").length * 100;

  //   setTimeout(() => {
  //     // Handle the bot's response here, you might want to add it to the state.
  //     // For example, you can use the setDataArray function to add the bot's message.
  //     const botMessage = { text: msgText, user: 'Bot' };
  //     setDataArray([...dataArray, botMessage]);
  //   }, delay);
  // }
  const simulateBotResponse = (userMessage) => {
    // You can implement your bot logic here
    // For example, you can use a chatbot library or API to generate bot responses.
    // For this example, let's just echo the user's message.
    return `You said "${userMessage}"`;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setSelectedFile(file);
    // Display the file name in the textarea
    // setInputValue(file ? file.name : '');
    const userMessage = inputValue;
    const newMessages = [
      ...dataArray,
      { text: userMessage, user: "You", file: file },
    ];

    // Add the user's message to the state
    setDataArray(newMessages);
  };
  const handleEnterPress = () => {
    if (inputValue.trim() !== "") {
      const userMessage = inputValue;
      const newMessages = [...dataArray, { text: userMessage, user: "You" }];

      // Add the user's message to the state
      setDataArray(newMessages);

      // Simulate a bot response
      // botResponse();
      const botResponse = simulateBotResponse(userMessage);
      const updatedMessages = [
        ...newMessages,
        { text: botResponse, user: "Bot" },
      ];

      // Add the bot's response to the state
      setDataArray(updatedMessages);

      setInputValue("");
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [dataArray]);

  return (
    <Layout layoutStyle={layoutStyle}>
      <NotifyMobdiv>
        <Topdiv2
          style={{ alignItems: "baseline", justifyContent: "space-between" }}
        >
          <IconContainer>
            <FontAwesomeIcon
              icon="fa-regular fa-angle-left"
              size="sm"
              color="white"
              onClick={() => {
                setScreeningNeedHelpChatbox(true);
                setScreeningNeedHelpChatbox2(false);
              }}
            />
          </IconContainer>
          <h5>Need help?</h5>
          <IconContainer>
            <CloseIcon
              fontSize="small"
              sx={{ color: "white" }}
              onClick={() => {
                setScreeningNeedHelpChatbox(false);
                setScreeningNeedHelpChatbox2(false);
                setScreeningMessageModal(false);
              }}
            />
          </IconContainer>
        </Topdiv2>
        <div>
          <Flexdiv>
            <Heading>We will answer within 12 hours</Heading>
          </Flexdiv>
        </div>
      </NotifyMobdiv>
      <Notifydiv2>
        <Flexdiv
          style={{ alignItems: "baseline", justifyContent: "space-between" }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <IconContainer
              className="cursor d-flex align-items-center justify-content-center"
              style={{ width: "40px", height: "40px" }}
              onClick={() => {
                setScreeningNeedHelpChatbox(true);
                setScreeningNeedHelpChatbox2(false);
              }}
            >
              <FontAwesomeIcon
                icon="fa-regular fa-angle-left"
                size="sm"
                color="white"
              />
            </IconContainer>
            <H4 className="text-white text-start mb-0 ms-2">{data.Headline}</H4>
          </div>
          <IconContainer
            className="cursor d-flex align-items-center justify-content-center"
            style={{ width: "40px", height: "40px" }}
          >
            <CloseIcon
              fontSize="small"
              sx={{ color: "white" }}
              onClick={() => {
                setScreeningNeedHelpChatbox(false);
                setScreeningNeedHelpChatbox2(false);
                setScreeningMessageModal(false);
              }}
            />
          </IconContainer>
        </Flexdiv>
      </Notifydiv2>
      <Chatdiv2>
        <Somediv className="p-2">
          <H5
            className="cursor"
            style={{ marginBottom: "0px", color: "#ADBE9E" }}
          >
            Go to all messages
          </H5>
        </Somediv>
        <Headlinediv>
          <h4 style={{ marginBottom: "0px" }}>Headline : {data.Headline}</h4>
        </Headlinediv>
        <ChatMsgDisplay ref={chatContainerRef}>
          <div className="w-100">
            <Flexdiv className="fs-12" style={{ color: "#00000080" }}>
              {formattedDate}
            </Flexdiv>
            {dataArray.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.user === "You" ? "sent" : "received"
                } pb-3`}
              >
                {/* <Usernamediv>{message.user}</Usernamediv>
                      <Msgcontainer>
                        <Msgtext>{message.text}</Msgtext>
                      </Msgcontainer> */}
                {message.user === "You" ? (
                  <>
                  <div className="d-flex justify-content-end fw-bold">
                    {message.user}
                  </div>
                  <div className="d-flex justify-content-end text-break">
                    <div
                      className="p-2 radius-10 "
                      style={{
                        backgroundColor: "#ADBE9E",
                        color: "#fff",
                      }}
                    >
                      {!message.text && <FontAwesomeIcon
                        icon="fa-regular fa-file-lines"
                        style={{
                          color: "#000000",
                          height:'16px',
                          width:'16px',
                          paddingRight: "10px",
                        }}
                      />}
                      {message.text}
                      {message?.file?.name}
                    </div>
                  </div>
                </>
                ) : (
                  <>
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end pe-2">
                        <div
                          className="rounded-circle d-flex justify-content-center align-items-center"
                          style={{
                            width: "36px",
                            height: "36px",
                            backgroundColor: "#97BFB5",
                            color: "#fff",
                          }}
                        >
                          <p className="m-0">LL</p>
                        </div>
                      </div>
                      <div>
                        <div className="fw-bold">{message.user}</div>
                        <div className="d-flex justify-content-start text-break">
                          <div
                            className="p-2 radius-10 "
                            style={{
                              backgroundColor: "#E9EBF1",
                              color: "#000",
                            }}
                          >
                            {message.text}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </ChatMsgDisplay>
        <Msgdiv2 className="px-1 py-3">
          <Row className="h-100">
            <Col lg={12} className="h-100">
              <div
                className="screening-form-textarea-border position-relative h-100"
                style={{ border: "1px solid #d1d1d1", borderRadius:'5px'}}
              >
                <div id="chatbox2textarea" className="d-flex form-message-input h-100" >
                  <textarea
                    type="text"
                    placeholder="Start Writing..."
                    id="Message"
                    className="Message"
                    style={{ resize: "none", height: "calc(100% - 38px)" }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={handleEnterPress}
                  />
                  <label htmlFor="Lastname">Message</label>
                </div>
                <Flexdiv
                  className="w-100 px-2"
                  style={{
                    justifyContent: "space-between",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    paddingBottom: "5px",
                  }}
                >
                  <div>
                    <input
                      className="d-none"
                      type="file"
                      name="needdHelpUploadfile"
                      id="needdHelpUploadfile"
                      onChange={handleFileChange}
                    ></input>
                    <label htmlFor="needdHelpUploadfile">
                      <div
                        className="d-flex align-items-center justify-content-center cursor"
                        style={{ width: "25px", height: "25px" }}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-paperclip"
                          style={{ fontSize: "1.3rem", color: "#888888" }}
                        />
                      </div>
                    </label>
                  </div>
                  <div>
                    <button
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        border: "none",
                        backgroundColor: "#ADBE9E",
                        borderRadius: "4px",
                        width: "25px",
                        height: "25px",
                      }}
                      onClick={() => handleEnterPress()}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-paper-plane-top"
                        style={{ color: "white" }}
                      />
                    </button>
                  </div>
                </Flexdiv>
              </div>
            </Col>
          </Row>
        </Msgdiv2>
      </Chatdiv2>
    </Layout>
  );
}

const Backdrop = styled.div`
  left:0;
  z-index: 1057;
  background-color: #11174780;
`;

const Layout = styled.div`
  background-color: white;
  width: 320px;
  position: fixed;

  ${(props) =>
    props.layoutStyle === 'center' &&
    `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
  ${(props) =>
    props.layoutStyle === 'bottomRight' &&
    `
    bottom: 100px;
    right: 20px;
  `}
  
  z-index: 1500;
  border-radius: 12px;
  overflow: auto;
  @media (min-width: 1300px) {
    width : 350px;
  }
  @media (max-width: 576px) {
    z-index: 1500;
    height: 100%; 
    width : 100%;
    bottom: 0px;
    right: 0px;
  }
`;
const Notifydiv = styled.div`
  background-color: #111747;
  color: white;
  height: auto; 
  border-radius: 12px 12px 0px 0px;
  @media (max-width: 576px) {
    height:30%
  }
`;
const Topdiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 576px) {
    height:44%;
    // position:relative;
    // top:20%
  }
`;
const Topdiv2 = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 576px) {
    height:70%;
    position:relative;
    top:20%
  }
`;
const Flexdiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
`;
const IconContainer = styled.div`
  padding:18px;
  @media (max-width: 1400px) {
    padding:9px
  }
`;
const Heading = styled.p`
  margin:0px 0 8px 0;
  font-size:22px;
  @media (max-width: 1400px) {
    font-size:16px
  }
`;
const Para = styled.p`
  color:#ffffff80;
  font-size:16px;
  text-align:center;
  margin:0px;
  @media (max-width: 1400px) {
    font-size:12px
  }
  @media (max-width: 576px) {
    padding:0 40px;
    color:#ffffff;
    margin-top: 10px;
  }
`;
const Chatdiv = styled.div`
  background-color: #F7F8FA;
  border-top: 1px solid #1117471A;
  margin-top: 20%;
  // height:470px;
  // border-radius:0 0 12px 12px;
  // position:relative;
  // @media (max-width: 1400px) {
  //   height:321px
  // }
  @media (max-width: 576px) {
    margin-top: 55%;
  }
`;
const FAQdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 576px) {
    display:none;
  }
`;
const Msgdiv = styled.div`
  // height:270px;
  // position:absolute;
  // bottom:0;
`;
const Notifydiv2 = styled.div`
  background-color: #111747;
  color: white;
  height: auto; 
  border-radius: 12px 12px 0px 0px;
  @media (max-width: 576px) {
    display:none;
  }
`;
const NotifyMobdiv = styled.div`
  background-color: #111747;
  color: white;
  height: 15%; 
  border-radius: 12px 12px 0px 0px;
  @media (min-width: 576px) {
    display:none;
  }
`;
const Chatdiv2 = styled.div`
  background-color:white;
  height:566px;
  border-radius:0 0 12px 12px;
  position:relative;
  @media (max-width: 1400px) {
    height:386px
  }
  @media (max-width: 576px) {
    height:85%
  }
`;
const Somediv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 576px) {
    display:none;
  }
`;
const Headlinediv = styled.div`
  background-color: #9FA2B5;
  color:#ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 10px;
  @media (min-width: 576px) {
    display:none;
  }
`;
const Msgdiv2 = styled.div`
  background-color: #F7F8FA;
  border-top: 1px solid #1117471A;
  position:absolute;
  width: 100%;
  bottom:0;
  height: 150px;
  // @media (max-width: 576px) {
  //   bottom:50px;
  // }
`;
const ChatMsgDisplay = styled.div`
  // height:350px;
  padding:0 10px 0 10px;
  overflow-y:auto;
  display:flex;
  flex-direction:row;
  height: calc(100% - 187px);
  width: 100%;
  justify-content: end;
  // @media (max-width: 1400px) {
  //   height:170px;
  // }
  // @media (max-width: 576px) {
  //   height:272px;
  // }
`;
const Msgtext = styled.div`
  background-color: #ADBE9E;
  color:#ffffff;
  border-radius: 6px;
  padding: 5px 10px;
  max-width:70%;
  display: flex;
  align-items: center;
`;
const Usernamediv = styled.div`
  display: flex;
  justify-content: end;
  font-weight: 400;
  font-size: 14px;
`;
const Msgcontainer = styled.div`
  display: flex;
  justify-content: end;
  overflow-wrap: anywhere;
}
`;
const Botdiv = styled.div`
  display: flex;
  justify-content: left;
  font-weight: 600;
`;
const BotMsgcontainer = styled.div`
  display: flex;
  justify-content: left;
  overflow-wrap: anywhere;
}
`;
const BotMsgtext = styled.div`
  background-color: #E9EBF1;
  color:#000000;
  border-radius: 6px;
  padding: 5px 10px;
  max-width:70%;
  display: flex;
  align-items: center;
`;
