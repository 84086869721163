import { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/recent-activity.css";
import Modal from "../../Components/widgets/modal/Modal";

export const RecentActivityComponent = ({ componentData }) => {
  const [openPdf, setOpenPdf] = useState(false);
  return componentData?.length != 0 ? (
    <div className="process-container pe-3 pt-3">
      <div className="process-timeline">
        {componentData?.map((data, i) => {
          return (
            <div
              className={`process-item ${
                componentData.length - 1 !== i ? "recent-timeline-line" : ""
              } d-flex mb-3`}
              key={i}
            >
              <div className="px-4 mt-1">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#CACCDB",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={`${data?.imgLink}`} />
                </div>
              </div>
              <div className="process-content w-100">
                <div className="ellipsis">
                  <span className="date-time fs-8 cards-secondary-text-color">
                    {data?.dataTimeInfo}
                  </span>
                  <p
                    className="light-font-text mb-2 text-truncate"
                    style={{ color: "black", width: "80%" }}
                  >
                    {data?.info}
                  </p>
                  {data?.document !== "" && (
                    <div className="download-file">
                      <div className="d-flex gap-2 align-items-center fs-10 fw-400 pb-2 pt-1 ">
                        <div
                          className={`d-flex radius-4  d-inline-block fs-8 cursor `}
                          style={{
                            backgroundColor: "#E3E5F8",
                            padding: "0.1rem 0.5rem",
                            color: "#2A39AF",
                            fontWeight : 600,
                            border: "1px solid #2A39AF",
                          }}
                          // onClick={() => setPreviewModal(true)}
                        >
                          <div className="d-flex align-items-center justify-content-center fs-8 radius-4 me-1">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "0.9rem",
                               
                                color: "rgba(42, 57, 175, 1)",
                              }}
                              icon="fa-solid fa-file-lines"
                              flip="horizontal"
                            />
                          </div>
                          {/* {item?.document_type_name} */}
                          Document.pdf
                        </div>
                      </div>
                      {/* <Button
                        className="border fs-8 fw-400 d-flex align-items-center background-primary cards-secondary-text-color"
                        style={{
                          borderRadius: "50px",
                          letterSpacing: "0.07rem",
                          lineHeight: "0",
                        }}
                        onClick={() => setOpenPdf(true)}
                      >
                        <div className="d-flex align-items-center justify-content-center fs-8 radius-4 me-2">
                          <FontAwesomeIcon
                            style={{ fontSize: "0.9rem", color: "rgba(42, 57, 175, 1)" }}
                            icon="fa-solid fa-file-lines"
                            flip="horizontal"
                          />
                        </div>
                        Document.pdf
                      </Button> */}
                      <Modal
                        open={openPdf}
                        cancel_button={false}
                        topborder={false}
                        ModalHeader={false}
                        onHide={() => setOpenPdf(false)}
                        headerRequired={false}
                      >
                        <div
                          className="p-3 radius-4"
                          style={{ height: "550px" }}
                        >
                          <iframe
                            title="recent-pdf"
                            src="https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"
                            height="100%"
                            width="100%"
                          ></iframe>
                        </div>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="d-flex justify-content-center pt-4 text-muted">
      no recent activity data found
    </div>
  );

  // <div className="recent-activities-card mb-1">
  //   <div className="position-relative">
  //     <div className="line msg-line"></div>

  //     { componentData?.map((data, i) => {
  //       return (
  //           componentData?.length - 1 !== i && (
  //           <div
  //             className={`d-flex px-3 ${!i ? "pt-3" : ""} pb-3 position-relative`}
  //             key={i}
  //           >
  //             <div className="px-3">
  //               <div
  //                 className="d-flex justify-content-center align-items-center"
  //                 style={{
  //                   width: "30px",
  //                   height: "30px",
  //                   backgroundColor: "#CACCDB",
  //                   borderRadius: "50%",
  //                 }}
  //               >
  //                 <FontAwesomeIcon icon={`${data?.imgLink}`} />
  //               </div>
  //             </div>
  //             <div className="ellipsis">
  //               <span className="date-time text-muted fw-400 fs-8">
  //                 {data?.dataTimeInfo}
  //               </span>
  //               <p className="fs-7 fw-300 mb-2 ellipsis">{data?.info}</p>
  //               {data?.document !== "" && (
  //                 <div className="download-file">
  //                   <Button
  //                     className="border fs-8 fw-400 d-flex align-items-center background-primary cards-secondary-text-color"
  //                     style={{
  //                       borderRadius: "50px",
  //                       letterSpacing: "0.07rem",
  //                       lineHeight: "0",
  //                     }}
  //                   >
  //                     <div className="d-flex align-items-center justify-content-center fs-6 radius-4 me-2">
  //                       <FontAwesomeIcon
  //                         style={{ fontSize: "0.9rem", color: "#d00404f0" }}
  //                         icon="fa-regular fa-file-lines"
  //                         flip="horizontal"
  //                       />
  //                     </div>
  //                     Document.pdf
  //                   </Button>
  //                 </div>
  //               )}
  //             </div>
  //           </div>
  //         )
  //       );
  //     })}
  //   </div>

  //   <div className="d-flex px-3 mb-1">
  //     <div className="px-3">
  //       <div
  //         className="d-flex justify-content-center align-items-center"
  //         style={{
  //           width: "30px",
  //           height: "30px",
  //           backgroundColor: "#CACCDB",
  //           borderRadius: "50%",
  //         }}
  //       >
  //         <FontAwesomeIcon
  //           icon={`${
  //               componentData[componentData?.length - 1]?.imgLink
  //           }`}
  //         />
  //       </div>
  //     </div>
  //     <div className="ellipsis">
  //       <span className="date-time text-muted fw-400 fs-8">
  //         {componentData[componentData?.length - 1]?.dataTimeInfo}
  //       </span>
  //       <p className="fs-7 fw-300 mb-2 ellipsis">
  //         {componentData[componentData?.length - 1]?.info}
  //       </p>
  //       {componentData[componentData?.length - 1]?.document !==
  //         "" && (
  //         <div className="download-file">
  //           <Button
  //             className="border fs-8 fw-400 d-flex align-items-center background-primary cards-secondary-text-color"
  //             style={{
  //               borderRadius: "50px",
  //               letterSpacing: "0.07rem",
  //               lineHeight: "0",
  //             }}
  //           >
  //             <div className="d-flex align-items-center justify-content-center fs-6 radius-4 pe-2">
  //               <FontAwesomeIcon
  //                 style={{ fontSize: "0.9rem", color: "#d00404f0" }}
  //                 icon="fa-regular fa-file-lines"
  //                 flip="horizontal"
  //               />
  //             </div>
  //             Document.pdf
  //           </Button>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // </div>
};
