import React from "react";
import { useState } from "react";
import managementLogo from "../../assets/images/management-logo.svg";
import loginImagePattern from "../../assets/images/login-image-pattern.svg";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import "../../styles/sessionexpired.css";
import { PostValidateOtp } from "../../services/SMS";
import { toast } from "react-toastify";
import { H1 } from "../../styles/Common.style";

const LoginVerification = ({ getActorBasicDetailsData, sendOTP }) => {
  const navigate = useNavigate();

  //--------------Validation----------------//
  const {
    handleSubmit,
    register,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({});

  //-----------handle login / validate otp-----------//
  const handleLogin = (data) => {
    const validateOtpPayload = {
      actor_id: getActorBasicDetailsData?.data?.[0]?.actor_id,
      mobile_no: `${getActorBasicDetailsData?.data?.[0]?.phone_calling_code_id}${getActorBasicDetailsData?.data?.[0]?.mobilenumber}`,
      otp_code: data?.otp,
    };
    PostValidateOtp(validateOtpPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
          navigate("/");
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div
      className="pt-4 pb-3 bg-login d-flex justify-content-center align-items-center px-4"
      style={{ position: "relative" }}
    >
      <div>
        {" "}
        <img
          src={managementLogo}
          alt="management-logo"
          className="management-logo d-block m-auto"
        />
        <Form onSubmit={handleSubmit(handleLogin)}>
          <Row className="mb-1">
            <Col sm={12} className="m-auto">
              <Row className="loginForm mt-3 mb-3">
                <Col sm={6} md={6} className="login-right-section p-md-4 p-2">
                  <H1>Login verification</H1>
                  <div className="">
                    <div className="form-group">
                      <div className="fw-300 mb-1 pt-4 pb-4">
                        We have sent you a code on your phone number ending
                        with&nbsp;
                        <span className="medium-text">
                          {getActorBasicDetailsData?.data?.[0]?.mobilenumber?.slice(
                            -2
                          )}
                          .
                        </span>
                      </div>
                      <div className="pb-3 medium-text">6-digit number</div>
                      <Controller
                        {...register(`otp`)}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <input
                            style={{ border: "1px solid #ADBE9E" }}
                            className="d-block w-100 radius-6"
                            name="password"
                            type="number"
                            id="otp"
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("otp");
                            }}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 6))
                            }
                          />
                        )}
                      />
                    </div>
                    {errors.otp && (
                      <p className="px-3 error-msg">This field is required</p>
                    )}
                    {/* {formValue.otp !== formValue.otpvalue ? (
                    <p className="px-3 input-error">Enter valid OTP</p>
                  ) : (
                    ""
                  )} */}
                    <div className="mt-4">
                      <Button
                        className="w-100"
                        style={{
                          height: "44px",
                          fontFamily: "Cerebri sans-medium",
                          background: "#ADBE9E",
                          border: "none",
                          borderRadius: "6px",
                        }}
                        type="submit"
                      >
                        LOGIN
                      </Button>
                    </div>
                  </div>
                  <div
                    className=" medium-text mt-4"
                    style={{ color: "#ADBE9E" }}
                  >
                    <span className="cursor cla" onClick={() => sendOTP()}>
                      Send code again
                    </span>
                    <div className="mt-1">
                      <span className="cursor">
                        Having trouble receiving the code?
                      </span>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="d-none d-sm-block">
                  <Image
                    className="w-100 h-100"
                    src={loginImagePattern}
                    alt="login-image-pattern"
                    style={{ objectFit: "cover", borderRadius: "8px" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default LoginVerification;
