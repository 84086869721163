import { Col, Image, Row } from "react-bootstrap";
import Modal from "../../../Components/widgets/modal/Modal";
import {
  useMobileFaqPopup,
  useScreeningFormPopup,
} from "../../../hooks/stores/useScreeningFormStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreScreening from "./preScreeningPages/PreScreening";
import ScreeningForm from "./screeningForm/ScreeningForm";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { useRef } from "react";
import ScreeningFormStepper from "../screeningComponent/ScreeningFormStepper";
import { Box } from "@mui/material";
import ScreeningMobileFAQ from "../screeningModal/ScreeningMobileFAQ";

const ScreeningMobileContainer = ({ Screening_Form_State, screeningFormNames, prev, next, isConditionTrue, skipFormCategory, handleScreeningData }) => {
    const Screening_Form_Popup = useScreeningFormPopup(
        (state) => state.Screening_Form_Popup
    );

  const setScreeningFormPopup = useScreeningFormPopup(
    (state) => state.setScreening_Form_Popup
  );
  const setMobileFaqPopup = useMobileFaqPopup(
    (state) => state.setMobileFaqPopup
  );
  const mobileFaqPopup = useMobileFaqPopup((state) => state.mobileFaqPopup);
  // console.log("🚀 ~ file: ScreeningFormMobileContainer.js:33 ~ mobileFaqPopup:", mobileFaqPopup)
  

  const submitRef = useRef(); // create the ref for next button
  const prevRef = useRef(); // create the ref for back button

  return (
    <>
      <Modal
        open={Screening_Form_Popup}
        topborder={false}
        className="modal-dialog-centered"
        center={true}
        isFullscreen={true}
        onHide={() => setScreeningFormPopup(false)}
        title_color="black"
        headerBg={"#f7f8fa"}
        bodyStyle={{
          marginBottom: !Screening_Form_State?.screeningIndex ? "0" : "208px",
          background: "#fff",
        }}
        bodyClassName={"p-0"}
        title={<div className="screening-mobile-wizard-header"></div>}
        onCloseAction={() => {
          // setShare(false);
          alert("Action Peformed Successfuly.");
        }}
      >
        <div style={{ height: "calc(100% - 109px)" }}>
          {Screening_Form_State?.screeningIndex === 1 && (
            <Row
              className="d-flex justify-content-center pt-2 pb-4 overflow-auto position-relative"
              style={{ background: "#f7f8fa" }}
            >
              <Col md={9}>
                <ScreeningFormStepper
                  isMobile={true}
                  skipFormCategory={skipFormCategory}
                />
              </Col>
            </Row>
          )}
          <div
            className="screening-form-container screening-mobile-form-container screening-mobile-rounded position-relative bg-white text-dark"
            style={{ height: "calc(100% - 48px)" }}
          >
            {Screening_Form_State?.screeningIndex ? (
              <div>
                <div>
                    <div className="bg-white text-dark mt-3 pt-3 border-top screening-mobile-rounded">
                        <ScreeningForm
                        formData={Screening_Form_State}
                        nextPage={next}
                        prevPage={prev}
                        parentSubmitRef={submitRef}
                        parentPrevRef={prevRef}
                        isConditionTrue={isConditionTrue}
                        skipFormCategory={skipFormCategory}
                        />
                    </div>
                </div>
                <div className="screening-mobile-footer position-fixed">
                  <div className="position-relative d-flex justify-content-center align-items-center my-2">
                    <div className="fw-bold fs-14">
                      {(Screening_Form_State.currentIndexIncludingFormSubWizard +
                        1 >
                      Screening_Form_State.maxIndexIncludingFormSubWizard
                        ? Screening_Form_State.maxIndexIncludingFormSubWizard
                        : Screening_Form_State.currentIndexIncludingFormSubWizard +
                          1) ?? 0}
                      /
                      {Screening_Form_State.maxIndexIncludingFormSubWizard ?? 0}
                    </div>
                    <div
                      className="position-absolute"
                      style={{
                        width: "35px",
                        height: "35px",
                        right: "10px",
                        bottom: "5px",
                      }}
                      onClick={() => {
                        setMobileFaqPopup(true);
                      }}
                    >
                      <FontAwesomeIcon
                        className="w-100 h-100"
                        color="#BB9F83"
                        icon="fa-solid fa-circle-question"
                      />
                    </div>
                  </div>
                  <div className="container px-4 pt-4 pb-4">
                    <div className={`${Screening_Form_State.currentFormIndex > Screening_Form_State.maxIndex && 'd-none'}`}>
                      <div className="text-white d-flex align-items-center justify-content-center pb-3" onClick={()=> handleScreeningData()}>
                        <FontAwesomeIcon
                          className="me-2"
                          color="#ffffff"
                          icon="fa-regular fa-bookmark"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span className="fs-20">Save and finish later</span>
                      </div>
                    </div>
                    <div>
                      <Box
                        sx={{
                          height: "8px",
                          position: "relative",
                          background: "#D0D1DE",
                          borderRadius: "10px",
                          width: "100%",
                          transition: "0.5s",
                          mb: 2,
                          "::before": {
                            position: "absolute",
                            content: '""',
                            height: "8px",
                            borderRadius: "10px",
                            transition: " 0.5s",
                            top: 0,
                            width: `${
                              ((Screening_Form_State.currentIndexIncludingFormSubWizard +
                                1) /
                                Screening_Form_State.maxIndexIncludingFormSubWizard) *
                              100
                            }%`,
                            background: "#54A64D",
                          },
                        }}
                      ></Box>
                    </div>
                    <div className={`${Screening_Form_State.currentFormIndex - 1 < Screening_Form_State.maxIndex && 'd-none'}`}>
                      <NormalButton
                        textColor="black"
                        border="none"
                        backgroundColor="white"
                        padding="10px 25px"
                        borderRadius="6"
                        fontSize="14"
                        bstClassName="fw-bold mx-auto"
                        buttonText="BACK"
                        onClick={() => {
                          setScreeningFormPopup(false)
                        }}
                      />
                    </div>
                    <div className={`${Screening_Form_State.currentFormIndex >
                        Screening_Form_State.maxIndex && 'd-none'} d-flex justify-content-between align-items-center mt-1 px-1`}>
                       { !Screening_Form_State.isOverViewBtnTrue || Screening_Form_State?.isOverViewBackBtnTrue ?
                         <NormalButton
                         textColor="black"
                         border="none"
                         backgroundColor="white"
                         padding="10px 25px"
                         borderRadius="6"
                         fontSize="14"
                         bstClassName="fw-bold"
                         buttonText="BACK"
                         onClick={() => {
                           if (prevRef && prevRef.current)
                             prevRef.current.click();
                           else prev();
                         }}
                       />
                        :
                        <NormalButton
                        type="submit"
                        className="fw-900"
                        textColor=" black"
                        backgroundColor="white"
                        buttonText="GO TO OVERVIEW"
                        border="1px solid #F0F0F0"
                        padding="6px 16px"
                        borderRadius="4"
                        fontSize="14"
                        fontWeight="500"
                        onClick={() => {
                          if (prevRef && prevRef.current) prevRef.current.click();
                          prev()
                        }}
                      />
                        }
                         {Screening_Form_State.currentFormIndex !==
                            Screening_Form_State.maxIndex ? (
                              <NormalButton
                                textColor="white"
                                border="none"
                                backgroundColor="green"
                                padding={`${!Screening_Form_State.isOverViewBtnTrue || Screening_Form_State?.isOverViewBackBtnTrue  ? "10px 80px" : "10px 20px"}`}
                                borderRadius="6"
                                fontSize="14"
                                bstClassName="fw-bold"
                                buttonText="NEXT"
                                onClick={() => {
                                  if (submitRef && submitRef.current) {
                                    submitRef.current.click();
                                  } else {
                                    next();
                                  }
                                }}
                              />
                            ) : (
                              <NormalButton
                                textColor="white"
                                border="none"
                                backgroundColor="green"
                                padding="10px 80px"
                                borderRadius="6"
                                fontSize="14"
                                bstClassName="fw-bold"
                                buttonText="SUBMIT"
                                onClick={(e) => {
                                  handleScreeningData();
                                }}
                              />
                            )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="position-relative h-100">
                <PreScreening />
              </div>
            )}
          </div>
        </div>
      </Modal>
      {mobileFaqPopup && <ScreeningMobileFAQ />}
    </>
  );
};

export default ScreeningMobileContainer;
