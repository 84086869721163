import { OtherScreeningConfirmation } from "./OtherScreeningConfirmation"
import CustomerSelectPage from "./CustomerSelectPage";
import { useScreeningFormStore } from "../../../../hooks/stores/useScreeningFormStore";
import { ScreeningInfo } from "./ScreeningInfo";

const PreScreening = ({ prevPage, nextPage}) => {
    const Screening_Form_State = useScreeningFormStore(
        (state) => state.Screening_Form_State
    );
    const setScreening_Form_State = useScreeningFormStore(
        (state) => state.setScreening_Form_State
    );

    const childProps = { getIndex: Screening_Form_State, setIndex: setScreening_Form_State }

    // prescreening component to render
    const components = {
        0: () => <CustomerSelectPage {...childProps} />,
        1: () => <OtherScreeningConfirmation {...childProps} />,
        2: () => <ScreeningInfo {...childProps} />,
    }
   
    if(!Object.keys(Screening_Form_State ?? {}).length) return null
    return (
        components[Screening_Form_State.prevIndex]()
    )
}



export default PreScreening;
