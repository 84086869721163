import { Post, Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//-------------Get country code---------------//
export const GetGeographyCountry = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_Get_Country, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//---------------Get actor profile--------------//
export const GetActorProfile = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Actor_Get_Actor_Profile, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Post Upload profile pictuire
export const PostUpdateActorProfilePicture = (data) => {
	return new Promise((resolve, reject) => {
		Post(
			Site.ET,
			Endpoint.ET.Actor_Post_UpdateProfilePicture,
			{ "Content-Type": "multipart/form-data" },
			data
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};



