import React, { useMemo, useRef, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NoticeBoardTableData } from "../../utils/dummy-data/notice-board-table-data/noticeTableData";
import Modal from "../../Components/widgets/modal/Modal";
import { H3, H4, H5 } from "../../styles/Common.style";

const NoticeBoard = () => {
  // console.log("NoticeBoardTableData", NoticeBoardTableData);
  // Like the Post

  const [likeThePost, setLikeThePost] = useState(false);
  // Open Attached Pdf File

  const [openAttachedPdf, setOpenAttachedPdf] = useState(false);

  // thumb icon
  const [icons, setIcon] = useState("up");
  const handleThumbsUp = () => {
    setIcon("down");
  };
  const handleThumbsDown = () => {
    setIcon("up");
  };

  const tableInstanceRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: "headline",

        header: "Headline",
        size: 180,
        enableSorting: false,

        Cell: ({ renderedCellValue, row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1.3rem",
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: row?.original?.bgColor,
                borderRadius: "5px",
              }}
            >
              <FontAwesomeIcon
                color={`${row?.original?.iconColor}`}
                icon={row?.original?.icon}
              />
            </div>
            <span
              className="fw-500 fs-16 nowrap Cerebri-sans-medium"
              style={{ color: "#202020" }}
            >
              {renderedCellValue}
            </span>
          </div>
        ),
      },

      {
        accessorKey: "author",
        header: "Author",
        size: 180,
        Cell: ({ renderedCellValue }) => (
          <div
            className="Cerebri-sans-light fs-16"
            style={{ color: "#202020" }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: "likes",
        header: "Likes",
        size: 50,

        Cell: ({ renderedCellValue, row }) => (
          // console.log("object",row?.original?.id),
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <span
              className="Cerebri-sans-light fs-16"
              style={{ color: "#202020" }}
            >
              {renderedCellValue}
            </span>

            <div
              className="d-flex align-items-center justify-content-center fs-6 p-1 radius-circle me-2 rounded-circle cursor"
              // style={{
              //   background: "rgba(17, 23, 71, 0.1)",
              //   border: "1px solid green",
              // }}
            >
              <div
                onClick={() => setLikeThePost(!likeThePost)}
                className="d-flex align-items-center justify-content-center fs-6 p-2 radius-circle me-2 rounded-circle"
                style={{
                  background: likeThePost
                    ? "rgba(247, 248, 250, 1)"
                    : "rgba(205, 230, 210, 1)",
                  border: "1px solid rgba(17, 23, 71, 0.1)",
                }}
              >
                {likeThePost ? (
                  <FontAwesomeIcon
                    icon="fa-solid fa-thumbs-up"
                    style={{ color: "rgba(209, 209, 209, 1)" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="fa-solid fa-thumbs-up"
                    style={{ color: "rgba(53, 154, 75, 1)" }}
                  />
                )}
              </div>
              {/* {icons === "up" && (
                <>
                  <FontAwesomeIcon
                    icon="fa-regular fa-thumbs-up"
                    style={{ color: "black" }}
                    onClick={handleThumbsUp}
                  />
                </>
              )}
              {icons === "down" && (
                <>
                  <FontAwesomeIcon
                    icon="fa-regular fa-thumbs-up"
                    style={{ color: "green" }}
                    onClick={handleThumbsDown}
                  />
                </>
              )} */}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "seen",
        header: "Seen",
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <div
            className="Cerebri-sans-light fs-16"
            style={{ color: "#202020" }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: "uploaded",
        header: "Uploaded",
        size: 180,
        Cell: ({ renderedCellValue }) => (
          <div
            className="Cerebri-sans-light fs-16"
            style={{ color: "#202020" }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: "file",

        header: "File",
        size: 50,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <div className="cursor">
            <FontAwesomeIcon
              style={{ fontSize: "1.3rem" }}
              icon="fa-regular fa-paperclip"
            />
          </div>
        ),
      },
    ],
    [likeThePost]
  );

  return (
    <div className="px-3">
      <MaterialReactTable
        muiTableHeadCellProps={{
          sx: {
            fontSize: "12px !important",
            fontFamily: "Cerebri sans-medium !important",
            fontWeight: "500 !important",
            color: "#202020",
            ".css-1omuo8w-MuiToolbar-root": {
              backgroundColor: "blue !important",
            },
          },
        }}
        icons={{
          // SearchIcon: () => <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />,
          ArrowDownwardIcon: (props) => (
            <FontAwesomeIcon icon="fa-solid fa-sort" />
          ),
          // ExpandMoreIcon: (props) => (
          //   <FontAwesomeIcon
          //     icon="fa-regular fa-angle-right"
          //     {...props}
          //     color="#C7C7C7"
          //   />
          // ),
        }}
        muiTableContainerProps={{
          sx: {
            borderRadius: "12px !important",
            background: "#FFF !important",
            boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06) !important",
            // border: "none !important",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,

          sx: {
            ".css-1omuo8w-MuiToolbar-root": {
              backgroundColor: "#f9f8fa !important",
            },
            backgroundColor: "transparent !important",
            borderRadius: "12px !important",
            // boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06) !important",
            border: "none !important",
            ".css-di3982": {
              border: "1px solid rgba(17, 23, 71, 0.1) !important",
              position: "relative",
            },

            ".MuiBox-root.css-di3982": {
              paddingRight: "3rem",
              paddingLeft: "0.5rem",
              backgroundColor: "white",
              marginLeft: "1rem",
              borderRadius: "6px",
              height: "38px !important",
            },

            ".MuiBox-root.css-di3982::before ": {
              position: "absolute",
              content: '"Filter"',
              top: "8px",
              right: "12px",
            },

            ".css-929hxt-MuiInputBase-input-MuiInput-input": {
              fontSize: "12px !important",
            },
            "..css-5ka3a4": {
              fontSize: "12px !important",
            },

            ".css-78trlr-MuiButtonBase-root-MuiIconButton-root": {
              color: "#000000 !important",
              fontSize: "14px !important",
            },
            // ".css-1f2qhs8": {
            //   // search field placeholder
            //   fontFamily: "Cerebri sans !important",
            // },
            ".css-1j54yht-MuiTableCell-root": {
              // expandable table css
              padding: "16px 0px !important",
            },
            ".css-mnn31": {
              padding: "7px 0px 5px !important",
            },

            ".css-sq9qdz": {
              // topbar color

              backgroundColor: "#f7f8fa",
            },

            ".css-l4u8b9-MuiInputBase-root-MuiInput-root": {
              width: "66% !important",
            },

            ".css-vv34gr-MuiTableRow-root": {
              // expanded detail panel radius

              borderRadius: "6px 0 0 0 !important",
            },

            ".css-v4u5dn-MuiInputBase-root-MuiInput-root:before": {
              // search bar

              borderBottom: "none !important",
            },

            ".MuiInput-underline:after": {
              borderBottom: "none !important",
            },

            ".css-v4u5dn-MuiInputBase-root-MuiInput-root ": {
              // search filed
              border: "1px solid rgba(17, 23, 71, 0.1) !important",
              height: "38px !important",
              borderRadius: "6px !important",
              padding: "0 0px 0 7px !important",
              backgroundColor: "#fff !important",
              color: "#000000 !important",
            },

            ".css-i44wyl": {
              border: "1px solid rgba(17, 23, 71, 0.1) !important",
              height: "38px !important",
              borderRadius: "6px !important",
              padding: "0 0px 0 7px !important",
              backgroundColor: "#fff !important",
              color: "#000000 !important",
            },

            ".css-zrqpq1::before": {
              borderBottom: "none !important",
            },

            ".css-zrqpq1::after": {
              borderBottom: "none !important",
            },
            ".css-1laqsz7-MuiInputAdornment-root": {
              display: "none !important",
            },

            ".css-1j7qk7u.Mui-disabled": {
              display: "none !important",
            },
          },
        }}
        columns={columns}
        data={NoticeBoardTableData}
        muiSelect
        displayColumnDefOptions={{
          "mrt-row-expand": { header: "" },
        }}
        enableExpandAll={false}
        enableColumnDragging={false}
        enableGrouping
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableBottomToolbar={false}
        // enableTopToolbar={false}
        tableInstanceRef={tableInstanceRef}
        initialState={{ showGlobalFilter: true }}
        positionToolbarAlertBanner="bottom"
        renderDetailPanel={({ row }) => (
          <div>
            <div className="px-5 pt-2">
              <p className="paragraph1-light px-4">
                Update on the renovation of the facade and Nam libero tempore,
                cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus id quod maxime placeat facere possimus, omnis voluptas
                assumenda est, omnis dolor repellendus. Temporibus autem
                quibusdam et aut officiis debitis aut rerum necessitatibus saepe
                eveniet ut et voluptates repudiandae sint et molestiae non
                recusandae. Itaque earum rerum hic tenetur a sapiente delectus,
                ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat.
              </p>
            </div>
            <hr style={{ color: "rgba(0, 0, 0, 0.60)" }} />
            <Row
              className="d-flex px-5 ms-4 pb-1"
              style={{ lineHeight: "30px" }}
            >
              <Col className="p-0">
                <div>
                  <H5 color="#202020">Attached file</H5>
                </div>
                <div>
                  {/* <Button
                    onClick={() => setOpenAttachedPdf(!openAttachedPdf)}
                    className="border  d-flex align-items-center regular"
                    style={{
                      backgroundColor: "#f7f8fa",
                      borderRadius: "50px",
                      color: "#999999",
                      letterSpacing: "0.07rem",
                      lineHeight: "0",
                    }}
                  >
                    <FontAwesomeIcon
                      className="me-2 regular"
                      style={{ fontSize: "0.9rem", color: "#d00404f0" }}
                      icon="fa-solid fa-file-lines"
                      flip="horizontal"
                    />
                    <span className="paragraph2-regular grey-text">
                      Newsletter-January_Blomsterbjerget.pdf
                    </span>
                  </Button> */}
                  <Button
                    onClick={() => setOpenAttachedPdf(!openAttachedPdf)}
                    className="border paragraph2-regular d-flex align-items-center"
                    style={{
                      backgroundColor: "#f7f8fa",
                      borderRadius: "100px",
                      color: "#000",
                      letterSpacing: "0.07rem",
                      lineHeight: "0",
                      padding: "0px 16px 0px 13px",
                      height: "28px",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center fs-6 radius-4 me-2">
                      <FontAwesomeIcon
                        style={{
                          fontSize: "0.9rem",
                          color: "#d00404f0",
                        }}
                        icon="fa-solid fa-file-lines"
                        flip="horizontal"
                      />
                    </div>
                    <span
                      className="paragraph2-regular"
                      style={{ color: "#999999" }}
                    >
                      Newsletter-January_Blomsterbjerget.pdf
                    </span>
                  </Button>
                  <Modal
                    topborder={false}
                    open={openAttachedPdf}
                    ModalHeader={false}
                    onHide={() => setOpenAttachedPdf(false)}
                    headerRequired={false}
                  >
                    <div className="p-3" style={{ height: "550px" }}>
                      <iframe
                        title="attached-file"
                        src="https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"
                        height="100%"
                        width="100%"
                        scrolling="no"
                      ></iframe>
                    </div>
                    <div
                      className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                      style={{
                        backgroundColor: "#F9FAFA",
                        borderRadius: " 0px 0px 8px 8px",
                        border: "1px solid #F0F0F0",
                      }}
                    ></div>
                  </Modal>
                </div>
              </Col>
              <Col>
                <H5 color="#202020">Like the post</H5>
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex align-items-center justify-content-center fs-6 p-1 radius-circle me-2 rounded-circle cursor"
                    style={{
                      background: "#F7F8FA",
                      border: "1px solid rgba(17, 23, 71, 0.1",
                      height: "28px",
                      width: "28px",
                    }}
                  >
                    {/* <FontAwesomeIcon
                      icon="fa-sharp fa-solid fa-thumbs-up"
                      color="#D1D1D1"
                    /> */}
                    <FontAwesomeIcon
                      icon="fa-solid fa-thumbs-up"
                      color="#D1D1D1"
                    />
                  </div>

                  <div className="paragraph1-light">
                    14 neighbours have liked this post
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <div>
              <H3 color="#111747">Notice Board</H3>
            </div>
          );
        }}
      />
    </div>
  );
};

export default NoticeBoard;
