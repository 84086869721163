import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import "../../../styles/document.css";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Takeapicture from "./Takeapicture";
import avatarPerson from "../../../assets/icons/avatar-person.svg";
import { Col, Row } from "react-bootstrap";
import { useUpdateActorProfilePicture } from "../../../hooks/queries/useActorQueries";
import { toast } from "react-toastify";

const Changeprofilepicture = ({
  getActorProfileData,
  setOpenChangePictureModal,
  getActorProfileDataRefetch,
  openProfileModalName,
  setProfileModalName
}) => {
  //-----------------------states-------------------//
  const [files, setFiles] = useState([]);

  //--------------------react query--------------------//
  const {
    mutateAsync: PostUpdateActorProfilePicture,
    isLoading: PostUpdateActorProfilePictureIsLoading,
  } = useUpdateActorProfilePicture();

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles?.length > 0) {
      setFiles(
        acceptedFiles.map((file) =>
        file
        )
      );
      setProfileModalName("PreviewAndUpload")
    } else {
      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type");
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  // end image upload

  //-----------------------update profile picture----------------------//
  const handleSaveProfilePicture = () => {
    let id;
    if (!PostUpdateActorProfilePictureIsLoading) {
      id = toast.loading("Updating profile");
    }
    let formData = new FormData();
    formData.append("files[]", files?.[0]);
    formData.append(
      "actor_id",
      getActorProfileData?.data?.[0]?.actor_id?.toString()
    );
    PostUpdateActorProfilePicture(formData)
      .then((response) => {
        if (response.data.bReturn) {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "success",
            autoClose: 2000,
          });
          setOpenChangePictureModal(false);
          getActorProfileDataRefetch();
          setProfileModalName("SelectionPage");
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(id, {
          render: error.message,
          isLoading: false,
          type: "error",
          autoClose: 2000,
        });
      });
  };

  //------------------step wizard for changing profile picture--------------//
  const UploadProfilePictureFlow = {
    SelectionPage: (
      <SelectionPage
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        getActorProfileData={getActorProfileData}
        setUploadProfilePictureFlow={setProfileModalName}
      />
    ),
    PreviewAndUpload: (
      <PreviewAndUpload
        files={files}
        setFiles={setFiles}
        handleSaveProfilePicture={handleSaveProfilePicture}
        setOpenChangePictureModal={setOpenChangePictureModal}
        setProfileModalName={setProfileModalName}
      />
    ),
    TakeAPicture: (
      <Takeapicture
        setFiles={setFiles}
        setUploadProfilePictureFlow={setProfileModalName}
      />
    ),
  };

  return (
    <>
      <div>{UploadProfilePictureFlow[openProfileModalName]}</div>
    </>
  );
};
export default Changeprofilepicture;

//-----------------selction page, Upload from PC or Take a picture--------------------//
const SelectionPage = ({
  getRootProps,
  getInputProps,
  getActorProfileData,
  setUploadProfilePictureFlow,
}) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column m-4 gap-4">
        <div
          className="d-flex justify-content-center"
          style={{ width: "180px", height: "180px", borderRadius: "100%" }}
        >
          <img
            src={
              getActorProfileData?.data?.[0]?.profile_photo !== ""
                ? getActorProfileData?.data?.[0]?.profile_photo
                : avatarPerson
            }
            alt="user_image"
            height={"100%"}
            width={"100%"}
            style={{
              borderRadius: "100%",
            }}
          />
        </div>
        <div className="d-flex">
              <div className="d-flex me-3" {...getRootProps({})}>
                <input {...getInputProps()} />
                <NormalButton
                  type="submit"
                  textColor="black"
                  backgroundColor="white"
                  buttonText="UPLOAD"
                  iconbutton={true}
                  icon={<FontAwesomeIcon icon="fa-regular fa-desktop" />}
                  border="1px solid #D1D1D1"
                  padding="6px 16px"
                  borderRadius="6px"
                  fontSize="14"
                />
              </div>
              <NormalButton
                type="submit"
                // borderColor="#F0F0F0"
                textColor="#000"
                backgroundColor="white"
                buttonText="TAKE A PICTURE "
                iconbutton={true}
                icon={<FontAwesomeIcon icon="fa-regular fa-camera" />}
                border="1px solid #D1D1D1"
                padding="6px 16px"
                borderRadius="6px"
                fontSize="16"
                onClick={() => {
                  setUploadProfilePictureFlow("TakeAPicture");
                }}
              />
        </div>
      </div>
    </>
  );
};

//-----------------Preview uploaded image & uploading--------------------//
const PreviewAndUpload = ({
  files,
  setFiles,
  handleSaveProfilePicture,
  setOpenChangePictureModal,
  setProfileModalName
}) => {
  return (
    <>
      <div className="d-flex justify-content-center flex-column m-4 gap-4">
        <div>
          <div
            className="d-flex justify-content-center position-relative"
            style={{
              height: "180px",
              width: "180px",
              borderRadius: "100%",
              margin: "auto",
            }}
          >
            
            <img
              src={
                files?.length ? URL.createObjectURL(files[0]) : avatarPerson
              }
              alt=""
              height={"100%"}
              width={"100%"}
              style={{
                borderRadius: "100%",
              }}
            />
            <div className="ms-auto mt-auto position-absolute profile-edit cursor-pointer" onClick={()=> setFiles([])}>
              <FontAwesomeIcon
                // onClick={() => setEditProfilePictureModal(true)}
                icon="fa-regular fa-trash"
                style={{
                  color: "#202020",
                }}
              />
            </div>
          </div>
          <div className="text-center">
            <p className="mb-0 mt-3">This image will be used on your profile</p>
          </div>
        </div>
        
        <div className="d-flex justify-content-center">
          <Row className="gap-2">
            <Col className="px-0">
              <NormalButton
                type="submit"
                className="fw-900"
                bstClassName="justify-content-center"
                // borderColor="#F0F0F0"
                textColor="black"
                backgroundColor="white"
                buttonText="CANCEL "
                border="1px solid #F0F0F0"
                padding="8px 20px"
                borderRadius="4"
                fontSize="14px"
                width="100%"
                fontWeight="500"
                onClick={() => {
                  setOpenChangePictureModal(false)
                  setProfileModalName("SelectionPage")
                }}
              />
            </Col>

            <Col className="px-0">
              <NormalButton
                type="submit"
                className="fw-900 "
                textColor="white"
                border="none"
                width="100%"
                backgroundColor="green"
                buttonText="SAVE AND CLOSE"
                padding="8px 20px"
                borderRadius="6"
                fontSize="14px"
                fontWeight="500"
                onClick={() => {
                  handleSaveProfilePicture()
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
