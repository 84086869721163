import React from "react";
import congratulation from "../../assets/icons/congratulation.svg";
import { Col, Image, Row } from "react-bootstrap";

const Congratulation = () => {
  return (
    <div>
      <Row className="d-flex p-2">
        <Col>
          <div>
            <div className=" d-flex flex-column congratulations-desc m-auto position-relative">
              <div className="Cerebri-sans-medium fs-48">
                Congratulations
              </div>{" "}
              <span className="fs-16">
              You have finished your screening, we will process your answers
              and get back to you.
              </span>
              <div className="p-0 position-absolute w-auto d-none d-md-block congratulation-wave-guy">
                <Image
                  // className="h-100 mx-auto d-block"
                  fluid
                  src={congratulation}
                  alt="waving guy"
                  style={{ objectFit: "cover" }}
                ></Image>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} className="p-0 d-block d-md-none">
          <Image
            // className="h-100 mx-auto d-block"
            fluid
            src={congratulation}
            alt="waving guy"
            style={{ objectFit: "cover" }}
          ></Image>
        </Col>
      </Row>
    </div>
  );
};

export default Congratulation;
