import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import Modal from "../../../Components/widgets/modal/Modal";
import "../../../styles/document.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PostUpdateActorProfilePicture } from "../../../services/Profile";

const Pictureremove = (props,getActorProfileData) => {
  const [editModal, setEditModal] = useState(false);
  const [remove, setRemove] = useState(true);
  const removeBtnHandel = () => {
    setRemove(!remove);
  };

    //-----------------------update profile picture----------------------//
    const handleSaveProfilePicture = () => {

      let id;
      // if (!PostUpdateActorProfilePictureIsLoading) {
      //   id = toast.loading("Updating profile");
      // }
      let formData = new FormData();
      formData.append("files[]", '');
      formData.append(
        "actor_id",
        getActorProfileData?.data?.[0]?.actor_id?.toString()
      );
      PostUpdateActorProfilePicture(formData)
        .then((response) => {
          console.log(response,'enter')
          if (response.data.bReturn) {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: "success",
              autoClose: 2000,
            });
            // getActorProfileDataRefetch();
            // setProfileModalName("SelectionPage");
          } else {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: "error",
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: error.message,
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        });
    };

  return (
    <div className="d-flex">
      <div className="cursor" 
      onClick={()=> {
        handleSaveProfilePicture()
        // setEditModal
      }}
      >
        <NormalButton
          type="submit"
          textColor="black"
          backgroundColor="white"
          buttonText="REMOVE"
          border="1px solid #D1D1D1"
          padding="6px 16px"
          borderRadius="6px"
          fontSize="14"
          iconbutton={true}
          icon={
            <FontAwesomeIcon icon="fa-regular fa-trash" className=" cursor" />
          }
          onClick={() => {}}
        />
      </div>
      <Modal
        // size="sm"
        open={editModal}
        className="modal-dialog-centered"
        onHide={() => setEditModal(false)}
        title={
          <div className="d-flex justify-content-between p-3">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <div className="Cerebri-sans-medium fs-18">
                  {" "}
                  Remove profile picture
                </div>
              </div>
            </div>
            <div onClick={() => setEditModal(false)}>
              <FontAwesomeIcon className="cursor" icon="fa-regular fa-xmark" />
            </div>
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setEditModal(false);
          alert("Action Peformed Successfuly.");
        }}
      >
        <div
          className=" py-2 d-flex justify-content-center mb-4 p-4"
          style={{ fontSize: "16px" }}
        >
          <div
            className=" mt-4 remove-profile-div bg-orange image d-flex justify-content-center"
            style={{
              height: "220px",
              width: "220px",
              borderRadius: "100%",
            }}
          >
            <div className="d-flex profile-fname-lname">
              {props.fname}
              <div>{props.lname}</div>
            </div>
          </div>
        </div>
        <>
          <div className="fs-16 regular" style={{ textAlign: "center" }}>
            This image will be used on your profile
          </div>
          <div className=" p-4">
            {remove ? (
              <>
                <div>
                  <Row>
                    <Col lg={6} className="mb-2">
                      <div>
                        <NormalButton
                          type="submit"
                          // borderColor="#F0F0F0"
                          textColor="#ADBE9E"
                          backgroundColor="white"
                          width="100%"
                          buttonText="CANCEL "
                          border="1px solid #F0F0F0"
                          padding="6px 73px"
                          borderRadius="4"
                          fontSize="14"
                          onClick={() => setEditModal(false)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <NormalButton
                        type="submit"
                        textColor="white"
                        border="none"
                        backgroundColor="green"
                        buttonText="REMOVE"
                        width="100%"
                        padding="6px 73px"
                        borderRadius="6"
                        fontSize="14"
                        onClick={removeBtnHandel}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div>
                <Row>
                  <Col lg={12}>
                    <div>
                      <NormalButton
                        type="submit"
                        // borderColor="#F0F0F0"
                        textColor="#ADBE9E"
                        backgroundColor="white"
                        width="100%"
                        buttonText="OK"
                        border="1px solid #F0F0F0"
                        padding="6px 200px"
                        borderRadius="6px"
                        fontSize="14"
                        onClick={() => {
                          setEditModal(false);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </>
        <div></div>
      </Modal>
    </div>
  );
};

export default Pictureremove;
