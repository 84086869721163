import { Col, Row } from "react-bootstrap"
import { H1 } from "../../../../styles/Common.style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const CustomerSelectPage = ({ getIndex, setIndex }) => {
    
    return(
    <Row className="py-3 py-lg-5 justify-content-center">
        <Col className="px-sm-2 me-0 me-xl-5 mt-2" lg={6} xxl={5}>
           <div className="text-center">
               <H1>How many customers should be on the lease?</H1>
           </div>
           <div className="text-center mx-auto" style={{ maxWidth: '450px' }}>
               <p>We need to know how many customers should be on the lease. Therefore, select below how many customers you want on the contract. All customers must then complete a screening and be approved based on our terms and conditions.</p>
           </div>
           <div className="mt-4 px-3">
               <div className="mb-2">
                   <span className="fs-14">Number of customers on the contract</span>
               </div>
               <Row className="gap-3">
                   {
                       cutomerList.map((cl, clIndex) => {
                           return(
                               <Col key={cl.customerNo} md={3} className="position-relative customer-list-prescreening d-flex align-items-end justify-content-center cursor" style={{ width: '150px', height: '190px', background: clIndex + 1 === getIndex?.numberOfCustomer ? '#FFF' : '#F2F4F6' }} onClick={()=> {
                                setIndex({
                                    ...getIndex,
                                    prevIndex: 1,
                                    numberOfCustomer: clIndex+1
                                })
                               }}>
                                   <div className="lh-base">
                                       <H1 className="text-center mb-0">{cl.customerNo}</H1>
                                       <p className="text-center">{cl.text}</p>
                                   </div>
                                   <div className="position-absolute d-flex gap-1" style={{
                                       top: '20px',
                                       left: '20px'
                                   }}>
                                       {
                                           cl.icons.map((c, ci) => {
                                               return (
                                                   <FontAwesomeIcon key={ci+1} icon={c} />
                                               )
                                           })
                                       }
                                   </div>
                               </Col>
                           )
                       })
                   }
               </Row>
           </div>
        </Col>
   </Row>
    )
}

export default CustomerSelectPage;


const cutomerList = [
    {
        icons: ["fa-solid fa-circle-user"],
        customerNo: 1,
        text: 'Customer'
    },
    {
        icons: ["fa-solid fa-circle-user", "fa-solid fa-circle-user"],
        customerNo: 2,
        text: 'Customers'
    },
    {
        icons: ["fa-solid fa-circle-user", "fa-solid fa-circle-user", "fa-solid fa-circle-user"],
        customerNo: 3,
        text: 'Customers'
    },
    {
        icons: ["fa-solid fa-circle-user", "fa-solid fa-circle-user", "fa-solid fa-circle-user", "fa-solid fa-circle-user"],
        customerNo: 4,
        text: 'Customers'
    }
]