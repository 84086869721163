import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { H4 } from "../../styles/Common.style";
import moment from "moment";

const UnitInfoCard = ({ unitInformation }) => {
  console.log("unitInformation", unitInformation);

  return (
    <div>
      <Row>
        <Col className="card-box p-3" style={{ height: "448px" }}>
          {unitInformation?.image == "" ? (
            <div
              style={{ height: "150px", border: "1px solid #888" }}
              className=" radius-4 grey-text d-flex justify-content-center align-items-center"
            >
              Property image is not available
            </div>
          ) : (
            <div
              className="radius-8"
              style={{
                backgroundImage: `url(${unitInformation?.image})`,
                height: "150px",
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                boxSizing: "border-box",
              }}
            ></div>
          )}

          <Row className="my-3 grey-text">
            <Col lg={5} md={12}>
              <H4 className="mb-0">{unitInformation?.property_name}</H4>

              <p className="mb-0 paragraph2-light grey-text">
                {unitInformation?.street_name} {unitInformation?.house_number},{" "}
                {unitInformation?.floor_number}
              </p>
              <p className="paragraph2-light grey-text mb-0">
                {unitInformation?.zipcode_id} {unitInformation?.city}
              </p>
              <p className="paragraph2-light grey-text mb-0">
                {unitInformation?.country}
              </p>
            </Col>

            <Col lg={7} md={12} className="property-details p-0">
              <div className="d-flex justify-content-between screening-border py-1">
                <span className="paragraph2-light">
                  <span className="pe-3 ps-1 py-1">
                    <FontAwesomeIcon
                      icon="fak fa-square-meter-light-3"
                      size="lg"
                    />
                  </span>{" "}
                  Size
                </span>
                <div className="paragraph2-regular">
                  {unitInformation?.area ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${unitInformation?.area} ${unitInformation?.square_measure_unit}`,
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between screening-border py-1">
                <span className="paragraph2-light">
                  <span className="pe-3 ps-1 py-1">
                    <FontAwesomeIcon icon="fa-regular fa-bed-front" size="lg" />
                  </span>{" "}
                  Rooms
                </span>
                <span className="paragraph2-regular">
                  {unitInformation?.no_of_rooms
                    ? unitInformation?.no_of_rooms +
                      " " +
                      (unitInformation?.no_of_rooms > 1 ? "rooms" : "room")
                    : "-"}
                </span>
              </div>
              <div className="d-flex justify-content-between screening-border py-1">
                <span className="paragraph2-light nowrap">
                  <span className="pe-3 ps-1 py-1">
                    <FontAwesomeIcon
                      icon="fa-regular fa-piggy-bank"
                      size="lg"
                    />
                  </span>{" "}
                  Monthly rent
                </span>
                <span className="paragraph2-regular">
                  {unitInformation?.rent
                    ? unitInformation?.rent + " " + unitInformation?.currency_id
                    : "-"}
                </span>
              </div>
              <div className="d-flex justify-content-between screening-border py-1">
                <span className="paragraph2-light">
                  <span className="pe-3 ps-1 py-1">
                    <FontAwesomeIcon
                      icon="fa-regular fa-house-water"
                      size="lg"
                    />
                  </span>{" "}
                  Move-in
                </span>
                <span className="paragraph2-regular">
                  {unitInformation?.move_in_date
                    ? moment(unitInformation?.move_in_date).format("DD.MM.YY")
                    : "-"}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UnitInfoCard;
