import { Col, Row } from "react-bootstrap";
import dashboardHeaderImage from "../../../../assets/images/top-header-image-pattern.svg";
import dashboardHeaderImage2 from "../../../../assets/images/top-header-image-pattern2.svg";
import unitImage from "../../../../assets/images/unit-square-image.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H1 } from '../../../../styles/Common.style';
import { useState } from "react";

export const ScreeningInfo = () => {
    return(
        <Row className = "justify-content-center mt-2 mt-xll-5 pb-4">
        <Col className = "me-2 px-xl-5 me-0 mt-5" lg = {5} xxl = {5}>
                <div>
                    <div>
                        <H1>Okay, we are ready for the next step!</H1>
                    </div>
                    <div className = "fw-light">
                        <p>
                            We are now ready to start the screening and since you have chosen to fill this out on behalf of the other customers, you will be asked to answer questions about them.
                        </p>
                        <p>
                            Below you can see the apartment that you want to rent and that your screening will be based on. This is also the unit you will be approved to rent if we can approve the screening.
                        </p>
                    </div>
                    <div className = "mt-5">
                    <img src       = {unitImage} className = "w-100 h-100" alt = "unit" />
                    </div>
                </div>
            </Col>
            <Col lg = {4} xxl = {4} className = "px-xxl-5 mx-2 mx-xl-5 mt-5">
                <div>
                    <div className = "bg-body rounded p-4 p-xl-5">
                        <div>
                            <div className = "fw-normal fs-20">
                                Blomsterbjerget
                            </div>
                            <div>
                                <span className = "text-black-50 w-75 d-inline-block">
                                Blomstervangen 14, 2.tv
                                2765 Smørum
                                Denmark
                                </span>
                            </div>
                        </div>
                        <div className = "mt-4">
                            {
                                unitInfo.map((ui, uiIndex) => {
                                    return (
                                        <div             key       = {uiIndex} className = {`${!uiIndex && 'border-top'} border-bottom d-flex justify-content-between flex-wrap py-2`}>
                                        <div             className = "d-flex">
                                        <div             className = "d-flex me-2 me-xl-3 justify-content-center align-items-center">
                                        <div             style     = {{ width: '20px', height: '20px'}}>
                                        <FontAwesomeIcon className = "w-100 h-100" icon  = {ui.icon} />
                                                    </div>
                                                </div>
                                                <div className = "fs-6">
                                                    {ui.name}
                                                </div>
                                            </div>
                                            <div className = "d-flex fs-14">
                                            <p   className = "mb-0 fw-bold">{ui.data}</p>
                                                { Boolean(ui.postfix_data.length) && <span className="ms-1 fw-light"> {ui.postfix_data}</span>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}


const preScreeningTopImage = [
    // dashboardHeaderImage,
    // dashboardHeaderImage,
  dashboardHeaderImage2
]

const unitInfo = [
  {
      'name'        : 'Size',
      'data'        : '128 m2',
      'postfix_data': '',
      'icon'        : 'fak fa-square-meter-light-3'
  },
  {
      'name'        : 'Rooms',
      'data'        : '4 rooms',
      'postfix_data': '',
      'icon'        : 'fa-regular fa-bed-front'
  },
  {
      'name'        : 'Monthly rent',
      'data'        : '12.500 DKK',
      'postfix_data': '',
      'icon'        : 'fak fa-light-house-circle-euro-3'
  },
  {
      'name'        : 'Deposit',
      'data'        : '37.500',
      'postfix_data': '(3 months)',
      'icon'        : 'fa-regular fa-piggy-bank'
  },
  {
      'name'        : 'Prepaid rent',
      'data'        : '12.500 DKK',
      'postfix_data': '(1 month)',
      'icon'        : 'fak fa-light-circle-euro-circle-plus'
  },
  {
      'name'        : 'Rent regulation',
      'data'        : 'Netto prize index',
      'postfix_data': '',
      'icon'        : 'fak fa-rent-regulation'
  },
  {
      'name'        : 'Move-in',
      'data'        : '1 April, 2023',
      'postfix_data': '',
      'icon'        : 'fa-regular fa-calendar'
  },
  {
      'name'        : 'Pets allowed',
      'data'        : 'Yes',
      'postfix_data': '(application required)',
      'icon'        : 'fa-regular fa-paw'
  },
  {
      'name'        : 'Parking',
      'data'        : 'Yes',
      'postfix_data': '(Possible to rent)',
      'icon'        : 'fa-regular fa-car-side'
  },
  {
      'name'        : 'Orientation',
      'data'        : 'South',
      'postfix_data': '',
      'icon'        : 'fa-regular fa-compass'
  },
  {
      'name'        : 'Outdoor space',
      'data'        : 'Balcony',
      'postfix_data': '',
      'icon'        : 'fa-regular fa-sun-bright'
  },
  
]