import { IconButton, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "../../../assets/icons/Search.svg";

const FilterComponent = ({ data, handleSearch, lableText, placeholder }) => (
  <>
    <div className="search-property">
      <TextField
        fullWidth
        size="small"
        id="outlined-basic"
        value={data}
        onChange={handleSearch}
        placeholder={placeholder}
        label={lableText}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFF",
            color: "#000",
            "& fieldset": {
              borderRadius: "6px",
              fontSize: "14px",
            },
            "&:hover fieldset": {
              borderColor: "rgba(17, 23, 71, 0.1)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(17, 23, 71, 0.1)",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(17, 23, 71, 0.1)",
            },
          },
        }}
        InputLabelProps={{
          sx: {
            fontFamily: "Cerebri sans",
            fontSize: "14px",
            color: "#000",
            "&.Mui-focused": { color: "#888888" },
          },
        }}
        InputProps={{
          style: {
            borderRadius: "8px",
            // border: "1px solid #F4F5F5",
            Color: "#f4f5f5",
            paddingLeft: "5px",
          },
          startAdornment: (
            <IconButton>
              <img
                height={16}
                className="question-mark"
                src={SearchIcon}
                alt="Question mark"
              />
            </IconButton>
          ),
        }}
      />
    </div>
  </>
);

export default FilterComponent;
