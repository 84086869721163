import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import FilterComponent from "../../../Components/input/searchField/SearchField";
import { useQuery } from "react-query";
import { GetDocumentByPropertyId } from "../../../services/Documents";
import ReactSelect from "../../../Components/input/ReactSelect";
import moment from "moment";
import Image from "react-bootstrap/Image";

export default function GuideViews({ selectedUnitData }) {
  const [gridList, setGridList] = useState(0);
  const [guide, setGuide] = useState([]);
  const [guidesPerPage, setGuidesPerPage] = useState(12);
  const [currentGuidePage, setCurrentGuidePage] = useState(1);
  const [guidesToDisplayData, setGuidesToDisplayData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(
    Math.min(startIndex + guidesPerPage - 1, guide?.length - 1)
  );
  const [filteredGuides, setFilteredGuides] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleGridListToggle = (id) => {
    setGridList(id);
  };

  const {
    data: getDocumentsByPropertyId,
    refetch: getDocumentsByPropertyIdRefetch,
    isLoading,
  } = useQuery(
    ["getDocumentsByPropertyId", selectedUnitData?.property_id],
    () => GetDocumentByPropertyId(selectedUnitData?.property_id),
    { enabled: !!selectedUnitData?.property_id }
  );
  // State Management for Guides

  useEffect(() => {
    setGuide(getDocumentsByPropertyId?.data);
    getDocumentsByPropertyIdRefetch();
  }, [getDocumentsByPropertyId]);

  console.log("filteredGuides?.length", filteredGuides?.length);

  // Pagination

  const totalNumberOfGuide = guide?.length; // Total number of items

  const totalPages = Math.ceil(totalNumberOfGuide / guidesPerPage);
  const handleGuidesPerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value);
    setGuidesPerPage(newPerPage);
    setCurrentGuidePage(1); // Reset to the first page when changing the number of items per page
  };

  useEffect(() => {
    // Calculate the new start and end indices based on the current page and items per page
    const newStartIndex = (currentGuidePage - 1) * guidesPerPage;
    const newEndIndex = Math.min(
      newStartIndex + guidesPerPage - 1,
      totalNumberOfGuide - 1
    );

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);

    // Slice the data to display based on the new indices
    setGuidesToDisplayData(guide?.slice(newStartIndex, newEndIndex + 1));
  }, [guide, currentGuidePage, guidesPerPage]);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };

  useEffect(() => {
    // If the input field is empty, display all guides
    if (searchValue == "") {
      setFilteredGuides(guidesToDisplayData);
    } else {
      // Parse the searchValue as a Date object (assuming it's in the "DD MMM" format)
      const searchDate = moment(searchValue, "DD MMM").toDate();

      // Filter the guides based on the search criteria
      const filtered = guide?.filter((data) => {
        const documentName = data?.title?.toLowerCase().includes(searchValue);
        const documentType = data?.description

          ?.toLowerCase()
          .includes(searchValue);

        // Parse the date from the data in the same format
        const dateOfUpload = moment(data?.created_on).toDate();

        const uploadBy = data?.created_by?.toLowerCase().includes(searchValue);
        const title = data?.title?.toLowerCase().includes(searchValue);

        // Compare the parsed dates
        return (
          documentName ||
          documentType ||
          title ||
          uploadBy ||
          moment(dateOfUpload)?.isSame(searchDate, "day") // Compare dates without considering hours, minutes, and seconds
        );
      });

      setFilteredGuides(filtered);
    }
  }, [searchValue, guidesToDisplayData]);

  // useEffect(() => {
  //   // If the input field is empty, display all guides
  //   if (searchValue === "") {
  //     setFilteredGuides(guidesToDisplayData);
  //   } else {
  //     // Otherwise, filter the guides based on the input value
  //     const filtered = guidesToDisplayData.filter(
  //       (data) =>
  //         data?.name?.toLowerCase().includes(searchValue) &&
  //         guidesToDisplayData.filter((data) =>
  //           data?.name?.toLowerCase().includes(searchValue)
  //         )
  //     );
  //     setFilteredGuides(filtered);
  //   }
  // }, [searchValue, guidesToDisplayData]);

  // sorting

  const [sortOrder, setSortOrder] = useState("ascending"); // Initialize with ascending order
  const [sortBy, setSortBy] = useState("doc_file_id"); // Default sorting by doc_file_id

  // Function to toggle the sorting order
  const toggleSortOrder = () => {
    if (sortOrder === "ascending") {
      // Sort in ascending order
      const sortedGuides = [...filteredGuides].sort((a, b) =>
        a[sortBy] < b[sortBy] ? -1 : 1
      );

      setFilteredGuides(sortedGuides);
      setSortOrder("descending");
    } else {
      // Sort in descending order
      const sortedGuides = [...filteredGuides].sort((a, b) =>
        a[sortBy] > b[sortBy] ? -1 : 1
      );
      setFilteredGuides(sortedGuides);
      setSortOrder("ascending");
    }
  };

  // Function to set the sorting column
  const setSortingColumn = (column) => {
    setSortBy(column);
    toggleSortOrder(); // Reset sorting order when changing columns
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <div className="Cerebri-sans-bold mt-1" style={{ fontSize: "32px" }}>
            Guides
          </div>
        </Col>

        <Col className="mt-3" lg={3}>
          {/* <input type="search"  className="p-1"/> */}
          <FilterComponent
            placeholder="Search"
            className="py-2 px-3"
            data={searchValue}
            handleSearch={handleInputChange}
          />
        </Col>
        <Col className="mt-3" lg={3}>
          <ButtonGroup aria-label="Basic example">
            <Button
              onClick={() => {
                handleGridListToggle(0);
              }}
              className="py-2"
              style={{
                color: gridList == 0 ? "#ffffff" : "#000",
                backgroundColor: gridList == 0 ? "#0C1356" : "#fff",
                fontWeight: gridList == 0 ? 600 : 300,
                border: "1px solid #888888",
                padding: "1.8rem",
              }}
            >
              <FontAwesomeIcon
                className="me-1"
                icon=" me-1 fa-regular fa-grid"
              />
              GRID
            </Button>
            <Button
              onClick={() => {
                handleGridListToggle(1);
              }}
              className="py-2"
              style={{
                color: gridList == 1 ? "#ffffff" : "#000",
                backgroundColor: gridList == 1 ? "#0C1356" : "#fff",
                fontWeight: gridList == 1 ? 600 : 300,
                border: "1px solid #888888",
                padding: "1.8rem",
              }}
            >
              <FontAwesomeIcon className="me-1" icon="fa-regular fa-list" />
              LIST
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {gridList === 0 ? (
        !filteredGuides || !filteredGuides.length ? (
          <div className="d-flex justify-content-center align-item-center p-5">
            NO GUIDES DATA
          </div>
        ) : (
          <Row className="px-0 pt-3">
            {filteredGuides?.map((data, i) => {
              return (
                <Col
                  key={i}
                  lg={3}
                  md={6}
                  xl={2}
                  sm={3}
                  className="position-relative d-sm-block my-2 cursor guide-manager"
                  style={{
                    width: "240px",
                  }}
                >
                  <div onClick={() => window.open(data?.guide_url)}>
                    <div
                      className="d-inline-block position-relative guide-img radius-12 overflow-hidden mx-auto"
                      style={{
                        width: "100%",
                        height: "150px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                        src={
                          data?.image_url === ""
                            ? selectedUnitData.image
                            : data?.image_url
                        }
                        alt="guide"
                      />
                    </div>
                    <div
                      className="position-absolute px-3 color-white"
                      style={{ bottom: "20px" }}
                    >
                      <div
                        className="medium-text mb-0 text-truncate"
                        style={{
                          width: "190px",
                          fontSize: "16px",
                        }}
                      >
                        {data?.title.charAt(0).toUpperCase() +
                          data?.title.slice(1)}
                      </div>
                      <div
                        className="fs-7 mb-0 text-truncate grey-text"
                        style={{ width: "190px" }}
                      >
                        {data?.description.charAt(0).toUpperCase() +
                          data?.description.slice(1)}
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        )
      ) : (
        <div className="px-3 py-2 pt-4 pb-0">
          <Table
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06)",
              borderBottom: "none !important",
              borderRadius: "12px",
              overflowX: "scroll",
            }}
            className="p-3"
          >
            <thead>
              <tr>
                <th className="medium-text-14 px-4 py-3" width="25%">
                  Document name{" "}
                  <span
                    className="ps-1 cursor"
                    onClick={() => setSortingColumn("title")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-sort" color="#c7c7c7" />
                  </span>
                </th>
                <th
                  className="medium-text-14  py-3"
                  width="25%"
                  onClick={() => setSortingColumn("description")}
                >
                  Description{" "}
                  <span className=" cursor ps-1">
                    <FontAwesomeIcon icon="fa-solid fa-sort" color="#c7c7c7" />
                  </span>
                </th>
                <th className="medium-text-14  py-3" width="20%">
                  Date of upload{" "}
                  <span
                    className=" cursor ps-1"
                    onClick={() => setSortingColumn("created_on")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-sort" color="#c7c7c7" />
                  </span>
                </th>
                <th className="medium-text-14  py-3 " width="15%">
                  Uploaded by{" "}
                  <span
                    className="cursor ps-1"
                    onClick={() => setSortingColumn("created_by")}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-sort" color="#c7c7c7" />
                  </span>
                </th>
                <th
                  className="medium-text-14  py-3  "
                  width="15%"
                  onClick={() => setSortingColumn("title")}
                >
                  File type{" "}
                  <span className=" cursor ps-1">
                    <FontAwesomeIcon icon="fa-solid fa-sort" color="#c7c7c7" />
                  </span>
                </th>
              </tr>
            </thead>

            {!filteredGuides || !filteredGuides.length ? (
              <tbody className="p-5">
                <tr>
                  <td></td>
                  <td
                    align="right"
                    width="30%"
                    style={{ padding: "35px", height: "114px" }}
                  >
                    NO GUIDES DATA
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredGuides?.map((item, index) => (
                  <tr key={index}>
                    <td
                      className="light-text-14 px-4 cursor"
                      width="25%"
                      onClick={() => window.open(item?.guide_url)}
                    >
                      {item?.title.charAt(0).toUpperCase() +
                        item?.title.slice(1)}
                    </td>
                    <td
                      className="light-text-14"
                      width="25%"
                      style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                      {item?.description.charAt(0).toUpperCase() +
                        item?.description.slice(1)}
                    </td>
                    <td className="light-text-14" width="20%">
                      {" "}
                      {item?.created_on !== ""
                        ? moment(item?.created_on).format("DD MMM, YYYY")
                        : "-"}
                    </td>
                    <td className="light-text-14" width="15%">
                      {/* {item.created_by} */}
                      {item.created_by.charAt(0).toUpperCase() +
                        item?.created_by.slice(1)}
                    </td>
                    <td
                      className="light-text-14"
                      width="15%"
                      // style={{borderBottomWidth : 'inherit'}}
                    >
                      {item.guide_filename?.split(".")[1]?.toUpperCase()}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </div>
      )}

      <Row>
        <div className="d-flex justify-content-between align-items-center flex-wrap px-3">
          {/* Display current page and total items */}
          <div className="regular fs-13">
            Showing {endIndex + 1} of {totalNumberOfGuide} items
          </div>

          <div className="pt-3">
            <Pagination className="d-flex gap-1">
              <Pagination.Prev
                onClick={() => setCurrentGuidePage(currentGuidePage - 1)}
                disabled={
                  currentGuidePage === 1 ||
                  !filteredGuides ||
                  !filteredGuides.length
                }
              >
                {" "}
                <FontAwesomeIcon
                  icon="fa-regular fa-angle-left"
                  size="lg"
                />{" "}
                {/* Use FontAwesome icon here */}
              </Pagination.Prev>
              {pageNumbers.map((pageNumber) => (
                <Pagination.Item
                  className="custom-pagination-button" // Apply your custom CSS class here
                  key={pageNumber}
                  onClick={() => {
                    setCurrentGuidePage(pageNumber);
                  }}
                  active={currentGuidePage === pageNumber}
                >
                  {pageNumber}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => setCurrentGuidePage(currentGuidePage + 1)}
                disabled={
                  currentGuidePage === totalPages ||
                  !filteredGuides ||
                  !filteredGuides.length
                }
              >
                <FontAwesomeIcon icon="fa-regular fa-angle-right" size="lg" />{" "}
              </Pagination.Next>
            </Pagination>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="regular fs-13">Display</div>
            <div>
              <select
                className="fs-13 form-select cursor"
                aria-label="Default select example"
                style={{ width: "66px", height: "37px" }}
                onChange={handleGuidesPerPageChange}
                value={guidesPerPage}
              >
                <option value={12}>
                  <div className="cursor">12</div>
                </option>
                <option className="cursor" value={24}>
                  24
                </option>
              </select>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}
