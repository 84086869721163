import managementLogo      from "../../assets/images/management-logo.svg";
import loginImagePattern   from "../../assets/images/login-image-pattern.svg";
import { Col, Row, Image } from "react-bootstrap";
import { useMediaQuery }   from "@material-ui/core";
import "./loginLayout.css"

export const LoginLayout = ({children}) => {
    const isMobile = useMediaQuery("(max-width:576px)")

  return (
    <>
      <div
        className = "pt-3 bg-login d-flex justify-content-center align-items-center px-md-4"
        style     = {{ position: "relative" }}
      >
        <div className = "mb-4">
          <img
            src       = {managementLogo}
            alt       = "management-logo"
            className = "management-logo d-block m-auto"
          />
            <Row className = "login-layout mb-1">
            <Col sm        = {12}>
            <Row className = "loginForm mt-3 mb-3 flex-column-reverse flex-sm-row position-relative">
            <Col sm        = {6} className = "login-right-section position-relative px-4">
                    {children}
                  </Col>
                  <Col sm = {6} className = "d-sm-block">
                    <Image
                      fluid
                      className = "w-100 h-100"
                      src       = {loginImagePattern}
                      alt       = "login-image-pattern"
                      style     = {{ objectFit: "cover", borderRadius: "8px", maxHeight: isMobile ? '80px' : '100%' }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
        </div>
        <div className = "copyright copyright-text">
          Copyright 2013-2023 © Estatetool A/S
        </div>
      </div>
    </>
  );
};
