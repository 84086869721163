export const question = [
    {
        "screening_category_id": 1,
        "icon_name": "fa-solid fa-user",
        "display_order_no": 1,
        "is_toggle_visibility_allowed": 0,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 1,
        "name": "Information",
        "description": "Please fill out your personal information below",
        "section": [
            {
                "screening_form_section_id": 1,
                "screening_form_id": 0,
                "display_order_no": 1,
                "screening_category_id": 1,
                "name": "Information",
                "description": "Please fill out your personal information below",
                "question": [
                    {
                        "screening_question_id": 1,
                        "screening_field_id": 1,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your first name, and if you have a middle name",
                        "placeholder": "First name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "text",
                        "is_group_field": 0,
                        "parent_screening_field_id": 3
                    },
                    {
                        "screening_question_id": 2,
                        "screening_field_id": 2,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your last name(s)",
                        "placeholder": "Last name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "text",
                        "is_group_field": 0,
                        "parent_screening_field_id": 3
                    },
                    {
                        "screening_question_id": 3,
                        "screening_field_id": 3,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "",
                        "placeholder": "Full Name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "name",
                        "is_group_field": 1,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 4,
                        "screening_field_id": 4,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your current street name",
                        "placeholder": "Street",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "text",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 5,
                        "screening_field_id": 5,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your current house number",
                        "placeholder": "Number",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "number",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 6,
                        "screening_field_id": 6,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please select your current floor, if you live in a aparment",
                        "placeholder": "Floor",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": null,
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 7,
                        "screening_field_id": 7,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please select the side your apartment is located, if necessary",
                        "placeholder": "Side",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [
                            {
                                "value": "tv.",
                                "text": "tv.",
                                "isSelected": 0
                            },
                            {
                                "value": "mf.",
                                "text": "mf.",
                                "isSelected": 0
                            },
                            {
                                "value": "th.",
                                "text": "th.",
                                "isSelected": 0
                            }
                        ],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 8,
                        "screening_field_id": 8,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your current door, if necessary",
                        "placeholder": "Door",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "text",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 9,
                        "screening_field_id": 9,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your current city",
                        "placeholder": "City",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "text",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 10,
                        "screening_field_id": 10,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your current zip code of your city",
                        "placeholder": "Zip Code",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 11,
                        "screening_field_id": 11,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in the country you are living in right now",
                        "placeholder": "Country",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "1",
                        "populate_db_field_name": "country",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 12
                    },
                    {
                        "screening_question_id": 12,
                        "screening_field_id": 12,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "",
                        "placeholder": "Current address",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "address",
                        "is_group_field": 1,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 13,
                        "screening_field_id": 13,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your primary email address",
                        "placeholder": "Email",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "email",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 14,
                        "screening_field_id": 14,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your primary phone number",
                        "placeholder": "Phone number",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "number",
                        "is_group_field": 0,
                        "parent_screening_field_id": 16
                    },
                    {
                        "screening_question_id": 15,
                        "screening_field_id": 15,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "",
                        "placeholder": "Country Code",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "1",
                        "populate_db_field_name": "phone",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 16
                    },
                    {
                        "screening_question_id": 16,
                        "screening_field_id": 16,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "",
                        "placeholder": "Phone number",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "phone",
                        "is_group_field": 1,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 17,
                        "screening_field_id": 17,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your birthday",
                        "placeholder": "Birthday",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "date",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 19,
                        "screening_field_id": 19,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please select your nationality",
                        "placeholder": "Nationality",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "1",
                        "populate_db_field_name": "country",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    }
                ]
            },
            {
                "screening_form_section_id": 2,
                "screening_form_id": 0,
                "display_order_no": 2,
                "screening_category_id": 1,
                "name": "Information",
                "description": "Please fill out your personal information below",
                "question": [
                    {
                        "screening_question_id": 18,
                        "screening_field_id": 18,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your danish social security number",
                        "placeholder": "Social security number",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "number",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    }
                ]
            }
        ]
    },
    {
        "screening_category_id": 2,
        "icon_name": "fa-solid fa-shield-halved",
        "display_order_no": 2,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Credit check",
        "description": "Before we can continue the screening, it is a requirement to complete a credit check based on the information you have provided.",
        "section": [
            {
                "screening_form_section_id": 3,
                "screening_form_id": 0,
                "display_order_no": 1,
                "screening_category_id": 1,
                "name": "Information",
                "description": "Please fill out your personal information below",
                "question": [
                    {
                        "screening_question_id": 20,
                        "screening_field_id": 20,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fadd your comment",
                        "placeholder": "First name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "textarea",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    }
                ]
            }
        ]
    },
    {
        "screening_category_id": 3,
        "icon_name": "fa-solid fa-briefcase",
        "display_order_no": 3,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Occupation",
        "description": "Please select your answer and upload the necessary documents below",
        "section": [
            {
                "screening_form_section_id": 3,
                "screening_form_id": 0,
                "display_order_no": 1,
                "screening_category_id": 1,
                "name": "Information",
                "description": "Please fill out your personal information below",
                "question": [
                    {
                    "screening_question_id": 21,
                    "screening_field_id": 21,
                    "is_question_skipped": "0",
                    "answer": "",
                    "is_yellow_flag": "0",
                    "is_red_flag": "0",
                    "headline": null,
                    "description": "Please fill in your first name, and if you have a middle name",
                    "placeholder": "First name",
                    "screening_yellow_flag_message": "1",
                    "screening_red_flag_message": "2",
                    "is_required": 1,
                    "field_rules": [
                        {
                            "maxLength": " "
                        }
                    ],
                    "screening_yellow_flag_condition_id": 0,
                    "screening_red_flag_condition_id": 0,
                    "is_prescreening_question": 1,
                    "display_order_no": 1,
                    "is_field_condition_activated": 0,
                    "is_prepopulated_from_db": "0",
                    "populate_db_field_name": "",
                    "field_condition_rules": {
                        "action_type": 1,
                        "logic_type": 1,
                        "rules": [
                            {
                                "screening_question_id": 211,
                                "operator": "=",
                                "value": "employed"
                            },
                            {
                                "screening_question_id": 212,
                                "operator": "=",
                                "value": "student"
                            }
                        ]
                    },
                    "screening_condition_rules": [],
                    "is_screening_condition_activated": 0,
                    "possible_values": [
                        {
                            "value": "student",
                            "text": "student",
                            "isSelected": 0
                        },
                        {
                            "value": "employed",
                            "text": "employed",
                            "isSelected": 0
                        }
                    ],
                    "field_type": "radio",
                    "is_group_field": 0,
                    "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 211,
                        "screening_field_id": 20,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please add your comment",
                        "placeholder": "First name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [
                            {
                                "value": 1,
                                "text": "Computer engineer",
                                "isSelected": 0
                            },
                            {
                                "value": 2,
                                "text": "Doctor",
                                "isSelected": 0
                            }
                        ],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 212,
                        "screening_field_id": 20,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fadd your comment",
                        "placeholder": "First name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [
                            {
                                "value": 1,
                                "text": "Primary education",
                                "isSelected": 0
                            },
                            {
                                "value": 2,
                                "text": "Secondary education",
                                "isSelected": 0
                            },
                            {
                                "value": 3,
                                "text": "Bachelor's degree",
                                "isSelected": 0
                            },
                            {
                                "value": 4,
                                "text": "Master's degree",
                                "isSelected": 0
                            },
                            {
                                "value": 5,
                                "text": "Doctorate or higher",
                                "isSelected": 0
                            }
                        ],
                        "field_type": "select",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 429,
                        "screening_field_id": 20,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fadd your comment",
                        "placeholder": "First name",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [
                            {
                                "maxLength": " "
                            }
                        ],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "file",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                ],
            }
        ]
    },
    {
        "screening_category_id": 4,
        "icon_name": "fa-solid fa-landmark",
        "display_order_no": 4,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Economy",
        "description": "Please upload the necessary documents below",
        "section": [
           {
            "screening_form_section_id": 3,
            "screening_form_id": 0,
            "display_order_no": 1,
            "screening_category_id": 1,
            "name": "Information",
            "description": "Please fill out your personal information below",
            "question": [
                {
                    "screening_question_id": 425,
                    "screening_field_id": 1,
                    "is_question_skipped": "0",
                    "answer": "",
                    "is_yellow_flag": "0",
                    "is_red_flag": "0",
                    "headline": "headline en",
                    "description": "description en",
                    "placeholder": "425",
                    "screening_yellow_flag_message": "Yellow message english",
                    "screening_red_flag_message": "Red message english",
                    "is_required": 1,
                    "field_rules": [
                        {
                            "maxlength": "120"
                        }
                    ],
                    "screening_yellow_flag_condition_id": 1,
                    "screening_red_flag_condition_id": 1,
                    "is_prescreening_question": 1,
                    "display_order_no": 1,
                    "is_field_condition_activated": 1,
                    "is_prepopulated_from_db": "0",
                    "populate_db_field_name": "",
                    "field_condition_rules": {
                        "action_type": 1,
                        "logic_type": 1,
                        "rules": [
                            {
                                "screening_question_id": 424,
                                "operator": ">=",
                                "value": "20"
                            }
                        ]
                    },
                    "screening_condition_rules": {
                        "flag_type": 1,
                        "logic_type": 1,
                        "show_message": 1,
                        "rules": [
                            {
                                "operator": ">",
                                "value": "30"
                            },
                            {
                                "operator": "<",
                                "value": "50"
                            }
                        ]
                    },
                    "is_screening_condition_activated": 1,
                    "possible_values": [],
                    "field_type": "number",
                    "is_group_field": 0,
                    "parent_screening_field_id": 0
                },
                {
                    "screening_question_id": 426,
                    "screening_field_id": 1,
                    "is_question_skipped": "0",
                    "answer": "",
                    "is_yellow_flag": "0",
                    "is_red_flag": "0",
                    "headline": "headline en",
                    "description": "description en",
                    "placeholder": "placeholder en",
                    "screening_yellow_flag_message": "Yellow message english",
                    "screening_red_flag_message": "Red message english",
                    "is_required": 1,
                    "field_rules": [
                        {
                            "maxlength": "120"
                        }
                    ],
                    "screening_yellow_flag_condition_id": 1,
                    "screening_red_flag_condition_id": 1,
                    "is_prescreening_question": 1,
                    "display_order_no": 1,
                    "is_field_condition_activated": 1,
                    "is_prepopulated_from_db": "0",
                    "populate_db_field_name": "",
                    "field_condition_rules": [],
                    "screening_condition_rules": [],
                    "is_screening_condition_activated": 1,
                    "possible_values": [
                        {
                            "text": 0,
                            "value": "Texten",
                            "isSelected": 1
                        },
                        {
                            "text": 1,
                            "value": "Text 2en",
                            "isSelected": 0
                        },
                        {
                            "text": 0,
                            "value": "Texten",
                            "isSelected": 1
                        },
                        {
                            "text": 1,
                            "value": "Text 2en",
                            "isSelected": 0
                        },
                        {
                            "text": 0,
                            "value": "Texten",
                            "isSelected": 1
                        },
                        {
                            "text": 1,
                            "value": "Text 2en",
                            "isSelected": 0
                        }
                    ],
                    "field_type": "text",
                    "is_group_field": 0,
                    "parent_screening_field_id": 0
                },
                {
                    "screening_question_id": 424,
                    "screening_field_id": 20,
                    "is_question_skipped": "0",
                    "answer": "",
                    "is_yellow_flag": "0",
                    "is_red_flag": "0",
                    "headline": null,
                    "description": "Please fadd your comment",
                    "placeholder": "First name",
                    "screening_yellow_flag_message": "1",
                    "screening_red_flag_message": "2",
                    "is_required": 1,
                    "field_rules": [
                        {
                            "maxLength": " "
                        }
                    ],
                    "screening_yellow_flag_condition_id": 0,
                    "screening_red_flag_condition_id": 0,
                    "is_prescreening_question": 1,
                    "display_order_no": 1,
                    "is_field_condition_activated": 0,
                    "is_prepopulated_from_db": "0",
                    "populate_db_field_name": "",
                    "field_condition_rules": [],
                    "screening_condition_rules": [],
                    "is_screening_condition_activated": 0,
                    "possible_values": [],
                    "field_type": "textarea",
                    "is_group_field": 0,
                    "parent_screening_field_id": 0
                }
            ]
           }
        ]
    },
    {
        "screening_category_id": 5,
        "icon_name": "fa-kit fa-salary-slip-svg-test2",
        "display_order_no": 5,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Salary slip",
        "description": "",
        "section": [
            {
                "screening_form_section_id": 100,
                "screening_form_id": 0,
                "display_order_no": 1,
                "screening_category_id": 1,
                "name": "Information",
                "description": "Please fill out your personal information below",
                "question": [
                    {
                        "screening_question_id": 50,
                        "screening_field_id": 50,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your primary email address",
                        "placeholder": "Email",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [
                            {
                                "value": 1,
                                "text": "dog",
                                "isSelected": 0
                            },
                            {
                                "value": 2,
                                "text": "2",
                                "isSelected": 0
                            },
                            {
                                "value": 3,
                                "text": "3",
                                "isSelected": 0
                            }
                        ],
                        "field_type": "checkbox",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                    {
                        "screening_question_id": 52,
                        "screening_field_id": 52,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your primary phone number",
                        "placeholder": "Phone number",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "number",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    },
                ]
            },
            {
                "screening_form_section_id": 2,
                "screening_form_id": 0,
                "display_order_no": 2,
                "screening_category_id": 1,
                "name": "Information",
                "description": "Please fill out your personal information below",
                "question": [
                    {
                        "screening_question_id": 18,
                        "screening_field_id": 18,
                        "is_question_skipped": "0",
                        "answer": "",
                        "is_yellow_flag": "0",
                        "is_red_flag": "0",
                        "headline": null,
                        "description": "Please fill in your danish social security number",
                        "placeholder": "Social security number",
                        "screening_yellow_flag_message": "1",
                        "screening_red_flag_message": "2",
                        "is_required": 1,
                        "field_rules": [],
                        "screening_yellow_flag_condition_id": 0,
                        "screening_red_flag_condition_id": 0,
                        "is_prescreening_question": 1,
                        "display_order_no": 1,
                        "is_field_condition_activated": 0,
                        "is_prepopulated_from_db": "0",
                        "populate_db_field_name": "",
                        "field_condition_rules": [],
                        "screening_condition_rules": [],
                        "is_screening_condition_activated": 0,
                        "possible_values": [],
                        "field_type": "number",
                        "is_group_field": 0,
                        "parent_screening_field_id": 0
                    }
                ]
            }
        ]
    },
    {
        "screening_category_id": 6,
        "icon_name": "fa-solid fa-house-user",
        "display_order_no": 6,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Household",
        "description": "Please select your answer below",
        "section": []
    },
    {
        "screening_category_id": 7,
        "icon_name": "fa-solid fa-passport",
        "display_order_no": 7,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Identification",
        "description": "Please upload the necessary documents below",
        "section": []
    },
    {
        "screening_category_id": 8,
        "icon_name": "fa-solid fa-file-lines",
        "display_order_no": 8,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Additional documents",
        "description": "",
        "section": []
    },
    {
        "screening_category_id": 9,
        "icon_name": "fa-solid fa-circle-exclamation",
        "display_order_no": 9,
        "is_toggle_visibility_allowed": 1,
        "is_section_add_allowed": 0,
        "is_question_add_allowed": 0,
        "name": "Additional information",
        "description": "Authorize access to check below",
        "section": []
    }
]