import { Col, Row } from "react-bootstrap";
import waitingMan from "../../assets/images/waiting_man.png";
import creditSuccess from "../../assets/images/creditCheck-success.svg";

const ScreeningCreditCheck = ({ creditCheck, creditCheckClicked }) => {
    return (
        <Row>
            <Col>
            {
                creditCheck === true ?
                <CreditCheckShowResult />
                : creditCheck === false ?
                <CreditCheckShowNotResult />
                : null
            }
           { creditCheck === null && <div className="screening-loader-container mt-5">
                <div className="wavy">
                    <span style={{"--i": "1"}}></span>
                    <span style={{"--i": "2"}}></span>
                    <span style={{"--i": "3"}}></span>
                    <span style={{"--i": "4"}}></span>
                </div>
            </div>}
            <div className="d-none d-md-block position-absolute" style={{ width: 'fit-content', bottom: '10px' }}>
                <img style={{ width: '200px', height: '100%' }} src={waitingMan} alt="waiting-man"></img>
            </div>
            </Col>
        </Row>
    )
}

export default ScreeningCreditCheck;

const CreditCheckShowNotResult = () => {
    return (
    <div className="mx-auto" style={{ maxWidth: '420px' }}>
        <p>Thank you, we have now completed the automated credit check and you can now continue and complete the screening.</p>
    </div>
    )
}

const CreditCheckShowResult = () => {
    return (
    <div className="mx-auto" style={{ maxWidth: '470px' }}>
        <p>Thank you, we have now completed the automated credit check and everything meets our guidelines. You can now continue and complete the screening.</p>
        <span className="fs-20">Your credit check is approved</span>
        <div className="mt-3">
            <img style={{ widht: '60px', height: '60px' }} src={creditSuccess} alt="success" />
        </div>
    </div>
    )
}