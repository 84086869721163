import { NavLink } from "react-router-dom";
import { H3 } from "../../styles/Common.style";
import { myPage } from "../../styles/Theme";

const CardTopbar = ({ label, path }) => {
  return (
    <div className="d-flex justify-content-between fs-8 fw-400 p-3 bottom-border-line ">
      <H3 color={myPage.primaryTextColor} className="mb-0">
        {label}
      </H3>
      {path && (
        <NavLink className="paragraph2-regular" to={`/${path}`}>
          View all
        </NavLink>
      )}
    </div>
  );
};

export const Card = ({
  label,
  path,
  Component,
  leadType,
  data,
  showBtn,
  noDataStatus,
}) => {
  // console.log( Component, data, 'card')
  return (
    <div className="card-container card-box p-0 mt-3">
      <CardTopbar label={label} path={path} />
      <div className="scrollable-container-y" style={{ height: "320px" }}>
        <Component
          leadType={leadType}
          componentData={data}
          showBtn={showBtn}
          noDataStatus={noDataStatus}
        />
      </div>
    </div>
  );
};
