import { Button, Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import unitImage from "../../../../../src/assets/images/unit-square-image.jpg";

const Welcome = ({ getIndex, setIndex }) => {

    return (
        <div>
            <Layout>
                <Image
                className="w-100 h-100"
                   src={unitImage}
                   alt='unit-square-image'
                   style={{ objectFit: "cover", borderRadius: "8px" }}
                />
            </Layout>
            <Content className="mb-4">
                <div className="mx-auto" style={{width:'80%', textAlign:'center'}}>
                        <Heading >
                            We're excited to have you here!
                        </Heading>
                        <Para >
                            We know you're looking for a new home, and we're here to help.
                            This screening is designed to help us learn more about you and your needs.
                        </Para> 
                        <Button style={{ border:'none', backgroundColor:'#ADBE9E', borderRadius:'6px', width: '180px' }} onClick={() => setIndex({
                            ...getIndex,
                            prevIndex: 0,
                            welcomePage: false
                        })}>
                            Next
                        </Button>
                </div>
            </Content>
        </div>
    )
}


const Layout = styled.div`
    position:fixed;
    z-index: 100;
    width: 100%;
    height: 100%; 
    bottom: 0px;
    left: 0px;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(37, 37, 37, 0.1) 42.48%, #111747 62.25%, #111747 69.44%);
    }
`
const Content = styled.div`
    // height: 39%;
    // width : 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
    text-align: center;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
`
const Heading = styled.h1`
    color:white;
    font-weight: 700;

`
const Para = styled.p`
    color:white;
`

export default Welcome;