import { Link } from 'react-router-dom';
import NormalButton from '../../../Components/input/normalButton/NormalButton';
import { H1 } from '../../../styles/Common.style';
import { LoginLayout } from './../../../Components/LoginLayout/LoginLayout';
import { useState } from 'react';
import EmailVerification from './EmailVerification';
import { PhoneVerification } from './PhoneVerification';

const CreateNewProfile = () => {
    const [wizardIndex, setWizardIndex] = useState(0);

    return(
        <LoginLayout>
            {
                createNewProfileSteps?.[wizardIndex](setWizardIndex)
            }
        </LoginLayout>
    )
}

export default CreateNewProfile;


const createNewProfileSteps = {
    0: (setWizardIndex) => <CreateNewProfileHome setWizardIndex={setWizardIndex} />,
    1: (setWizardIndex) => <EmailVerification setWizardIndex={setWizardIndex} />,
    2: (setWizardIndex) => <PhoneVerification setWizardIndex={setWizardIndex} />
}

const CreateNewProfileHome = ({ setWizardIndex }) => {
    return(
        <>
        <div>
           <H1>Create profile</H1>
           <p className='fs-16 fw-bold mt-5'>Before we start</p>
           <p>We want to get to know you better.</p>
           <p>To be sure that we have the correct information we need to validate your information.</p>
           <p>Please go through the flow and update your information if needed.</p>
       </div>
       <NormalButton
           type="submit"
           className="fw-900"
           textColor=" white"
           border="none"
           backgroundColor="green"
           padding="9px 18px"
           borderRadius="6"
           fontSize="14"
           fontWeight="600"
           buttonText="START CREATION OF PROFILE"
           bstClassName="mt-5 mb-5"
           onClick={()=> setWizardIndex(1)}
           />
           <div className='position-absolute bottom-0'>
               <span className='primary-text-color fs-14 me-1'>Already have an account?</span>
               <Link to={'login'}><span className='button-primary-text fs-14'>Sign in</span></Link>
           </div>
       </>
    )
}