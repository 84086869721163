import { Endpoint, Site } from "./API/Endpoints";
import { Post } from "./API/Method";

// Actor - Post Add actor login
export const PostSendOtp = (otpPayload) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.SMS_Send_OTP, null, otpPayload)
			.then((response) => {
				resolve(response.data);
			})

			.catch((error) => {
				reject({ error: true, message: error.response.data.error });
			});
	});
};
// Actor - Post Add actor login
export const PostValidateOtp = (validateOtpPayload) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.SMS_Validate_OTP, null, validateOtpPayload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.response.data.error });
			});
	});
};
