import { Button, Col, Row } from "react-bootstrap";
import IntercomMessages from "./IntercomMessages";
import { H4 } from "../../styles/Common.style";

export const MessagesComponent = ({ componentData }) => {
  return componentData.length != 0 ? (
    <Row>
      <Col className="p-0">
        <div className="px-3 pt-3">
          {componentData?.map((data, i) => {
            return (
              <Row className="mb-3 py-2" key={i}>
                <Col sm={1} className="p-0">
                  <div
                    className="d-flex align-items-center justify-content-center position-relative rounded-circle mx-auto bg-blue"
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "white",
                    }}
                  >
                    <p
                      className="mb-0  medium-text"
                      style={{ letterSpacing: "2px", paddingLeft: "3px" }}
                    >
                      LL
                    </p>
                    {data?.incomingMsg && (
                      <div
                        className="position-absolute rounded-circle fs-9 bg-incoming-color d-flex justify-content-center align-items-center"
                        style={{
                          width: "15px",
                          height: "15px",
                          top: "-5px",
                          left: "17px",
                          border: "1px solid white",
                        }}
                      >
                        1
                      </div>
                    )}
                  </div>
                </Col>
                <Col
                  className={`${
                    data?.incomingMsg ? "incoming-text-color" : ""
                  }`}
                  sm={7}
                  style={{ lineHeight: "1" }}
                >
                  <p className="mb-0 fs-8 fw-400">{data?.name}</p>
                  <H4 color="#2A39AF" className="mb-1 mb-0 ">
                    {data?.heading}
                  </H4>
                  <span
                    className={`${
                      data?.incomingMsg ? "" : "text-muted"
                    } fs-10 d-inline-block text-truncate w-100`}
                  >
                    {data?.headingInfo}
                  </span>
                </Col>
                <Col
                  sm={4}
                  className="d-flex align-items-center justify-content-end flex-column justify-content-around"
                >
                  <span
                    className={`${
                      data?.incomingMsg ? "incoming-text-color" : "text-muted"
                    } fs-8 nowrap`}
                  >
                    {data?.timeInfo}
                  </span>

                  <IntercomMessages />
                </Col>
              </Row>
            );
          })}
        </div>
      </Col>
    </Row>
  ) : (
    <div className="d-flex justify-content-center pt-4 text-muted">
      no messages found
    </div>
  );
};
