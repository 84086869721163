import * as React from "react";

import { IntercomProvider, useIntercom } from "react-use-intercom";
import { Button } from "react-bootstrap";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import HomeChatbox from "./HomeChatbox";
import { useHomeChatbox, useHomeChatbox2 } from "../../hooks/stores/useScreeningFormStore";
// import inter from './inter.html';

const INTERCOM_APP_ID = "sl4lp0ml";
const API_BASE = "https://api-iam.intercom.io";

const IntercomMessages = () => (
  <IntercomProvider appId={INTERCOM_APP_ID} apiBase={API_BASE} autoBoot>
    <HomePage />
  </IntercomProvider>
);

// Anywhere in your app
const HomePage = () => {
  const { boot, show } = useIntercom();
  const [open, setOpen] = React.useState(false);
  const setHomeChatbox2 = useHomeChatbox2(
    (state) => state.setHomeChatbox2
  );
  boot({
    name: "Russo",
    app_id: { INTERCOM_APP_ID },
    apiBase: { API_BASE },
    email: "john.doe@example.com",
    // created_at: 1234567890,
  });

  return (
    <>
      <div>
        <NormalButton
          className="fw-900"
          textColor=" white"
          backgroundColor="green"
          buttonText="GO TO MESSAGE"
          border="none"
          padding="6px 12px"
          borderRadius="6"
          fontSize="12px"
          fontWeight="500"
          onClick={() => { setOpen(true); setHomeChatbox2(true)}}
        />
        {open && <HomeChatbox />}
      </div>
    </>
  );
};

export default IntercomMessages;
