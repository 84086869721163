import React from "react";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import "../../styles/help.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H1, H3 } from "../../styles/Common.style";
import { myPage } from "../../styles/Theme";

const Faqcard = () => {
  // const[lorem_1, setLorem_1]=useState(false);
  // const[lorem_2, setLorem_2]=useState(false);

  // const handellorem=()=>{
  //     setLorem_1(!lorem_1)
  //     setLorem_2(!lorem_2)
  // }

  const data = [
    {
      Lorem_ipsum_1: (
        <FontAwesomeIcon icon="fa-solid fa-bolt" style={{ color: "#999999" }} />
      ),
      Title1: " Lorem ipsum 1",
      Pera_0:
        "0 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
      Pera_1:
        "1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",

      Lorem_ipsum_2: (
        <FontAwesomeIcon icon="fa-solid fa-bolt" style={{ color: "#999999" }} />
      ),
      Title2: " Lorem ipsum 2",
      pera_2:
        "2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
      pera_3:
        "3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
    },
  ];
  const element = data;
  return (
    <div className="mb-5">
      {/* <div className="fnt-40 Cerebri-sans-bold">FAQ</div> */}
      <H1 className="text-center pt-2" color={myPage.primaryTextColor}>
        FAQ
      </H1>
      <div className="fs-16 Cerebri-sans-light text-center my-3">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor <br></br>
        incididunt ut labore et dolore magna aliqua.
      </div>
      {element.map((e, i) => (
        <div key={i}>
          <div className="card-box p-4 Faq-container">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="first"
              onSelect={(key) => console.log(key)}
            >
              <Row className="gap-2 gap-xl-0">
                <Col className="px-0" xl={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="bolt-wraper d-flex justify-content-center align-items-center rounded">
                              {e.Lorem_ipsum_1}
                            </div>

                            <div className="Cerebri-sans-bold fs-18 px-2 ">
                              {e.Title1}
                            </div>
                          </div>

                          <div className="faq-angle-icon d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon="fa-solid fa-angle-down"
                              // size="sm"
                            />
                          </div>
                        </div>
                      </Nav.Link>
                      <Nav.Link eventKey="second">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="bolt-wraper d-flex justify-content-center align-items-center rounded">
                              {e.Lorem_ipsum_2}
                            </div>

                            <div className="Cerebri-sans-bold fs-18 px-2 ">
                              {e.Title2}
                            </div>
                          </div>

                          <div className="faq-angle-icon d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon="fa-solid fa-angle-down"
                              // size="sm"
                            />
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col xl={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item
                          eventKey="0"
                          className="rounded overflow-hidden"
                        >
                          <Accordion.Header>
                            <p className="text-wrap fs-16 Cerebri-sans-medium">
                              Ornare laoreet mi tempus neque
                            </p>
                          </Accordion.Header>
                          <Accordion.Body className="pt-0 regular fs-14">
                            {e.Pera_0}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <p className="text-wrap fs-16 Cerebri-sans-medium">
                              Rhoncus nullam aliquam nam proin
                            </p>
                          </Accordion.Header>
                          <Accordion.Body className="pt-0 regular fs-14">
                            {e.Pera_1}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <p className="text-wrap fs-16 Cerebri-sans-medium">
                              Accordion Item #3
                            </p>
                          </Accordion.Header>
                          <Accordion.Body className="pt-0 regular fs-14">
                            {e.pera_2}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <p className="text-wrap fs-16 Cerebri-sans-medium">
                              Accordion Item #4
                            </p>
                          </Accordion.Header>
                          <Accordion.Body className="pt-0 regular fs-14">
                            {e.pera_3}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faqcard;
