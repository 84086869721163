import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { myPage } from "../../../styles/Theme";
//
export default function PortalButton({
  width,
  backgroundColor,
  onClick,
  buttonText,
  icon,
  fontSize,
  rotate,
  disabled,
  type,
  variant,
  height,
  iconAlign,
  borderRadius,
  size,
}) {
  return (
    <StyledButton
      // <button
      width={width}
      disabled={disabled}
      onClick={onClick}
      // onSubmit={onSubmit}
      variant={variant}
      type={type == "button" ? "button" : type}
      className={[" justify-content-center"].join(" ")}
      style={{
        opacity: disabled ? 0.8 : "inherit",
        width: width,
        display: "flex",
        whiteSpace: "nowrap",
        alignItems: "center",
        justifyContent: "center",
        gap: "7px",
        fontFamily: "Cerebri sans-medium",
        borderRadius: borderRadius ? borderRadius :  "8px",
        cursor: disabled ? "not-allowed" : "pointer",
        textAlign: "center",
        height:
          size === "lg"
            ? "50px"
            : size === "sm"
            ? "26px"
            : height
            ? height
            : "40px",
        padding: "6px 24px",
        fontSize:
          size === "lg"
            ? "20px"
            : size === "sm"
            ? "11px"
            : fontSize
            ? fontSize
            : "12px",
      }}
    >
      {icon && iconAlign === "left" && (
        <div className="d-flex">
          {backgroundColor === "gray" || variant === "secondary" ? (
            <FontAwesomeIcon
              icon="fa-solid  fa-plus"
              size="lg"
              style={{ color: "#000000", transform: rotate }}
            />
          ) : (
            <FontAwesomeIcon
              icon="fa-solid fa-plus"
              size="lg"
              style={{ color: "#ffffff", transform: rotate }}
            />
          )}
        </div>
      )}

      <div style={{ color: "#fff" }}>{buttonText}</div>
      {/* </button> */}
      {icon && iconAlign === "right" && (
        <div className="d-flex">
          {backgroundColor === "gray" || variant === "secondary" ? (
            <FontAwesomeIcon
              icon="fa-solid  fa-plus"
              size="lg"
              style={{ color: "#000000", transform: rotate }}
            />
          ) : (
            <FontAwesomeIcon
              icon="fa-solid fa-plus"
              size="lg"
              style={{ color: "#ffffff", transform: rotate }}
            />
          )}
        </div>
      )}
    </StyledButton>
  );
}

PortalButton.defaultProps = {
  disabled: false,
  type: "button",
  iconAlign: "left",
};

// const StyledButton = styled.button(
//   ({ disabled, variant }) => css`
//     background-color: ${myPage.primaryColor};
//     text-align: center;
//     white-space: nowrap;
//   `
// );
const StyledButton = styled.button(
  ({ disabled, variant }) => css`
    transition: background-color 0.1s ease-in, border 0.1s ease-in;
    background-color: ${disabled && variant == "primary"
      ? (props) => props.theme.component.button.primary.disabledBgColor + "80"
      : disabled
      ? "#D1D1D1"
      : disabled && variant == "secondary"
      ? (props) => props.theme.component.button.secondary.disabledBgColor + "80"
      : disabled && variant == "tertiary"
      ? (props) => props.theme.component.button.tertiary.disabledBgColor + "80"
      : variant == "white"
      ? "#FFFFFF"
      : variant == "quinary"
      ? (props) => props.theme.component.button.quinary.bgColor
      : variant == "quaternary"
      ? (props) => props.theme.component.button.quaternary.bgColor
      : variant == "tertiary"
      ? (props) => props.theme.component.button.tertiary.bgColor
      : variant == "secondary"
      ? (props) => props.theme.component.button.secondary.bgColor
      : variant == "primary"
      ? (props) => props.theme.component.button.primary.bgColor
      : variant === "success"
      ? "#77C7A1"
      : variant === "error"
      ? "#F1664B"
      : (props) => props.theme.component.button.default.bgColor};

    color: ${variant == "white"
      ? (props) => props.theme.component.button.tertiary.color
      : variant == "secondary"
      ? (props) => props.theme.component.button.secondary.color
      : variant == "primary"
      ? (props) => props.theme.component.button.primary.color
      : (props) => props.theme.component.button.default.color};
    border: ${disabled
      ? "none"
      : variant == "white"
      ? (props) => props.theme.component.button.tertiary.borderColor
      : disabled && variant == "tertiary"
      ? (props) => props.theme.component.button.tertiary.disabledBorder
      : variant == "secondary"
      ? (props) => props.theme.component.button.secondary.borderColor
      : variant == "primary"
      ? (props) => props.theme.component.button.primary.borderColor
      : "none"};
    text-align: center;
    white-space: nowrap;

    &:hover {
      background-color: ${disabled
        ? "none"
        : variant == "white"
        ? (props) => props.theme.component.button.tertiary.hoverBgColor
        : variant == "secondary"
        ? (props) => props.theme.component.button.secondary.hoverBgColor
        : variant == "primary"
        ? (props) => props.theme.component.button.primary.hoverBgColor
        : variant === "success"
        ? "#65B693"
        : variant === "error"
        ? "#E4583D"
        : (props) => props.theme.component.button.primary.hoverBgColor};
      border: ${disabled
        ? "none"
        : variant == "white"
        ? (props) => props.theme.component.button.tertiary.hoverBorder
        : variant == "secondary"
        ? (props) => props.theme.component.button.secondary.hoverBorder
        : variant == "primary"
        ? (props) => props.theme.component.button.primary.hoverBorder
        : variant === "success"
        ? "#65B693"
        : variant === "error"
        ? "#E4583D"
        : (props) => props.theme.component.button.primary.hoverBorder};
    }
    &:focus {
      outline: none;
      background-color: ${variant == "white"
        ? (props) => props.theme.component.button.tertiary.focusBgColor
        : variant == "secondary"
        ? (props) => props.theme.component.button.secondary.focusBgColor
        : variant == "primary"
        ? (props) => props.theme.component.button.primary.focusBgColor
        : variant === "success"
        ? "#65B693"
        : variant === "error"
        ? "#E4583D"
        : (props) => props.theme.component.button.primary.focusBgColor};
      border: ${variant == "white"
        ? (props) => props.theme.component.button.tertiary.focusBorder
        : variant == "secondary"
        ? (props) => props.theme.component.button.secondary.focusBorder
        : variant == "primary"
        ? (props) => props.theme.component.button.primary.focusBorder
        : variant === "success"
        ? "#65B693"
        : variant === "error"
        ? "#E4583D"
        : (props) => props.theme.component.button.primary.focusBorder};
    }
  `
);
