import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Get region by country id
export const GetRegionByCountryId = (countryId) => {
	countryId =
		countryId === "" || countryId === null || countryId === undefined
			? 0
			: countryId;
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_GetRegionByCountryId, null, countryId)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get zipcode by country Id
export const GetZipcodeByCountry = (countryId) => {
	countryId =
		countryId === "" || countryId === null || countryId === undefined
			? 0
			: countryId;
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_GetZipCode_By_Country, null, countryId)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Get countries
export const GetCountries = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_Get_Country, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
