import React from "react";
import styled from "styled-components";

const CustomCheckBox = ({
  // color,
  // size,
  onClick,
  value,
  onChange,
  id,
  // className,
  name,
  checked,
  key,
  // paddingleft,
  disabled,
  // height,
  label,
}) => {
  return (
    <CheckboxWrapper>
      <CheckboxInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        key={key}
        name={name}
        value={value}
        onClick={onClick}
        disabled={disabled}
      />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default CustomCheckBox;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 2px 8px 8px;
`;

const CheckboxInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  margin: 0;
  width: 17px;
  height: 17px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:checked {

    background-color: #ADBE9E;
   
    &::before {
      content: "\\2713";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 11px;
    }
  }

  &:hover {
    border-color : #888
  }
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  font-size: 14px;
`;


 // background-color: ${(props) =>
    //   props.theme.component.checkbox.primary.color};
    // border-color: ${(props) => props.theme.component.checkbox.primary.color};

  //  hover -- border-color: ${(props) => props.theme.component.checkbox.primary.color};
