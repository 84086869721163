import React, { useState } from "react";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useScreeningFaqPopup } from "../../hooks/stores/useScreeningFormStore";
import { H1 } from "../../../styles/Common.style";
import { myPage } from "../../../styles/Theme";
import "../../../styles/help.css";
import { useScreeningFaqPopup, useScreeningMessageModal, useScreeningNeedHelpChatbox } from "../../../hooks/stores/useScreeningFormStore";
import useMediaQuery from '@mui/material/useMediaQuery';
import ScreeningMobileFAQ from "./ScreeningMobileFAQ";

const ScreeningFAQ = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [activeTab, setActiveTab] = useState("first");

  const setScreeningFaqPopup = useScreeningFaqPopup(
    (state) => state.setScreeningFaqPopup
  );

  const setScreeningMessageModal = useScreeningMessageModal(
    (state) => state.setScreeningMessageModal
  );

  const setScreeningNeedHelpChatbox = useScreeningNeedHelpChatbox(
    (state) => state.setScreeningNeedHelpChatbox
  );

  const data = [
    {
      id: "first",
      icon: "fa-solid fa-bolt",
      title: "Documents ",
      sections: [
        {
          headerText: "Header for the third paragraph",
          paragraph:
            "2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
        },
        {
          headerText: "Header for the fourth paragraph",
          paragraph:
            "3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
        },
      ],
    },
    {
      id: "second",
      icon: "fa-solid fa-bolt",
      title: "Credit check",
      sections: [
        {
          headerText: "Why do I have to make a credit check?",
          paragraph:
            "1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
        },
        {
          headerText: "Do both customers have to do the credit check?",
          paragraph:
            "2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
        },
        {
          headerText: "Can I make the credit check for my partner?",
          paragraph:
            "3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
        },
        {
          headerText: "Can I skip the credit check?",
          paragraph:
            "4 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
        },
      ],
    },
  ];

  // Custom Accordion.Header component
  const CustomAccordionHeader = ({ section }) => (
    <Accordion.Header>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div
            className="custom-icon-wrapper d-flex justify-content-center align-items-center rounded"
            style={{
              height: "32px",
              width: "32px",
              background: activeTab === section.id ? "#ced2eb" : "#99999952",
            }}
          >
            {/* Modify this icon as needed */}
            <FontAwesomeIcon
              icon={section.icon}
              style={{
                color: activeTab === section.id ? "#2A39AF" : "#999999",
              }}
            />
          </div>
          <div className="Cerebri-sans-bold fs-18 px-2">{section.title}</div>
        </div>
        <div className="faq-angle-icon d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            icon="fa-solid fa-angle-down"
            style={{
              transform:
                activeTab === section.id ? "rotate(270deg)" : "rotate(0deg)",
            }}
          />
        </div>
      </div>
    </Accordion.Header>
  );

  return (
    
    <>
      <H1 className="text-center m-0" color={myPage.primaryTextColor}>
        FAQ
      </H1>
      <div className="fs-16 Cerebri-sans-light text-center">
        <div className="mb-3 paragraph1-regular">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor <br></br>
          incididunt ut labore et dolore magna aliqua.
        </div>

        <div className="pb-4 paragraph1-regular">
          Can't find what you're looking for? <u className="cursor" onClick={()=> {
            setScreeningMessageModal(true);
            setScreeningFaqPopup(false);
            setScreeningNeedHelpChatbox(true);
          }}>Send us a message</u>
        </div>
      </div>

      <Tab.Container
        id="faq-accordion"
        defaultActiveKey="first"
        onSelect={(key) => setActiveTab(key)}
      >
        <Row className="gap-xl-0 px-3 pb-5">
          <Col className="px-0" xl={4}>
            <Nav variant="pills" className="flex-column">
              {data.map((item) => (
                <Nav.Item key={item.id}>
                  <Nav.Link eventKey={item.id}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <div
                          className="bolt-wrapper d-flex justify-content-center align-items-center rounded"
                          style={{
                            height: "32px",
                            width: "32px",
                            background:
                              activeTab === item.id ? "#ced2eb" : "#99999952",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={item.icon}
                            style={{
                              color:
                                activeTab === item.id ? "#2A39AF" : "#999999",
                            }}
                          />
                        </div>
                        <div className="Cerebri-sans-bold fs-18 px-2">
                          {item.title}
                        </div>
                      </div>
                      <div className="faq-angle-icon d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon
                          icon="fa-solid fa-angle-down"
                          style={{
                            transform:
                              activeTab === item.id
                                ? "rotate(270deg)"
                                : "rotate(0deg)",
                          }}
                        />
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col xl={8} className="pe-0">
            <Tab.Content>
              {data.map((item) => (
                <Tab.Pane eventKey={item.id} key={item.id}>
                  <Accordion defaultActiveKey="0">
                    {item.sections.map((section, index) => (
                      <Accordion.Item
                        eventKey={index.toString()}
                        key={index}
                        className="rounded overflow-hidden"
                        style={
                          activeTab === item.id
                            ? { background: "#f7faf8" }
                            : { background: "#99999952" }
                        }
                      >
                        <Accordion.Header>
                          <div className="text-wrap fs-16 Cerebri-sans-medium">
                            {section.headerText}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="pt-0 regular fs-14">
                          {section.paragraph}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default ScreeningFAQ;
