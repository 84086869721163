import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Image,
  Row,
  Accordion,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import unitImg from "../../assets/images/unit-image.jpg";
import { reservationDummyImgData } from "../../utils/dummy-data/rervation-page-data/reservation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../../styles/reservations.css";

// dummy data have to remove after api done
import { leadPersonalDetailsData } from "../../utils/dummy-data/rervation-page-data/reservation";
import Faqcard from "../help/Faqcard";
import { useGetScreeningForm, useGetScreeningTemplateQuestionAnswer, usePostAddRKI } from "../../hooks/queries/useScreeningQueries";
import { useIsFormValueChanged, useScreeningFormAnswerStore, useScreeningFormPopup, useScreeningFormStore } from "../../hooks/stores/useScreeningFormStore";
import Countdown from "react-countdown";
import { H1, H3, H4, H5 } from "../../styles/Common.style";
import { myPage } from "../../styles/Theme";
import PortalButton from "../../Components/input/normalButton/PortalButton";
import cancelHeaderImage from "../../assets/icons/cancel-screening.svg";
import CancelScreeningModal from "./screeningModal/CancelScreeningModal";
import Modal from "../../Components/widgets/modal/Modal";
import {
  useUnitId,
  useUnitInformationStore,
} from "../../hooks/stores/useUnitStore";
import AccordionContext from "react-bootstrap/AccordionContext";
import { question } from "../../utils/dummy-data/screening-data/screening-data";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomReactSelect from "../../Components/Dropdown/CustomReactSelect";
import ReactSelect from "../../Components/input/ReactSelect";
import {
  useGetCountries,
  useGetZipcodeByCountryId,
} from "../../hooks/queries/useLocationQueries";
import { toast } from "react-toastify";
import NormalButton from "../../Components/input/normalButton/NormalButton";
import ScreeningCreditCheck from "./ScreeningCreditCheck";
import {
  TypeCheckbox,
  TypeDate,
  TypeEmail,
  TypeFile,
  TypeNumber,
  TypeRadio,
  TypeSelect,
  TypeText,
  TypeTextarea,
} from "./screeningComponent/FormFieldsComponent";
import moment from "moment";

// custom button for lead form -> accordion
function CustomToggle({ children, eventKey, status, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  let bgColr = "#d1d1d1b5";
  if (status === "edit") bgColr = "#D1D1D1";
  else if (status === "fill out") bgColr = "#ADBE9E";

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      type="button"
      className="fw-500 fs-8 d-flex justify-content-end align-items-center text-uppercase ms-auto"
      style={{ border: "none", width: "fit-content" }}
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? (
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-up"
          color="#000"
          style={{ height: "15px" }}
        />
      ) : (
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          color="#000"
          style={{ height: "15px" }}
        />
      )}
    </div>
  );
}

export const Screening = ({ getActorProfileData, selectedUnitData }) => {
  const notRenderScreeningQueId = [];
  const { data: getScreeningFormData, refetch: getScreeningFormDataRefetch } =
    useGetScreeningForm(2, "0");

  const {
    data: GetScreeningTemplateQuestionAnswerData,
    refetch: getScreeningTemplateQuestionAnswerRefetch,
  } = useGetScreeningTemplateQuestionAnswer(
    selectedUnitData?.property_id,
    selectedUnitData?.contract_id,
    getActorProfileData?.data?.[0]?.actor_id
  );

  const setScreeningFormPopup = useScreeningFormPopup(
    (state) => state.setScreening_Form_Popup
  );

  const { UnitId, setUnitId } = useUnitId((state) => state);

  const { unitInformation, setUnitInformation } = useUnitInformationStore(
    (state) => state
  );

  const [leadDetailsDropDown, setLeadDetailsDropDown] = useState({
    occupation: false,
  });
  const Screening_Form_State = useScreeningFormStore((state) => state.Screening_Form_State);
  const Screening_Form_Answer_State = useScreeningFormAnswerStore((state) => state.screeningFormAnswerStore);
  const setScreeningFormAnswerStore = useScreeningFormAnswerStore((state) => state.setScreeningFormAnswerStore);

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    unregister,
    watch,
    reset,
    formState: { errors, isDirty },
    control,
  } = useForm(Screening_Form_Answer_State);

  const {
    data: getZipcodeByCountryIdData,
    refetch: getZipcodeByCountryIdDataRefetch,
  } = useGetZipcodeByCountryId(watch("11")); // country is coming from api which has question id 11

  const { data: getCountriesData } = useGetCountries(getActorProfileData?.data?.[0]?.country_id);

  const { mutateAsync: PostAddRKI, isLoading: PostAddRKIIsLoading } = 
  usePostAddRKI();

  const [activeAccordion, setActiveAccordion] = useState({});
  const [occupationDropDown, setOccupationDropDown] = useState({});
  const [openCancelScreening, setOpenCancelScreening] = useState(false);
  const [creditCheckClicked, setCreditCheckClicked] = useState(false);
  const [creditCheck, setCreditCheck] = useState(null);

  // updating form data on changing any form answers
  useEffect(() => {
    reset(Screening_Form_Answer_State);
  }, [reset, Screening_Form_Answer_State]);

  // changing lead details particular accordion background color
  const onHandleAccordion = (e, btn, index) => {
    setActiveAccordion({
      ...activeAccordion,
      [leadPersonalDetailsData[index]?.type]: true,
    });

    if (activeAccordion?.[leadPersonalDetailsData[index]?.type]) {
      setActiveAccordion({
        ...activeAccordion,
        [leadPersonalDetailsData[index]?.type]: false,
      });
    }
  };

  // occupation dropdown handling
  const handleOccupationSelect = (e, btn, i) => {
    setOccupationDropDown({ ...occupationDropDown, [e]: btn.target.text });
  };

  // checking if there is any changes between the screening wizard answer and screening overview answer
  function screeningAnsSameOrNot(obj1, obj2) {
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          // Values are different, return false
          return false;
        }
      }
    }
  
    // If no differences found, return true
    return true;
  }


  // handle edited Form
  const handleSave = (data) => {
    setScreeningFormAnswerStore({
      ...data
    })
    // console.log(data, 'data')
  }

  const groupFieldNames = ['name', 'phone', 'address']
  // This array will contain the parent field id as i wanted to render group field in one line but because of map the group fields rendering 2 times so i have made this array and it will check in map if the parent field id is present or not if present it will return null if that same id comes in map
  let fieldShouldRenderOneTime = [];

  const GroupField = ({ groupData, formIndex, formSubIndex }) => {
    const parentGroupData = Screening_Form_State?.formData?.[formIndex].section[
      formSubIndex
    ].question.filter(
      (q) => q.screening_field_id === groupData[0].parent_screening_field_id
    );
    return (
      <Row className="mt-3 mb-3 justify-content-start">
        <Col md={4} xxl={3} className="fs-16 fw-bold mt-2 ms-xxl-5">
          <div className="text-start fs-14 fw-bold px-2 ms-1">
            {parentGroupData?.[0]?.placeholder}
          </div>
        </Col>
        <Col md={8} xxl={4}>
          {/* <div className = "text-start fs-14 fw-bold">
          {parentGroupData?.[0]?.placeholder}
          {Boolean(parentGroupData?.[0]?.is_required) && "*"}
        </div> */}
          <Row>
            {groupData.map((d) => {
              return (
                <Col key={d.screening_question_id}>
                  <Controller
                    defaultValue=""
                    control={control}
                    name={`${d.screening_question_id}`}
                    // rules        = {{ required: d?.is_required }}
                    render={({ field, fieldState }) => {
                      return (
                        <div key={d.screening_question_id}>
                          <div>
                            {fieldTypeRender[d.field_type](
                              d,
                              field,
                              fieldState,
                              setValue
                            )}
                          </div>
                          {errors?.[field.name] && field.value === "" && (
                            <p
                              className="error-msg text-start"
                              style={{ color: "red" }}
                            >
                              This field is required.
                            </p>
                          )}
                        </div>
                      );
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  const SingleField = ({ data, formIndex, formSubIndex }) => {
    return (
      <Row className="my-3">
        <Col md={4} xxl={3} className="mt-2 ms-xxl-5">
          <div className="text-start fs-14 fw-bold px-2 ms-1">
            {data[0]?.description}
          </div>
        </Col>
        <Col md={8} xxl={4}>
          <div>
            {/* <div className = "text-start fs-14 fw-bold px-2 ms-1">
              {data[0]?.description} {Boolean(data[0]?.is_required) && "*"}
            </div> */}
            <Controller
              key={data[0].screening_question_id}
              defaultValue=""
              control={control}
              name={`${data[0].screening_question_id}`}
              // rules        = {{ required: `${ data[0]?.is_required}`}}
              render={({ field, fieldState }) => {
                return (
                  <Row key={data[0].screening_question_id} className="mb-3">
                    <Col>
                      {fieldTypeRender[data[0].field_type](
                        data[0],
                        field,
                        fieldState,
                        setValue
                      )}
                    </Col>
                  </Row>
                );
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const AddressField = ({ addressData, formIndex, formSubIndex }) => {
    const parentGroupData = Screening_Form_State?.formData?.[
      formIndex
    ].section?.[formSubIndex]?.question.filter(
      (q) => q.screening_field_id === addressData[0].parent_screening_field_id
    );
    return (
      <Row className="mt-3 mb-3 justify-content-start">
        <Col md={4} xxl={3} className="mt-2 ms-xxl-5">
          <div className="text-start fs-14 fw-bold px-2 ms-1">
            {parentGroupData?.[0]?.placeholder}
          </div>
        </Col>
        <Col md={8} xxl={4}>
          {/* <div className = "text-start fs-14 fw-bold px-2 ms-1">
              {parentGroupData?.[0]?.placeholder}{" "}
              {Boolean(parentGroupData?.[0]?.is_required) && "*"}
            </div> */}
          {/* <div className="screening-slide-animation">
            <div className = "text-start fw-bold px-1">{screeningQuestion?.newQuestionBank[Screening_Form_State?.currentActiveIndex]?.section_name}{screeningQuestion?.newQuestionBank[Screening_Form_State?.currentActiveIndex]?.is_mandatory && "*"}</div>
          </div> */}
          <Row>
            <Col xs={6} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[0]?.screening_question_id}`}
                // rules        = {{ required: addressData[0]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className="px-0">
                      <Col className="px-0" md={12}>
                        {fieldTypeRender[addressData[0].field_type](
                          addressData[0],
                          field,
                          fieldState,
                          setValue
                        )}
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs={6} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[1]?.screening_question_id}`}
                // rules        = {{ required: addressData[1]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className="px-0">
                      <Col className="px-0" md={12}>
                        {fieldTypeRender[addressData[1].field_type](
                          addressData[1],
                          field,
                          fieldState,
                          setValue
                        )}
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs={4} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[2]?.screening_question_id}`}
                // rules        = {{ required: addressData[2]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterVal = addressData[3].possible_values?.filter(
                    (p) => p.value === field.value
                  );
                  const selectedVal = filterVal?.length
                    ? filterVal?.map((s) => ({
                        label: s.text,
                        value: s.value,
                      }))
                    : "";

                  return (
                    <Row className="px-0">
                      <Col
                        className={`px-0 mt-2 ${
                          errors?.[field.name] && "error"
                        } text-start`}
                      >
                        <ReactSelect
                          clearable={false}
                          placeholder={addressData[2]?.placeholder}
                          dropdownLine={true}
                          // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
                          value={selectedVal}
                          onChange={(e) => {
                            // clearErrors(name);
                            field.onChange(e);
                            setValue(field.name, e.value, {
                              shouldValidate: true,
                            });
                          }}
                          options={addressData[2].possible_values?.map((s) => ({
                            label: s.text,
                            value: s.value,
                          }))}
                        />
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs={4} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[3]?.screening_question_id}`}
                // rules        = {{ required: addressData[3]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterVal = addressData[3].possible_values?.filter(
                    (p) => p.value === field.value
                  );
                  const selectedVal = filterVal?.length
                    ? filterVal?.map((s) => ({
                        label: s.text,
                        value: s.value,
                      }))
                    : "";

                  return (
                    <Row className="px-0">
                      <Col
                        className={`px-0 mt-2 ${
                          errors?.[field.name] && "error"
                        } text-start`}
                      >
                        <ReactSelect
                          clearable={false}
                          placeholder={addressData[3]?.placeholder}
                          dropdownLine={true}
                          // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
                          value={selectedVal}
                          onChange={(e) => {
                            // clearErrors(name);
                            field.onChange(e);
                            setValue(field.name, e.value, {
                              shouldValidate: true,
                            });
                            // if(name === '9') {
                            //   setValue('8', '');
                            //   setValue("7", '');
                            // }
                          }}
                          options={addressData[3].possible_values?.map((s) => ({
                            label: s.text,
                            value: s.value,
                          }))}
                        />
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs={4} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[4]?.screening_question_id}`}
                // rules        = {{ required: addressData[4]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className="px-0">
                      <Col className="px-0" md={12}>
                        {fieldTypeRender[addressData[4].field_type](
                          addressData[4],
                          field,
                          fieldState
                        )}
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[5]?.screening_question_id}`}
                // rules        = {{ required: addressData[5]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <Row className="px-0">
                      <Col className="px-0" md={12}>
                        {fieldTypeRender[addressData[5].field_type](
                          addressData[5],
                          field,
                          fieldState
                        )}
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs={6} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[6]?.screening_question_id}`}
                // rules        = {{ required: addressData[6]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterVal = getZipcodeByCountryIdData?.data?.filter(
                    (p) => p.Id === field.value
                  );
                  const selectedVal = filterVal?.length
                    ? filterVal?.map((s) => ({
                        label: s.Id,
                        value: s?.Id,
                      }))
                    : "";

                  return (
                    <Row>
                      <Col
                        className={`px-0 mt-2 ${
                          fieldState.error && "error"
                        } text-start`}
                      >
                        <ReactSelect
                          clearable={false}
                          placeholder={addressData[6]?.placeholder}
                          dropdownLine={true}
                          // value={Object.keys(selectedValue ?? {}).length ? selectedValue : ''}
                          value={selectedVal}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(field.name, e.value, {
                              shouldValidate: true,
                            });
                            setValue(
                              `${addressData[5]?.screening_field_id}`,
                              getZipcodeByCountryIdData?.data?.filter(
                                (el) => el.Id === e.value
                              )?.[0]?.Text,
                              { shouldValidate: true }
                            );
                          }}
                          options={getZipcodeByCountryIdData?.data?.map(
                            (item) => ({
                              label: item.Id,
                              value: item?.Id,
                              // value:  `${item?.Id} ${item?.Text}`
                            })
                          )}
                        />
                        {errors?.[field.name] && field.value === "" && (
                          <p
                            className="error-msg text-start"
                            style={{ color: "red" }}
                          >
                            This field is required.
                          </p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
            <Col xs={9} md={4}>
              <Controller
                defaultValue=""
                control={control}
                name={`${addressData[7]?.screening_question_id}`}
                // rules        = {{ required: addressData[7]?.is_required }}
                render={({ field, fieldState }) => {
                  const filterValue = getCountriesData?.data?.filter(
                    (cd) => cd.Id === field.value
                  );

                  const selectedVal = filterValue?.length
                    ? {
                        value: filterValue[0]?.Id,
                        label: (
                          <div
                            // key={i}
                            className="d-flex align-items-center"
                            style={{
                              fontFamily: "Cerebri sans",
                            }}
                          >
                            <div className="radius-4 me-2">
                              <img
                                src={filterValue[0].DataValue1}
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                }}
                                alt="country flag"
                              />
                            </div>
                            <div>
                              <div className="fs-14 light-font-text ">
                                {filterValue[0]?.Text}
                              </div>
                            </div>
                          </div>
                        ),
                      }
                    : "";
                  return (
                    <Row>
                      <Col className="screening-custom-react-select mt-2 px-0 text-start">
                        <div className={`${fieldState.error && "error"}`}>
                          <CustomReactSelect
                            setValue={setValue}
                            placeholder={addressData[7].placeholder}
                            name={`${addressData[7]?.screening_question_id}`}
                            iserror={fieldState.error}
                            options={getCountriesData?.data?.map((item, i) => ({
                              value: item?.Id,
                              label: (
                                <div
                                  // key={i}
                                  className="d-flex align-items-center"
                                  style={{
                                    fontFamily: "Cerebri sans",
                                  }}
                                >
                                  <div className="radius-4 me-2">
                                    <img
                                      src={item.DataValue1}
                                      style={{
                                        width: "20px",
                                        marginRight: "10px",
                                      }}
                                      alt="country flag"
                                    />
                                  </div>
                                  <div>
                                    <div className="fs-14 light-font-text ">
                                      {item?.Text}
                                    </div>
                                  </div>
                                </div>
                              ),
                              searchText: item?.Text,
                            }))}
                            value={selectedVal}
                            // iserror={errors?.screeninginvitation}
                            // inputValue={inputValue}
                            isSearchable={false}
                            // onInputChange={(val) => setInputValue(val)}
                          />
                        </div>
                        {errors?.[field.name] && (
                          <p className="error-msg">This field is required</p>
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const PhoneField = ({ phoneData, formIndex, formSubIndex }) => {
    const parentGroupData = Screening_Form_State?.formData?.[
      formIndex
    ].section?.[formSubIndex]?.question.filter(
      (q) => q.screening_field_id === phoneData[0].parent_screening_field_id
    );

    return (
      <Row className="my-3">
        <Col md={4} xxl={3} className="ms-xxl-5">
          <div className="text-start fs-14 fw-bold px-2 ms-1">
            {parentGroupData?.[0]?.placeholder}
          </div>
        </Col>
        <Col md={8} xxl={4} className="px-4">
          {/* <Row>
            <Col className = "text-start fs-14 fw-bold px-0 mb-2">
              {parentGroupData?.[0]?.placeholder}
              {Boolean(parentGroupData?.[0]?.is_required) && "*"}
            </Col>
          </Row> */}
          <Row
            className={`screening-phone-number-container ${
              (errors?.[phoneData[1]?.screening_question_id] ||
                errors?.[phoneData[0]?.screening_question_id]) &&
              "error"
            }`}
          >
            <Col xs={4} className="px-0">
              <Controller
                defaultValue=""
                control={control}
                name={`${phoneData[1]?.screening_question_id}`}
                // rules        = {{ required: phoneData[1]?.is_required }}
                render={({ field: { onChange, value } }) => {
                  const filterValue = getCountriesData?.data?.filter(
                    (cd) => cd.Id === value
                  );
                  const selectedVal = filterValue?.length
                    ? {
                        value: filterValue[0]?.Id,
                        label: (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              fontFamily: "Cerebri sans",
                            }}
                          >
                            <div className="radius-4 me-2">
                              <img
                                src={filterValue[0].DataValue1}
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                }}
                                alt="country flag"
                              />
                            </div>
                            <div>
                              <div className="fs-14 light-font-text d-flex gap-1">
                                <span>{filterValue[0]?.Id}</span>
                                <span>{filterValue[0]?.DataValue2}</span>
                              </div>
                            </div>
                          </div>
                        ),
                      }
                    : "";
                  return (
                    <CustomReactSelect
                      setValue={setValue}
                      name={`${phoneData[1]?.screening_question_id}`}
                      bgColor={"#f9fafa"}
                      border={"none"}
                      description={"Search country code"}
                      placeholder={phoneData[1]?.placeholder}
                      value={selectedVal}
                      options={getCountriesData?.data?.map((item, i) => ({
                        value: item?.Id,
                        label: (
                          <div
                            // key={i}
                            className="d-flex align-items-center"
                            style={{
                              fontFamily: "Cerebri sans",
                            }}
                          >
                            <div className="radius-4 me-2">
                              <img
                                src={item.DataValue1}
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                }}
                                alt="country flag"
                              />
                            </div>
                            <div>
                              <div className="fs-14 light-font-text d-flex gap-1">
                                <span>{item?.Id}</span>
                                <span>{item?.DataValue2}</span>
                              </div>
                            </div>
                          </div>
                        ),
                        searchText: item?.DataValue2,
                      }))}
                      // value={value}
                      dropDownIndicator={false}
                      // iserror={errors?.screeninginvitation}
                      // inputValue={inputValue}
                      isSearchable={false}
                      // onInputChange={(val) => setInputValue(val)}
                      // onInputChange={(e)=> {
                      //   console.log(value,'hi')
                      // }}
                    />
                  );
                }}
              />
            </Col>
            <Col xs={8} className="px-0">
              <Controller
                defaultValue=""
                control={control}
                name={`${phoneData[0]?.screening_question_id}`}
                // rules        = {{ required: phoneData[0]?.is_required }}
                render={({ field, fieldState }) => {
                  return (
                    <div className="h-100">
                      <div
                        className={`screening-information-phone-number h-100 ${
                          fieldState.error?.[field.name] && "error"
                        }`}
                      >
                        <input
                          className="w-100 h-100 border-0 rounded-end p-2"
                          {...field}
                          type="number"
                          placeholder={``}
                          id={phoneData[0].placeholder}
                        />
                        {/* <label htmlFor={ phoneData[0].placeholder}>{ phoneData[0].placeholder}</label> */}
                      </div>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
          {(errors?.[`${phoneData[1]?.screening_question_id}`] ||
            errors?.[`${phoneData[0]?.screening_question_id}`]) && (
            <p className="error-msg text-start" style={{ color: "red" }}>
              This field is required.
            </p>
          )}
        </Col>
      </Row>
    );
  };

  const CreditCheck = ({ creditCheckData }) => {
    return (
      <>
        {!creditCheckClicked ? (
          <Row>
            <Col>
              <div className={"mx-auto"} style={{ maxWidth: "450px" }}>
                <p>
                  Before we can continue the screening, it is a requirement to
                  complete a credit check based on the information you have
                  provided.
                </p>
              </div>
              <div className="fs-14 mt-2">
                <span>Click below to start the credit check</span>
              </div>
              <div>
                <div>
                  <NormalButton
                    type="submit"
                    className="fw-900"
                    bstClassName={" mx-auto mt-3"}
                    textColor=" white"
                    backgroundColor="green"
                    buttonText="START CREDIT CHECK"
                    border="1px solid #F0F0F0"
                    padding="6px 16px"
                    borderRadius="6"
                    fontSize="14"
                    fontWeight="500"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCreditCheck();
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <ScreeningCreditCheck
            data={creditCheckData}
            creditCheck={creditCheck}
          />
        )}
      </>
    );
  };

  // Check wheter a actor have credit check or not
  const handleCreditCheck = async () => {
    let id;
    if (!PostAddRKIIsLoading) {
      setCreditCheckClicked(true);
    }
    const payload = {
      property_id: 923,
      contract_id: 374,
      actor_id: 100489929779842857,
    };
    PostAddRKI(payload).then(
      (response) => {
        if (response?.data?.bReturn) {
          setCreditCheck(true);
          // getScreeningTemplateQuestionAnswerRefetch();
          // toast.update(id, {
          //   render: response?.data?.message,
          //   isLoading: false,
          //   type: "success",
          //   autoClose: 2000,
          // });
        } else {
          setCreditCheck(false);
          // toast.update(id, {
          //   render: response?.data?.message,
          //   isLoading: false,
          //   type: "error",
          //   autoClose: 2000,
          // });
        }
      },
      (error) => {
        toast.update(id, {
          render: "Failed to Submit answer",
          isLoading: false,
          type: "error",
          autoClose: 2000,
        });
      }
    );
  };

  return (
    <>
      <div className="card-box reservation-container mx-md-3">
        <Row>
          <Col
            className="px-0 bottom-border-line"
            style={{ background: "white", overflow: "hidden" }}
          >
            {/* <div className="d-flex fs-8 fw-400 p-3 align-items-center bottom-border-line">
              <div className="me-2" style={{ width: "20px" }}>
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: "#77C970",
                    textColor: "black",
                    textSize: "1.5rem",
                    strokeWidth: 1,
                  })}
                  value={76}
                  strokeWidth={15}
                />
              </div>
              <H3 color={myPage.primaryTextColor} className="mb-0">
                Finish Your Screening
              </H3>
            </div> */}
            <Row>
              <Col className="bottom-border-line px-0">
                <div
                  className="radius-12 px-4 py-3"
                  style={{ backgroundColor: "#fff", overflow: "hidden" }}
                >
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="me-2" style={{ width: "20px" }}>
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: "#77C970",
                              textColor: "black",
                              textSize: "1.5rem",
                              strokeWidth: 1,
                            })}
                            value={76}
                            strokeWidth={15}
                          />
                        </div>
                        <H3 className="mb-0" color={myPage.primaryTextColor}>
                          Finish Your Screening
                        </H3>
                      </div>
                    </div>
                    <div className="my-2">
                      {GetScreeningTemplateQuestionAnswerData?.data?.[0]
                        ?.completion_percentage === 100 ? (
                        ""
                      ) : (
                        <NormalButton
                        type            = "submit"
                        className       = "fw-900"
                        textColor       = " white"
                        backgroundColor = "green"
                        buttonText      = "USE SCREENING WIZARD"
                        border          = "1px solid #F0F0F0"
                        padding         = "6px 28px"
                        borderRadius    = "6"
                        fontSize        = "12"
                        fontWeight      = "600"
                        bstClassName    = {`ms-auto`}
                        onClick         = {()=> {
                           // if (GetScreeningTemplateQuestionAnswerData?.data?.[0]?.completion_percentage !== 100) {
                            setScreeningFormPopup(true);
                        }}
                      />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              {/* finish screening */}
            </Row>
            <Row
              className="py-4 px-3 gap-3 gap-lg-0"
              style={{ backgroundColor: "#fff" }}
            >
              <Col xs={12} xl={6} className="px-0">
                <div>
                  {unitInformation?.[UnitId]?.image == "" ? (
                    <div
                      style={{ height: "150px", border: "1px solid #888" }}
                      className=" radius-4 grey-text d-flex justify-content-center align-items-center"
                    >
                      Property image is not available
                    </div>
                  ) : (
                    <div
                      className="radius-8"
                      style={{
                        backgroundImage: `url(${unitInformation?.[UnitId]?.image})`,
                        height: "150px",
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        boxSizing: "border-box",
                      }}
                    ></div>
                  )}
                  <Row className="my-3 grey-text">
                    <Col lg={5} md={12}>
                      <H4 className="mb-0">
                        {unitInformation?.[UnitId]?.property_name}
                      </H4>

                      <p className="mb-0 paragraph2-light grey-text">
                        {unitInformation?.[UnitId]?.street_name}{" "}
                        {unitInformation?.[UnitId]?.house_number},{" "}
                        {unitInformation?.[UnitId]?.floor_number}
                      </p>
                      <p className="paragraph2-light grey-text mb-0">
                        {unitInformation?.[UnitId]?.zipcode_id}{" "}
                        {unitInformation?.[UnitId]?.city}
                      </p>
                      <p className="paragraph2-light grey-text mb-0">
                        {unitInformation?.[UnitId]?.country}
                      </p>
                    </Col>

                    <Col lg={7} md={12} className="property-details p-0">
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fak fa-square-meter-light-3"
                              size="lg"
                            />
                          </span>{" "}
                          Size
                        </span>
                        <div className="paragraph2-regular">
                          {unitInformation?.[UnitId]?.area ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${unitInformation?.[UnitId]?.area} ${unitInformation?.[UnitId]?.square_measure_unit}`,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fa-regular fa-bed-front"
                              size="lg"
                            />
                          </span>{" "}
                          Rooms
                        </span>
                        <span className="paragraph2-regular">
                          {unitInformation?.[UnitId]?.no_of_rooms
                            ? unitInformation?.[UnitId]?.no_of_rooms +
                              " " +
                              (unitInformation?.[UnitId]?.no_of_rooms > 1
                                ? "rooms"
                                : "room")
                            : "-"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light nowrap">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fa-regular fa-piggy-bank"
                              size="lg"
                            />
                          </span>{" "}
                          Monthly rent
                        </span>
                        <span className="paragraph2-regular">
                          {unitInformation?.[UnitId]?.rent
                            ? unitInformation?.[UnitId]?.rent +
                              " " +
                              unitInformation?.[UnitId]?.currency_id
                            : "-"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between screening-border py-1">
                        <span className="paragraph2-light">
                          <span className="pe-3 ps-1 py-1">
                            <FontAwesomeIcon
                              icon="fa-regular fa-house-water"
                              size="lg"
                            />
                          </span>{" "}
                          Move-in
                        </span>
                        <span className="paragraph2-regular">
                          {unitInformation?.[UnitId]?.move_in_date
                            ? moment(unitInformation?.[UnitId]?.move_in_date).format(
                                "DD.MM.YY"
                              )
                            : "-"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} xl={6}>
                <Row className="gap-3 gap-lg-0 justify-content-center">
                  <Col xs={12} sm={6}>
                    <Row>
                      {reservationDummyImgData?.map((data, i) => {
                        if (i < 5)
                          return (
                            <Col xs={6} lg={4} key={i} className="p-1">
                              <div className="radius-12">
                                <img
                                  className="d-block w-100"
                                  src={data.imgLink}
                                  alt="flats"
                                />
                              </div>
                            </Col>
                          );
                        else if (i === 5)
                          return (
                            <Col className="p-1" xs={6} lg={4} key={i}>
                              <div className="position-relative radius-12 overflow-hidden">
                                <img
                                  className="d-block w-100"
                                  src={data.imgLink}
                                  alt="flats"
                                />
                                <div
                                  className="position-absolute fw-700 fs-5 w-100 h-100 d-flex justify-content-center align-items-center color-white"
                                  style={{
                                    top: "0",
                                    left: "0",
                                    backgroundColor: "#11174773",
                                  }}
                                >
                                  + {reservationDummyImgData.length - 6}
                                </div>
                              </div>
                            </Col>
                          );
                        else return null;
                      })}
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <H4 className="text-center">Screen fulfillment</H4>
                      <div
                        className="mx-auto"
                        style={{
                          width: "52%",
                          // , maxWidth: "140px"
                        }}
                      >
                        <CircularProgressbar
                          styles={buildStyles({
                            pathColor: "#77C970",
                            textColor: "black",
                            textSize: "1.5rem",
                          })}
                          value={
                            GetScreeningTemplateQuestionAnswerData?.data?.[0]
                              ?.completion_percentage ?? 0
                          }
                          text={`${
                            GetScreeningTemplateQuestionAnswerData?.data?.[0]
                              ?.completion_percentage ?? 0
                          }%`}
                        />
                      </div>

                      <div className="p-3 d-flex justify-content-center align-items-center">
                        <PortalButton
                          fontSize="14px"
                          buttonText="SUBMIT SCREENING"
                          variant="primary"
                          disabled={
                            GetScreeningTemplateQuestionAnswerData?.data?.[0]
                              ?.completion_percentage !== 100
                          }
                          // disabled={true}
                        />
                      </div>

                      <div className="m-0 d-flex justify-content-center flex-wrap fs-8 mt-2">
                        <span className="gray paragraph2-regular">
                          Time to finish your screening:
                        </span>
                        <span className="ms-1 text-dark paragraph2-regular">
                          <Countdown
                            date={1696075241000}
                            daysInHours
                            zeroPadTime={2}
                            onComplete={() => <>Screening expired</>}
                            className="d-flex"
                          />
                        </span>
                      </div>
                      <div
                        // onClick={() => setOpenCancelScreening(true)}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCancelScreening(true);
                        }}
                        className="
                        justify-content-center d-flex gap-2 mt-3 
                         cursor paragraph2-regular"
                      >
                        <span className="screening-green-text">
                          Cancel screening
                        </span>
                      </div>
                      <Modal
                        open={openCancelScreening}
                        topborder={false}
                        modalPosition="top"
                        className="modal-dialog-centered"
                        onHide={() => setOpenCancelScreening(false)}
                        size={"md"}
                        title={
                          <div>
                            <div onClick={() => setOpenCancelScreening(false)}>
                              <FontAwesomeIcon
                                className="p-2 cursor radius-6 m-3"
                                icon="fa-regular fa-xmark"
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  backgroundColor: "#fff",
                                  height: "24px",
                                  width: "24px",
                                }}
                              />
                            </div>
                            <img
                              style={{
                                borderRadius: "8px 8px 0  0 ",
                                // width: "100%",
                                // height: "92px",
                                width: "100%",
                                objectFit: "none",
                              }}
                              alt=""
                              src={cancelHeaderImage}
                            />
                          </div>
                        }
                        // title={
                        //   <div className="d-flex justify-content-between p-3">
                        //     <div className="d-flex justify-content-center align-items-center gap-3">
                        //       <div>
                        //         <div className="font-weight-bold fs-16">
                        //           Your screening has been saved
                        //         </div>
                        //       </div>
                        //     </div>
                        //   </div>
                        // }
                        title_color="black"
                        onCloseAction={() => {
                          setOpenCancelScreening(false);
                          alert("Action Peformed Successfuly.");
                        }}
                      >
                        <CancelScreeningModal
                          setOpenCancelScreening={setOpenCancelScreening}
                        />
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* lead - user information form */}
        <Row className="px-3 py-2">
          <Col className="px-0">
            {/* <div className="text-center p-4 rounded">
              <div>
                <H1>SCREENING DECILNED</H1>
              </div>
              <div>
                <p>Unfortunately, it has not been possible to approve you to rent the desired unit based on the information you have provided in your screening and we are therefore unable to offer you a rental contract for the desired property.
                If you believe there has been an error, please contact us here.
                If we do not hear from you, your profile and the information you have provided will be deleted in 3 months</p>
              </div>
              <div></div>
            </div> */}
            <form>
              {Screening_Form_State?.formData?.map((data, i) => {
                const allQuestionArr = [];
                const allAnswerArr = [];
                data?.section?.forEach((section) => {
                  if (section?.question?.length) {
                    section?.question.forEach((questionObj) => {
                      if (!groupFieldNames.includes(questionObj?.field_type)) {
                        if (questionObj?.field_condition_rules?.rules?.length) {
                          questionObj?.field_condition_rules?.rules.forEach(
                            (r) => {
                              if (
                                !Screening_Form_Answer_State[
                                  r?.screening_question_id
                                ]
                              ) {
                                notRenderScreeningQueId.push(
                                  r?.screening_question_id
                                );
                              }
                            }
                          );
                        }
                        if (
                          !notRenderScreeningQueId.includes(
                            questionObj?.screening_question_id
                          )
                        ) {
                          allQuestionArr.push(questionObj);
                        }
                        if (
                          !notRenderScreeningQueId.includes(
                            questionObj?.screening_question_id
                          ) &&
                          Screening_Form_Answer_State[
                            questionObj?.screening_question_id
                          ]
                        ) {
                          allAnswerArr.push(questionObj);
                        }
                      }
                    });
                  }
                });

                return (
                  <Accordion
                    className="my-2"
                    key={i}
                    onSelect={(e, data) => onHandleAccordion(e, data, i)}
                  >
                    <Card className="border-0">
                      <Card.Header
                        className={`background-primary border-bottom-0 py-2 radius-10`}
                      >
                        <Row className="align-items-center gap-2 gap-sm-0">
                          <Col
                            className="d-flex px-0 align-items-center flex-wrap"
                            sm={4}
                            lg={4}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center me-3 p-2 position-relative"
                              style={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#d6d6d8",
                                borderRadius: "5px",
                              }}
                            >
                              <FontAwesomeIcon
                                color="#0C1356"
                                icon={data.icon_name}
                              />
                              {/* change this when we will get how can i know if the status is done or yellow or red flag */}
                              {data.status === "edit" && (
                                <FontAwesomeIcon
                                  className="position-absolute lead-checkbox-color"
                                  icon="fa-solid fa-circle-check"
                                  style={{ top: "-5px", right: "-6px" }}
                                />
                              )}
                            </div>
                            <div className="lh-1">
                              <H5 className="mb-0">{data.name}</H5>
                              <span className="fw-500 fs-12 text-muted">
                                Done
                              </span>
                            </div>
                          </Col>
                          <Col className="px-0" sm={8} lg={4}>
                            <div className="lh-sm">
                              <ProgressBar
                                className="lead-loader-color"
                                now={
                                  (allAnswerArr.length /
                                    allQuestionArr.length) *
                                  100
                                }
                              />
                              <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                  <span className="d-inline-block mb-0 me-2 fs-500 fs-8">
                                    {allAnswerArr.length} /
                                    {allQuestionArr.length}
                                  </span>
                                  <span className="fs-500 fs-8 text-muted">
                                    Total task
                                  </span>
                                </div>
                                <div>
                                  <span className="fs-500 fs-8 mb-0">
                                    {!allAnswerArr.length &&
                                    !allQuestionArr.length
                                      ? 0
                                      : Math.floor(
                                          (allAnswerArr.length /
                                            allQuestionArr.length) *
                                            100
                                        )}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="px-0" lg={4}>
                            <CustomToggle eventKey={i} status={data.status} />
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse
                        className={`background-primary mt-1 `}
                        eventKey={i}
                        style={{ borderRadius: "0 0 10px 10px" }}
                      >
                        <Card.Body className="screening-accordion-body">
                          {data.section?.map((s, formIndex) => {
                            return s?.question?.map((q, keyIndex) => {
                              const isGroupField =
                                q.parent_screening_field_id > 0;
                              const fieldToShow = isGroupField
                                ? s?.question.filter(
                                    (sameParentID) =>
                                      sameParentID.parent_screening_field_id ===
                                      q.parent_screening_field_id
                                  )
                                : [q];

                              if (!groupFieldNames.includes(q.field_type)) {
                                if (
                                  fieldShouldRenderOneTime.includes(
                                    q.parent_screening_field_id
                                  )
                                )
                                  return null;
                                if (isGroupField)
                                  fieldShouldRenderOneTime.push(
                                    q.parent_screening_field_id
                                  );

                                let switchCase = data?.section.length
                                  ? q.screening_field_id
                                  : data?.screening_category_id;
                                if (
                                  notRenderScreeningQueId.includes(
                                    q.screening_question_id
                                  ) &&
                                  !Screening_Form_Answer_State[
                                    q.screening_question_id
                                  ]
                                )
                                  return null;

                                switch (switchCase) {
                                  case 4:
                                    return (
                                      <AddressField
                                        key={
                                          data.section[
                                            Screening_Form_State?.keyIndex
                                          ]?.name + `${keyIndex}`
                                        }
                                        addressData={fieldToShow}
                                        formIndex={formIndex}
                                        formSubIndex={keyIndex}
                                      />
                                    );
                                  case 14:
                                    return (
                                      <PhoneField
                                        key={
                                          data.section[
                                            Screening_Form_State?.keyIndex
                                          ]?.name + `${keyIndex}`
                                        }
                                        phoneData={fieldToShow}
                                        formIndex={formIndex}
                                        formSubIndex={keyIndex}
                                      />
                                    );
                                  default:
                                    return isGroupField ? (
                                      <GroupField
                                        key={
                                          data.section[
                                            Screening_Form_State?.keyIndex
                                          ]?.name + `${keyIndex}`
                                        }
                                        groupData={fieldToShow}
                                        formIndex={formIndex}
                                        formSubIndex={keyIndex}
                                      />
                                    ) : (
                                      <SingleField
                                        key={
                                          data.section[
                                            Screening_Form_State
                                              ?.currentFormSubIndex
                                          ]?.name + `${keyIndex}`
                                        }
                                        data={fieldToShow}
                                        formIndex={formIndex}
                                        formSubIndex={keyIndex}
                                      />
                                    );
                                }
                              }
                              return null;
                            });
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              })}
              
               { isDirty && 
                <NormalButton
                type            = "submit"
                className       = "fw-900"
                textColor       = " white"
                backgroundColor = "green"
                buttonText      = "SAVE"
                border          = "1px solid #F0F0F0"
                padding         = "6px 28px"
                borderRadius    = "6"
                fontSize        = "14"
                fontWeight      = "500"
                bstClassName    = {`ms-auto mt-3 mb-2`}
                onClick         = { handleSubmit(handleSave)}
              />
              }
            </form>
          </Col>
        </Row>
      </div>
      <Row className="mb-5">
        <Col>
          <Faqcard />
        </Col>
      </Row>

      {/* <CancelScreeningModal/> */}
    </>
  );
};

const fieldTypeRender = {
  text: (data, field, fieldState, setValue) => (
    <TypeText
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  radio: (data, field, fieldState, setValue) => (
    <TypeRadio
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  number: (data, field, fieldState, setValue) => (
    <TypeNumber
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  textarea: (data, field, fieldState, setValue) => (
    <TypeTextarea
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  date: (data, field, fieldState, setValue) => (
    <TypeDate
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  file: (data, field, fieldState, setValue) => (
    <TypeFile
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  checkbox: (data, field, fieldState, setValue) => (
    <TypeCheckbox
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  select: (data, field, fieldState, setValue) => (
    <TypeSelect
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
  email: (data, field, fieldState, setValue) => (
    <TypeEmail
      field={field}
      data={data}
      fieldState={fieldState}
      setValue={setValue}
    />
  ),
};
