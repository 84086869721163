import React from 'react'
import './switch.css'

const Activedisableswitch = () => {

  return (
    <div className="container">
    <label className="switch mt-1">
      <input type="checkbox" checked value="click"/>
      <span className="slider round"  style={{opacity:"0.5" , cursor:"not-allowed"}}></span>
    </label>
  </div>
  )
}

export default Activedisableswitch