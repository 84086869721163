export const Site = {
  ET: "ET",
  AUTH: "AUTH",
};

export const Url = {
  ET: process.env.REACT_APP_API_ET50_BASE_URL,
  AUTH: process.env.REACT_APP_API_AUTH_URL,
};
export const Endpoint = {
  AUTH: {
    Login: "/login",
    RefreshToken: "/refresh",
    Logout: "/logout",
  },
  ET: {
    AuthToken: "/v1/test/0",
    Geography_Get_Country: "/v1/location/country",
    Geography_GetRegion_By_CountryId: "/v1/location/regions",
    Geography_GetZipCode_By_Country: "/v1/location/zipcode",

    //------Actors------//
    Actor_Get_Actor_BasicDetails: "/v1/actors",
    Actor_Post_Add_Actor_Login: "/v1/actors/login",
    Actor_Get_Actor_Profile: "/v1/actor/profile",
    Actor_UpdateActorProfile: "/v1/actor/profile",
    Actor_GetUnitList_By_ActorId: "/v1/actors",
    Actor_Post_UpdateProfilePicture: "/v1/actors/profile/image",
    Actor_PutResetActorPassword: "/v1/actors/password/reset",
    Actor_PostUploadDocument: "/v1/documents/actor",

    //-----Documents-----//
    Get_Document_Type: "/v1/documents/type",

    Actor_Get_DocumentDetails_By_ContactId: "/v1/reservation",
    Actor_Delete_ArchiveDocument: "/v1/reservation",
    Actor_Delete_ArchiveDocument_Permanent: "/v1/reservation",
    Actor_Update_RestoreArchiveDocument: "/v1/reservation/documents",
    Actor_ActorDocumentViewDetail: "/v1/documents/view",

    Actor_Send_Document_byMail: "/v1/documents/share",
    //---------------- Guides ---------------//

    // Get_Document_By_PropertyId: "/v1/documents/property",
    Get_Document_By_PropertyId: "/v1/properties",

    //-----SMS-------//
    SMS_Send_OTP: "/v1/otp/send",
    SMS_Validate_OTP: "/v1/otp/validate",

    // ----EMAIL VALIDATE PROFILE---- //
    EMAIL_Validate: "/v1/mail/verification",


    //---- Screening-----//

    GetScreeningForm : "/v1/customers",
    PostAddScreeningResponse : "/v1/customers/screening/response",
    GetScreeningTemplateQuestionAnswer : "/v1/screening/template/property",

    //---- Credit check ----//
    PostAddRKI: "/v1/actor/rki"
  },
};
