import { Col, Row } from 'react-bootstrap';
import { H1 }       from './../../../../styles/Common.style';
import GroupButtons from '../../../../Components/GroupButton/GroupButton';

export const OtherScreeningConfirmation = ({ getIndex, setIndex }) => {

    return(
    <Row className = "py-3 py-lg-5 justify-content-center">
    <Col className = "px-sm-2 me-0 me-xl-5 mt-2" lg = {6} xxl = {5}>
    <div className = "text-center">
               <H1>Do you want to fill in information in the screening on behalf of the other customers?</H1>
           </div>
           <div className = "text-center mx-auto" style = {{ maxWidth: '450px' }}>
               <p> The questions regarding other customers that you cannot answer, the other customers will be asked to answer themselves. Just like they will also have to complete a credit check themselves.</p>
           </div>
           <div  className = "mt-4">
           <div  className = "mb-2 text-center">
           <span className = "fs-14">Do you want to fill out the information about the other customers?</span>
               </div>
               <Row className = "gap-3">
                   <Col>
                   <div className = {"mt-2"}>
                        <GroupButtons
                            tabs={confirmationTabs?.map((o) => {
                            return {
                                id      : o?.value,
                                tabTitle: o?.text,
                            };
                            })}
                            currentTab     = {getIndex?.fillingOtherCustomerScreening}
                            handleTabClick = {(tab) => {
                                setIndex({
                                    ...getIndex,
                                    prevIndex                    : 1,
                                    fillingOtherCustomerScreening: tab
                                })
                            }}
                        />
                        </div>
                   </Col>
               </Row>
           </div>
        </Col>
   </Row>
    )
}

const confirmationTabs = [
    {
        value: 1,
        text : 'Yes'
    },
    {
        value: 2,
        text : 'No'
    }
]