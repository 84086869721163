import { useRef, useState } from "react";
import Modal from "../../../Components/widgets/modal/Modal";
import {
  Col,
  Row,
} from "react-bootstrap";
import ScreeningFormStepper from "../screeningComponent/ScreeningFormStepper";
import "../../../styles/screening.css";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import {
  useGetScreeningForm,
  useGetScreeningTemplateQuestionAnswer,
  usePostAddScreeningResponse,
} from "../../../hooks/queries/useScreeningQueries";
import DynamicScreenForm from "../screeningComponent/DynamicScreenForm";
import dashboardHeaderImage from "../../../assets/images/top-header-image-pattern.svg";
import Overview from "../Overview";
import Congratulation from "../Congratulation";
import {
  useScreeningFormStore,
  useScreeningFormPopup,
  useScreeningFormAnswerStore,
  useScreeningFormsNames,
  useScreeningFormsAnswerData,
  useScreeningFaqPopup,
  useScreeningMessageModal,
} from "../../../hooks/stores/useScreeningFormStore";
import Countdown from "react-countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScreeningFAQ from "../screeningModal/ScreeningFAQ";
import screeningExitHead from "../../../assets/icons/reporthead.svg";
import PreScreening from "./../desktopView/preScreeningPage/PreScreening";
import SaveAndFinishLater from "../screeningModal/SaveAndFinishLater";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreeningNeedHelpChatbox from "../screeningModal/ScreeningNeedHelpChatbox";
import ScreeningMobileFAQ from "../screeningModal/ScreeningMobileFAQ";

function ScreeningFormContainer({ next, prev, isConditionTrue, skipFormCategory, handleScreeningData }) {

  const {
    mutateAsync: PostAddScreeningResponse,
    isLoading: PostAddScreeningResponseIsLoading,
  } = usePostAddScreeningResponse();

  const Screening_Form_State = useScreeningFormStore(
    (state) => state.Screening_Form_State
  );
  const setScreening_Form_State = useScreeningFormStore(
    (state) => state.setScreening_Form_State
  );
  const Screening_Form_Popup = useScreeningFormPopup(
    (state) => state.Screening_Form_Popup
  );
  const setScreening_Form_Popup = useScreeningFormPopup(
    (state) => state.setScreening_Form_Popup
  );
  const Screening_Form_Answer_State = useScreeningFormAnswerStore(
    (state) => state.screeningFormAnswerStore
  );
  const setScreening_Form_Answer_State = useScreeningFormAnswerStore(
    (state) => state.setScreeningFormAnswerStore
  );
  const screeningFormNames = useScreeningFormsNames(
    (state) => state.screeningFormNames
  );
  const setScreeningFormAnswerData = useScreeningFormsAnswerData(
    (state) => state.setScreeningFormAnswerData
  );
  const setScreeningFaqPopup = useScreeningFaqPopup(
    (state) => state.setScreeningFaqPopup
  );
  const screeningFaqPopup = useScreeningFaqPopup(
    (state) => state.screeningFaqPopup
  );
  const screeningMessageModal = useScreeningMessageModal(
    (state) => state.screeningMessageModal
  );
  const setScreeningMessageModal = useScreeningMessageModal(
    (state) => state.setScreeningMessageModal
  );
  const matches = useMediaQuery("(max-width:1016px)");

  const [openSaveFinishLater, setOpenSaveFinishLater] = useState(false);

  const submitRef = useRef(); // create the ref for next button
  const prevRef = useRef(); // create the ref for back button  

  // Post form data to API
  const HandleSubmitScreening = async (data) => {

   
    // let id;
    // if (!PostScreeningQuestionAnswerIsLoading) {
    //   id = toast.loading("Submitting Answer");
    // }
    // let allFileIds = getScreeningFormData?.data
    //   .filter((ans) => ans.question_type_name === "file")
    //   .map((m) => m.screening_question_id);

    // let formData = new FormData();
    // let payload = [];
    // for (const key in Screening_Form_Answer_State) {
    //   const answerValue = Screening_Form_Answer_State[key];
    //   const id = key;
    //   if (Screening_Form_Answer_State[key] instanceof File) {
    //     // Check if the value is a File object and add it to the array
    //     formData.append("documents[]", Screening_Form_Answer_State[key]);
    //   }
    //   if (!allFileIds.includes(+id)) {
    //     payload.push({
    //       screening_question_id: `${id}`,
    //       answer_value: `${answerValue}`,
    //       is_skipped: 0,
    //     });
    //   } else {
    //     payload.push({
    //       screening_question_id: `${id}`,
    //       answer_value: `${
    //         Screening_Form_Answer_State[key]?.name ??
    //         Screening_Form_Answer_State[key]
    //       }`,
    //       is_skipped: 0,
    //     });
    //   }
    // }
    // formData.append("actor_id", `${actorProfile?.actor_id}`);
    // formData.append("property_id", `${selectedUnitData?.property_id}`);
    // formData.append("screening_template_id", "6");
    // formData.append("responses", JSON.stringify(payload));
    // formData.append("contract_id", `${selectedUnitData?.contract_id}`);

    // PostScreeningQuestionAnswer(formData).then(
    //   (response) => {
    //     if (response?.data?.bReturn) {
    //       getScreeningTemplateQuestionAnswerRefetch();
    //       toast.update(id, {
    //         render: response?.data?.message,
    //         isLoading: false,
    //         type: "success",
    //         autoClose: 2000,
    //       });
          next();
    //     } else {
    //       toast.update(id, {
    //         render: "Failed to Submit answer",
    //         isLoading: false,
    //         type: "error",
    //         autoClose: 2000,
    //       });
    //     }
    //   },
    //   (error) => {
    //     toast.update(id, {
    //       render: "Failed to Submit answer",
    //       isLoading: false,
    //       type: "error",
    //       autoClose: 2000,
    //     });
    //   }
    // );
  };

  // if (!Object.keys(Screening_Form_State ?? {}).length) return null; // if the form question isn't there we won't be rendering anything
  return (
    <>
      <Modal
        open={Screening_Form_Popup}
        topborder={false}
        modalClassName="screening-modal overflow-hidden"
        center={true}
        isFullscreen={true}
        onHide={() => setScreening_Form_Popup(false)}
        title_color="black"
        headerBg={"#f7f8fa"}
        bodyStyle={{
          marginBottom: matches ? "70px" : "37px",
          background: "#f7f8fa",
        }}
        bodyClassName={"p-0"}
        title={
          <div className="screening-form-label-container">
            <div className="d-flex">
              <img
                className="w-100 h-100"
                src={dashboardHeaderImage}
                alt="dashboard top header"
              />
            </div>
            <Row className="d-flex justify-content-center mt-2">
              <Col md={9}>
                { Boolean(Screening_Form_State?.screeningIndex) && <ScreeningFormStepper skipFormCategory={skipFormCategory} />}
              </Col>
            </Row>
          </div>
        }
        onCloseAction={() => {
          // setShare(false);
          alert("Action Peformed Successfuly.");
        }}
      >
        <div className="h-100">
          <div className="screening-form-container">
            { Screening_Form_State?.screeningIndex ?
            <Row className="pb-5">
              <Col className="px-lg-5 px-0 py-0">
                <div className="mt-3 mx-lg-4 px-3 px-xl-5">
                  <ScreeningFormLabelSection
                    formData={Screening_Form_State}
                    nextPage={next}
                    prevPage={prev}
                    parentSubmitRef={submitRef}
                    parentPrevRef={prevRef}
                    isConditionTrue={isConditionTrue}
                    skipFormCategory={skipFormCategory}
                  />
                  <div
                    className="d-flex align-items-center justify-content-end gap-2 need-help cursor-pointer px-3"
                    onClick={() => setScreeningFaqPopup(true)}
                  >
                    <span>Need help</span>
                    <FontAwesomeIcon
                      color="#BB9F83"
                      icon="fa-solid fa-circle-question"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            : 
            <div className="position-relative"> 
              <PreScreening />
            </div>
            }
          </div>
          { Screening_Form_State?.screeningIndex ?
            <ScreeningFooter nextPage={next} prevPage={prev} HandleSubmitScreening={HandleSubmitScreening} submitRef={submitRef} prevRef={prevRef} Screening_Form_State={Screening_Form_State} setOpenSaveFinishLater={setOpenSaveFinishLater} handleScreeningData={handleScreeningData} setScreening_Form_Popup={setScreening_Form_Popup} />
          : 
            <PreScreeningFooter Screening_Form_State={Screening_Form_State} setScreening_Form_State={setScreening_Form_State} />
          }
          {screeningMessageModal && <ScreeningNeedHelpChatbox layoutStyle="bottomRight" onBackClick={() => {setScreeningMessageModal(false);setScreeningFaqPopup(true);}} />}
        </div>
      </Modal>
      <Modal
        onHide={() => setScreeningFaqPopup(null)}
        size="xl"
        open={screeningFaqPopup}
        modalPosition="top"
        backdropClassName={"screening-need-help-modal-backdrop"}
        title={
          <div className="d-flex justify-content-between px-2 py-2">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <div></div>
              </div>
            </div>
            <div
              onClick={() => setScreeningFaqPopup(false)}
              className="radius-6"
              style={{
                // backgroundColor: "#EBEBEB",
                height: "36px",
                width: "36px",
                backgroundColor: "rgb(235, 235, 235)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                size="lg"
                className="cursor"
                icon="fa-regular fa-xmark"
              />
            </div>
          </div>
        }
        topborder={false}
      >
        <ScreeningFAQ />
        {/* <ScreeningMobileFAQ/> */}
      </Modal>

      {/* Save and finish later popup */}
      <Modal
        // size="sm"
        // height={"60px"}
        open={openSaveFinishLater}
        topborder={false}
        modalPosition="center"
        modalClassName="screening-modal"
        onHide={() => setOpenSaveFinishLater(false)}
        backdropClassName={"screening-save-and-finish-letter-modal"}
        size={"md"}
        title={
          <div>
            <div onClick={() => setOpenSaveFinishLater(false)}>
              <FontAwesomeIcon
                className="p-2 cursor radius-6 m-3"
                icon="fa-regular fa-xmark"
                style={{
                  position: "absolute",
                  right: 0,
                  backgroundColor: "#fff",
                  height: "24px",
                  width: "24px",
                }}
              />
            </div>
            <img
              style={{
                borderRadius: "8px 8px 0  0 ",
                width: "100%",
                objectFit: "none",
              }}
              alt=""
              src={screeningExitHead}
            />
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setOpenSaveFinishLater(false);
          alert("Action Peformed Successfuly.");
        }}
      >
        <SaveAndFinishLater
          setOpenSaveFinishLater={setOpenSaveFinishLater}
          setScreening_Form_Popup={setScreening_Form_Popup}
        />
      </Modal>
    </>
  );
}

export default ScreeningFormContainer;


const ScreeningFormLabelSection = ({ formData: { currentFormIndex, ...data }, prevPage, nextPage, parentPrevRef, parentSubmitRef, isConditionTrue, skipFormCategory }) => {
  
  const componentToRender = {

    [data?.maxIndex]: (
      <Overview skipFormCategory={skipFormCategory} />
    ),
    [data.maxIndex+1]: (
      <Congratulation />
    )
  };

  return (
    <Row>
      <Col className="px-lg-5 text-center">
        {currentFormIndex < data?.maxIndex ? (
          <div>
            <div>
              <div>
                <h2>
                  {data?.formData?.[currentFormIndex]?.name ?? ""}
                </h2>
              </div>
              <div>
                {!data?.isFlagConditionActivate && Boolean(data?.formData?.[currentFormIndex].section.length) && <p className="fs-6">
                  {data?.formData?.[currentFormIndex]?.description ??
                    ""}
                </p>}
              </div>
              <div>
             { !data?.isFlagConditionActivate && Boolean(data?.formData?.[currentFormIndex].section.length) && <span className="fs-7">(*) means they are mandatory</span>}
              </div>
            </div>
            <div className="screening-render-component">
              <DynamicScreenForm
                index={currentFormIndex}
                data={data?.formData?.[currentFormIndex]}
                nextPage={nextPage}
                prevPage={prevPage}
                parentSubmitRef={parentSubmitRef}
                parentPrevRef={parentPrevRef}
                isConditionTrue={isConditionTrue}
              />
            </div>
          </div>
      )
        : currentFormIndex === data?.maxIndex ? (
        componentToRender[data?.maxIndex]
      ) : currentFormIndex === data?.maxIndex + 1 ? (
        componentToRender[data?.maxIndex + 1]
      ) : null
    }
      </Col>
    </Row>
  );
};

const PreScreeningFooter = ({
  Screening_Form_State,
  setScreening_Form_State,
}) => {
  const setScreeningFormPopup = useScreeningFormPopup(
    (state) => state.setScreening_Form_Popup
  );

  // start screening handle function
  const handleStartScreening = () => {
    setScreening_Form_State({
      ...Screening_Form_State,
      screeningIndex: 1,
    });
  };

  // close screeening Modal
  const handleScreenModal = () => {
    setScreeningFormPopup(false);
  };

  return (
    <div className="primary-text-color screening-footer">
      <div
        className="d-flex align-items-center flex-wrap justify-content-between gap-2"
        style={{
          borderTop: "1px solid #d1d1d1 ",
          background: "#111747",
        }}
      >
          { Screening_Form_State.prevIndex === 0 && (
            <NormalButton
              type="submit"
              className="fw-900"
              textColor=" black"
              backgroundColor="white"
              buttonText="CLOSE"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="4"
              fontSize="14"
              fontWeight="500"
              bstClassName={"position-absolute"}
              // disabled={
              //   !Screening_Form_State.currentFormCategorySubIndex
              // }
              onClick={handleScreenModal}
            />
          )}
          { Screening_Form_State.prevIndex === 1 && (
          <NormalButton
            type="submit"
            className="fw-900"
            textColor=" black"
            backgroundColor="white"
            buttonText="BACK"
            border="1px solid #F0F0F0"
            padding="6px 16px"
            borderRadius="4"
            fontSize="14"
            fontWeight="500"
            onClick={()=>  setScreening_Form_State({
              ...Screening_Form_State,
              prevIndex: 0,
            })}
          />
          )}
          { Screening_Form_State.prevIndex === 2 && (
          <NormalButton
              type="submit"
              className="fw-900"
              textColor=" black"
              backgroundColor="white"
              buttonText="BACK"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="4"
              fontSize="14"
              fontWeight="500"
              onClick={()=>  setScreening_Form_State({
                ...Screening_Form_State,
                prevIndex: 1,
              })}
            />
          )}
        <div className="mx-auto">
          <p className="mb-0">
            <span className="m-0 text-light opacity-75 d-flex gap-2 text-nowrap">
              <span>Time to finish your screening:</span>
              <Countdown
                date={1696075241000}
                daysInHours
                zeroPadTime={2}
                onComplete={() => <>Screening expired</>}
                className="d-flex justify-content-center"
                // renderer={renderer}
              />
            </span>
          </p>
        </div>
        { Screening_Form_State.prevIndex === 1 && (
          <NormalButton
              type="submit"
              className="fw-900"
              textColor=" black"
              backgroundColor="white"
              buttonText="Next"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="4"
              fontSize="14"
              fontWeight="500"
              onClick={()=>  setScreening_Form_State({
                ...Screening_Form_State,
                prevIndex: 2,
              })}
            />
          )}
          {  Screening_Form_State.prevIndex === 2 && 
          <NormalButton
            className="fw-900"
            textColor=" white"
            backgroundColor="button-success"
            buttonText="START SCREENING"
            border="1px solid #ADBE9E"
            padding="6px 16px"
            borderRadius="4"
            fontSize="14"
            fontWeight="500"
            onClick={handleStartScreening}
          />
        }
        </div>
    </div>
  );
};

const ScreeningFooter = ({
  Screening_Form_State,
  submitRef,
  prevRef,
  prevFun,
  HandleSubmitScreening,
  setOpenSaveFinishLater,
  nextPage,
  prevPage,
  handleScreeningData,
  setScreening_Form_Popup
}) => {
  return (
    <div className="primary-text-color screening-footer">
      <div
        className="d-flex align-items-center flex-wrap justify-content-between gap-2"
        style={{
          borderTop: "1px solid #d1d1d1 ",
          background: "#111747",
        }}
      >
        <div className={`${Screening_Form_State.currentFormIndex - 1 < Screening_Form_State.maxIndex && 'd-none'}`}>
          <NormalButton
              type="submit"
              className="fw-900"
              textColor=" black"
              backgroundColor="white"
              buttonText="CLOSE"
              border="1px solid #F0F0F0"
              padding="6px 16px"
              borderRadius="4"
              fontSize="14"
              fontWeight="500"
              onClick={() => {
                setScreening_Form_Popup(false)
              }}
            />
        </div>
        <div className={`${Screening_Form_State.currentFormIndex > Screening_Form_State.maxIndex && 'd-none'}`}>
          { !Screening_Form_State.isOverViewBtnTrue || Screening_Form_State?.isOverViewBackBtnTrue ?
          <NormalButton
            type="submit"
            className="fw-900"
            textColor=" black"
            backgroundColor="white"
            buttonText="BACK"
            border="1px solid #F0F0F0"
            padding="6px 16px"
            borderRadius="4"
            fontSize="14"
            fontWeight="500"
            onClick={() => {
              if (prevRef && prevRef.current) prevRef.current.click();
              else prevPage();
            }}
          />
          :
          <NormalButton
          type="submit"
          className="fw-900"
          textColor=" black"
          backgroundColor="white"
          buttonText="GO TO OVERVIEW"
          border="1px solid #F0F0F0"
          padding="6px 16px"
          borderRadius="4"
          fontSize="14"
          fontWeight="500"
          onClick={() => {
            if (prevRef && prevRef.current) prevRef.current.click();
            prevPage()
          }}
        />
          }
        </div>
        <div className="lh-lg time-to-finish-screening-container d-none d-md-flex">
          <p className="mb-0">
            <span className="m-0 text-light opacity-75 d-flex gap-2 text-nowrap">
              <span>Time to finish your screening:</span>
              <Countdown
                date={1696075241000}
                daysInHours
                zeroPadTime={2}
                onComplete={() => <>Screening expired</>}
                className="d-flex justify-content-center"
                // renderer={renderer}
              />
            </span>
          </p>
        </div>
        <div className="d-flex gap-2">
          <NormalButton
            className="fw-900"
            textColor="black"
            backgroundColor="white"
            buttonText="SAVE AND FINISH LATER"
            border="1px solid #ADBE9E"
            padding="6px 16px"
            borderRadius="4"
            fontSize="14"
            fontWeight="500"
            bstClassName={`${Screening_Form_State.currentFormIndex >
              Screening_Form_State.maxIndex && 'd-none'}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenSaveFinishLater(true);
              handleScreeningData();
            }}
          />
          {Screening_Form_State.currentFormIndex !==
          Screening_Form_State.maxIndex ? (
            <NormalButton
              className="fw-900"
              textColor="white"
              backgroundColor="button-success"
              buttonText="NEXT"
              border="1px solid #ADBE9E"
              padding="6px 16px"
              borderRadius="4"
              fontSize="14"
              fontWeight="500"
              bstClassName={`${Screening_Form_State.currentFormIndex >
                Screening_Form_State.maxIndex && 'd-none'}`}
              onClick={() => {
                if (submitRef && submitRef.current) {
                  submitRef.current.click();
                }else {
                  nextPage()
                }
              }}
            />
          ) : (
            <NormalButton
              type="submit"
              className="fw-900"
              textColor="white"
              border="none"
              backgroundColor="green"
              buttonText={"SUBMIT"}
              padding="6px 16px"
              borderRadius="6"
              fontSize="14"
              fontWeight="500"
              // disabled={Screening_Form_State.filledQuestion !== Screening_Form_State.totalQuestion - 1}
              onClick={(e) => {
                // nextPage()
                handleScreeningData();
                // HandleSubmitScreening();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
