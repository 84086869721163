import React, { useState } from "react";
import { H3, H4, H5 } from "../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";
import Modal from "../../Components/widgets/modal/Modal";
import NormalButton from "../../Components/input/normalButton/NormalButton";

const Flexdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const EventDecline = ({ setAnchorElEventDecline }) => {
  const [openSendMessage, setopenSendMessage] = useState(false);

  return (
    <div className="private-view-card-container">
      <div className="d-flex gap-2 py-3 px-3 events-headers-border">
        <div
          className="bg-private-view-color square"
          style={{ marginTop: "2px" }}
        ></div>
        <div>
          <H4 className="mb-1">Mads Johnson private viewing</H4>
          <div className="caption-regular">Private viewing</div>
        </div>
      </div>
      <div className="d-flex gap-2 py-3 px-3 events-headers-border">
        <div>
          <FontAwesomeIcon icon="fa-regular fa-user" />
        </div>
        <div>
          <H5 className="mb-1">Agent</H5>
          <div className="paragraph2-light">Mads Johnson</div>
        </div>
      </div>
      <div className="d-flex gap-2 py-3 px-3 events-headers-border">
        <div>
          <FontAwesomeIcon icon="fa-regular fa-clock" size="sm" />
        </div>
        <div>
          <H5 className="mb-1">Date and time</H5>
          <div className="regular fs-13 mb-1">Sunday 5th February 2023</div>
          <div className="regular fs-13">10:00 to 11:00 (1 hours)</div>
        </div>
      </div>
      <div className="d-flex gap-2 py-3 px-3 events-headers-border">
        <div>
          <FontAwesomeIcon icon="fa-regular fa-location-dot" size="sm" />
        </div>
        <div>
          <H5 className="mb-1">Unit address</H5>
          <div className="paragraph2-regular">Blomsterbjerget</div>
          <div className="regular fs-13">Blomstervej 14, 3. tv</div>
          <div className="regular fs-13">2765 Smørum</div>
          <div className="regular fs-13">7-W260</div>
        </div>
      </div>
      {/* <Row className="mb-3">
        <Col lg={12}> */}
      <div className="px-4 pt-2 pb-4">
        <H5>Why are you declining the event?</H5>
        <div className="d-flex form-message-input position-relative">
          <textarea
            type="text"
            style={{
              resize: "none",
              border: "1px solid #d1d1d1",
            }}
            placeholder="Start Writing..."
            id="Message"
            className="Message pe-5"
            // {...register("Message", {
            //   required: true,
            //   onChange: (e) => {
            //     setFormValue({
            //       ...formValue,
            //       Message: e.target.value,
            //     });
            //   },
            // })}
          />
          {/* <label htmlFor="Lastname">Message</label> */}
          <Flexdiv
            style={{
              position: "absolute",
              bottom: "12px",
              right: "12px",
            }}
          >
            <button
              className="d-flex align-items-center justify-content-center"
              style={{
                border: "none",
                backgroundColor: "#ADBE9E",
                borderRadius: "4px",
                width: "25px",
                height: "25px",
              }}
              onClick={() => {
                console.log("open")
                setAnchorElEventDecline(null);
                setopenSendMessage(!openSendMessage);
                console.log("close")
              }}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-paper-plane-top"
                style={{ color: "white" }}
              />
            </button>
          </Flexdiv>
        </div>
        {/* {errors.Message && (
              <p className="error-msg" style={{ color: "red" }}>
                Message is Required.
              </p>
            )} */}
      </div>
      {/* </Col>
      </Row> */}
      <Modal
        size="sm"
        open={openSendMessage}
        className="modal-dialog-centered"
        onHide={() => setopenSendMessage(false)}
        title={
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <H3>Message sent</H3>
              </div>
            </div>
            <div onClick={() => setopenSendMessage(false)}>
              <FontAwesomeIcon className="cursor" icon="fa-regular fa-xmark" />
            </div>
          </div>
        }
        title_color="black"
        onCloseAction={() => {
          setopenSendMessage(false);
          alert("Action Peformed Successfuly.");
        }}
      >
        <div className="paragraph2-light p-5">Your message has been sent</div>

        <div
          className="d-flex justify-content-between p-2"
          style={{
            borderTop: "1px solid #d1d1d1",
            background: "#f7f8fa",
            borderRadius: "0 0 12px 12px",
          }}
        >
          <div></div>
          <div>
            <NormalButton
              type="submit"
              className="fw-900"
              textColor="white"
              border="none"
              onClick={() => setopenSendMessage(false)}
              backgroundColor="green"
              buttonText="CLOSE"
              //   disabled={PostShareDocumentIsLoading}
              padding="10px 24px"
              borderRadius="6"
              fontSize="14"
              fontWeight="500"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventDecline;
