import styled, { css } from "styled-components";

const GroupButtons = ({ tabs, currentTab, handleTabClick, height, error }) => {

  return (
    <MyTabsWrapper>
      {tabs.map((tab, i) => (
          <Tab
            key={i}
            id={i}
            mode={`${tab?.id === currentTab}`}
            className={`py-1 ${tabs.length -1 !== i && 'me-3'} screening-radio-tabs`}
            style={{
              height: height,
              border: `${ tab?.id === currentTab ? 'unset' : `1px solid ${error ? '#E33716' : '#d1d1d1' }`}`,
              borderRadius: "8px",
              fontWeight: tab?.id === currentTab ? '600' : 'unset'
            }}
            onClick={() => handleTabClick(tab?.id)}
          >
            {tab.tabTitle}
          </Tab>
        ))}
    </MyTabsWrapper>
  );
};

GroupButtons.defaultProps = {
  tabs: [
    {
      id: 1,
      tabTitle: "Tab Title",
      title: "Title",
      content: "This page contains data related to tab",
    },
    {
      id: 2,
      tabTitle: "Tab Title",
      title: "Title",
      content: "This page contains data related to tab",
    },
  ],
  currentTab: 0,
  bordercolor: "#888888",
  height: "44px",
};

export default GroupButtons;

const MyTabsWrapper = styled.div`
  display: flex;
  // justify-content: start;
  justify-content: center;
  width: -webkit-fill-available;
`;

const Tab = styled.span(
  ({ mode }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${mode === 'true' ? "#DAD4CE" : "#F2F4F6"};
    color: #000;
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    border-radius: 8px;
    font-family: Cerebri sans-light;
    width: -webkit-fill-available;
  `
);
/* background-color: ${mode === "true"
      ? (props) => props.theme.component.checkbox.primary.color + "22"
      : "white"};
    border: 1px solid #d1d1d1;
    color: ${mode === "true"
      ? (props) => props.theme.component.checkbox.primary.color
      : "#888888"};
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    border-radius: 8px;
    font-family: Cerebri sans-light;
    width: -webkit-fill-available; */
