import React from "react";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  NotificationIcon,
  ProfileDesign,
  ProfileGrayIcon,
} from "../../../assets/icons";
import Profile from "./Profile";
import { useState } from "react";
import "../../../styles/Profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../Components/widgets/modal/Modal";
import Changeprofilepicture from "./Changeprofilepicture";
import Pictureremove from "./Pictureremove";
import profileImg from "../../../assets/images/Profile Pic.png";
const Notifaction = (props) => {
  const [profile, setProfile] = useState(false);
  const [picture, setPicture] = useState(null);

  const handelprofile = () => {
    setProfile(!profile);
  };
  const [editModal, setEditModal] = useState(null);
  const [remove, setRemove] = useState(false);
  return (
    <>
      {profile ? (
        <Profile />
      ) : (
        <Container fluid>
          <div className="Main-div">
            <div
              className="pb-2"
              style={{ fontWeight: "700", fontSize: "17px" }}
            >
              {" "}
              Profile{" "}
            </div>

            <div className="form-div pt-4 px-4">
              <div className="d-flex profile-header ps-md-4  d-flex flex-wrap flex-sm-nowrap px-3 py-xl-0 py-2">
                <div
                  className="d-flex flex-wrap flex-lg-nowrap"
                  style={{
                    // height: "210px",
                    margin_top: "10px",
                    // paddingTop: "36px",
                    alignItems: "center",
                  }}
                >
                  <div className="profile-div image d-flex justify-content-between">
                    <div style={{ position: "absolute" }}>
                      <Image src={profileImg} roundedCircle />
                      <div
                        className="ms-auto mt-auto cursor"
                        onClick={() => setEditModal(true)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-pen"
                          style={{ color: "#202020" }}
                          className="edit fa-pen p-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className=" ps-md-4 d-flex"
                    style={{ flexDirection: "column", gap: "5px" }}
                  >
                    <div style={{ fontWeight: "700", fontSize: "26px" }}>
                      George Young Blackheat
                    </div>
                    <div>
                      {" "}
                      <span style={{ fontWeight: "500" }}>E-mail</span>:
                      george.young@gmail.com
                    </div>
                    <div>
                      {" "}
                      <span style={{ fontWeight: "500" }}>Phone </span>: +45 20
                      89 63 54
                    </div>
                  </div>
                </div>
                <div className="profile-image">
                  <ProfileDesign />
                </div>
              </div>
              {/* form body  */}
              <div className="form-body">
                <Row className="mt-4">
                  <Col className="ps-0 pb-3" lg={3}>
                    <div className="d-flex p-2 rounded cursor disable-profile-text mb-2 ">
                      <div className="mb-2 mt-2 px-2 p-1 bg-light-gray">
                        <FontAwesomeIcon
                          icon="fa-regular fa-user"
                          style={{ color: "#999999" }}
                          className="mt-1"
                        />
                      </div>
                      <div
                        className="px-2"
                        style={{ marginTop: "12px" }}
                        onClick={handelprofile}
                      >
                        Profile
                      </div>
                    </div>
                    <div className="d-flex p-2 rounded background-primary cursor profile-text ">
                      <div className="mb-2 mt-2 px-2 p-1 bg-light-blue">
                        <FontAwesomeIcon
                          icon="fa-regular fa-bell"
                          style={{ color: "#2a39af" }}
                          className="mt-1"
                        />
                      </div>
                      <div className="px-2" style={{ marginTop: "12px" }}>
                        Notifications
                      </div>
                    </div>
                  </Col>

                  <div
                    className="mb-5 mt-5"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <NormalButton
                      className="fw-900"
                      textColor="white"
                      backgroundColor="green"
                      border="none"
                      buttonText="SAVE CHANGES"
                      padding="6px 16px"
                      borderRadius="6"
                      fontSize="14"
                      fontWeight="500"
                      onClick={() => {}}
                    />
                  </div>
                </Row>
              </div>

              {/* FormBody End */}
            </div>
          </div>
        </Container>
      )}
      <div>
        <Modal
          size="sm"
          open={editModal}
          className="modal-dialog-centered"
          onHide={() => setEditModal(false)}
          title={
            <div className="d-flex justify-content-between p-3">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div>
                  <div className="fw-600 fs-18">Profile picture</div>
                </div>
              </div>
              <div onClick={() => setEditModal(false)}>
                <FontAwesomeIcon
                  className="cursor"
                  icon="fa-regular fa-xmark"
                />
              </div>
            </div>
          }
          title_color="black"
          onCloseAction={() => {
            setEditModal(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <div
            className=" py-2 d-flex justify-content-center mb-5 p-4"
            style={{ fontSize: "16px", fontWeight: "300" }}
          >
            <div className="profile-div image d-flex">
              <Image src={profileImg} roundedCircle />
            </div>
          </div>

          <div className="d-flex justify-content-around p-4">
            <div>
              <Changeprofilepicture picture={picture} setPicture={setPicture} />
            </div>
            <div>
              <Pictureremove fname={props.fname} lname={props.lname} />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Notifaction;
