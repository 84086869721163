import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// Actor - Get document details by ContractID
export const GetDocumentDetailsByContactId = (contract_id, actor_id, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_DocumentDetails_By_ContactId,
      null,
      contract_id + "/actor/" + actor_id + "/documents/" + type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};

// Actor - Delete document to archive
export const DeleteArchiveDocument = (
  contract_Id,
  document_id,
  actor_id,
  signing_order_id
) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Actor_Delete_ArchiveDocument,
      null,
      contract_Id +
        "/documents/" +
        document_id +
        "/actor/" +
        actor_id +
        "/signing/" +
        signing_order_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};
// Actor - Delete archive document permanent
export const DeleteArchiveDocumentPermanent = (contract_Id, document_id) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Actor_Delete_ArchiveDocument_Permanent,
      null,
      contract_Id + "/documents/" + document_id + "/permanent"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};
// Actor - Update restore archive document
export const PutUpdateRestoreArchiveDocument = (restorePayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Actor_Update_RestoreArchiveDocument,
      null,
      restorePayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};
// Actor- Document Seen/Read

export const PostActorDocumentViewDetail = (viewDetailPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Actor_ActorDocumentViewDetail,
      null,
      viewDetailPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};

export const PostActorUploadDocument = (data) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Actor_PostUploadDocument,
      { "Content-Type": "multipart/form-data" },
      data
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log("errorssssss",error)
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};

//Get Document Type
export const GetDocumentType = (DoctyType) => {
  // console.log("DOCAC",DoctyType)
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Document_Type, null, DoctyType)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Documents by property Id t display Guides
export const GetDocumentByPropertyId = (propertyId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Document_By_PropertyId,
      null,
      propertyId + "/documents/guide"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Share document via mail

export const PostShareDocumentViaMail = (data) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Actor_Send_Document_byMail,
      { "Content-Type": "multipart/form-data" },
      data
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log("errorssssss",error)
        reject({
          error: true,
          message: error.response.data.message,
          code: error.response.data.code,
        });
      });
  });
};
