import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";
import { GetSessionItem } from "../utils/SessionStorage";

export const LogoutApi = () => {
  return new Promise((resolve, reject) => {
    Get(
      Site.AUTH,
      Endpoint.AUTH.Logout,
      { Authorization: `Bearer ${GetSessionItem("et-rt")}` },
      null
    )
      .then((response) => {
        resolve(response.data);
        // if (response.status === 204) {
        //   window.location.href = "/";
        // }
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
        console.log("Logout api err", error);
      });
  });
};
