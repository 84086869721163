import { Col, Row } from "react-bootstrap";
import { useScreeningFormStore } from "../../hooks/stores/useScreeningFormStore";
import redFlagMan from "../../assets/images/red-flag-man.png";
import yellowFlagMan from "../../assets/images/yellow-flag-man.png";

export const Flags = () => {
    const screeningFormState = useScreeningFormStore((state) => state.Screening_Form_State);
    const flag_type = screeningFormState?.activeFlagData?.screening_condition_rules?.flag_type

    return (
        <Row>
            <Col>
           { 
            flag_type === 1 ?
            <YellowFlag data={screeningFormState} />

            : flag_type === 2 ? 

            <RedFlag data={screeningFormState} />

            : null
            }
            <div className="screening-flag-image">
               { flag_type === 1 && <img style={{ height: '100%' }} src={yellowFlagMan} alt="waiting-man"></img> }
               { flag_type === 2 && <img style={{ height: '100%' }} src={redFlagMan} alt="waiting-man"></img> }
            </div>
            </Col>
        </Row>
    )
}



const YellowFlag = ({ data }) => {
    return(
        <p>{ data?.activeFlagData?.screening_yellow_flag_message }</p>
    )
}

const RedFlag = ({ data }) => {
    return(
        <p>{ data?.activeFlagData?.screening_red_flag_message }</p>
    )
}