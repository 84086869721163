import { Link, NavLink } from "react-router-dom";
import "../../styles/sidebar.css";
import sidebarLogo from "../../assets/images/sidebar-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavBar } from "../../styles/Common.style";

export const Sidebar = ({ role }) => {
  // const jwt = sessionStorage.getItem('et-jwt')
  //  const externalIcon = {
  //  'fa-user-magnifying-glass' :<ScreeningIcon />
  // }
  const userLinks = [
    {
      path: "home",
      icon: "fa-solid fa-grid-2",
      tabName: "Home",
      color: "#0C1356",
    },
    {
      path: "screening",
      icon: "fa-user-magnifying-glass",
      tabName: "Screening",
    },
    // {
    //   path: "former-customer",
    //   icon: "fa-solid fa-grid-2",
    //   tabName: "FormerCustomer",
    // },
    // {
    //   path: "signin-customer",
    //   icon: "fa-solid fa-grid-2",
    //   tabName: "Signing document home",
    // },
    // {
    //   path: "potential-customer",
    //   icon: "fa-solid fa-grid-2",
    //   tabName: "PotentialCustomer",
    // },
    {
      path: "notice-board",
      icon: "fa-solid fa-circle-info",
      tabName: "Notice board",
    },
    {
      path: "messages",
      icon: "fa-solid fa-comment-lines",
      tabName: "Messages",
    },
    {
      path: "documents",
      icon: "fa-solid fa-file-lines",
      tabName: "Documents",
    },
    // {
    //   path: "contact-book",
    //   icon: "fa-solid fa-address-book",
    //   tabName: "Contact book",
    // },
    // {
    //   path: "matching",
    //   icon: "fa-solid fa-house-heart",
    //   tabName: "Matching",
    // },
    // { path: "settings", icon: "fa-solid fa-gear", tabName: "Settings" },
    { path: "lead-home", icon: "fa-solid fa-grid-2", tabName: "Lead Home" },

    {
      path: "events-calendar",
      icon: "fa-solid fa-calendar-star",
      tabName: "Events calendar",
    },
  ];
  // <i class="fa-solid fa-user-magnifying-glass" style="color: #111474;"></i>

  const leadLinks = [
    // { path: "lead-home", icon: "fa-solid fa-grid-2", tabName: "Home" },
    // { path: "reservation", icon: "fa-solid fa-pen", tabName: "Reservation" },
    // {
    //   path: "screening",
    //   icon: "fa-solid fa-user-magnifying-glass",
    //   tabName: "Screening",
    // },
    // {
    //   path: "messages",
    //   icon: "fa-solid fa-comment-lines",
    //   tabName: "Messages",
    // },
    // { path: "settings", icon: "fa-solid fa-gear", tabName: "Settings" },
    // {
    //   path: "events-calendar",
    //   icon: "fa-solid fa-calendar-star",
    //   tabName: "Events calendar",
    // },
    // { path: "create-user", icon: "fa-regular fa-gear", tabName: "Create User" },
  ];

  const links = role === "user" ? userLinks : leadLinks;

  const isActive = true;
  return (
    <>
      {/* {jwt &&  */}
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
        <div className="sidebarlogo px-3 my-4">
          <img
            className="d-block mx-auto w-100 h-100"
            src={sidebarLogo}
            alt="management logo"
          />
        </div>
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: "60%" }}
        >
          <div className="tab-lists ">
            <ul className="p-0">
              {links.map((link, i) => {
                return (
                  <NavLink
                    to={link?.path}
                    key={link?.tabName}
                    state={{ tabName: link?.path }}
                    className="sidebar nav-bar cursor"
                  >
                    {/* <NavBar className={isActive ? 'active' : ''}> */}
                    <li className="d-flex align-items-center gap-2 px-3 py-2 my-2 rounded fw-400 fs-14 sidebar-tabs-color">
                      <div
                        className="d-flex sidebar-svg radius-4"
                        style={{
                          padding: "4px 4px",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ width: "13px", height: "13px" }}
                          color="#0C1356"
                          icon={link.icon}
                        />
                      </div>
                      <div style={{ width: "90%" }}>{link.tabName}</div>
                    </li>
                    {/* </NavBar> */}
                  </NavLink>
                );
              })}
            </ul>
          </div>
          
        </div>
        </div>
        <div style={{paddingBottom:'40px'}}>
            <Link
              className="px-3 py-2 mt-4 rounded fw-400 fs-14 d-flex align-items-center gap-2 cursor bg-white"
              to="help"
            >
              <div
                className="d-flex align-items-center justify-content-center fs-6"
                style={{ width: "10%" }}
              >
                <FontAwesomeIcon icon="fa-regular fa-headphones" />
              </div>
              <div style={{ width: "90%" }}>Need help?</div>
            </Link>

            {/* <Link
            className="px-3 py-2 rounded fw-400 fs-14 d-flex align-items-center gap-2 cursor mt-3"
            to="lead-home"
            style={{ color: "black", backgroundColor: "white" }}
          >
            Lead Home
          </Link> */}
          </div>
      </div>
      {/* } */}
    </>
  );
};
