import reservationImg1 from "../../../assets/images/reservation-image-1.jpg";
import reservationImg2 from "../../../assets/images/reservation-image-2.jpg";
import reservationImg3 from "../../../assets/images/reservation-image-3.jpg";
import reservationImg4 from "../../../assets/images/reservation-image-4.jpg";
import reservationImg5 from "../../../assets/images/reservation-image-5.jpg";
import reservationImg6 from "../../../assets/images/reservation-image-6.jpg";

// reservation page flat images
export const reservationDummyImgData = [
    {
        imgLink: reservationImg1
    },
    {
        imgLink: reservationImg2
    },
    {
        imgLink: reservationImg3
    },
    {
        imgLink: reservationImg4
    },
    {
        imgLink: reservationImg5
    },
    {
        imgLink: reservationImg6
    },
    {
        imgLink: reservationImg1
    },
    {
        imgLink: reservationImg2
    },
    {
        imgLink: reservationImg3
    },
    {
        imgLink: reservationImg4
    },
    {
        imgLink: reservationImg5
    },
    {
        imgLink: reservationImg6
    },
    {
        imgLink: reservationImg1
    },
    {
        imgLink: reservationImg1
    }
];
// reservation page flat images

export const leadPersonalDetailsData = [
    {
       type: 'Information',
       taskDone: 10,
       totalTask: 10,
       status: 'edit',
       icon: 'fa-regular fa-user'
    },
    {
        type: 'RKI',
        taskDone: 1,
        totalTask: 1,
        status: 'edit',
        icon: 'fa-regular fa-shield-halved'
    },
    {
        type: 'Occupation',
        taskDone: 3,
        totalTask: 6,
        status: 'fill out',
        icon: 'fa-regular fa-briefcase'
    },
    {
        type: 'Economy',
        taskDone: 2,
        totalTask: 4,
        status: 'fill out',
        icon: 'fa-regular fa-landmark'
    },
    {
        type: 'Household',
        taskDone: 5,
        totalTask: 5,
        status: 'edit',
        icon: 'fa-regular fa-house-user'
    },
    {
        type: 'Identification',
        taskDone: 1,
        totalTask: 2,
        status: 'fill out',
        icon: 'fa-regular fa-passport'
    },
    {
        type: 'Salary slip',
        taskDone: 2,
        totalTask: 3,
        status: 'fill out',
        icon: 'fa-regular fa-file-lines'
    },
    {
        type: 'Confirmation',
        taskDone: 0,
        totalTask: 1,
        status: 'confirm',
        icon: 'fa-regular fa-circle-check'
    }
]