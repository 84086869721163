import { H1, H4 } from './../../../styles/Common.style';
import NormalButton from './../../../Components/input/normalButton/NormalButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'react-bootstrap';
import circleApproved from '../../../assets/icons/circle-approved.svg';
import { useGetCountries } from '../../../hooks/queries/useLocationQueries';
import { useActorProfileStore } from '../../../hooks/stores/useActorDetails';
import CustomReactSelect from '../../../Components/Dropdown/CustomReactSelect';

export const PhoneVerification = ({ setWizardIndex }) => {
    const [verificationCode, setVerificationCode] = useState(false);

    const { register, handleSubmit, getValues, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
          email: "",
        }
      });

    const actorProfile                            = useActorProfileStore((state) => state.actorDetail);
    const { data: getCountriesData } = useGetCountries(actorProfile?.country_id);
    const onFormSubmit = () => {
        // setVerificationCode(true);
    }
    
    return(
        <>
            {
            !verificationCode ?
                <Form className="h-100" onSubmit={handleSubmit(onFormSubmit)}>
                    <div>
                        <H1 className='primary-text-color'>Phone verification</H1>
                        <p className='primary-text-color fs-16 fw-bold mt-5'>Do we have the correct phone number?</p>
                        <p className="primary-text-color">Before we can create a profile for you we also need to make sure that we have the correct phone number.</p>
                        <p className="primary-text-color">Please make sure that you have access to this phone number.</p>
                    </div>
                    <div>
                    <Row>
                        <Col className = "mt-2 mx-2 px-1">
                        <Row>
                            <Col className = "text-start fs-14 fw-bold px-0 mb-2">
                                Phone number
                            </Col>
                        </Row>
                        <Row className = "screening-phone-number-container">
                        <Col xs        = {4} className = "px-0">
                            <Controller
                                defaultValue = ""
                                control      = {control}
                                name         = {`countryId`}
                                // rules        = {{ required: phoneData[1]?.is_required }}
                                // rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                const filterValue = getCountriesData?.data?.filter(
                                    (cd) => cd.Id === value
                                );
                                const selectedVal = filterValue?.length
                                    ? {
                                        value: filterValue[0]?.Id,
                                        label: (
                                        <div
                                            // key={i}
                                            className = "d-flex align-items-center"
                                            style     = {{
                                            fontFamily: "Cerebri sans",
                                            }}
                                        >
                                            <div className = "radius-4 me-2">
                                            <img
                                                src   = {filterValue[0].DataValue1}
                                                style = {{
                                                width      : "20px",
                                                marginRight: "10px",
                                                }}
                                                alt = "country flag"
                                            />
                                            </div>
                                            <div>
                                            <div className = "fs-14 light-font-text d-flex gap-1">
                                                <span>{filterValue[0]?.Id}</span>
                                                <span>{filterValue[0]?.DataValue2}</span>
                                            </div>
                                            </div>
                                        </div>
                                        ),
                                    }
                                    :  "";
                                return (
                                    <CustomReactSelect
                                    className="w-100"
                                    setValue    = {setValue}
                                    name        = {`countryId`}
                                    bgColor     = {"#f9fafa"}
                                    border      = {"none"}
                                    description = {"Search country code"}
                                    placeholder = {'phone number'}
                                    value       = {selectedVal}
                                    options     = {getCountriesData?.data?.map((item, i) => ({
                                        value: item?.Id,
                                        label: (
                                        <div
                                            // key={i}
                                            className = "d-flex align-items-center"
                                            style     = {{
                                            fontFamily: "Cerebri sans",
                                            }}
                                        >
                                            <div className = "radius-4 me-2">
                                            <img
                                                src   = {item.DataValue1}
                                                style = {{
                                                width      : "20px",
                                                marginRight: "10px",
                                                }}
                                                alt = "country flag"
                                            />
                                            </div>
                                            <div>
                                            <div className = "fs-14 light-font-text d-flex gap-1">
                                                <span>{item?.Id}</span>
                                                <span>{item?.DataValue2}</span>
                                            </div>
                                            </div>
                                        </div>
                                        ),
                                        searchText: item?.DataValue2
                                    }))}
                                        // value={value}
                                    dropDownIndicator = {false}
                                        // iserror={errors?.screeninginvitation}
                                        // inputValue={inputValue}
                                    isSearchable = {false}
                                        // onInputChange={(val) => setInputValue(val)}
                                        // onInputChange={(e)=> {
                                        //   console.log(value,'hi')
                                        // }}
                                    />
                                );
                                }}
                            />
                            </Col>
                            <Col xs = {8} className = "px-0">
                            <Controller
                                defaultValue = ""
                                control      = {control}
                                name         = {`phoneno`}
                                // rules        = {{ required: phoneData[0]?.is_required }}
                                // rules={{required: false}}
                                render={({ field, fieldState }) => {
                                return (
                                    <div>
                                    <div
                                        className={`form-input information-form-number rounded ${
                                        fieldState.error?.[field.name] && "error"
                                        }`}
                                    >
                                        <input
                                        {...field}
                                        type        = "number"
                                        placeholder = {``}
                                        id          = {'phoneno'}
                                        />
                                        {/* <label htmlFor={ phoneData[0].placeholder}>{ phoneData[0].placeholder}</label> */}
                                    </div>
                                    </div>
                                );
                                }}
                            />
                            </Col>
                        </Row>
                        {(errors?.[`phoneno`] || errors?.[`countryId`]) && (
                        <p className="error-msg text-start" style={{ color: "red" }}>
                            This field is required.
                        </p>
                        )}
                        </Col>
                    </Row>
                    </div>
                    <NormalButton
                        type="submit"
                        className="fw-900"
                        textColor=" white"
                        border="none"
                        backgroundColor="green"
                        padding="9px 18px"
                        borderRadius="6"
                        fontSize="14"
                        fontWeight="600"
                        buttonText="GET VERIFICATION CODE"
                        bstClassName="mt-5 mb-5"
                        />
                        <div className="position-absolute bottom-0 cursor d-flex align-items-center fs-14" onClick={()=> setWizardIndex(1) }>
                            <FontAwesomeIcon className="me-1" icon="fa-regular fa-arrow-left" />
                            <span>Go Back</span>
                        </div>
                </Form>
            :
                <PhoneVerificationCode setVerificationCode={setVerificationCode} setWizardIndex={setWizardIndex} />
            }
        </>
    )
}

const PhoneVerificationCode = ({ setVerificationCode, setWizardIndex }) => {
    const { register, handleSubmit, getValues, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
          verificationCode: "",
        },
    });

    const [emailSuccess, setEmailSuccess] = useState(null)
    const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);

    const otpFieldsRef = useRef([]);

    const handleInput = (index, value) => {
        if (value.length > 1) {
          return;
        }
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        setValue(`otp${index+1}`, value, { shouldValidate: true })
        if (value.length === 1 && index < otpValues.length - 1) {
          otpFieldsRef.current[index + 1].focus();
        }
    };

    const handleBackspace = (index) => {
        if (otpValues[index] !== "") {
          const newOtpValues = [...otpValues];
          newOtpValues[index] = "";
          setValue(`otp${index+1}`, '', { shouldValidate: true })
          setOtpValues(newOtpValues);
        } else if (index > 0) {
          otpFieldsRef.current[index - 1].focus();
        }
    };

    const onFormSubmit = () => {
        setEmailSuccess(true)
        setOtpValues(["", "", "", "", "", ""])
        reset();
    }

    return(
        <>
        {
        emailSuccess === null ?
        <Form className="h-100" onSubmit={handleSubmit(onFormSubmit)}>
             <div>
                 <H1 className='primary-text-color'>Email verification</H1>
                 <p className='fs-16 fw-bold mt-4 primary-text-color'>Verification code</p>
                 <p className='primary-text-color'>We have send you an email with a six digit verification code.</p>
                 <p className='primary-text-color'>Please fill out the code below to verify your email.</p>
             </div>
             <div>
             <Row>
                 <div className = "primary-text-color px-0">
                 Verification code
                 </div>
                 <Col className = "pb-2 px-0">
                 <div>
                     <div className="profile-otp-container gap-3 flex-wrap px-2 mt-1
                     ">
                         {otpValues.map((value, index) => (
                             <div className="d-flex flex-column" key={index}>
                                 <input
                                     type="number"
                                     maxLength="1"
                                     className="profile-otp-input"
                                     style={{ width: '40px', height: '40px' }}
                                     value={value}
                                     {...register(`otp${index+1}`, {
                                         required: true,
                                     })}
                                     onChange={(e) => handleInput(index, e.target.value)}
                                     onKeyDown={(e) => {
                                     if (e.key === "Backspace") {
                                         handleBackspace(index);
                                     }
                                     }}
                                     ref={(ref) => {
                                     otpFieldsRef.current[index] = ref;
                                     }}
                                 />
                                 <div className="otp-border"></div>
                             </div>
                         ))}
                     </div>
                     {Boolean(Object.keys(errors).length) && (
                             <p className="error-msg">
                             This OTP is required
                             </p>
                     )}
                     <div className="mt-3">
                         <span className="fs-14 me-1">Didn't get a code?</span>
                         <span className="fs-14" style={{ color: '#91AA7B' }}>Resend code</span>
                     </div>
                 </div>
                 </Col>
             </Row>
             </div>
             <NormalButton
                 type="submit"
                 className="fw-900"
                 textColor=" white"
                 border="none"
                 backgroundColor="green"
                 padding="9px 18px"
                 borderRadius="6"
                 fontSize="14"
                 fontWeight="600"
                 buttonText="VERIFY"
                 bstClassName="mt-3 mb-5"
                 />
                 <div className="position-absolute bottom-0 cursor d-flex align-items-center fs-14" onClick={()=> setVerificationCode(false) }>
                     <FontAwesomeIcon className="me-1" icon="fa-regular fa-arrow-left" />
                     <span>Go Back</span>
                 </div>
         </Form>
         : emailSuccess === true ? 
            <PhoneVerificationSuccess />
         : <PhoneVerificationDeclined setEmailSuccess={setEmailSuccess} />
        }
        </>
    )
}

const PhoneVerificationSuccess = ({ setWizardIndex }) => {
    return(
        <div className = "p-3" style = {{ height: '180px' }}>
        <Row>
          <Col className = "px-0">
          <H1 className='primary-text-color'>Email verification</H1>
          <p   className = "fs-16 fw-bold primary-text-color mt-4">Verification approved</p>
          </Col>
        </Row>
        <Row>
          <Col className = "px-0 mt-3">
          <p   className = "fs-16"> Wuhu, the code was correct.</p>
          <p   className = "fs-16">You are now ready for next step.</p>
          </Col>
        </Row>
        <Row>
            <Col>
                <img className='d-block mx-auto' src={circleApproved} alt='approved' />
            </Col>
        </Row>
        <NormalButton
            type="submit"
            className="fw-900"
            textColor=" white"
            border="none"
            backgroundColor="green"
            padding="9px 20px"
            borderRadius="6"
            fontSize="14"
            fontWeight="600"
            buttonText="NEXT"
            bstClassName="mt-3 mb-5"
            onClick={()=> setWizardIndex(2)}
        />
      </div>
    )
}

const PhoneVerificationDeclined = ({ setEmailSuccess }) => {
    return(
        <div className = "p-3" style = {{ height: '180px' }}>
        <Row>
          <Col className = "px-0">
          <H1 className='primary-text-color'>Email verification</H1>
          <p   className = "fs-16 fw-bold primary-text-color mt-4">Verification declined</p>
          </Col>
        </Row>
        <Row>
          <Col className = "px-0 mt-3">
          <p   className = "fs-16 primary-text-color">Oh no, something went wrong. The verification code was declined.</p>
          <p   className = "fs-16 primary-text-color">Please go back and try again.</p>
          </Col>
        </Row>
        <NormalButton
            type="submit"
            className="fw-900"
            textColor=" white"
            border="none"
            backgroundColor="green"
            padding="9px 20px"
            borderRadius="6"
            fontSize="14"
            fontWeight="600"
            buttonText="GO BACK"
            bstClassName="mt-3 mb-5"
            onClick={()=> setEmailSuccess(null)}
        />
      </div>
    )
}