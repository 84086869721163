import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import managementLogo from "../../../assets/images/management-logo.svg";
import loginImagePattern from "../../../assets/images/login-image-pattern.svg";
import { Col, Row, Form, Button, Image, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../../../styles/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetSessionItem } from "../../../utils/SessionStorage";
import { Snackbar, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useLogin } from "../../../hooks/queries/useLoginQueries";
import { H1, H4 } from "../../../styles/Common.style";
import NormalButton from "../../../Components/input/normalButton/NormalButton";
import PasswordValidation from "../../create-user/PasswordValidation";

export const CreateNewPassword = () => {

  const isMobile = useMediaQuery("(max-width:576px)")

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
        password: "",
        Confirmpassword: "",
    },
  });
  const onFormSubmit = () => {
    setPasswordChange(true);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const [passwordChanged, setPasswordChange] = useState(false)

  const [isStrong, initRobustPassword] = useState(null);

  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <>
      <div
        className = "pt-3 bg-login d-flex justify-content-center align-items-center px-md-4"
        style     = {{ position: "relative" }}
      >
        <div className = "mb-4 forgot-password-container">
          <img
            src       = {managementLogo}
            alt       = "management-logo"
            className = "management-logo d-block m-auto"
          />
            <Row className = "mb-1">
            <Col sm        = {12}>
            <Row className = "loginForm mt-3 mb-3 flex-column-reverse flex-sm-row position-relative">
                  <Col sm        = {6} className = "login-right-section">
                  {
                    !passwordChanged ?
                    <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Row className="mb-1">
                      <Col sm={12}>
                        <Row className="mt-3 mb-3 flex-column-reverse flex-sm-row position-relative">
                          <Col className="px-0">
                            <H1 className="primary-text-color">Password</H1>
                            <div>
                                <H4 className="primary-text-color">Create a secure password</H4>
                                <p className="primary-text-color">It is important that your password is strong so we can keep your personal information and documents safe.</p>
                            </div>
                            <div className="login-form">
                            <div className="form-group mb-2">
                                  <H4>Password</H4>
                                <InputGroup className="position-relative">
                                  <input
                                    className="d-block w-100 radius-6"
                                    placeholder="Enter password"
                                    type={showPassword ? "text" : "password"}
                                    {...register("password", {
                                      required: true,
                                      validate: (val) => {
                                        if(val.length < 8 || val.length > 16) {
                                            return "Password must be between 8 and 16 characters."
                                        }
                                      }
                                    })}
                                  />
                                <label className="input-headings mb-1 position-absolute">
                                    password
                                </label>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClickShowPassword}
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        showPassword
                                          ? "fa-regular fa-eye-slash"
                                          : "fa-regular fa-eye"
                                      }
                                    />
                                  </div>
                                </InputGroup>
                                <PasswordValidation
                                    type={'line'}
                                    password={watch('password')}
                                    actions={initPwdInput}
                                    isStrong={isStrong}
                                />
                                <div className="d-flex justify-content-between mt-1 flex-wrap">
                                {errors.password ? (
                                  <p className="error-msg">{errors.password.message ? errors.password.message : "Password is required."}</p>
                                ) : null}
                                </div>
                            </div>
                            <div className="form-group mb-2 mt-3">
                                <H4>Confirm password</H4>
                                <InputGroup className="position-relative">
                                  <input
                                    className="d-block w-100 radius-6"
                                    placeholder="Enter password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    {...register("Confirmpassword", {
                                      required: true,
                                      validate: (val) => {
                                        if(watch('password') !== val) {
                                            return "Confirm password must be same as password."
                                        }
                                      }
                                    })}
                                  />
                                <label className="input-headings mb-1">
                                    Confirm password
                                </label>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClickShowConfirmPassword}
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        showConfirmPassword
                                          ? "fa-regular fa-eye-slash"
                                          : "fa-regular fa-eye"
                                      }
                                    />
                                  </div>
                                </InputGroup>
                                <div className="d-flex justify-content-between mt-1 flex-wrap">
                                    { (Boolean(watch('Confirmpassword').length) && Boolean(watch('Confirmpassword').length)) && (watch('Confirmpassword') === watch('password') ) && <div className="fs-8">
                                    <FontAwesomeIcon style={{ marginRight: '0.3rem', color: '#54A64D'}} icon="fa-solid fa-circle-check" />
                                    <span style={{ color: '#54A64D' }}>Your password and confirmation password match.</span>
                                    </div>
                                    }
                                    { (Boolean(watch('Confirmpassword').length) && Boolean(watch('Confirmpassword').length)) && (watch('Confirmpassword') !== watch('password') ) && <div className="fs-8">
                                    <FontAwesomeIcon style={{ marginRight: '0.3rem', color: '#E33716'}} icon="fa-solid fa-circle-exclamation" />
                                    <span style={{ color: '#E33716' }}>Your password and confirmation password does not match.</span>
                                    </div>
                                    }
                                {/* {errors.Confirmpassword ? (
                                  <p className="error-msg">{errors.Confirmpassword.message ? errors.Confirmpassword.message : "Password is required."}</p>
                                ) : null} */}
                                </div>
                            </div>

                              <NormalButton
                                type="submit"
                                className="fw-900"
                                textColor=" white"
                                border="none"
                                backgroundColor="green"
                                padding="9px 18px"
                                borderRadius="6"
                                fontSize="14"
                                fontWeight="600"
                                buttonText="NEXT"
                                bstClassName="mt-5"
                                disabled={!watch("password").length || !watch("Confirmpassword").length }
                                // onClick={() => setPreviewModal(false)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    </Form>
                    :
                    <NewPasswordUpadateMsg />
                  }
                  </Col>
                  <Col sm = {6} className = "d-sm-block">
                    <Image
                      fluid
                      className = "w-100 h-100"
                      src       = {loginImagePattern}
                      alt       = "login-image-pattern"
                      style     = {{ objectFit: "cover", borderRadius: "8px", maxHeight: isMobile ? '80px' : '100%' }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
        </div>
        <div className = "copyright copyright-text">
          Copyright 2013-2023 © Estatetool A/S
        </div>
      </div>
    </>
  );
};


const NewPasswordUpadateMsg = () => {
    const navigate = useNavigate();

    return(
        <div>
            <div>
                <H1 className="primary-text-color mb-5">New password</H1>
                <p className="primary-text-color fw-bold">Your password has been updated</p>
                <p className="primary-text-color">You must use your new password when you login to your MyPage profile. </p>
            </div>
            <NormalButton
                type="submit"
                className="fw-900"
                textColor=" white"
                border="none"
                backgroundColor="green"
                padding="9px 18px"
                borderRadius="6"
                fontSize="14"
                fontWeight="600"
                buttonText="GO TO LOGIN"
                bstClassName="mt-5"
                onClick={() => navigate("/")}
                />
        </div>
    )
}

export default CreateNewPassword;