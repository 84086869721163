import React, { useState } from "react";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect";

import Select, { components } from "react-select";
import CustomCheckBox from "../input/Checkbox/Checkbox";

export const { ValueContainer, Placeholder } = components;

const MultiSelect = () => {
  const [optionSelected, setOptionSelected] = useState(null);
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };
  // console.log(languageList, "languageList");
  return (
    <MySelect
      options={languageList}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
        MultiValue,
        animatedComponents,
        CustomValueContainer,
      }}
      onChange={handleChange}
      allowSelectAll={true}
      value={optionSelected}
      placeholder={""}
      styles={myInputStyle}
      isSearchable={true}
    />
  );
};
export default MultiSelect;
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex align-items-center ">
          <CustomCheckBox
            id={props?.data?.id}
            checked={props.isSelected}
            // onChange={(e) => handleCheckedLanguage(e, data)}
            onChange={() => null}
          />
          <div>
            <div className="light-font-text d-flex align-items-center gap-2">
              {props.data?.DataValue1 && (
                <img
                  src={props.data?.DataValue1}
                  width={"18px"}
                  height={"12px"}
                />
              )}
              {props.data?.Text}
            </div>
          </div>
        </div>
      </components.Option>
    </div>
  );
};
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.Text}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();
export const myInputStyle = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    position: "relative",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    fontFamily: "Cerebri sans-light !important",
    fontSize: 14,
    // fontWeight:300,
    marginLeft: "10px",
    padding: "0px",
    paddingTop: "2px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    fontWeight: "300",
    background: "rgb(255,255,255)",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 55%, rgba(247,247,247,0) 45%)",
    borderRadius: 2,
    padding:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "0px 4px"
        : "0px 1px",
    margin: "0px",
    // marginLeft: "10px",
    color:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "#888888"
        : "#000000",
    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-58%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    fontWeight: 300,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    fontSize: 14,
    fontWeight: 300,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#D1D1D1 !important" : "#D1D1D1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "&:hover": {
      borderColor: "#D1D1D1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    // opacity: state.isDisabled ? "0.5" : "1",
  }),
  option: (base, { isDisabled }, state) => ({
    ...base,
    backgroundColor: "#fff",
    fontSize: 14,
    fontFamily: "Cerebri sans-light !important",
    zIndex: 1,

    color: isDisabled ? "#888888" : "#000000",
    paddingLeft: "15px",
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    fontWeight: 300,
    width: "max-content",
    minWidth: "100%",
    // overflowX: "scroll",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};



export const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.key !== "placeholder" ? child : null
        )}
      </ValueContainer>
    );
  };

  export const languageList = [
    {
      id: "da",
      Text: "Danish",
      DataValue1: "https://millicent-test.b-cdn.net/assets/flags/dk.svg",
    },
    {
      id: "en",
      Text: "English",
      DataValue1: "https://millicent-test.b-cdn.net/assets/flags/gb.svg",
    },
    {
      id: "sv",
      Text: "Swedish",
      DataValue1: "https://millicent-test.b-cdn.net/assets/flags/se.svg",
    },
  ];