import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const GreenSpan = styled.span`
  color: #619130;
`;
// color: ${(props) => props.theme.colors.primaryColor};

export const DropZoneBorder = styled.div`
  border-radius: 8px;
  border-width: 1px;
  border-style: dashed;
  border-color: #619130;
`;

export const StyledNavLink = styled(NavLink)`
  &.cursor {
    /* Add any common styles for the "cursor" class here */

    cursor: pointer;
  }

  li {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 2px 10px;
    margin: 2px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    // color: #fff;y
    text-decoration: none;
  }

  .sidebar-svg {
    background-color: #ffffff;
    padding: 0px 4px;
    border-radius: 4px;
  }

  &:active {
    background-color: #205d5c;
    color: white !important;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  /* Add any custom styles for FontAwesomeIcon here */

  background-color: #ffffff;
`;

// --------------Class Level Typography------------//

export const H1 = styled.h1`
  font-size: 32px;
  font-family: Cerebri sans-bold;
  line-height: 48px;
  letter-spacing: 0;
  color: ${(props) => props.color || "#252525"};
`;
export const H2 = styled.h2`
  font-size: 24px;
  font-family: Cerebri sans-bold;
  line-height: 30px;
  letter-spacing: 0;
  color: ${(props) => props.color || "#252525"};
`;
export const H3 = styled.h3`
  font-size: 20px;
  font-family: Cerebri sans-medium;
  line-height: 24px;
  letter-spacing: 0;
  color: ${(props) => props.color || "#252525"};
`;
export const H4 = styled.h4`
  font-size: 16px;
  font-family: Cerebri sans-medium;
  line-height: ${(props) => props.lineHeight};
  letter-spacing: 0;
  color: ${(props) => props.color || "#252525"};
`;
export const H5 = styled.h5`
  font-size: 14px;
  font-family: Cerebri sans-medium;
  line-height: 20px;
  letter-spacing: 0;
  color: ${(props) => props.color || "#252525"};
`;
