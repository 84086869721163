import React, { useState } from "react";
import "../../styles/help.css";
import { Col, Row } from "react-bootstrap";
import Needhelp from "../../assets/images/needhelp.png";
import Img from "../../assets/images/img.png";

import NormalButton from "../../Components/input/normalButton/NormalButton";
import FaqCard from "./Faqcard";
import { useForm } from "react-hook-form";
import { H2 } from "../../styles/Common.style";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NeedHelp = () => {
	const { handleSubmit, getValues, register, setValue, formState: { errors } } = useForm({
		defaultValues: {
			Headline: "",
			Message: "",
			img: "",
		},
	});
	const [formValue, setFormValue] = useState({
		Headline: getValues("Headline"),
		Message: getValues("Message"),
		img: getValues("img"),
	});

	 // dropzone
	 const { getRootProps, getInputProps, open } = useDropzone({
		accept: {
		  "application/pdf": [],
		  "image/jpeg": [],
		  "image/png": [],
		},
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		maxSize: 5242880,
		maxFiles: 1,
		onDrop: (acceptedFiles, fileRejections) => {
		  acceptedFiles.forEach((file) => {
			const filePreviewData = Object.assign(file, {
			  preview: URL.createObjectURL(file),
			});
			setValue('needhelpFile', filePreviewData, { shouldValidate: true });
			// append({ name: file.name, document_type: "", file: file });
		  });
		  fileRejections.map(({ file, errors }) => {
			return errors.map((e) =>
			  toast.error(
				e.code === "file-too-large" ? "File is larger than 5MB" : e.message
			  )
			);
		  });
		},
	  });
	
	const handleFileChange = (event) => {};
	const onSubmit = (data) => {
		console.log(data);
	}; // your form subm

	return (
		<>
			<div className="px-3">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="card-box">
						<Row>
							<Col xl={6} sm={12}>
								<div className="bg-lite-orange py-4 px-5 my-4 m-2">
									<img
										className="help-img w-100 h-100"
										src={Needhelp}
										alt="need-help"
									/>
								</div>
							</Col>
							<Col xl={6} sm={12}>
								<div className="p-3">
									<div className="mt-4 fw-700 ">
										<H2 className="fw-bold">
											Let us help you solve your problem
										</H2>
									</div>
									<div className="mt-3 fs-18 fw-300 fnt-18">
										Temporibus autem quibusdam et aut officiis debitis aut rerum
										necessitatibus saepe eveniet ut et voluptates repudiandae
										sint et molestiae non recusandae.
									</div>
								</div>
								<div>
									<Row>
										<Col lg={12}>
											<div>
												<div className="form-input input-bg-gray mt-3">
													<input
														type="text"
														placeholder="e.g. I can’t download my documents"
														id="Headline"
														className="input-bg-gray"
														{...register("Headline", {
															onChange: (e) => {
																setFormValue({
																	...formValue,
																	Headline: e.target.value,
																});
															},
														})}
													/>
													<label htmlFor="Headline">Headline</label>
												</div>
											</div>
										</Col>
									</Row>
								</div>
								<div>
									<Row>
										<Col lg={12}>
											<div>
												<div className="form-message-input mt-3">
													<textarea
														type="text"
														rows="10"
														cols="50"
														style={{ resize: "none" }}
														placeholder="Message"
														id="Message"
														className="Message input-bg-gray"
														{...register("Message", {
															onChange: (e) => {
																setFormValue({
																	...formValue,
																	Message: e.target.value,
																});
															},
														})}
													/>
													<label htmlFor="Lastname">Message</label>
												</div>
											</div>
										</Col>
									</Row>
								</div>

								<div>
									<Row>
										<Col lg={12} className="need-help-file-container">
										<div className="pt-2 pb-4">
										{
										getValues('needhelpFile').name ? 
										<div className="screening-remove-bg d-flex align-items-center justify-content-between">
											<div className="d-flex py-3 ps-3">
												<div className="screening-remove-uploaded-img">
													<div className="file-img-icon">
														<FontAwesomeIcon
															style={{ fontSize: "1.2rem", color: "#202020" }}
															icon="fa-solid fa-file-lines"
															flip="horizontal"
														/>
														</div>
													</div>
													<div className="fs-14 px-3 d-flex align-items-center">
														{getValues('needhelpFile').name}
													</div>
												</div>
												<div className="d-flex me-2 me-sm-5 align-items-center">
													<span
														// onClick={() => {
														// setFileUploadModal(true);
														// }}
													>
														<FontAwesomeIcon
														className="me-2 me-sm-4 fs-5 cursor"
														icon="fa-regular fa-magnifying-glass-plus"
														/>
													</span>
													<div
														className="mb-2 mt-2 px-3 cursor browse-btn py-1"
														onClick={() => {
															setValue('needhelpFile', "", { shouldValidate: true })
														}}
													>
														<label className="cursor fs-7 fw-bold">Remove</label>
													</div>
												</div>
										</div>
										:
										<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<div
											className={`file-select py-2 py-lg-3 ps-3 d-flex align-items-center justify-content-between flex-wrap upload-bg`}
										>
											<div className="d-flex">
											<div className="d-flex align-items-center">
												<div className="screening-upload-img">
												<div className="file-img-icon">
													<FontAwesomeIcon
													style={{ fontSize: "1.2rem", color: "#888888" }}
													icon="fa-solid fa-file-lines"
													flip="horizontal"
													/>
												</div>
												</div>

												<div className="fs-14 px-3 fw-400 color-gray text-start py-2 lh-sm">
												<div className="fw-bold">
													Drop your file here, or browse your computer
												</div>
												<div>JPG, PNG or PDF (Max. file size 5MB)</div>
												</div>
											</div>
											</div>
											<div
											className="mb-2 browse-btn mt-2 px-3 py-1 cursor ms-auto me-2 me-sm-5"
											onClick={open}
											>
											<label className="cursor fs-7 fw-bold">
												Browse
											</label>
											</div>
										</div>
										</div>
										}

										</div>
											{/* <div>
												<div className=" mt-3 ms-2  dashed-border d-flex ">
													<div className="input-img px-3">
														<div className="img-icon">
															<img
																className="px-3 "
																style={{ paddingTop: "12px" }}
																src={Img}
																alt="img"></img>
														</div>
													</div>
													<div className="pt-2 px-3 fw-400 color-gray">
														Upload a picture or video to illustrate your
														problem. PNG, JPG, GIF, MP4 or MOV (Max. file size
														5MB)
													</div>
													<div className="mb-2 rectangle mt-2 px-3 cursor browes-border ">
														<input
															className="cursor"
															type="file"
															name="uploadfile"
															id="img"
															accept=".png, .jpg , .gif , .MP4 , .MOV"
															style={{ display: "none" }}
															{...register("img", {
																onChange: (e) => {
																	setFormValue({
																		...formValue,
																		img: e.target.value,
																	});
																},
															})}
															// onChange={handleFileChange}
														/>
														<label className="cursor" htmlFor="img">
															Browse
														</label>
													</div>
												</div>
											</div> */}
										</Col>
										<Col className="ms-2 mt-3 mb-5">
											<NormalButton
												type="submit"
												className="fw-900"
												border="none"
												textColor="white"
												backgroundColor="green"
												buttonText="SUBMIT "
												padding="6px 16px"
												borderRadius="4"
												fontSize="14"
												fontWeight="500"
												bstClassName="ms-auto"
												onClick={() => {}}
											/>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
				</form>
				<FaqCard />
			</div>
		</>
	);
};

export default NeedHelp;
