// export const myPage = {
//   id: "1",
//   // ContainerBackground: "rgba(48, 62, 75, 0.9)",
//   // Biocolor: "white",
//   // TextfieldLabelcolor: "#FFFFFF",
//   //   TextfieldUsernameIcon: <Textimage />,
//   //   TextfieldPassIcon: <Passimage />,

//   primarySidebarColor: "#0C1356",
//   primaryTextColor: "#ffffff",

//   Textfieldcolor: "#FFFFFF",
//   TextfieldBordercolor: "#FFFFFF",

//   primarybtnBgColor: "#FF8A1F",
//   primarybtnColor: "#FFFFFF",

//   primarybtnBorderColor: "1px solid #FF8A1F",
//   // primarybtnHoverBgColor: "#FF8A1F",
//   // primarybtnFocusBgColor: "#FF8A1F",
//   // primarybtnHoverBorder  : "2.5px solid #FF8A1F",
//   // primarybtnFocusBorder  : "2.5px solid #FF8A1F",
//   // disabledprimarybtnBgColor: "#FF8A1F",

//   secondarybtnBgColor: "#ADBE9E",
//   secondarybtnColor: "#FFFFFF",

//   secondarybtnBorderColor: "1px solid #ADBE9E",
//   // secondarybtnHoverBgColor: "#ADBE9E",
//   // secondarybtnFocusBgColor: "#D1D1D1",
//   // secondarybtnHoverBorder: "2.5px solid #ADBE9E",
//   // secondarybtnFocusBorder: "2.5px solid #ADBE9E",
//   // disabledsecondarybtnBgColor: "#D1D1D1",

//   tertiarybtnBgColor: "#D00200",
//   tertiarybtnColor: "#ffffff",

//   // tertiarybtnBorderColor: "1px solid #D00200",
//   // tertiarybtnHoverBgColor: "#D00200",
//   // tertiarybtnFocusBgColor: "#D00200",
//   // tertiarybtnHoverBorder: "1px solid #D00200",
//   // tertiarybtnFocusBorder: "2.5px solid #D00200",
//   // disabledtertiarybtnBgColor: "#FFFFFF",
//   // disabletertiarybtnBorderColor: "1px solid #D1D1D1",

//   quaternarybtnBgColor: "#54a64d",
//   quaternarybtnColor: "#ffffff",

//   // quinarybtnBgColor: "#FFFFFF",
//   // quinarybtnColor: "#000000",

//   quinarybtnBgColor: "#1976d2",
//   quinarybtnColor: "#ffffff",

//   // transitionInUse: "all 0.2s ease-in",

//   tabsColor: "#FF8A1F",
//   tabsBackgroundColor: "#2B3C49",
//   groupButtonColor: "#FF8A1F",

//   FormLinkandTextboxCheckColor: "#FFFFFF",
//   TextfieldDecoration: "1px solid #FFFFFF",
//   date: "2020-09-12",
//   Currency: 123456.49,
// };

import React, { useState } from "react";
import { ThemeProvider } from "styled-components";

export const myPage = {
  id: "1",
  primarySidebarColor: "#0C1356",
  primaryTextColor: "#111747",
  primarybtnBgColor: "#ADBE9E",
  primarybtnColor: "#FFFFFF",

  // primarybtnBorderColor: "1px solid #ADBE9E",
  primarybtnHoverBgColor: "#91AA7B",
  // primarybtnHoverBorder: "1px solid #ADBE9E",
  primarybtnFocusBgColor: "#91AA7B",
  primarybtnFocusBorder: "2.5px solid #ADBE9E",
  disabledprimarybtnBgColor: "#ADBE9E",

  secondarybtnBgColor: "#FFFFFF",
  secondarybtnColor: "#202020",
  // secondarybtnBorderColor: "1px solid #ADBE9E",
  // secondarybtnHoverBorder: "1px solid #ADBE9E",
  secondarybtnFocusBorder: "2.5px solid #E9EBF1",
  secondarybtnHoverBgColor: "#F7F8FA",
  secondarybtnFocusBgColor: "#F7F8FA",
  disabledsecondarybtnBgColor: "#FFFFFF",

  faqTextColor: "#2A39AF",

  screeningApprovedbtnBgColor: "#54A64D",
  screeningApprovedbtnColor: "#FFFFFF",

  screeningAwaitingApprovalbtnBgColor: "#F7A61E",
  screeningAwaitingApprovalbtnColor: "#FFFFFF",

  screeningDeclinedbtnBgColor: "#E33716",
  screeningDeclinedbtnColor: "#FFFFFF",

  questionSelectedbtnBgColor: "#DAD4CE",
  questionSelectedbtnColor: "#202020",

  questionSelectedbtnHoverBgColor: "#BBB2A9",

  questionSelectedbtnFocusBorder: "2.5px solid #BBB2A9",
  questionSelectedbtnFocusBgColor: "#DAD4CE",

  disabledQuestionSelectedbtnBgColor: "#DAD4CE",

  questionNotSelectedbtnBgColor: "#F2F4F6",
  questionNotSelectedbtnColor: "#202020",
  questionNotSelectedbtnBorder: " 1px solid #D1D1D1",
  questionNotSelectedbtnHoverBgColor: "#F7F8FA",
  questionNotSelectedbtnHoverBorder: "1px solid #888",
  disabledQuestionNotSelectedbtnBgColor: "#F2F4F6",
  disabledQuestionNotSelectedBorder: "1px solid #D1D1D1",

  desireToMovebtnBgColor: "#FF8A1F",
  desireToMovebtnColor: "#FFFFFF",
  desireToMovebtnHoverBgColor: "#E46E00",
  desireToMovebtnFocusBgColor: "#FF8A1F",
  desireToMovebtnFocusBorder: "2.5px solid #E46E00",
  disableddesireToMovebtnBgColor: "#FF8A1F",

  reportAProblembtnBgColor: "#E33716",
  reportAProblembtnColor: "#FFFFFF",
  reportAProblembtnHoverBgColor: "#BB1E00",
  reportAProblembtnFocusBgColor: "#E33716",
  reportAProblembtnFocusBorder: "2.5px solid #BB1E00",
  disabledreportAProblembtnBgColor: "#E33716",

  highlightedbtnBgColor: "#E3E5F8",
  highlightedbtnBorder: "1px solid #2A39AF",
  highlightedbtnColor: "#2A39AF",

  highlightedbtnHoverBgColor: "#2A39AF",
  highlightedbtnHoverBorder: "1px solid #E3E5F8",
  highlightedbtnHoverColor: "#FFFFFF",

  highlightedbtnFocusBorder: "2.5px solid #E3E5F8",
  highlightedbtnFocusBgColor: "#2A39AF",
  highlightedbtnFocusColor: "#FFFFFF",

  disabledHighlightedbtnBgColor: "#E3E5F8",
  disabledHighlightedbtnColor: "#2A39AF",
  disabledHighlightedbtnBorder: "1px solid #2A39AF",
};
const Theme = ({ children }) => {
  //   const [mytheme, setMyTheme] = useState(myPage);
  //   console.log(mytheme, "myPage");
  // console.log(myPage, "myPage");
  const theme = {
    component: {
      button: {
        default: {
          bgColor: "#d1d1d1",
          color: "white",
          fontSize: "14px",
          border: "#F4F5F5",
        },
        primary: {
          bgColor: myPage?.primarybtnBgColor
            ? myPage?.primarybtnBgColor
            : "#FF8A1F",
          color: myPage?.primarybtnColor ? myPage?.primarybtnColor : "white",
          fontSize: "14px",
          borderColor: myPage?.primarybtnBorderColor
            ? myPage?.primarybtnBorderColor
            : "none",
          // hoverBgColor:myPage?.primarybtnHoverBgColor
          // ? myPage?.primarybtnHoverBgColor
          // : "none",
          // hoverBorder: myPage?.primarybtnHoverBorder
          //   ? myPage?.primarybtnHoverBorder
          //   : "none",
          // focusBgColor: myPage?.primarybtnFocusBgColor
          // ? myPage?.primarybtnFocusBgColor
          // : "none",
          // focusBorder: myPage?.primarybtnFocusBorder
          //   ? myPage?.primarybtnFocusBorder
          //   : "none",
          disabledBgColor: myPage?.disabledprimarybtnBgColor
            ? myPage?.disabledprimarybtnBgColor
            : "none",
        },
        secondary: {
          bgColor: myPage?.secondarybtnBgColor
            ? myPage?.secondarybtnBgColor
            : "#d1d1d1",
          color: myPage?.secondarybtnColor
            ? myPage?.secondarybtnColor
            : "black",
          fontSize: "14px",
          borderColor: myPage?.secondarybtnBorderColor
            ? myPage?.secondarybtnBorderColor
            : "none",
          // hoverBgColor: myPage?.secondarybtnHoverBgColor
          //   ? myPage?.secondarybtnHoverBgColor
          //   : "none",
          // focusBgColor: myPage?.secondarybtnFocusBgColor
          //   ? myPage?.secondarybtnFocusBgColor
          //   : "none",
          // hoverBorder: myPage?.secondarybtnHoverBorder
          //   ? myPage?.secondarybtnHoverBorder
          //   : "none",
          // focusBorder: myPage?.secondarybtnFocusBorder
          //   ? myPage?.secondarybtnFocusBorder
          //   : "none",
          // disabledBgColor: myPage?.disabledsecondarybtnBgColor
          //   ? myPage?.disabledsecondarybtnBgColor
          //   : "none",
        },
        tertiary: {
          bgColor: myPage?.tertiarybtnBgColor
            ? myPage?.tertiarybtnBgColor
            : "#d1d1d1",
          color: myPage?.tertiarybtnColor ? myPage?.tertiarybtnColor : "black",
          fontSize: "14px",
          // borderColor: myPage?.tertiarybtnBorderColor
          //   ? myPage?.tertiarybtnBorderColor
          //   : "none",
          // hoverBgColor: myPage?.tertiarybtnHoverBgColor
          //   ? myPage?.tertiarybtnHoverBgColor
          //   : "none",
          // focusBgColor: myPage?.tertiarybtnFocusBgColor
          //   ? myPage?.tertiarybtnFocusBgColor
          //   : "none",
          // hoverBorder: myPage?.tertiarybtnHoverBorder
          //   ? myPage?.tertiarybtnHoverBorder
          //   : "none",
          // focusBorder: myPage?.tertiarybtnFocusBorder
          //   ? myPage?.tertiarybtnFocusBorder
          //   : "none",
          // disabledBgColor: myPage?.disabledtertiarybtnBgColor
          //   ? myPage?.disabledtertiarybtnBgColor
          //   : "none",
          // disabledBorder: myPage?.disabletertiarybtnBorderColor
          //   ? myPage?.disabletertiarybtnBorderColor
          //   : "none",
        },

        quaternary: {
          bgColor: myPage?.quaternarybtnBgColor
            ? myPage?.quaternarybtnBgColor
            : "#54a64d",
          color: myPage?.quaternarybtnColor
            ? myPage?.quaternarybtnColor
            : "white",
          fontSize: "14px",
        },
        quinary: {
          bgColor: myPage?.quinarybtnBgColor
            ? myPage?.quinarybtnBgColor
            : "#ffffff",
          color: myPage?.quinarybtnColor ? myPage?.quinarybtnColor : "#000000",
          fontSize: "14px",
        },
        senary: {
          bgColor: myPage?.senarybtnBgColor
            ? myPage?.senarybtnBgColor
            : "#d1d1d1",
          color: myPage?.senarybtnColor ? myPage?.senarybtnColor : "white",
          fontSize: "14px",
        },
      },
      checkbox: {
        default: {
          color: "#619130",
        },
        primary: {
          color: myPage?.primarySidebarColor
            ? myPage?.primarySidebarColor
            : "#619130",
        },
      },
      tabs: {
        default: {
          color: "#619130",
        },
        primary: {
          color: myPage?.tabsColor ? myPage?.tabsColor : "#619130",
          backGroundColor: myPage?.tabsBackgroundColor
            ? myPage?.tabsBackgroundColor
            : "#619130",
        },
      },
    },

    colors: {
      red: "#FF8A1F",
      bgColor: myPage?.Signinbuttonbgcolor
        ? myPage?.Signinbuttonbgcolor
        : "#FF8A1F",
      lightBlue: "#AFDBD2",
      white: "#ffffff",
      primarySidebarColor: myPage?.primarySidebarColor
        ? myPage?.primarySidebarColor
        : "#FF8A1F",
    },
    transition: {
      btnTransition: myPage?.transitionInUse ? myPage?.transitionInUse : "none",
    },
  };
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
