import React from "react";
import ToggleSwitch from "../../../Components/input/Switch/Switch";
import { Col, Row } from "react-bootstrap";
import Activedisableswitch from "../../../Components/input/Switch/Activedisableswitch";

const Notifications = () => {
  return (
    <>
      <Col className="gray-bg px-0 " lg={9}>
        <div
          className="profile-info Cerebri-sans-medium fs-18"
          style={{ marginTop: "36px", marginLeft: "31px" }}
        >
          {" "}
          Notification settings
        </div>

        <div
          className="profile-title"
          style={{ marginTop: "6px", marginLeft: "31px" }}
        >
          We may still send you important notifications about your
          <div>account outside of the notification settings.</div>
        </div>
        <hr style={{ border: "1.5px solid #FFFFFF", marginTop: "17px",opacity : '2' }}></hr>
        <div
          className="profile-info Cerebri-sans-medium"
          style={{
            marginLeft: "36px",
            marginTop: "24px",
            marginBottom: "6px",
          }}
        >
          Updates and warnings
        </div>
        <Row className="me-5">
          <Col>
            <div className="notifaction-text" style={{ marginLeft: " 24px" }}>
              These are notifications for<br></br>
              important updates and warnings<br></br>
              where we need to notify or<br></br>
              contact you.
            </div>
          </Col>
          <Col>
            <Row>
              <Col className="d-flex me-5">
                <div>
                  <Activedisableswitch />
                </div>
                <Col className="Cerebri-sans-medium">Push</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <Activedisableswitch />
                </div>
                <Col className="Cerebri-sans-medium">Email</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <Activedisableswitch />
                </div>
                <Col className="Cerebri-sans-medium">SMS</Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          className="profile-info Cerebri-sans-medium"
          style={{
            marginLeft: "36px",
            marginTop: "24px",
            marginBottom: "6px",
          }}
        >
          Messages
        </div>
        <Row className="me-5">
          <Col>
            <div className="notifaction-text" style={{ marginLeft: " 24px" }}>
              These are notifications for<br></br>
              messages in your inbox.
            </div>
          </Col>
          <Col>
            <Row>
              <Col className="d-flex me-5">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Push</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Email</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">SMS</Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          className="profile-info Cerebri-sans-medium"
          style={{
            marginLeft: "36px",
            marginTop: "24px",
            marginBottom: "6px",
          }}
        >
          Documents
        </div>
        <Row className="me-5">
          <Col>
            <div className="notifaction-text" style={{ marginLeft: " 24px" }}>
              These are notifications for<br></br>
              when a document is<br></br>
              uploaded.
            </div>
          </Col>
          <Col>
            <Row>
              <Col className="d-flex me-5">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Push</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Email</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">SMS</Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          className="profile-info Cerebri-sans-medium"
          style={{
            marginLeft: "36px",
            marginTop: "24px",
            marginBottom: "6px",
          }}
        >
          Matching
        </div>
        <Row className="me-5">
          <Col>
            <div className="notifaction-text" style={{ marginLeft: " 24px" }}>
              These are notifications for<br></br>
              when you are having a match.
            </div>
          </Col>
          <Col>
            <Row>
              <Col className="d-flex me-5">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Push</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Email</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">SMS</Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          className="profile-info Cerebri-sans-medium"
          style={{
            marginLeft: "36px",
            marginTop: "24px",
            marginBottom: "6px",
          }}
        >
          Notice board
        </div>
        <Row className="me-5">
          <Col>
            <div className="notifaction-text" style={{ marginLeft: " 24px" }}>
              These are notifications for<br></br>
              when there is a new post<br></br>
              from your landlord
            </div>
          </Col>
          <Col>
            <Row>
              <Col className="d-flex me-5">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Push</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">Email</Col>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex mb-5">
                <div>
                  <ToggleSwitch />
                </div>
                <Col className="Cerebri-sans-medium">SMS</Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Notifications;
