import React, { useState } from "react";
import "../../styles/device-status.css";
import managementLogo from "../../assets/images/management-logo.svg";
import { Button } from "react-bootstrap";

const DeviceStatus = () => {
  const [approvalStatus, setApprovalStatus] = useState(false);

  //   const handleApproval = (status) => {
  //     setApprovalStatus(status);
  //   };

  return (
    <div className="main-device-approval">
      <div className="pt-5 pb-4">
        <div className="py-4">
          <img
            src={managementLogo}
            alt="management-logo"
            className="management-logo d-block m-auto"
          />
        </div>
        {/* you have approved */}
        <div className="device-approval-container py-4 px-4 mb-4">
          <div className="d-flex align-items-center justify-content-center">
            <h2 className="header-main-title ">You have approved the device</h2>
          </div>

          {/* <p>Status: {approvalStatus}</p> */}
          <div className="px-2 py-3">
            <div>Id and other info of user</div>
          </div>
          <div className=" d-flex align-items-center justify-content-end">
            <Button
              className="medium-text-14"
              color="success"
              variant="success"
              //   onClick={handleDownloadDocument}
            >
              CONTINUE
            </Button>
          </div>
        </div>

        {/* you have rejected */}
        <div className="device-approval-container py-4 px-4">
          <div className="d-flex align-items-center justify-content-center">
            <h2 className="header-main-title ">You have rejected the device</h2>
          </div>

          {/* <p>Status: {approvalStatus}</p> */}
          <div className="px-2 py-3">
            <div>All login device are logout automatically</div>
          </div>
          <div className=" d-flex align-items-center justify-content-end">
            <Button
              className="medium-text-14"
              color="success"
              variant="success"
              //   onClick={handleDownloadDocument}
            >
              CONTINUE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceStatus;
