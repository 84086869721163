import { forwardRef } from 'react'

const NormalButton = forwardRef(function ({
  type,
  width,
  backgroundColor,
  textColor,
  onClick,
  borderColor,
  buttonText,
  icon,
  border,
  onSubmit,
  fontWeight,
  fontSize,
  rotate,
  disabled,
  borderRadius,
  padding,
  iconbutton,
  bgColor,
  opacity,
  cursor,
  buttonStyle,
  fontFamily,
  bstClassName // boostrap classnames
}, ref) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type == "button" ? "button" : type}
      onSubmit={onSubmit}
      className={[
        `fs-${fontSize}`,
        `fw-${fontWeight}`,
        `radius-${borderRadius}`,
        `bg-${backgroundColor}`,
        `color-${textColor}`,
        `${bstClassName}`
        // borderColor,
      ].join(" ")}
      style={{
        opacity: disabled ? '0.5' : opacity,
        width: width,
        color: textColor,
        borderRadius: borderRadius,
        fontSize: fontSize,
        borderColor: borderColor,
        whiteSpace: "nowrap",
        alignItems: "center",
        display: "flex",
        gap: "7px",
        fontFamily: fontFamily ? fontFamily: "Cerebri sans-medium",
        border: border,
        cursor:  disabled ? "not-allowed" : cursor,
        textAlign: "center",
        padding: padding,
        background: bgColor,
        ...buttonStyle
      }}
      ref={ref}
    >
      {iconbutton ? icon : ""}
      <div>{buttonText}</div>
    </button>
  );
})

NormalButton.defaultProps = {
  // borderColor: "none",
  // fontWeight: 600,
  disabled: false,
  padding: "2px 3px",
  cursor: 'pointer',
  opacity: 'inherit',
  bstClassName: ''
};

export default NormalButton;