import React from "react";
import "../../styles/createuser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import "./StrengthMeter.css";
const PasswordValidation = (props) => {
  const pwdValidate = props.password;
  const initPwdChecker = () => {
    let pwdCheck = 0;
    let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(pwdValidate)) {
        pwdCheck += 1;
      }
    });

    // if(!pwdValidate.length) return null

    switch (pwdCheck) {
      case 0:
        return {
          strength: 0,
          val: "",
        };
      case 1:
        return {
          strength: 1,
          val: "weak",
        };
      case 2:
        return {
          strength: 2,
          val: "fair",
        };
      case 3:
        return {
          strength: 3,
          val: "good",
        };
      case 4:
        return {
          strength: 4,
          val: "strong",
        };
      default:
        return null;
    }
  };
  
    props.actions(initPwdChecker().val);
  

  const possibilityColor = {
    weak: {
      class: 'weakline',
      text: 'Your password is easily guessable.',
      icon: 'fa-solid fa-circle-exclamation',
      textColor: 'red'
    },
    fair: {
      class: 'fairline',
      text: 'Your password is easily guessable.',
      icon: 'fa-solid fa-circle-exclamation',
      textColor: 'red'
    },
    good: {
      class: 'goodline',
      text: 'Your password is strong!',
      icon: false,
      textColor: 'black'
    },
    strong: {
      class: 'goodline',
      text: 'Your password is strong!',
      icon: false,
      textColor: 'black'
    }
  }
 
  switch (props?.type) {
    case 'line':
        return (
          <div>
            <div className={`${possibilityColor[props.isStrong]?.class}`}></div>
            <div className="fs-8 mt-1" style={{ color: possibilityColor[props.isStrong]?.textColor}}>
              {possibilityColor[props.isStrong]?.icon && 
              <FontAwesomeIcon style={{ marginRight: '0.3rem'}} icon={possibilityColor[props.isStrong]?.icon} />
              }
              {possibilityColor[props.isStrong]?.text}
            </div>
          </div>
          );
    default:
    return <div style={{ marginBottom: "50px" }}>
            <div className={`${props.isStrong == "weak" ? "week" : ""}`}>
              {props.isStrong === "weak" ? "Weak" : ""}
            </div>
            <div className={`${props.isStrong == "fair" ? "fair" : ""}`}>
              {props.isStrong === "fair" ? "Medium" : ""}
            </div>
            <div className={`${props.isStrong == "strong" ? "good" : ""}`}>
              {props.isStrong === "strong" ? "Strong" : ""}
            </div>
            <div className={`${props.isStrong == "good" ? "good" : ""}`}>
              {props.isStrong === "good" ? "Strong" : ""}
            </div>
          </div>;
  }
};
export default PasswordValidation;
