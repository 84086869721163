import { Button, Col, Row } from 'react-bootstrap';
import { H2 }       from './../../../../styles/Common.style';
import GroupButtons from '../../../../Components/GroupButton/GroupButton';
import Countdown from 'react-countdown';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScreeningFaqPopup } from '../../../../hooks/stores/useScreeningFormStore';

export const OtherScreeningConfirmation = ({ getIndex, setIndex }) => {
    const setScreeningFaqPopup = useScreeningFaqPopup(
        (state) => state.setScreeningFaqPopup
    );

    return(
        <div className='h-100'>
            <div className="mx-auto pt-4" style={{ background: '#f7f8fa' }}>
                <div className="mb-0 d-flex justify-content-center align-items-center">
                    <span className="m-0 text-light opacity-75 d-flex gap-2 text-nowrap">
                    <span className="primary-text-color fs-12">Time to finish your screening:</span>
                    <Countdown
                        date={1696075241000}
                        daysInHours
                        zeroPadTime={2}
                        onComplete={() => <>Screening expired</>}
                        className="primary-text-color fs-12"
                    />
                    </span>
                </div>
            </div>
            <Row className="pt-3 mb-2 justify-content-center h-100" style={{ background: '#f7f8fa' }}>
                <Col className="px-sm-3 pt-3 pb-3 me-0 me-xl-5 mt-2 bg-white text-dark" lg={6} xxl={5} style={{ borderRadius: "24px 24px 0px 0px", borderTop: "1px solid rgba(0, 0, 0, 0.10)"}}>
                <div className = "text-center mb-3">
                        <H2>Do you want to fill in information in the screening on behalf of the other customers?</H2>
                    </div>
                    <div className = "text-center mx-auto" style = {{ maxWidth: '450px' }}>
                        <p> The questions regarding other customers that you cannot answer, the other customers will be asked to answer themselves. Just like they will also have to complete a credit check themselves.</p>
                    </div>
                    <div  className = "mt-4">
                    <div  className = "mb-2 text-start">
                    <span className = "fs-14">Do you want to fill out the information about the other customers?</span>
                        </div>
                        <Row className = "gap-3">
                            <Col className='px-0'>
                            <div className = {"mt-2"}>
                                    <GroupButtons
                                        tabs={confirmationTabs?.map((o) => {
                                        return {
                                            id      : o?.value,
                                            tabTitle: o?.text,
                                        };
                                        })}
                                        currentTab     = {getIndex?.fillingOtherCustomerScreening}
                                        handleTabClick = {(tab) => {
                                            setIndex({
                                                ...getIndex,
                                                prevIndex                    : 2,
                                                fillingOtherCustomerScreening: tab,
                                            })
                                        }}
                                    />
                                    </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Footer>
                <div>
                    <NeedHelpdiv onClick={() => setScreeningFaqPopup(true)}>
                    <FontAwesomeIcon
                        color="#BB9F83"
                        icon="fa-solid fa-circle-question"
                        />
                    </NeedHelpdiv>
                </div>
                <InnerSection>
                    <div style={{width:'80%'}}>
                    <Box sx={{display:'flex', justifyContent:'center', padding:'20px 0 15px 0'}}>
                        <Button style={{width:'30%', border:'none', backgroundColor:'#fff', borderRadius:'6px', color: '#000', fontWeight: '600' }} onClick={() => {setIndex({
                            ...getIndex,
                            prevIndex: 0
                        })}}>
                            Back
                        </Button>
                    </Box>
                    </div>
                </InnerSection>
            </Footer> 
        </div>
    )
}

const confirmationTabs = [
    {
        value: 1,
        text : 'Yes'
    },
    {
        value: 2,
        text : 'No'
    }
]


const NeedHelpdiv = styled.div`
  position: absolute;
  right: 15px;
  top: -50px;
  width: 40px;
  height: 40px;

  & svg {
    width: 100%;
    height: 100%;
  }
`

const InnerSection = styled.div`
  // height:65%;
  // width:100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
   background: #0C1356;
   border-top-right-radius: 30px;
   border-top-left-radius: 30px;
  // position: absolute;
  // bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`

const Footer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background: #ffff;
`